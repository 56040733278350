import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: '2%',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '2%',
  },
}))

const DatePickerCustom = ({ searchAction, rangeRequired = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [since, setsince] = useState()
  const [until, setuntil] = useState()

  const handleSearchTrips = () => {
    let baseDate = new Date()
    const sincePast = `${
      baseDate.getFullYear() - 100
    }-${baseDate.getMonth()}-${baseDate.getDay()}`

    const untilFuture = `${
      baseDate.getFullYear() + 100
    }-${baseDate.getMonth()}-${baseDate.getDay()}`

    if (!since && !until) searchAction({ since, until })
    if (since && !until) searchAction({ since, until: untilFuture })
    if (until && !since) searchAction({ since: sincePast, until })
    if (until && since) searchAction({ since, until })
  }

  return (
    <div className={classes.datePickerContainer}>
      <TextField
        id="date"
        label={t('shared.since')}
        type="date"
        value={since}
        onChange={(e) => setsince(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="date"
        label={t('shared.until')}
        type="date"
        value={until}
        className={classes.textField}
        onChange={(e) => setuntil(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.textField}
        onClick={() => {
          handleSearchTrips(true)
        }}
        disabled={rangeRequired && (!until || !since)}
      >
        {t('shared.search')}
      </Button>
    </div>
  )
}

export default DatePickerCustom
