import { stationsConstants } from '../../constants/stations_constants'

const initialState = {
  loadingResults: true,
  results: [],
  station: {},
  dynamics: [],
  dynamic: {},
  stationsAssignedToZone: [],
  stationsAssignedToOperator: [],
  stationsUnassigned: [],
  stationsUnassignedByZone: [],
  stationsUnassignedByZoneAndOperator: [],
  stationsUnassignedByZoneAndOperator: [],
  unassignedsToOperatorCiaByZones: [],
  deliveriesForAdmin: [],
  weekDays: [],
}
export function stationsReducer(state = initialState, action) {
  switch (action.type) {
    case stationsConstants.GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        unassignedsToOperatorCiaByZones: action.results,
      }
    case stationsConstants.GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        unassignedsToOperatorCiaByZones: [],
      }

    case stationsConstants.GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        stationsUnassignedByZoneAndOperator: action.results,
      }
    case stationsConstants.GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        stationsUnassignedByZoneAndOperator: [],
      }

    case stationsConstants.GET_UNASSIGNED_BY_ZONE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_UNASSIGNED_BY_ZONE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        stationsUnassignedByZone: action.results,
      }
    case stationsConstants.GET_UNASSIGNED_BY_ZONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        stationsUnassignedByZone: [],
      }

    case stationsConstants.GET_ALL_UNASSIGNED_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_ALL_UNASSIGNED_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        stationsUnassigned: action.results,
      }
    case stationsConstants.GET_ALL_UNASSIGNED_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        stationsUnassigned: [],
      }

    case stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        stationsAssignedToOperator: action.results,
      }
    case stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        stationsAssignedToOperator: [],
      }

    case stationsConstants.GET_STATIONS_ASSIGNED_TO_ZONE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_STATIONS_ASSIGNED_TO_ZONE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        stationsAssignedToZone: action.results,
      }
    case stationsConstants.GET_STATIONS_ASSIGNED_TO_ZONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        stationsAssignedToZone: [],
      }

    case stationsConstants.GET_ALL_DYNAMICS_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_ALL_DYNAMICS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        dynamics: action.results,
      }
    case stationsConstants.GET_ALL_DYNAMICS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        dynamics: [],
      }
    case stationsConstants.GET_DYNAMICS_BY_STATION_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_DYNAMICS_BY_STATION_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        dynamic: action.results,
      }
    case stationsConstants.GET_DYNAMICS_BY_STATION_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        dynamic: {},
      }
    case stationsConstants.GET_BY_STATION_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_BY_STATION_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        station: action.results,
      }
    case stationsConstants.GET_BY_STATION_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        station: {},
      }
    case stationsConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case stationsConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }
    case stationsConstants.GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        deliveriesForAdmin: action.results,
      }
    case stationsConstants.GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        deliveriesForAdmin: [],
      }
    case stationsConstants.GET_WEEK_DAYS_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_WEEK_DAYS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        weekDays: action.results,
      }
    case stationsConstants.GET_WEEK_DAYS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        weekDays: [],
      }
    case stationsConstants.GET_STATIONS_FOR_LOGGED_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case stationsConstants.GET_STATIONS_FOR_LOGGED_SUCCESS:
      return {
        ...state,
        results: action.results,
        loadingResults: false,
      }
    case stationsConstants.GET_STATIONS_FOR_LOGGED_FAILURE:
      return {
        ...state,
        loadingResults: false,
      }
    default:
      return state
  }
}
