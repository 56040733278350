import store from '../../../redux/store'

import { history } from '../../../helpers/history'

const currentFilters = (tab, returnSelectedRow = false) => {
  const { uiReducer } = store.getState()
  const currentLocation = history.location.pathname.split('/:')[0]

  let array = []

  const filtercurrentLocation =
    uiReducer &&
    uiReducer.filterTable.filter(
      (f) => f.location === currentLocation && f.tab === tab,
    )
  let convertToArray =
    (filtercurrentLocation[0] && filtercurrentLocation[0].filters) || []
  convertToArray.map((x) => {
    array[x.columnId] = x.value
  })

  return returnSelectedRow
    ? filtercurrentLocation[0] && filtercurrentLocation[0].selectedRow
    : array
}

export const insertToColumns = (columns, tab) => {
  const currentFilter = currentFilters(tab)
  let updatedColumns = columns.map((c, index) => {
    return { ...c, defaultFilter: currentFilter[index] }
  })
  return updatedColumns
}

export const findSelectedRow = (tab) => {
  return currentFilters(tab, true)
}
