import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { batteriesActions } from '../../../redux/actions/batteries_actions'

const ChangeDeliveryPoint = ({
  isOpen,
  onClose,
  deliveryPoints,
  batteryData,
  assignToStation,
}) => {
  const { t } = useTranslation()

  const [selectedDelivery, setSelectedDelivery] = useState({})
  const [errors, setErrors] = useState({
    deliveryStation: { result: false, message: '' },
  })

  useEffect(() => {
    if (batteryData) {
      setSelectedDelivery(getActualDeliveryPoint())
    }
  }, [batteryData])

  const handleClose = () => {
    setErrors({ deliveryStation: { result: false, message: '' } })
    setSelectedDelivery(getActualDeliveryPoint())
    onClose()
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.deliveryStation = validations.required(selectedDelivery)
    setErrors({ ...newErrors })
  }

  const handleSubmit = () => {
    validateForm()
    if (isFormValid()) {
      const body = {
        batteries: [batteryData.id],
        stationId: selectedDelivery.id,
      }
      assignToStation(JSON.stringify(body))
      handleClose()
    }
  }

  const { batteryPackId } = batteryData

  const getActualDeliveryPoint = () =>
    deliveryPoints?.find(
      (deliveryPoint) =>
        deliveryPoint.id === batteryData?.batteryStation?.deliveryStationId,
    ) ?? ''

  const orderSelectedDeliveryPoints = () => {
    const actualDeliveryPoint = getActualDeliveryPoint()

    return actualDeliveryPoint
      ? [
          actualDeliveryPoint,
          ...deliveryPoints.filter(
            (delivery) => delivery.id !== actualDeliveryPoint.id,
          ),
        ]
      : [...deliveryPoints]
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        {t(`batteries.page.table.actions.changeDelivery`)}( {batteryPackId} ){' '}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple={false}
          options={orderSelectedDeliveryPoints()}
          getOptionLabel={(option) =>
            option.id === getActualDeliveryPoint().id
              ? `${option.name} (Actual)`
              : option.name
          }
          onChange={(event, value) => setSelectedDelivery(value)}
          value={selectedDelivery}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('batteries.editDeliveryPoint.selectDelivery')}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.deliveryStation.message.length === 0 ? false : true}
              helperText={errors.deliveryStation.message}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {' '}
          {t('batteries.editDeliveryPoint.cancel')}{' '}
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          {' '}
          {t('batteries.editDeliveryPoint.accept')}{' '}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const actionCreators = {
  assignToStation: batteriesActions.assignToStation,
}

export default connect(null, actionCreators)(ChangeDeliveryPoint)
