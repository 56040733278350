import { operatorUserConstant } from '../../constants/operatorUser_constants'

const initialState = {
  loadingResults: true,
  results: [],
  operatorUser: {},
}

export function operatorUserReducer(state = initialState, action) {
  switch (action.type) {
    case operatorUserConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case operatorUserConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case operatorUserConstant.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }

    default:
      return state
  }
}
