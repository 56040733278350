import React, { useEffect, useState } from 'react'

import useStyles from './style'

import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { batteriesActions } from '../../redux/actions/batteries_actions'
import { supervisorsActions } from '../../redux/actions/supervisor_action'

import { connect } from 'react-redux'

import { getOperatorId } from '../../helpers/operator'

const DATA_DUMMY_KWH = [...Array(30).keys()]
  .map((i) => i + 1)
  .map((e) => {
    return {
      name: ` DAY - ${e}`,
      pastMonth: Math.random() * e,
      currentMonth: Math.random() * e,
      amt: Math.random() * e,
    }
  })

const PageOperator = ({
  zonesReducer,
  operatorReducer,
  batteriesReducer,
  getBatteriesAssignedToOperator,
  getAllsupervisors,
  supervisorsReducer,
  getSupervisorByOperatorId,
}) => {
  const classes = useStyles()
  const { operator } = operatorReducer
  const [chartsItems, setchartsItems] = useState([
    { item: 'pastMonth', color: '#8884d8', show: true },
    { item: 'currentMonth', color: '#82ca9d', show: true },
  ])

  useEffect(() => {
    getBatteriesAssignedToOperator(getOperatorId())
    getSupervisorByOperatorId()
  }, [])

  const handleClickLegendItem = (e) => {
    const { dataKey } = e
    let updatedchartsItems = chartsItems.map((i) => {
      return i.item === dataKey ? { ...i, show: !i.show } : i
    })
    setchartsItems(updatedchartsItems)
  }

  return (
    <Grid container spacing={2} className={classes.contentPage}>
      <Grid item md={12}>
        <Autocomplete
          options={[]}
          getOptionLabel={(option) => option.title}
          style={{ width: 300, marginBottom: '2%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Zonas"
              // variant="outlined"
            />
          )}
        />
      </Grid>
      <div className={classes.cardContainer}>
        <Grid item md={4} className={classes.cardData}>
          <p>Total estaciones de carga</p>
          <p>{operator && operator.zones.length}</p>
          <Link>More</Link>
        </Grid>
        <Grid item md={4} className={classes.cardData}>
          <p>Total baterías asignadas</p>
          <p>{batteriesReducer.batteriesAssignedToOperator.length}</p>
          <Link>More</Link>
        </Grid>
        <Grid item md={4} className={classes.cardData}>
          <p>Total encargados creados</p>
          <p>{supervisorsReducer.results.length}</p>
          <Link>More</Link>
        </Grid>
      </div>
      <div className={classes.cardContainer} style={{ marginTop: '3%' }}>
        <Grid item md={4} className={classes.cardData}>
          <p>Total baterías con incidencias</p>
          <p>tbd</p>
          <Link>More</Link>
        </Grid>
        <Grid item md={4} className={classes.cardData}>
          <p>Total facturado mes pasado</p>
          <p>tbd</p>
          <Link>More</Link>
        </Grid>
        <Grid item md={4} className={classes.cardData}>
          <p>Estimación factura mes actual baterías</p>
          <p>tbd</p>
          <Link>More</Link>
        </Grid>
      </div>

      <Grid item md={12}>
        <p>kWh entregados</p>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <AreaChart
              width={730}
              height={250}
              data={DATA_DUMMY_KWH}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              {chartsItems.map((i) => {
                return (
                  <Area
                    type="monotone"
                    dataKey={i.item}
                    stroke={i.show ? i.color : '#c7c7c791'}
                    fillOpacity={i.show ? 1 : 0}
                    strokeWidth={i.show ? 2 : 0.5}
                    fill={
                      i.item === 'pastMonth' ? 'url(#colorUv)' : 'url(#colorPv)'
                    }
                  />
                )
              })}
              <Legend
                onClick={handleClickLegendItem}
                wrapperStyle={{ cursor: 'pointer' }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Grid>
    </Grid>
  )
}

function mapState(state) {
  const {
    operatorReducer,
    zonesReducer,
    batteriesReducer,
    supervisorsReducer,
  } = state
  return {
    operatorReducer,
    zonesReducer,
    batteriesReducer,
    supervisorsReducer,
  }
}
const actionCreators = {
  getBatteriesAssignedToOperator:
    batteriesActions.getBatteriesAssignedToOperator,
  getSupervisorByOperatorId: supervisorsActions.getSupervisorByOperatorId,
}

export default connect(mapState, actionCreators)(PageOperator)
