export const operatorConstant = {
  GET_ALL_REQUEST: 'OPERATOR_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'OPERATOR_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'OPERATOR_GET_ALL_FAILURE',

  GET_BY_ID_REQUEST: 'OPERATOR_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'OPERATOR_GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'OPERATOR_GET_BY_ID_FAILURE',

  GET_OPERATORS_ASSIGNED_TO_ZONE_REQUEST:
    'OPERATOR_GET_OPERATORS_ASSIGNED_TO_ZONE_REQUEST',
  GET_OPERATORS_ASSIGNED_TO_ZONE_SUCCESS:
    'OPERATOR_GET_OPERATORS_ASSIGNED_TO_ZONE_SUCCESS',
  GET_OPERATORS_ASSIGNED_TO_ZONE_FAILURE:
    'OPERATOR_GET_OPERATORS_ASSIGNED_TO_ZONE_FAILURE',

  GET_OPERATORS_PENDING_VALIDATE_REQUEST:
    'OPERATOR_GET_OPERATORS_PENDING_VALIDATE_REQUEST',
  GET_OPERATORS_PENDING_VALIDATE_SUCCESS:
    'OPERATOR_GET_OPERATORS_PENDING_VALIDATE_SUCCESS',
  GET_OPERATORS_PENDING_VALIDATE_FAILURE:
    'OPERATOR_GET_OPERATORS_PENDING_VALIDATE_FAILURE',
}
