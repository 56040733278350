import { combineReducers } from 'redux'

import { accountUserReducer } from './accountUser_reducer'
import { alertReducer } from './alert_reducer'
import { authenticationReducer } from './authentication_reducer'
import { batteriesReducer } from './batteries_reducer'
import { billingReducer } from './billing_reducer'
import { customerReducer } from './customer_reducer'
import { devolutionsReducer } from './devolution_reducer'
import { discountsReducer } from './discounts_reducer'
import { documentsReducer } from './documents_reducer'
import { incidentsReducer } from './incidents_reducer'
import { insurancesReducer } from './insurances_reducer'
import { operatorUserReducer } from './operatorUser_reducer'
import { operatorReducer } from './operator_reducer'
import { productReducer } from './product_reducer'
import { ratesReducer } from './rate_reducer'
import { servicesReducer } from './services_reducer'
import { stationsReducer } from './stations_reducer'
import { subscriptionsReducer } from './subscriptions_reducer'
import { supervisorsReducer } from './supervisors_reducer'
import { swapsReducer } from './swaps_reducer'
import { techniciansReducer } from './technicians_reducer'
import { uiReducer } from './ui_reducer'
import { userReducer } from './user_reducer'
import { zonesReducer } from './zones_reducer'

const reducer = combineReducers({
  userReducer,
  alertReducer,
  authenticationReducer,
  customerReducer,
  operatorReducer,
  servicesReducer,
  uiReducer,
  stationsReducer,
  zonesReducer,
  batteriesReducer,
  ratesReducer,
  supervisorsReducer,
  techniciansReducer,
  subscriptionsReducer,
  operatorUserReducer,
  discountsReducer,
  incidentsReducer,
  swapsReducer,
  documentsReducer,
  billingReducer,
  accountUserReducer,
  productReducer,
  devolutionsReducer,
  insurancesReducer,
})

export default reducer
