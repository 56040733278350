import React from 'react'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

import BatteriesAssignedToZones from '../../batteries/assignedToZones'
import OperatorsZone from '../../operator/byZones/operatorsZone'
import RatesZone from '../../rates/byZones/ratesZone'
import AssignedToZones from '../../stations/assignedToZones'

import { connect } from 'react-redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { operatorActions } from '../../../redux/actions/operator_action'
import { ratesActions } from '../../../redux/actions/rates_actions'
import { stationsActions } from '../../../redux/actions/stations_actions'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Details = ({
  isOpen,
  onClose,
  stationsReducer,
  getStationsAssignedToZone,
  selectedData,
  getBatteriesAssignedToZone,
  batteriesReducer,
  getOperatorsAssignedToZone,
  operatorReducer,
  getByZone,
  ratesReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const handleClose = () => {
    setExpanded('')
    onClose()
  }
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleEnter = () => {
    const { id } = selectedData
    getStationsAssignedToZone(id)
    getBatteriesAssignedToZone(id)
    getOperatorsAssignedToZone(id)
    getByZone(id)
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onEnter={handleEnter}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('zones.dialogs.details.dialogTitle')} ({selectedData.name})
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Accordion
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>
                {t('zones.dialogs.details.tabs.stationList')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AssignedToZones
                addTitle={false}
                readOnlyConfiguration={true}
                data={stationsReducer.stationsAssignedToZone}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                {t('zones.dialogs.details.tabs.batteriesList')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BatteriesAssignedToZones
                addTitle={false}
                readOnlyConfiguration={true}
                data={batteriesReducer.batteriesAssignedToZone}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>
                {t('zones.dialogs.details.tabs.operatorList')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OperatorsZone
                data={operatorReducer.operatorsAssignedToZone}
                addTitle={false}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>
                {t('zones.dialogs.details.tabs.rateList')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RatesZone data={ratesReducer.ratesByZone} addTitle={false} />
            </AccordionDetails>
          </Accordion>
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { stationsReducer, batteriesReducer, operatorReducer, ratesReducer } =
    state
  return { stationsReducer, batteriesReducer, operatorReducer, ratesReducer }
}

const actionCreators = {
  getStationsAssignedToZone: stationsActions.getStationsAssignedToZone,
  getBatteriesAssignedToZone: batteriesActions.getBatteriesAssignedToZone,
  getOperatorsAssignedToZone: operatorActions.getOperatorsAssignedToZone,
  getByZone: ratesActions.getByZone,
}

export default connect(mapState, actionCreators)(Details)
