import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useModal } from '../../../hooks/useModal'
import { alertActions } from '../../../redux/actions/alert_actions'
import { subscriptionsService } from '../../../services/subscriptionsServices'
import ConfirmationModal from '../../shared/modals/ConfirmationModal'
import useStyles from '../styles'

const Subrogate = ({ isOpen, onClose, selectedSubscription, onSubrogate }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    open: openConfirmationDialog,
    close: closeConfirmationDialog,
    isOpen: isConfirmationDialogOpen,
  } = useModal()

  const [targetEmail, setTargetEmail] = useState(null)

  const [errors, setErrors] = useState({
    userEmail: { result: true, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const handleClose = () => {
    setTargetEmail(null)

    setErrors({
      userEmail: {
        result: targetEmail != null,
        message:
          targetEmail != null
            ? t('subscriptions.dialogs.subrogate.validations.userEmailRequired')
            : '',
      },
    })

    if (onClose) onClose()
  }

  const handleCreateSubscription = () => {
    validateForm()

    if (isFormValid()) {
      openConfirmationDialog()
    }
  }

  const handleConfirmCreateSubscription = () => {
    subscriptionsService
      .subrogate(selectedSubscription, targetEmail)
      .then(() => {
        dispatch(
          alertActions.success(t('subscriptions.dialogs.subrogate.success')),
        )
        onSubrogate()
      })
      .catch(() => {
        dispatch(alertActions.error(t('subscriptions.dialogs.subrogate.error')))
      })
    closeConfirmationDialog()
    handleClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('subscriptions.dialogs.subrogate.dialogTitle')}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleCreateSubscription}
            >
              {t('subscriptions.dialogs.subrogate.confirmBtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('subscriptions.dialogs.subrogate.label.userEmail')}
                  value={targetEmail}
                  onChange={(e) => {
                    setTargetEmail(e.target.value)
                    errors.userEmail.message = ''
                    setErrors(errors)
                  }}
                  error={errors.userEmail.message.length === 0 ? false : true}
                  helperText={errors.userEmail.message}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        title={t('subscriptions.dialogs.confirmSubrogate.title')}
        message={t('subscriptions.dialogs.confirmSubrogate.message')}
        open={isConfirmationDialogOpen}
        onCancel={closeConfirmationDialog}
        onConfirm={handleConfirmCreateSubscription}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography
              variant="caption"
              display="block"
              style={{
                fontWeight: 'bold',
                color: 'rgba(0, 0, 0, 0.8)',
              }}
            >
              {t('subscriptions.dialogs.subrogate.label.userEmail')}
            </Typography>
            <Typography>{targetEmail}</Typography>
          </Grid>
        </Grid>
      </ConfirmationModal>
    </>
  )
}

function mapState(state) {
  return {}
}

const actionCreators = {}

export default connect(mapState, actionCreators)(Subrogate)
