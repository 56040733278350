import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import i18next from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { alertActions } from '../../../redux/actions/alert_actions'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { batteriesService } from '../../../services/batteriesServices'
import BatteriesInsertedModal from './batteriesInsertedModal'

const InsertBatteryModal = ({
  isOpen,
  onClose,
  stationId,
  batteriesReducer,
  row,
  getBatteries,
  createBatteryInsertion,
}) => {
  const [batteries, setBatteries] = useState([])
  const [insertionMessages, setInsertionMessages] = useState([])
  const [isInsertionMessagesModalOpen, setIsInsertionMessagesModalOpen] =
    useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClose = () => {
    setBatteries([])
    onClose()
  }

  const handleBatteryInsertion = () => {
    let batteryPackIds = []
    batteries.forEach((battery) => batteryPackIds.push(battery.batteryPackId))
    const body = {
      stationId,
      rowImei: row[0].imei,
      expiresInMinutes: 10,
      batteryPackIds,
    }

    batteriesService.createBatteryInsertion(JSON.stringify(body)).then(
      (result) => {
        const { feedback } = result
        setIsInsertionMessagesModalOpen(true)
        setInsertionMessages(feedback)
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.batteries.createBatteryInsertionError'),
          ),
        )
      },
    )

    handleClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onEnter={() => getBatteries(stationId)}
      >
        <DialogTitle>
          {t('station.dialogs.insertBatteries.title')}
          {onClose && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={batteriesReducer.batteriesDeliverableInStation}
                getOptionLabel={(option) => String(option.batteryPackId)}
                value={batteries}
                onChange={(event, value) => setBatteries(value)}
                renderInput={(params) => (
                  <TextField
                    style={{ width: 500 }}
                    {...params}
                    label="BatteryPackId"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="small"
            onClick={handleBatteryInsertion}
            disabled={batteries.length < 1}
          >
            {t('station.dialogs.insertBatteries.insert')}
          </Button>
        </DialogActions>
      </Dialog>
      <BatteriesInsertedModal
        isOpen={isInsertionMessagesModalOpen}
        messages={insertionMessages}
        onClose={() => setIsInsertionMessagesModalOpen(false)}
      />
    </>
  )
}

function mapState(state) {
  const { batteriesReducer } = state
  return { batteriesReducer }
}

const actionCreators = {
  getBatteries: batteriesActions.getDeliverablesInStation,
  createBatteryInsertion: batteriesActions.createBatteryInsertion,
}

export default connect(mapState, actionCreators)(InsertBatteryModal)
