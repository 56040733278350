import { handleCall } from './handleResponse'

export const documentsService = {
  getByCustomerId,
}

function getByCustomerId(customerId) {
  const filter = `?field=CustomerId&value=${customerId}`
  return handleCall(`/stations/v1/Documents${filter}`, {
    method: 'GET',
    body: null,
  })
}
