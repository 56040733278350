import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'
import BatteriesAssignedToOperators from '../../batteries/assignedToZones'
import AssignedToOperators from '../../stations/assignedToZones'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    // display: "flex",
    padding: '2%',
  },
  dialogContainer: {
    padding: '1%',
  },

  tabs: {
    marginTop: '4%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const NewZone = ({
  isOpen,
  onClose,
  batteriesReducer,
  stationsReducer,
  getAllUnassignedBatteries,
  getAllUnassignedStations,
  createZone,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const [name, setname] = useState('')
  const [country, setcountry] = useState('')
  const [stationBasePrice, setstationBasePrice] = useState('')
  const [batteryBasePrice, setbatteryBasePrice] = useState('')
  const [chargeMin, setchargeMin] = useState('')
  const [priceByKwhChargeBattery, setpriceByKwhChargeBattery] = useState('')
  const [
    priceByKwhDeliveryMaxFromCustomer,
    setpriceByKwhDeliveryMaxFromCustomer,
  ] = useState('')
  const [currency, setcurrency] = useState('')
  const [selectedBatteries, setselectedBatteries] = useState([])
  const [selectedStations, setselectedStations] = useState([])

  const [expanded, setExpanded] = React.useState('panel1')

  const [errors, setErrors] = React.useState({
    name: { result: false, message: '' },
    currency: { result: false, message: '' },
    country: { result: false, message: '' },
    chargeMin: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.name = validations.required(name)
    newErrors.currency = validations.required(currency)
    newErrors.country = validations.required(country)
    newErrors.chargeMin = validations.required(chargeMin)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    getAllUnassignedBatteries()
    getAllUnassignedStations()
  }, [])

  const handleClose = () => {
    onClose()
    setname('')
    setcountry('')
    setchargeMin('')
    setcurrency('')
    setselectedBatteries([])
    setselectedStations([])
    setErrors({
      name: { result: false, message: '' },
      currency: { result: false, message: '' },
      country: { result: false, message: '' },
      chargeMin: { result: false, message: '' },
    })
  }

  const handleBatteriesSelectedUpdate = (rows) => {
    setselectedBatteries(rows)
  }

  const handleStationsSelectedUpdate = (rows) => {
    setselectedStations(rows)
  }

  const handleCreateZone = () => {
    const body = {
      name,
      country,
      currency,
      centerLatitude: 0,
      centerLongitude: 0,
      batterySocMinToDeliver: parseFloat(chargeMin),
    }
    validateForm()
    if (isFormValid()) {
      createZone(body, {
        selectedBatteries: selectedBatteries.map((s) => s.id),
        selectedStations: selectedStations.map((s) => s.id),
      })
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('zones.dialogs.new.dialogTitle')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateZone}>
              {t('zones.dialogs.new.createBtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.dialogContainer}>
          <div className={classes.details}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  label={t('zones.dialogs.new.labels.name')}
                  fullWidth
                  value={name}
                  error={errors.name.message.length === 0 ? false : true}
                  helperText={errors.name.message}
                  onChange={(e) => {
                    setname(e.target.value)
                    errors.name.message = ''
                    setErrors(errors)
                  }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                {' '}
                <TextField
                  select
                  fullWidth
                  label={t('zones.dialogs.new.labels.currency')}
                  value={currency}
                  error={errors.currency.message.length === 0 ? false : true}
                  helperText={errors.currency.message}
                  onChange={(e) => {
                    setcurrency(e.target.value)
                    errors.currency.message = ''
                    setErrors(errors)
                  }}
                >
                  {[
                    {
                      value: 'eur',
                      label: 'EUR',
                    },
                    {
                      value: 'usd',
                      label: 'USD',
                    },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label={t('zones.dialogs.new.labels.country')}
                  fullWidth
                  error={errors.country.message.length === 0 ? false : true}
                  helperText={errors.country.message}
                  value={country}
                  onChange={(e) => {
                    setcountry(e.target.value)
                    errors.country.message = ''
                    setErrors(errors)
                  }}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={t('zones.dialogs.new.labels.socDeliveryMin')}
                  fullWidth
                  error={errors.chargeMin.message.length === 0 ? false : true}
                  helperText={errors.chargeMin.message}
                  value={chargeMin}
                  onChange={(e) => {
                    setchargeMin(e.target.value)
                    errors.chargeMin.message = ''
                    setErrors(errors)
                  }}
                  type="number"
                  required
                />
              </Grid>
            </Grid>
            <div className={classes.tabs}>
              <p>{t('operator.dialogs.create.infoText')}</p>
              <Accordion
                square
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>
                    {t('operator.dialogs.create.tabs.batteries')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <BatteriesAssignedToOperators
                    addTitle={false}
                    data={
                      batteriesReducer.batteriesUnassignedByZoneAndOperatorCia
                    }
                    onSelectionUpdate={handleBatteriesSelectedUpdate}
                    preSelectedRows={selectedBatteries}
                    tableWithSelectAvaiable={true}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                square
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>
                    {t('operator.dialogs.create.tabs.stations')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AssignedToOperators
                    addTitle={false}
                    data={stationsReducer.stationsUnassignedByZoneAndOperator}
                    onSelectionUpdate={handleStationsSelectedUpdate}
                    preSelectedRows={selectedStations}
                    tableWithSelectAvaiable={true}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { batteriesReducer, stationsReducer } = state
  return { batteriesReducer, stationsReducer }
}
const actionCreators = {
  getAllUnassignedBatteries:
    batteriesActions.getAllUnassignedZonesAndOperatorCia,
  getAllUnassignedStations: stationsActions.getAllUnassignedZonesAndOperatorCia,
  createZone: zonesActions.createZone,
}

export default connect(mapState, actionCreators)(NewZone)
