import React from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'

import SwapSection from './usage/swaps'

import { connect } from 'react-redux'
import { stationsActions } from '../../redux/actions/stations_actions'

const SwapsView = ({ getActiveManualSwapsForLogged }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return <SwapSection />
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}

const actionCreators = {
  getAllStations: stationsActions.getAllStations,
  getStationById: stationsActions.getStationById,
  createStation: stationsActions.createStation,
}

export default connect(mapState, actionCreators)(SwapsView)
