import React from 'react'

import GoogleMapReact from 'google-map-react'

import stringConstants from '../../../constants/strings'

const AppUrl = process.env.PUBLIC_URL

const Marker = ({ children }) => children
const markerStyle = {
  marginTop: '-34px',
  marginLeft: '-17px',
}

const MapDetails = ({
  location = { latitude: 0, longitude: 0 },
  height = '20vh',
}) => {
  const { latitude, longitude } = location

  return (
    <div style={{ height: height, width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: stringConstants.GOOGLE_API_KEY,
          libraries: ['drawing'],
        }}
        defaultCenter={{ lat: latitude, lng: longitude }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
      >
        <Marker lat={latitude} lng={longitude}>
          <div className="station-marker" style={markerStyle}>
            <img
              style={{ width: '35px', cursor: 'pointer' }}
              src={`${AppUrl}/content/icons/slot.png`}
              alt="station icon"
            />
          </div>
        </Marker>
      </GoogleMapReact>
    </div>
  )
}
export default MapDetails
