export const subscriptionConstants = {
  GET_ALL_REQUEST: 'SUBSCRIPTIONS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'SUBSCRIPTIONS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'SUBSCRIPTIONS_GET_ALL_FAILURE',

  GET_BY_SUBSCRIPTION_ID_REQUEST:
    'SUBSCRIPTIONS_GET_BY_SUBSCRIPTION_ID_REQUEST',
  GET_BY_SUBSCRIPTION_ID_SUCCESS:
    'SUBSCRIPTIONS_GET_BY_SUBSCRIPTION_ID_SUCCESS',
  GET_BY_SUBSCRIPTION_ID_FAILURE:
    'SUBSCRIPTIONS_GET_BY_SUBSCRIPTION_ID_FAILURE',

  GET_CONTRACT_REQUEST: 'SUBSCRIPTIONS_GET_CONTRACT_REQUEST',
  GET_CONTRACT_FAILURE: 'SUBSCRIPTIONS_GET_CONTRACT_FAILURE',
  GET_CONTRACT_SUCCESS: 'SUBSCRIPTIONS_GET_CONTRACT_SUCCESS',
}
