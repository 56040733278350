export const incidentsConstants = {
  GET_ALL_REQUEST: 'INCIDENTS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'INCIDENTS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'INCIDENTS_GET_ALL_FAILURE',

  GET_SWAPS_MANUAL_REQUEST: 'INCIDENTS_GET_SWAPS_MANUAL_REQUEST',
  GET_SWAPS_MANUAL_SUCCESS: 'INCIDENTS_GET_SWAPS_MANUAL_SUCCESS',
  GET_SWAPS_MANUAL_FAILURE: 'INCIDENTS_GET_SWAPS_MANUAL_FAILURE',

  GET_BY_ID_REQUEST: 'INCIDENTS_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'INCIDENTS_GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'INCIDENTS_GET_BY_ID_FAILURE',
}
