import React, { useEffect, useState } from 'react'

import { TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Popover from '@material-ui/core/Popover'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import SortIcon from '@material-ui/icons/Sort'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  mainContentPopover: {
    padding: '5%',
    display: 'flex',
  },
}))

export default function SimplePopover({ onActionChanges, onFilterChanged }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const [operator, setoperator] = useState()
  const [value, setvalue] = useState()
  const [textFieldValue, settextFieldValue] = useState('')

  useEffect(() => {
    operator && value && settextFieldValue(`${operator} ${value}`)
  }, [operator, value])

  const handleBackDropClick = () => {
    if (operator && value) {
      onActionChanges({ operator, value })
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <TextField
        value={textFieldValue}
        onClick={handleClick}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <SortIcon />
            </InputAdornment>
          ),
        }}
      />

      <Popover
        onBackdropClick={handleBackDropClick}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'bottom',
        }}
        transformOrigin={{
          vertical: 'left',
          horizontal: 'left',
        }}
      >
        <div className={classes.mainContentPopover}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="age-native-simple">Operators</InputLabel>
            <Select
              native
              value={operator}
              onChange={(e) => setoperator(e.target.value)}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <option value={'='}>{'='}</option>
              <option value={'<'}>{'<'}</option>
              <option value={'>'}>{'>'}</option>
            </Select>
          </FormControl>

          <TextField
            id="standard-basic"
            label="Value"
            fullWidth
            value={value}
            onChange={(e) => setvalue(e.target.value)}
            style={{ marginLeft: '5%' }}
            type={'number'}
          />

          <IconButton
            aria-label="delete"
            onClick={() => onActionChanges({ clear: true })}
          >
            <ClearIcon fontSize="large" />
          </IconButton>
        </div>
      </Popover>
    </div>
  )
}
