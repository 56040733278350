import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect, useSelector } from 'react-redux'
import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { ratesActions } from '../../redux/actions/rates_actions'
import { zonesActions } from '../../redux/actions/zones_actions'

import CreateRate from './dialogs/Create.dialog'
import UpdateRate from './dialogs/Update.dialog'

import { formatDateToDisplay } from '../shared/formatDate'

import { isCauUser, isManager, isSacUser } from '../../helpers/roles'
import { productActions } from '../../redux/actions/products_actions'
import ModalConfirm from '../shared/modalconfirm'

import { useDialog } from '../../hooks'

const RatesView = ({
  getAllRate,
  ratesReducer,
  deleteRate,
  zonesReducer,
  getAllZones,
  getAllProducts,
  productReducer,
}) => {
  const { t } = useTranslation()

  const { results: rates } = useSelector((state) => state.ratesReducer)
  const { results: zones } = useSelector((state) => state.zonesReducer)

  const {
    open: openCreateRateDialog,
    close: closeCreateRateDialog,
    isOpen: isCreateRateDialogOpen,
  } = useDialog()

  const {
    open: openUpdateRateDialog,
    close: closeUpdateRateDialog,
    isOpen: isUpdateRateDialogOpen,
  } = useDialog()

  const {
    open: openDeleteRateDialog,
    close: closeDeleteRateDialog,
    isOpen: isDeleteRateDialogOpen,
  } = useDialog()

  const [selectedRate, setSelectedRate] = useState(null)

  function handleAction(rate, action) {
    setSelectedRate(rate)

    switch (action) {
      case 'remove':
        openDeleteRateDialog()
        break
      case 'edit':
        openUpdateRateDialog()
        break
      default:
        break
    }
  }

  function renderZoneName(zoneId) {
    return zones.find((zone) => zone.id === zoneId)?.name
  }

  useEffect(() => {
    getAllRate()
    getAllZones()
    getAllProducts()
  }, [getAllProducts, getAllRate, getAllZones])

  return (
    <>
      <CustomMaterialTable
        addSummaryRow={true}
        isLoading={ratesReducer.loadingResults || zonesReducer.loadingResults}
        title={t('rates.table.title')}
        columns={[
          {
            title: t('rates.table.columns.zone'),
            render: (rate) => renderZoneName(rate.zoneId),
          },
          {
            title: t('rates.table.columns.ratesName'),
            field: 'name',
          },
          {
            title: t('rates.table.columns.permanenceMonths'),
            tooltip: t('rates.table.tooltips.permanenceMonths'),
            field: 'permanenceMonths',
          },
          {
            title: t('rates.table.columns.swapMarginMinutes'),
            tooltip: t('rates.table.tooltips.swapMarginMinutes'),
            field: 'swapMarginMinutes',
          },
          {
            title: t('rates.table.columns.isVisible'),
            tooltip: t('rates.table.tooltips.isVisible'),
            field: 'isVisibleInApp',
            lookup: {
              0: t('swaps.usage.labels.yes'),
              1: t('swaps.usage.labels.no'),
            },
            customFilterAndSearch: (term, rate) =>
              handleTrueFalsyTermSwitch(term, rate.isVisibleInApp),
            render: (rate) => (rate.isVisibleInApp ? t('Yes') : t('No')),
          },
          {
            title: t('rates.table.columns.startDate'),
            tooltip: t('rates.table.tooltips.startdate'),
            field: 'applySince',
            render: (rate) => formatDateToDisplay(rate.applySince),
          },
          {
            title: t('rates.table.columns.finishDate'),
            tooltip: t('rates.table.tooltips.finishDate'),
            field: 'applyUntil',
            render: (rate) => formatDateToDisplay(rate.applyUntil),
          },
        ]}
        summaryFields={[
          'priceBaseByBatteryFromCustomer',
          'freeMonthlyKwhsDeliveryByBattery',
        ]}
        data={rates}
        actions={[
          {
            icon: 'add',
            isFreeAction: true,
            tooltip: t('rates.table.actions.add'),
            onClick: openCreateRateDialog,
            hidden: isSacUser() || isCauUser(),
          },
          {
            icon: 'edit',
            tooltip: t('rates.table.actions.edit'),
            onClick: (_, rate) => handleAction(rate, 'edit'),
            hidden: !isManager() || isCauUser(),
          },
          {
            icon: 'delete',
            tooltip: 'Remove',
            onClick: (_, rate) => handleAction(rate, 'remove'),
            hidden: !isManager() || isCauUser(),
          },
        ]}
        exportButton={!isCauUser()}
      />

      <CreateRate
        isOpen={isCreateRateDialogOpen}
        productReducer={productReducer}
        onClose={closeCreateRateDialog}
      />

      <UpdateRate
        isOpen={isUpdateRateDialogOpen}
        productReducer={productReducer}
        onClose={closeUpdateRateDialog}
        selected={selectedRate}
      />

      <ModalConfirm
        isOpen={isDeleteRateDialogOpen}
        onClose={closeDeleteRateDialog}
        onConfirm={() => deleteRate(selectedRate.id)}
        title={`Eliminar tarifa seleccionada (${
          selectedRate && selectedRate.name
        })`}
        body={'Confirmación: ¿Quieres eliminar la tarifa seleccionada?'}
      />
    </>
  )
}

function mapState(state) {
  const { ratesReducer, zonesReducer, productReducer } = state
  return { ratesReducer, zonesReducer, productReducer }
}

const actionCreators = {
  getAllRate: ratesActions.getAllRate,
  deleteRate: ratesActions.deleteRate,
  getAllZones: zonesActions.getAllZones,
  getAllProducts: productActions.getAll,
}

export default connect(mapState, actionCreators)(RatesView)
