import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'
import ModalConfirm from '../shared/modalconfirm'
import AssignmentBatteries from './dialogs/assignmentBatteries'
import AssignmentStations from './dialogs/assignmentStations'
import Details from './dialogs/details'
import NewZone from './dialogs/new'
import UpdateZone from './dialogs/update'

import { connect } from 'react-redux'
import { zonesActions } from '../../redux/actions/zones_actions'

const ZonesView = ({
  getAllZones,
  getZoneById,
  zonesReducer,
  updateZone,
  deleteZone,
}) => {
  const { t } = useTranslation()
  const [dialogNewIsOpen, setdialogNewIsOpen] = useState(false)
  const [dialogUpdateIsOpen, setdialogUpdateIsOpen] = useState(false)
  const [dialogDetailsIsOpen, setdialogDetailsIsOpen] = useState(false)
  const [dialogDeleteZoneIsOpen, setdialogDeleteZoneIsOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState({})
  const [isDialogAssignmentBatteriesOpen, setisDialogAssignmentBatteriesOpen] =
    useState(false)
  const [isDialogAssignmentStationsOpen, setisDialogAssignmentStationsOpen] =
    useState(false)

  useEffect(() => {
    getAllZones()
  }, [])

  const handleAction = (rowData, action) => {
    rowData && setselectedRow(rowData)
    switch (action) {
      case 'details':
        setdialogDetailsIsOpen(true)
        break
      case 'assignmentBatteries':
        setisDialogAssignmentBatteriesOpen(true)
        break

      case 'assignmentStations':
        setisDialogAssignmentStationsOpen(true)
        break
      case 'add':
        setdialogNewIsOpen(true)
        break

      case 'delete':
        setdialogDeleteZoneIsOpen(true)
        break
      default:
        break
    }
  }

  const handleUpdateZone = (newData, oldData) => {
    const { country, centerPoint } = newData
    const { latitude, longitude } = centerPoint || {
      latitude: 0,
      longitude: 0,
    }

    const bodyUpdate = {
      id: newData.id,
      name: newData.name,
      country,
      centerLatitude: latitude || 0,
      centerLongitude: longitude || 0,
      currency: newData.currency,
      priceBaseByStationToOperator: parseFloat(
        newData.priceBaseByStationToOperator,
      ),
      priceBaseByBatteryToOperador: parseFloat(
        newData.priceBaseByBatteryToOperador,
      ),
      priceByKwhDeliveryMaxFromCustomer: parseFloat(
        newData.priceByKwhDeliveryMaxFromCustomer,
      ),
      priceByKwhChargeBattery: parseFloat(newData.priceByKwhChargeBattery),
      batterySocMinToDeliver: parseFloat(newData.batterySocMinToDeliver),
      outOfService: newData.outOfService,
    }

    updateZone(bodyUpdate)
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        saveFilters={true}
        title={t('zones.page.table.title')}
        isLoading={zonesReducer.loadingResults}
        columns={[
          {
            field: 'name',
            title: t('zones.page.table.columns.name'),
            editable: 'never',
          },
          /* {
                        field: "priceBaseByStationToOperator",
                        title: t("zones.page.table.columns.stationBasePrice"),
                        tooltip: t("zones.page.table.tooltips.stationBasePrice"),
                        editable: "onUpdate",
                    },
                    {
                        field: "priceBaseByBatteryToOperador",
                        title: t("zones.page.table.columns.batteryBasePrice"),
                        tooltip: t("zones.page.table.tooltips.batteryBasePrice"),
                        editable: "onUpdate",
                    },
                    {
                        field: "priceByKwhDeliveryMaxFromCustomer",
                        title: t("zones.page.table.columns.batteryMaxChargePrice"),
                        tooltip: t("zones.page.table.tooltips.batteryMaxChargePrice"),
                        editable: "onUpdate",
                    },
                    {
                        field: "priceByKwhChargeBattery",
                        title: t("zones.page.table.columns.batteryDischargePrice"),
                        tooltip: t("zones.page.table.tooltips.batteryDischargePrice"),
                        editable: "onUpdate",
                    }, */
          {
            field: 'batterySocMinToDeliver',
            title: t('zones.page.table.columns.minPercentajeSwap'),
            tooltip: t('zones.page.table.tooltips.minPercentajeSwap'),
            editable: 'onUpdate',
            render: (rowData) => rowData.batterySocMinToDeliver + '%',
          },
          {
            field: 'currency',
            title: t('zones.page.table.columns.currency'),
            editable: 'never',
          },
        ]}
        data={zonesReducer.results}
        actions={[
          {
            icon: 'add',
            tooltip: t('zones.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => handleAction(null, 'add'),
          },
          {
            icon: 'dehaze',
            tooltip: t('zones.page.table.actions.details'),
            onClick: (event, rowData) => handleAction(rowData, 'details'),
          },
          {
            icon: 'ev_station',
            tooltip: t('zones.page.table.actions.assignmentS'),
            onClick: (event, rowData) =>
              handleAction(rowData, 'assignmentStations'),
          },
          {
            icon: 'battery_charging_full',
            tooltip: t('zones.page.table.actions.assignmentB'),
            onClick: (event, rowData) =>
              handleAction(rowData, 'assignmentBatteries'),
          },
          // {
          //   icon: "edit",
          //   tooltip: t("zones.page.table.actions.edit"),
          //   onClick: (event, rowData) => handleAction(rowData, "edit"),
          // },
          {
            icon: 'delete',
            tooltip: t('zones.page.table.actions.delete'),
            onClick: (event, rowData) => handleAction(rowData, 'delete'),
          },
        ]}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              handleUpdateZone(newData, oldData)
              resolve()
            }),
        }}
      />
      <Details
        isOpen={dialogDetailsIsOpen}
        onClose={() => {
          setdialogDetailsIsOpen(false)
        }}
        selectedData={selectedRow}
      />
      <NewZone
        isOpen={dialogNewIsOpen}
        onClose={() => {
          setdialogNewIsOpen(false)
        }}
      />
      <UpdateZone
        isOpen={dialogUpdateIsOpen}
        onClose={() => {
          setdialogUpdateIsOpen(false)
        }}
        selectedData={selectedRow}
      />
      <AssignmentStations
        isOpen={isDialogAssignmentStationsOpen}
        onClose={() => {
          setisDialogAssignmentStationsOpen(false)
        }}
        selectedData={selectedRow}
      />

      <AssignmentBatteries
        isOpen={isDialogAssignmentBatteriesOpen}
        onClose={() => {
          setisDialogAssignmentBatteriesOpen(false)
        }}
        selectedData={selectedRow}
      />

      <ModalConfirm
        isOpen={dialogDeleteZoneIsOpen}
        onClose={() => setdialogDeleteZoneIsOpen(false)}
        onConfirm={() => deleteZone(selectedRow.id)}
        title={`${t('zones.page.table.modalConfirm.removeZones.title')} (${
          selectedRow.name
        })`}
        inputAvaiable={false}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { zonesReducer } = state
  return { zonesReducer }
}

const actionCreators = {
  getAllZones: zonesActions.getAllZones,
  getZoneById: zonesActions.getZoneById,
  updateZone: zonesActions.updateZone,
  deleteZone: zonesActions.deleteZone,
}

export default connect(mapState, actionCreators)(ZonesView)
