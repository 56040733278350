import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Slide from '@material-ui/core/Slide'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import SwipeableViews from 'react-swipeable-views'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'

import BatteriesAssignedToOperators from '../../batteries/assignedToZones'
import AssignedToOperators from '../../stations/assignedToZones'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    // display: "flex",
    padding: '2%',
  },
  dialogContainer: {
    padding: '1%',
  },

  tabs: {
    marginTop: '4%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}
const UpdateZone = ({
  isOpen,
  onClose,
  batteriesReducer,
  stationsReducer,
  getAllBatteries,
  getAllStations,
  update,
  selectedData,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const [name, setname] = useState('')
  const [country, setcountry] = useState('')
  const [stationBasePrice, setstationBasePrice] = useState('')
  const [batteryBasePrice, setbatteryBasePrice] = useState('')
  const [chargeMin, setchargeMin] = useState('')
  const [priceByKwhChargeBattery, setpriceByKwhChargeBattery] = useState('')
  const [
    priceByKwhDeliveryMaxFromCustomer,
    setpriceByKwhDeliveryMaxFromCustomer,
  ] = useState('')
  const [currency, setcurrency] = useState('')
  const [selectedBatteries, setselectedBatteries] = useState([])
  const [selectedStations, setselectedStations] = useState([])

  const [value, setValue] = React.useState(0)

  useEffect(() => {
    getAllBatteries()
    getAllStations()
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }
  const handleClose = () => {
    onClose()
    setname('')
    setcountry('')
    setstationBasePrice('')
    setbatteryBasePrice('')
    setchargeMin('')
    setpriceByKwhChargeBattery('')
    setpriceByKwhDeliveryMaxFromCustomer('')
    setcurrency('')
    setselectedBatteries([])
    setselectedStations([])
  }

  const handleBatteriesSelectedUpdate = (rows) => {
    setselectedBatteries(rows)
  }

  const handleStationsSelectedUpdate = (rows) => {
    setselectedStations(rows)
  }

  const handleupdate = () => {
    const body = {
      name,
      country,
      currency,
      priceBaseByStationToOperator: parseFloat(stationBasePrice),
      priceBaseByBatteryToOperador: parseFloat(batteryBasePrice),
      priceByKwhDeliveryMaxFromCustomer: parseFloat(
        priceByKwhDeliveryMaxFromCustomer,
      ),
      priceByKwhChargeBattery: parseFloat(priceByKwhChargeBattery),
      batterySocMinToDeliver: parseFloat(chargeMin),
    }

    update(body, {
      selectedBatteries: selectedBatteries.map((s) => s.id),
      selectedStations: selectedStations.map((s) => s.id),
    })
    handleClose()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('zones.dialogs.new.dialogTitle')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleupdate}>
              {t('zones.dialogs.new.createBtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.dialogContainer}>
          <div className={classes.details}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <TextField
                  label={t('zones.dialogs.new.labels.name')}
                  fullWidth
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                {' '}
                <TextField
                  select
                  fullWidth
                  label={t('zones.dialogs.new.labels.currency')}
                  value={currency}
                  onChange={(e) => setcurrency(e.target.value)}
                >
                  {[
                    {
                      value: 'eur',
                      label: 'EUR',
                    },
                    {
                      value: 'usd',
                      label: 'USD',
                    },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}></Grid>

              <Grid item xs={2}>
                <TextField
                  label={t('zones.dialogs.new.labels.country')}
                  fullWidth
                  value={country}
                  onChange={(e) => setcountry(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t('zones.dialogs.new.labels.stationBasePrice')}
                  fullWidth
                  value={stationBasePrice}
                  onChange={(e) => setstationBasePrice(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t('zones.dialogs.new.labels.batteryBasePrice')}
                  fullWidth
                  value={batteryBasePrice}
                  onChange={(e) => setbatteryBasePrice(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t(
                    'zones.dialogs.new.labels.priceByKwhDeliveryMaxFromCustomer',
                  )}
                  fullWidth
                  value={priceByKwhDeliveryMaxFromCustomer}
                  onChange={(e) =>
                    setpriceByKwhDeliveryMaxFromCustomer(e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t('zones.dialogs.new.labels.priceByKwhChargeBattery')}
                  fullWidth
                  value={priceByKwhChargeBattery}
                  onChange={(e) => setpriceByKwhChargeBattery(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t('zones.dialogs.new.labels.socDeliveryMin')}
                  fullWidth
                  value={chargeMin}
                  onChange={(e) => setchargeMin(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
            <div className={classes.tabs}>
              <p>{t('operator.dialogs.create.infoText')}</p>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    label={t('operator.dialogs.create.tabs.batteries')}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={t('operator.dialogs.create.tabs.stations')}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <BatteriesAssignedToOperators
                    data={batteriesReducer.results}
                    onSelectionUpdate={handleBatteriesSelectedUpdate}
                    preSelectedRows={selectedBatteries}
                    tableWithSelectAvaiable={true}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <AssignedToOperators
                    data={stationsReducer.results}
                    onSelectionUpdate={handleStationsSelectedUpdate}
                    preSelectedRows={selectedStations}
                    tableWithSelectAvaiable={true}
                  />
                </TabPanel>
              </SwipeableViews>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { batteriesReducer, stationsReducer } = state
  return { batteriesReducer, stationsReducer }
}
const actionCreators = {
  getAllBatteries: batteriesActions.getAllBatteries,
  getAllStations: stationsActions.getAllStations,
  update: zonesActions.updateZone,
}

export default connect(mapState, actionCreators)(UpdateZone)
