import { handleCall } from './handleResponse'

export const stationsService = {
  getAllStations,
  getStationById,
  createStation,
  getAllDynamics,
  getDynamicsById,
  getStationsAssignedToZone,
  getStationsAssignedToOperator,
  getStationsAssignedToSupervisor,
  create,
  assignToZone,
  assignToOperator,
  unassignFromOperator,
  getAllUnassignedStations,
  getUnassignedByZone,
  unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia,
  assignToZoneAndOperator,
  getUnassignedsToOperatorCiaByZones,
  setOutOfService,
  deleteStation,
  updateStation,
  getToBatteriesDeliveryForAdmin,
  createBatteryExtraction,
  unlockSlot,
  getToManualSwapSelect,
  getWeekDays,
  getForLogged,
}

function updateStation(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/Stations/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deleteStation(id) {
  return handleCall(`/stations/v1/Stations/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function setOutOfService(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/Stations/${id}/OutOfService`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}
function getUnassignedsToOperatorCiaByZones(filter = []) {
  // '?zones[]=sdsds&zones[]=sdsdsdsdsdsds'
  let filterUrl = ''
  filter.map((i, index) => {
    if (index === 0) {
      filterUrl = `?zones[]=${i}`
    } else {
      filterUrl += `&zones[]=${i}`
    }
  })

  return handleCall(
    `/stations/v1/Stations/GetUnassignedsToOperatorCiaByZones${filterUrl}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function assignToZoneAndOperator(body) {
  return handleCall(`/stations/v1/Stations/AssignToZoneAndOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function getAllUnassignedZonesAndOperatorCia() {
  return handleCall(
    `/stations/v1/Stations/GetUnassignedsToZoneAndOperatorCia`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getToManualSwapSelect(text, stationId = null) {
  return handleCall(
    `/stations/v1/Stations/GetToManualSwapSelect?text=` +
      text +
      (stationId ? '&stationId=' + stationId : ''),
    {
      method: 'GET',
      body: null,
    },
  )
}

function unassignFromZoneAndOperatorCia(body) {
  return handleCall(`/stations/v1/Stations/UnassignFromZoneAndOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function getUnassignedByZone(body) {
  return handleCall(`/stations/v1/Stations/GetUnassignedByZones`, {
    method: 'GET',
    body: JSON.stringify(body),
  })
}

function getAllUnassignedStations() {
  return handleCall(
    `/stations/v1/Stations/GetUnassignedsToOperatorCiaByZones`,
    {
      //GetUnassignedsToOperatorCia
      method: 'GET',
      body: null,
    },
  )
}

function assignToZone(body) {
  return handleCall(`/stations/v1/Stations/AssignToZone`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function assignToOperator(body) {
  return handleCall(`/stations/v1/Stations/AssignToOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function unassignFromOperator(body) {
  return handleCall(`/stations/v1/Stations/UnassignFromOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function create(body) {
  return handleCall(`/stations/v1/Stations/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getStationsAssignedToSupervisor(supervisor) {
  return handleCall(
    `/stations/v1/Stations?field=OperatorCiaId&value=${supervisor}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getWeekDays() {
  return handleCall(`/stations/v1/Stations/GetWeekDays`, {
    method: 'GET',
    body: null,
  })
}

function getStationsAssignedToOperator(operatorId) {
  return handleCall(
    `/stations/v1/stations/GetByOperatorCia?operatorCiaId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getStationsAssignedToZone(zoneId) {
  return handleCall(`/stations/v1/stations/GetByZone?zoneId=${zoneId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllDynamics() {
  return handleCall(`/stations/v1/Dynamics`, {
    method: 'GET',
    body: null,
  })
}

function getDynamicsById(stationId) {
  return handleCall(`/stations/v1/dynamics/${stationId}/GetByStation`, {
    method: 'GET',
    body: null,
  })
}

function createStation(body) {
  return handleCall(`/stations/v1/Stations/CreateStation`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getStationById(stationId) {
  return handleCall(`/stations/v1/Stations/${stationId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllStations() {
  return handleCall(`/stations/v1/Stations/GetAll`, {
    method: 'GET',
    body: null,
  })
}

function getForLogged() {
  return handleCall(`/stations/v1/Stations/GetForLogged`, {
    method: 'GET',
    body: null,
  })
}

function getToBatteriesDeliveryForAdmin() {
  return handleCall(`/stations/v1/Stations/GetToBatteriesDeliveryForAdmin`, {
    method: 'GET',
    body: null,
  })
}

function createBatteryExtraction(body) {
  return handleCall('/stations/v1/BatteryExtractions', {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function unlockSlot(id, body) {
  return handleCall(`/stations/v1/Stations/${id}/UnlockSlot`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}
