import { accountUserConstant } from '../../constants/accountUser_constants'
import { accountUserService } from '../../services/accountUserService'

import { alertActions } from './alert_actions'

import i18next from 'i18next'
export const accountUserActions = {
  getAll,
  deleteAcUser,
  create,
}

function create(body) {
  return (dispatch) => {
    accountUserService.create(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.operator.createSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(error))
      },
    )
  }
}

function deleteAcUser(id) {
  return (dispatch) => {
    accountUserService.deleteAcUser(id).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.operator.deleteOpSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(error))
      },
    )
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    accountUserService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all supervisor action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: accountUserConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: accountUserConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: accountUserConstant.GET_ALL_FAILURE, error }
  }
}
