import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Dashboard from '../dashboard'
import ProductView from './page'

const Product = (props) => {
  return <Dashboard component={<ProductView {...props} />} />
}

function mapState(state) {
  const { productReducer } = state
  return { productReducer }
}

const actionCreators = {}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Product)
