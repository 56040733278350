import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { connect } from 'react-redux'
import { isCauUser, isFinancial, isSacUser, isTechnician } from '../../helpers/roles'
import { incidentsActions } from '../../redux/actions/incidents_actions'
import CustomMaterialTable from '../shared/customMaterialTable'
import IncidentDetails from './dialogs/details'

const IncidentsView = ({ getAll, getById, incidentsReducer }) => {
  const { t } = useTranslation()

  const [isCreateIncidentOpen, setisCreateIncidentOpen] = useState(false)
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})

  useEffect(() => {
    getAll()
  }, [])

  const handleOpenDetails = (data) => {
    setSelectedRow(data)
    getById(data.id)
    setIsDetailsDialogOpen(true)
  }

  const handleCreateIncidence = (type) => {
    // setcreateType(type);
    setisCreateIncidentOpen(true)
  }

  return (
    <div>
      <CustomMaterialTable
        isLoading={incidentsReducer.loadingResults}
        data={incidentsReducer.results}
        title={t('incidents.page.table.title')}
        exportButton={!isTechnician() && !isFinancial() && !isCauUser() && !isSacUser()}
        columns={[
          //  {
          //      title: "id",
          //      field: "id"
          //  },
          {
            title: t('incidents.page.table.columns.created'),
            field: 'opened',
            render: (rowData) =>
              moment(rowData.opened).format('DD/MM/YYYY hh:mm:ss'),
          },
          {
            title: t('incidents.page.table.columns.customerSystemId'),
            field: 'customerSystemId',
          },
          {
            title: t('incidents.page.table.columns.type'),
            field: 'type',
          },
          {
            title: t('incidents.page.table.columns.station'),
            field: 'stationAddress',
          },
          {
            title: t('incidents.page.table.columns.status'),
            field: 'isOpen',
            render: (rowData) =>
              rowData.isOpen
                ? t('incidents.page.table.columns.open')
                : t('incidents.page.table.columns.close'),
          },
        ]}
        actions={[
          {
            icon: 'dehaze',
            tooltip: t('incidents.section.batteries.table.actions.details'),
            onClick: (event, rowData) => handleOpenDetails(rowData),
            hidden: isFinancial()
          },
        ]}
      />
      <IncidentDetails
        isOpen={isDetailsDialogOpen}
        onClose={() => setIsDetailsDialogOpen(false)}
        selectedRow={selectedRow}
      />
    </div>
  )
}

function mapState(state) {
  const { incidentsReducer } = state
  return { incidentsReducer }
}

const actionCreators = {
  getAll: incidentsActions.getAll,
  getById: incidentsActions.getById,
}

export default connect(mapState, actionCreators)(IncidentsView)
