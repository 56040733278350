import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { LockOpenRounded, LockRounded } from '@material-ui/icons'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import EjectRoundedIcon from '@material-ui/icons/EjectRounded'
import ContentCopy from '@material-ui/icons/FileCopy'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isAltechOperations } from '../../../helpers/roles'
import { isVersionGte, parseFirmwareVersion } from '../../../helpers/version'
import { stationsActions } from '../../../redux/actions/stations_actions'
import ModalConfirm from '../../shared/modalconfirm'
import InsertBatteryModal from './insertBatteryModal'

const StationInfo = ({
  selectedIndex,
  setSelectedIndex,
  rowsData,
  stationId,
  createBatteryExtraction,
  unlockSlot,
  lastConnection,
  setOutOfService,
  stationRows,
}) => {
  const { t } = useTranslation()

  const TableRows = [
    {
      title: '#',
      field: 'number',
    },
    {
      title: 'BSS ID',
      field: 'batteryData.batteryId',
    },
    {
      title: t('station.dialogs.stationDetails.status'),
      field: 'status',
    },
    {
      title: 'BattPack ID',
      field: 'batteryData.batteryPackId',
    },
    {
      title: 'SoC BP',
      field: 'batteryData.soc',
      prefix: '%',
    },
    {
      title: 'Temp. BP',
      field: 'batteryData.temperature',
      prefix: 'ºC',
    },
    {
      title: t('station.dialogs.stationDetails.charging'),
      field: 'batteryData.isBatteryCharging',
    },
    {
      title: t('station.dialogs.stationDetails.reserved'),
      field: 'isReserved',
    },
    {
      title: 'BP Flags',
      field: 'bpflags',
    },
    {
      title: t('station.dialogs.stationDetails.message'),
      field: 'message',
    },
  ]

  const [copyLabel, setCopyLabel] = useState(t('station.details.clickToCopy'))

  const [selectedRow, setSelectedRow] = useState([])
  const [selectedSlots, setSelectedSlots] = useState()
  const [slot, setSlot] = useState()
  const [isInsertModalOpen, setIsInsertModalOpen] = useState(false)
  const [isEjectModalOpen, setIsEjectModalOpen] = useState(false)
  const [isSlotOutOfServiceModalOpen, setIsSlotOutOfServiceModalOpen] =
    useState(false)

  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      setSelectedRow([rowsData[selectedIndex]])
      setSelectedSlots(
        rowsData[selectedIndex].slotsData?.sort(
          (a, b) => a.number - b.number,
        ) ?? null,
      )
    }
  }, [rowsData, selectedIndex])

  useEffect(() => {
    if (rowsData && rowsData.length > 0) {
      setSelectedRow([rowsData[selectedIndex]])
      setSelectedSlots(
        rowsData[selectedIndex].slotsData?.sort(
          (a, b) => a.number - b.number,
        ) ?? null,
      )
    }
  }, [selectedIndex, rowsData])

  const handleEjectSlot = (id, body) => {
    const fwVersion = parseFirmwareVersion(stationRows?.[0]?.fwVersion)

    if (isVersionGte(fwVersion, '1.26.2')) {
      createBatteryExtraction(body)
    } else {
      unlockSlot(id, body)
    }

    setIsEjectModalOpen(false)
  }

  const handleSlotOutOfService = (reason) => {
    setOutOfService({
      id: stationId,
      rowImei: selectedRow[0]?.imei,
      slotNumber: slot.number,
      outOfService: !slot.outOfService,
      reasons: [reason],
    })
    setIsSlotOutOfServiceModalOpen(false)
  }

  const handleHoverLeave = (event) => {
    setCopyLabel(t('station.details.clickToCopy'))
  }

  const handleMaintenanceButtonPressed = () => {
    setOutOfService({
      id: stationId,
      rowImei: selectedRow[0]?.imei,
      outOfService: !selectedRow[0]?.outOfService,
      reasons: [''],
    })
  }

  function flatten(o) {
    var getEntries = (o, prefix = '') =>
      Object.entries(o).flatMap(([k, v]) =>
        Object(v) === v
          ? getEntries(v, `${prefix}${k}.`)
          : [[`${prefix}${k}`, v]],
      )
    return Object.fromEntries(getEntries(o))
  }

  return (
    <>
      {(rowsData && rowsData.length > 0 && (
        <>
          <Grid
            item
            container
            xs={12}
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid
              item
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              <IconButton
                disabled={selectedIndex <= 0}
                onClick={() => {
                  setSelectedIndex((actual) => actual - 1)
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <IconButton
                disabled={selectedIndex >= rowsData.length - 1}
                onClick={() => setSelectedIndex((actual) => actual + 1)}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid>

            <Grid item style={{ flexGrow: 1, flexBasis: 0, maxWidth: '100%' }}>
              <TableContainer>
                <Table style={{ padding: 6, minWidth: 500 }}>
                  <TableHead style={{ backgroundColor: '#e41622' }}>
                    <TableCell style={{ color: '#fff' }}>
                      {t('station.details.dashboard.row')}
                    </TableCell>
                    <TableCell style={{ color: '#fff' }}>IMEI</TableCell>
                    <TableCell style={{ color: '#fff' }}>
                      {t('station.details.dashboard.lastReport')}
                    </TableCell>
                    <TableCell style={{ color: '#fff' }}>
                      {t('station.details.dashboard.operationStatus')}
                    </TableCell>
                    <TableCell style={{ color: '#fff' }}>
                      {t('station.details.dashboard.firmwareVersion')}
                    </TableCell>
                    <TableCell style={{ color: '#fff' }}>
                      {t('station.details.dashboard.ipAddress')}
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    <TableCell style={{ padding: 6 }}>
                      {selectedRow[0]?.letter}
                    </TableCell>
                    <TableCell style={{ padding: 6 }}>
                      {selectedRow[0]?.imei}
                    </TableCell>
                    <TableCell style={{ padding: 6 }}>
                      {moment(lastConnection).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell style={{ padding: 6 }}>
                      {selectedRow[0]?.status}
                    </TableCell>
                    <TableCell style={{ padding: 6 }}>
                      {stationRows?.[0]?.fwVersion}
                    </TableCell>
                    <TableCell style={{ padding: 6 }}>
                      {stationRows?.[0]?.ip}
                    </TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {(selectedSlots && selectedSlots.length > 0 && (
            <Grid container item xs={12} style={{ marginTop: 16 }}>
              <TableContainer>
                <Table
                  style={{
                    minWidth: 768,
                    tableLayout: 'fixed',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: '#fff',
                          textAlign: 'center',
                          position: 'sticky',
                          left: 0,
                          padding: 0,
                          paddingBottom: 2,
                          zIndex: 1,
                          width: '10%',
                        }}
                      >
                        <Button
                          color="primary"
                          fullWidth
                          variant="contained"
                          style={{ marginBottom: 8 }}
                          disabled={isAltechOperations()}
                          onClick={handleMaintenanceButtonPressed}
                        >
                          {t(
                            selectedRow[0]?.outOfService
                              ? 'station.details.dashboard.disableMaintenance'
                              : 'station.details.dashboard.enableMaintenance',
                          )}
                        </Button>
                        <Button
                          color="primary"
                          fullWidth
                          variant="contained"
                          style={{ marginBottom: 8 }}
                          onClick={() => setIsInsertModalOpen(true)}
                          disabled={isAltechOperations()}
                        >
                          {t('station.dialogs.stationDetails.insertBattery')}
                        </Button>
                        <Button
                          color="primary"
                          fullWidth
                          variant="contained"
                          style={{ marginBottom: 6 }}
                          onClick={() =>
                            (window.location.href = `/stations/row/${selectedRow[0]?.imei}/history`)
                          }
                          disabled={isAltechOperations()}
                        >
                          {t('station.dialogs.stationDetails.movementsHistory')}
                        </Button>
                      </TableCell>
                      {selectedSlots &&
                        selectedSlots.map((_, i) => {
                          let slot =
                            selectedSlots && selectedSlots.length > 0
                              ? selectedSlots[i]
                              : null

                          return (
                            <TableCell
                              key={i}
                              style={{
                                textAlign: 'center',
                                position: 'sticky',
                                left: 0,
                                height: 150,
                                ...(!slot && { border: 'none' }),
                              }}
                            >
                              {slot && (
                                <img
                                  src={require(`../../../assets/images/${
                                    slot.hasBattery ? 'full' : 'empty'
                                  }-socket.png`)}
                                  style={{
                                    width: 50,
                                  }}
                                />
                              )}
                            </TableCell>
                          )
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TableRows.map((row, rowIndex) => {
                      var cellStyle = {
                        textAlign: 'center',
                        justifyContent: 'center',
                        padding: '6px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }

                      if (rowIndex == 0)
                        cellStyle = {
                          ...cellStyle,
                          backgroundColor: '#e41622',
                          color: '#fff',
                        }

                      return (
                        <TableRow key={rowIndex}>
                          <TableCell
                            style={{
                              ...cellStyle,
                              position: 'sticky',
                              left: 0,
                              zIndex: 1,
                              width: '10%',
                              backgroundColor:
                                rowIndex == 0 ? '#e41622' : '#fff',
                              ...(!slot && { border: 'none' }),
                            }}
                          >
                            {row.title}
                          </TableCell>
                          {selectedSlots &&
                            selectedSlots.map((_, i) => {
                              let slot =
                                selectedSlots && selectedSlots.length > 0
                                  ? selectedSlots[i]
                                  : null

                              let value = slot
                                ? flatten(slot)[row.field] ?? false
                                : false

                              const handleClickToCopy = () => {
                                navigator.clipboard.writeText(value)
                                setCopyLabel(t('station.details.copied'))
                              }

                              return (
                                <TableCell
                                  key={i}
                                  style={{
                                    ...cellStyle,
                                    ...(!slot && {
                                      backgroundColor: '#fff',
                                      border: 'none',
                                    }),
                                    ...(rowIndex == 0 && {
                                      border: 'none',
                                    }),
                                  }}
                                  onPointerLeave={(e) =>
                                    row.field !== 'number' &&
                                    handleHoverLeave(e)
                                  }
                                >
                                  {(value && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <Tooltip title={value}>
                                        <Typography
                                          variant="inherit"
                                          style={{
                                            width: '100%',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textAlign: 'center',
                                          }}
                                        >
                                          {`${value} ${row?.prefix || ''}`}
                                        </Typography>
                                      </Tooltip>
                                      {rowIndex > 0 && (
                                        <Tooltip title={copyLabel}>
                                          <IconButton
                                            size="small"
                                            onClick={handleClickToCopy}
                                          >
                                            <ContentCopy
                                              style={{ height: 13 }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </div>
                                  )) || (
                                    <Typography variant="inherit">
                                      {slot && '-'}
                                    </Typography>
                                  )}
                                </TableCell>
                              )
                            })}
                        </TableRow>
                      )
                    })}
                    <TableRow>
                      <TableCell
                        style={{
                          textAlign: 'center',
                          justifyContent: 'center',
                          padding: 0,
                          position: 'sticky',
                          left: 0,
                          backgroundColor: '#fff',
                          zIndex: 1,
                        }}
                      ></TableCell>
                      {selectedSlots &&
                        selectedSlots.map((_, i) => {
                          let slot =
                            selectedSlots && selectedSlots.length > 0
                              ? selectedSlots[i]
                              : null

                          return (
                            <TableCell
                              key={i}
                              style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                padding: 0,
                              }}
                            >
                              {slot && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    gap: 8,
                                    padding: 8,
                                  }}
                                >
                                  <Tooltip
                                    title={t(
                                      'station.dialogs.stationDetails.eject',
                                    )}
                                  >
                                    <IconButton
                                      i
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        borderWidth: 1,
                                        borderStyle: 'groove',
                                        borderColor: '#eee',
                                        borderRadius: 50,
                                        padding: 8,
                                        boxShadow:
                                          '0px 1px 5px 0px rgba(0, 0, 0, 0.20)',
                                      }}
                                      onClick={() => {
                                        setSlot(slot)
                                        setIsEjectModalOpen(true)
                                      }}
                                      disabled={isAltechOperations()}
                                    >
                                      <EjectRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title={t(
                                      slot.outOfService
                                        ? 'station.dialogs.stationDetails.unlock'
                                        : 'station.dialogs.stationDetails.lock',
                                    )}
                                  >
                                    <IconButton
                                      i
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        borderWidth: 1,
                                        borderStyle: 'groove',
                                        borderColor: '#eee',
                                        borderRadius: 50,
                                        padding: 8,
                                        boxShadow:
                                          '0px 1px 5px 0px rgba(0, 0, 0, 0.20)',
                                      }}
                                      onClick={() => {
                                        setSlot(slot)
                                        setIsSlotOutOfServiceModalOpen(true)
                                      }}
                                      disabled={isAltechOperations()}
                                    >
                                      {slot.outOfService ? (
                                        <LockOpenRounded />
                                      ) : (
                                        <LockRounded />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )}
                            </TableCell>
                          )
                        })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )) || (
            <>
              <Typography
                style={{ textAlign: 'center', marginTop: 32, marginBottom: 32 }}
              >
                {t('station.details.rows.withoutSlots')}
              </Typography>
            </>
          )}
        </>
      )) || (
        <>
          <Typography
            style={{ textAlign: 'center', marginTop: 32, marginBottom: 32 }}
          >
            {t('station.details.rows.withoutRows')}
          </Typography>
        </>
      )}

      {slot && (
        <>
          <ModalConfirm
            isOpen={isEjectModalOpen}
            title={t('station.dialogs.ejectBattery.title')}
            body={t('station.dialogs.ejectBattery.body')}
            onConfirm={() => {
              handleEjectSlot(stationId, {
                rowImei: rowsData[selectedIndex]?.imei,
                slotNumber: slot['number'],
              })
            }}
            onClose={() => setIsEjectModalOpen(false)}
          />

          <ModalConfirm
            isOpen={isSlotOutOfServiceModalOpen}
            title={t(
              `station.dialogs.${
                slot.outOfService ? 'unlockSlot' : 'lockSlot'
              }.title`,
            )}
            body={t(
              `station.dialogs.${
                slot.outOfService ? 'unlockSlot' : 'lockSlot'
              }.body`,
            )}
            onConfirm={handleSlotOutOfService}
            onClose={() => setIsSlotOutOfServiceModalOpen(false)}
            inputAvaiable={!slot.outOfService}
            isInputRequired
            label={t('station.dialogs.lockSlot.input')}
          />
        </>
      )}

      {selectedSlots && (
        <InsertBatteryModal
          isOpen={isInsertModalOpen}
          stationId={stationId}
          row={selectedRow}
          onClose={() => setIsInsertModalOpen(false)}
          slotsNumber={selectedSlots.length}
        />
      )}
    </>
  )
}

const actionCreators = {
  setOutOfService: stationsActions.setOutOfService,
}

export default connect(null, actionCreators)(StationInfo)
