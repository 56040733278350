import { handleCall } from './handleResponse'

import {
  isManager,
  isOperator,
  isOperatorManager,
  isSupervisor,
  isTechnician,
} from '../helpers/roles'

export const subscriptionsService = {
  getAll,
  getById,
  getConditionsFor2ndAcceptFromEmailWebLink,
  getContract,
  getToManualSwapSelect,
  create,
  cancel,
  cancelAbort,
  confirmation,
  modifyVin,
  subrogate,
  cancelSubrogation,
}

function create(customerEmail, rateId, vin) {
  return handleCall(`/stations/v1/Subscriptions`, {
    method: 'POST',
    body: JSON.stringify({ customerEmail, rateId, vin }),
  })
}

function cancel(subscriptionId, devolutionStation = null) {
  return handleCall(
    `/stations/v1/Subscriptions/${subscriptionId}/Cancel?devolutionStationId=${devolutionStation}`,
    {
      method: 'PATCH',
      body: null,
    },
  )
}

function cancelAbort(id) {
  return handleCall(`/stations/v1/Subscriptions/${id}/Cancel/Abort`, {
    method: 'PATCH',
    body: null,
  })
}

function getById(subscriptionId) {
  return handleCall(`/stations/v1/Subscriptions/${subscriptionId}`, {
    method: 'GET',
    body: null,
  })
}

function getToManualSwapSelect(text, subscriptionId = null, customerId = null) {
  return handleCall(
    `/stations/v1/Subscriptions/GetToManualSwapSelect?filterText=${
      text + subscriptionId
        ? (subscriptionId ? '&subscriptionId=' + subscriptionId : '') +
          (customerId ? '&customerId=' + customerId : '')
        : ''
    }`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getConditionsFor2ndAcceptFromEmailWebLink(subscriptionId) {
  return handleCall(
    `/stations/v1/Subscriptions/${subscriptionId}/GetConditionsFor2ndAcceptFromEmailWebLinkMocked`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function confirmation(id) {
  return handleCall(
    `/stations/v1/Subscriptions/${id}/AcceptConditions2ndFromEmailWebLink`,
    {
      method: 'PATCH',
    },
  )
}

function getAll(rangeDate) {
  const { since, until } = rangeDate || { since: null, until: null }
  const filterDate = since && until ? `?since=${since}&until=${until}` : ''

  let getTypeByRole = '/'
  if (isOperator()) getTypeByRole = '/GetForOperatorUser'
  if (isTechnician()) getTypeByRole = '/GetForTechnician'
  if (isManager()) getTypeByRole = '/'
  if (isSupervisor()) getTypeByRole = '/GetForSupervisor'
  if (isOperatorManager()) getTypeByRole = '/GetForOperatorUser'

  return handleCall(`/stations/v1/Subscriptions${getTypeByRole}${filterDate}`, {
    method: 'GET',
    body: null,
  })
}

function getContract(id) {
  return handleCall(`/stations/v1/Subscriptions/${id}/GetContractPdfUrl`, {
    method: 'GET',
    body: null,
  })
}

function modifyVin(subscriptionId, vin) {
  return handleCall(`/stations/v1/Subscriptions/${subscriptionId}/VIN`, {
    method: 'PATCH',
    body: JSON.stringify(vin),
  })
}

function subrogate(subscriptionId, targetEmail) {
  return handleCall(`/stations/v1/Subscriptions/${subscriptionId}/Subrogate`, {
    method: 'PATCH',
    body: JSON.stringify(targetEmail),
  })
}

function cancelSubrogation(subscriptionId, targetEmail) {
  return handleCall(
    `/stations/v1/Subscriptions/${subscriptionId}/CancelSubrogation`,
    {
      method: 'PATCH',
      body: JSON.stringify(targetEmail),
    },
  )
}
