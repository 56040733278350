import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import i18next from 'i18next'
import React, { useState } from 'react'

const ShiftDays = ({
  item,
  errors,
  setShiftDay,
  setErrors,
  isInfo,
  index,
  length,
  add,
  remove,
  typeShift,
  checkUpdates,
  stationsReducer,
}) => {
  const [radioValue, setradioValue] = useState('')

  const handleChange = (event) => {
    setradioValue(event.target.value)
    checkUpdates(null, event.target.value)
  }

  const onChangeWeekDays = (body) => {
    checkUpdates(body)
    setradioValue('')
  }

  return (
    <React.Fragment>
      <Grid item xs={2}>
        <TextField
          label={
            typeShift == 'shiftDayInfo'
              ? i18next.t('station.create.labels.startHourInfo')
              : i18next.t('station.create.labels.startHourStation')
          }
          fullWidth
          value={item.startDate}
          error={
            errors[typeShift][index].startDate.message.length === 0
              ? false
              : true
          }
          helperText={errors[typeShift][index].startDate.message}
          name={'startDate_' + index}
          onChange={(e) => {
            setShiftDay(e, index, typeShift)
            errors[typeShift][index].startDate.message = ''
            setErrors(errors)
          }}
          disabled={!isInfo}
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={
            typeShift == 'shiftDayInfo'
              ? i18next.t('station.create.labels.endHourInfo')
              : i18next.t('station.create.labels.endHourStation')
          }
          fullWidth
          value={item.endDate}
          error={
            errors[typeShift][index].endDate.message.length === 0 ? false : true
          }
          helperText={errors[typeShift][index].endDate.message}
          name={'endDate_' + index}
          onChange={(e) => {
            setShiftDay(e, index, typeShift)
            errors[typeShift][index].endDate.message = ''
            setErrors(errors)
          }}
          disabled={!isInfo}
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item md={3} xs={7}>
        {stationsReducer && stationsReducer.weekDays.length > 0 && (
          <React.Fragment>
            <Autocomplete
              options={stationsReducer && stationsReducer.weekDays}
              freeSolo
              multiple
              value={item.weekDays}
              disabled={!isInfo}
              onChange={(event, newValue) => {
                onChangeWeekDays(newValue)
              }}
              getOptionSelected={(option, value) => option.id == value}
              getOptionLabel={(option) =>
                option && option.description
                  ? option && option.description
                  : stationsReducer.weekDays.find((x) => x.key == option)
                      .description
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'outlined'}
                  label={i18next.t('station.create.labels.weekDays')}
                  helperText={errors[typeShift][index].weekDays.message}
                  error={
                    errors[typeShift][index].weekDays.message.length === 0
                      ? false
                      : true
                  }
                />
              )}
            />

            <FormControl component="fieldset">
              <RadioGroup row value={radioValue} onChange={handleChange}>
                <FormControlLabel
                  value={'2'}
                  control={
                    <Radio
                      disabled={!isInfo}
                      checked={
                        JSON.stringify(item.weekDays) ==
                        JSON.stringify([0, 1, 2, 3, 4, 5, 6])
                      }
                      color="primary"
                    />
                  }
                  label={i18next.t('station.create.labels.allDays')}
                />
                <FormControlLabel
                  value={'3'}
                  control={
                    <Radio
                      disabled={!isInfo}
                      checked={
                        JSON.stringify(item.weekDays) ==
                        JSON.stringify([0, 1, 2, 3, 4])
                      }
                      color="primary"
                    />
                  }
                  label={i18next.t('station.create.labels.workDays')}
                />
                <FormControlLabel
                  value={'1'}
                  control={
                    <Radio
                      disabled={!isInfo}
                      checked={
                        JSON.stringify(item.weekDays) == JSON.stringify([5, 6])
                      }
                      color="primary"
                    />
                  }
                  label={i18next.t('station.create.labels.weekend')}
                />
              </RadioGroup>
            </FormControl>
          </React.Fragment>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {index + 1 == length ? (
          <React.Fragment>
            {length > 1 && (
              <IconButton
                onClick={() => remove(index, typeShift)}
                disabled={!isInfo}
              >
                <Remove />
              </IconButton>
            )}
            <IconButton onClick={() => add(typeShift)} disabled={!isInfo}>
              <Add />
            </IconButton>
          </React.Fragment>
        ) : (
          <>
            <IconButton
              onClick={() => remove(index, typeShift)}
              disabled={!isInfo}
            >
              <Remove />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid item xs={4} sm={4}></Grid>
    </React.Fragment>
  )
}

export default ShiftDays
