import { handleCall } from './handleResponse'

export const supervisorService = {
  getAllsupervisors,
  getSupervisorById,
  getSupervisorByOperatorId,
  create,
  canOperate,
  cannotOperate,
  deleteSup,
  update,
}

function update(body) {
  const { id } = body
  delete body.id

  return handleCall(`/stations/v1/supervisors/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deleteSup(id) {
  return handleCall(`/stations/v1/supervisors/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function canOperate(id) {
  return handleCall(`/stations/v1/supervisors/${id}/CanOperate`, {
    method: 'PUT',
    body: null,
  })
}

function cannotOperate(body) {
  const { id } = body
  delete body.id

  return handleCall(`/stations/v1/supervisors/${id}/CannotOperate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function create(body) {
  return handleCall(`/stations/v1/Supervisors/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getSupervisorByOperatorId(operatorId) {
  return handleCall(
    `/stations/v1/supervisors/GetByOperatorCia?operatorCiaId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getSupervisorById(supervisorId) {
  return handleCall(`/stations/v1/Supervisors/${supervisorId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllsupervisors() {
  return handleCall(`/stations/v1/Supervisors/GetAll`, {
    method: 'GET',
    body: null,
  })
}
