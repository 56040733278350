import React, { useState } from 'react'

import useStyles from './styles'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'

import { useTranslation } from 'react-i18next'
import MapsGeofence from '../shared/geofence/mapsGeofence'

import { formatDateToDisplay } from '../shared/formatDate'

import { connect } from 'react-redux'
import { batteriesActions } from '../../redux/actions/batteries_actions'

import { AppBar, Dialog, Slide, Toolbar, Typography } from '@material-ui/core'
import ModalConfirm from '../shared/modalconfirm'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DetailsContanierTable = ({
  onOpenSwapList,
  onOpenMoreInformation,
  setOutOfService,
  batteriesReducer,
  isOpen,
  onClose,
}) => {
  const handleClose = () => {
    onClose()
  }

  const {
    batteryPackId,
    batteryStation,
    bmsFwVersion,
    bmsId,
    bmsTarget,
    charging,
    created,
    deleted,
    id,
    imei,
    isDeleted,
    lastConnectionTimestamp,
    manufactureDate,
    modified,
    outOfService,
    outOfServiceReasons,
    outOfServiceSince,
    soc,
    userCreate,
    userDelete,
    userId,
    userModify,
    userOutOfService,
  } = batteriesReducer.battery || {
    batteryStation: {
      assigned: null,
      currentCustomerId: null,
      currentPosicion: null,
      currentStationId: null,
      currentVehicleId: null,
      operatorCiaId: null,
      status: null,
      unassigned: null,
      zoneId: null,
    },
  }

  const { t } = useTranslation()
  const classes = useStyles()

  const [isDialogConfirmOpen, setisDialogConfirmOpen] = useState(false)

  const handleOutOfService = () => {
    if (!outOfService) {
      setisDialogConfirmOpen(true)
    } else {
      const body = {
        id: id,
        outOfService: !outOfService,
        reasons: ['string'],
      }
      setOutOfService(body)
    }
  }

  const handleConfirmSetOutOfService = (reason) => {
    const bodyConfirm = {
      id: id,
      outOfService: !outOfService,
      reasons: [reason],
    }
    setOutOfService(bodyConfirm)
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar className={classes.appBar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('batteries.details.container.modalTitle')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <MapsGeofence
            toolsAvaiable={false}
            displayOnly={true}
            mapStyle={{ height: '30vh', width: '100%' }}
          />
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.lastConnection')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{formatDateToDisplay(lastConnectionTimestamp, true)}</p>
        </Grid>

        <Grid item md={6} xs={12}>
          <Link
            onClick={() => onOpenMoreInformation()}
            className={classes.link}
          >
            {t('batteries.details.container.moreInformation')}
          </Link>
        </Grid>
        <Grid item md={6} xs={12}>
          <Link onClick={() => onOpenSwapList()} className={classes.link}>
            {t('batteries.details.container.swapList')}
          </Link>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.batteryId')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{id}</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.imei')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{imei}</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.chargeLvl')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>55%</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.charging')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{charging ? t('shared.yes') : t('shared.no')}</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.lastSwapMonth')}: </p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>---</p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.historySwap')}: </p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>---</p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.lastChargesMonth')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>---</p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.lastChargeshistory')}: </p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>---</p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.firmwareVersionBMS')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{bmsFwVersion}</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.hardwareVersionBMS')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>2.36.8</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.hardwareBattery')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>0000</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.register')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>00/00/000</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.assignmentDate')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>00/00/000</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.unassignmentDate')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>00/00/000</p>
        </Grid>

        {/* <Grid item md={6} xs={12} sm={6} lg={6}>
                    <Tooltip title={t("batteries.details.container.assignment")}>
                        <IconButton aria-label="delete">
                            <PlaylistAddCheckIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item md={6} xs={12} sm={6} lg={6}>
                    <Tooltip title={t("batteries.details.container.unassign")}>
                        <IconButton aria-label="delete">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid> */}

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <Tooltip title={t('batteries.details.container.enable')}>
            <IconButton aria-label="delete">
              <CheckCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <Tooltip
            title={
              !outOfService ? t('shared.lock') : t('shared.unlock')
              // t("batteries.details.container.outOfService")
            }
          >
            <IconButton aria-label="delete" onClick={handleOutOfService}>
              {outOfService ? <LockOpenIcon /> : <LockIcon />}
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.disableReason')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{outOfServiceReasons}</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.disableUser')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>user@user.com</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.incidents')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>0</p>
        </Grid>

        <Grid item md={6} xs={12} sm={6} lg={6}>
          <p>{t('batteries.details.container.command')}: </p>
        </Grid>
        <Grid item md={6} xs={12} sm={6} lg={6}>
          <TextField id="standard-basic" label="Standard" />
        </Grid>
        <ModalConfirm
          isOpen={isDialogConfirmOpen}
          onClose={() => setisDialogConfirmOpen(false)}
          onConfirm={handleConfirmSetOutOfService}
          title={t('batteries.details.container.outOfService')}
          inputAvaiable={true}
          label={t('batteries.details.container.reason')}
          body={t('batteries.details.container.bodyConfirmModal')}
        />
      </Grid>
    </Dialog>
  )
}

function mapState(state) {
  const { batteriesReducer } = state
  return { batteriesReducer }
}

const actionCreators = {
  setOutOfService: batteriesActions.setOutOfService,
}

export default connect(mapState, actionCreators)(DetailsContanierTable)
