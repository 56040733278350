import { handleCall } from './handleResponse'

export const operatorUserService = {
  getAll,
  canOperate,
  cannotOperate,
  deleteOpUser,
  update,
  create,
}

function create(body) {
  return handleCall(`/stations/v1/OperatorUsers/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function update(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/OperatorUsers/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deleteOpUser(id) {
  return handleCall(`/stations/v1/OperatorUsers/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function canOperate(id) {
  return handleCall(`/stations/v1/OperatorUsers/${id}/CanOperate`, {
    method: 'PUT',
    body: null,
  })
}

function cannotOperate(body) {
  const { id } = body
  delete body.id

  return handleCall(`/stations/v1/OperatorUsers/${id}/CannotOperate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}
function getAll() {
  return handleCall(`/stations/v1/OperatorUsers/GetForManager`, {
    method: 'GET',
    body: null,
  })
}
