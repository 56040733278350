import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  leftNav: {
    top: '45%',
    position: 'absolute',
    zIndex: 99,
  },
  rightNav: {
    top: '45%',
    position: 'absolute',
    zIndex: 99,
    right: 0,
  },
  thumbnailContainer: {
    width: 'auto',
  },
  thumbnailImgContainer: {
    height: '10vh',
    width: '10vh',
    display: 'flex',
  },
  spinnerContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default useStyles
