import { batteriesConstants } from '../../constants/batteries_constants'

const initialState = {
  loadingResults: true,
  results: [],
  battery: {},
  dynamics: [],
  dynamic: {},
  batteriesAssignedToZone: [],
  batteriesAssignedToOperator: [],
  batteriesAssignedToCustomer: [],
  batteriesUnassigned: [],
  batteriesUnassignedByZone: [],
  batteriesUnassignedByZoneAndOperatorCia: [],
  unassignedsToOperatorCiaByZones: [],
  batteryRequest: [],
  batteriesAvailable: [],
  batteriesDeliverableInStation: [],
}

export function batteriesReducer(state = initialState, action) {
  switch (action.type) {
    case batteriesConstants.GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        unassignedsToOperatorCiaByZones: action.results,
      }
    case batteriesConstants.GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        unassignedsToOperatorCiaByZones: [],
      }

    case batteriesConstants.GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesUnassignedByZoneAndOperatorCia: action.results,
      }
    case batteriesConstants.GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        batteriesUnassignedByZoneAndOperatorCia: [],
      }

    case batteriesConstants.GET_UNASSIGNED_BY_ZONE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_UNASSIGNED_BY_ZONE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesUnassignedByZone: action.results,
      }
    case batteriesConstants.GET_UNASSIGNED_BY_ZONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        batteriesUnassignedByZone: [],
      }

    case batteriesConstants.GET_ALL_UNASSIGNED_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_ALL_UNASSIGNED_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesUnassigned: action.results,
      }
    case batteriesConstants.GET_ALL_UNASSIGNED_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        batteriesUnassigned: [],
      }

    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesAssignedToCustomer: action.results,
      }
    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        batteriesAssignedToCustomer: [],
      }

    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_OPERATOR_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_OPERATOR_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesAssignedToOperator: action.results,
      }
    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_OPERATOR_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        batteriesAssignedToOperator: [],
      }

    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_ZONE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_ZONE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesAssignedToZone: action.results,
      }
    case batteriesConstants.GET_BATTERIES_ASSIGNED_TO_ZONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        batteriesAssignedToZone: [],
      }

    case batteriesConstants.GET_ALL_DYNAMICS_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_ALL_DYNAMICS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        dynamics: action.results,
      }
    case batteriesConstants.GET_ALL_DYNAMICS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        dynamics: [],
      }
    case batteriesConstants.GET_DYNAMICS_BY_BATTERY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_DYNAMICS_BY_BATTERY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        dynamic: action.results,
      }
    case batteriesConstants.GET_DYNAMICS_BY_BATTERY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        dynamic: {},
      }
    case batteriesConstants.GET_BY_BATTERY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_BY_BATTERY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        battery: action.results,
      }
    case batteriesConstants.GET_BY_BATTERY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        battery: {},
      }
    case batteriesConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case batteriesConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }
    case batteriesConstants.GET_PICKUP_REQUEST_REQUEST:
      return {
        ...state,
        loadingResults: true,
        batteryRequest: [],
      }
    case batteriesConstants.GET_PICKUP_REQUEST_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteryRequest: action.results,
      }
    case batteriesConstants.GET_PICKUP_REQUEST_FAILURE:
      return {
        ...state,
        loadingResults: false,
        batteryRequest: [],
        error: action.error,
      }
    case batteriesConstants.GET_BATTERIES_AVAILABLE_REQUEST:
      return {
        ...state,
        loadingResults: true,
        batteriesAvailable: [],
      }
    case batteriesConstants.GET_BATTERIES_AVAILABLE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesAvailable: action.results,
      }
    case batteriesConstants.GET_BATTERIES_FOR_LOGGED_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case batteriesConstants.GET_BATTERIES_AVAILABLE_REQUEST:
      return {
        ...state,
        loadingResults: true,
        results: [],
      }
    case batteriesConstants.GET_BATTERIES_FOR_LOGGED_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }
    case batteriesConstants.GET_BATTERIES_AVAILABLE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        batteriesAvailable: [],
        error: action.error,
      }
    case batteriesConstants.GET_DELIVERABLE_IN_STATION_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.GET_DELIVERABLE_IN_STATION_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        batteriesDeliverableInStation: action.results,
      }
    case batteriesConstants.GET_DELIVERABLE_IN_STATION_FAILURE:
      return {
        ...state,
        loadingResults: false,
        batteriesDeliverableInStation: [],
        error: action.error,
      }
    case batteriesConstants.ASSIGN_TO_STATION_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case batteriesConstants.ASSIGN_TO_STATION_SUCCESS:
      return {
        ...state,
        loadingResults: false,
      }
    case batteriesConstants.ASSIGN_TO_STATION_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
