import { handleCall } from './handleResponse'

export const customerService = {
  getAllCustomers,
  getCustomerById,
  create,
  getPendingValidate,
  canOperate,
  cannotOperate,
  update,
  getAllCountries,
  getAllDocTypes,
  updatePaymentMode,
}

function canOperate(id) {
  return handleCall(`/stations/v1/Customers/${id}/CanOperate`, {
    method: 'PATCH',
    body: JSON.stringify({
      dniFront: 0,
      dniBack: 0,
      drivingLicenseFront: 0,
      drivingLicenseBack: 0,
    }),
  })
}

function cannotOperate(body) {
  const { id } = body
  delete body.id

  return handleCall(`/stations/v1/Customers/${id}/CannotOperate`, {
    method: 'PATCH',
    body: JSON.stringify(body),
  })
}

function getAllCustomers() {
  return handleCall(`/stations/v1/Customers/GetAll`, {
    method: 'GET',
    body: null,
  })
}

function create(body) {
  return handleCall(`/stations/v1/Customers/Register`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getCustomerById(customerId) {
  return handleCall(`/stations/v1/Customers/${customerId}`, {
    method: 'GET',
    body: null,
  })
}

function getPendingValidate() {
  return handleCall(`/stations/v1/Customers/GetPendingToValidate`, {
    method: 'GET',
    body: null,
  })
}

function update(email, body) {
  return handleCall(
    `/unified/v1/Customers/UpdateForAdmin?email=${encodeURIComponent(email)}`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  )
}

function getAllCountries() {
  return handleCall(`/unified/v1/Customers/GetAllCountries`, {
    method: 'GET',
    body: null,
  })
}

function getAllDocTypes() {
  return handleCall(`/stations/v1/Customers/GetIdDocTypes`, {
    method: 'GET',
    body: null,
  })
}

function updatePaymentMode(customerId) {
  return handleCall(`/stations/v1/Customers/${customerId}/PaymentMode`, {
    method: 'PATCH',
    body: null,
  })
}
