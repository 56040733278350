import React from 'react'

import Dashboard from '../dashboard'
import SwapsManualView from './page'

import { connect } from 'react-redux'
import { compose } from 'redux'

import { withTranslation } from 'react-i18next'

const SwapsManual = (props) => {
  return <Dashboard component={<SwapsManualView {...props} />} />
}

function mapState(state) {
  const { swapsReducer } = state
  return { swapsReducer }
}

const actionCreators = {}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(SwapsManual)
