import { makeStyles } from '@material-ui/core'

const INPUT_LABEL_FONT_SIZE = '1.1rem'
const INPUT_FIELD_FONT_SIZE = '0.9rem'
const FONT_WEIGHT = 700

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
    height: 'calc(100vh - 64px)',
    display: 'flex',
    backgroundColor: 'white',
    padding: 16,
  },
  contentWrapper: {},
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerLeftSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    fontSize: INPUT_LABEL_FONT_SIZE,
    fontWeight: FONT_WEIGHT,
    whiteSpace: 'nowrap',
    height: 20,
  },
  headerButton: {
    backgroundColor: '#e41622',
    color: 'white',
    height: 'fit-content',
  },
  label: {
    fontSize: INPUT_LABEL_FONT_SIZE,
    fontWeight: FONT_WEIGHT,
    whiteSpace: 'nowrap',
    height: 20,
  },
  input: {
    fontSize: INPUT_FIELD_FONT_SIZE,
  },
}))

export default useStyles
