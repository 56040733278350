import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { operatorActions } from '../../../redux/actions/operator_action'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'

import { validations } from '../../../helpers/validation'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  mainContainer: { padding: '2%' },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({
  isOpen,
  onClose,
  getAllOperators,
  getAllZones,
  stationsReducer,
  zonesReducer,
  getAllStations,
  create,
  operatorReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [zone, setzone] = useState()
  const [operator, setoperator] = useState()
  const [batteryPackId, setbatteryPackId] = useState('')
  const [station, setStation] = useState()
  const [errors, setErrors] = useState({
    zone: { result: false, message: '' },
    operator: { result: false, message: '' },
    batteryPackId: { result: false, message: '' },
    station: { result: false, message: '' },
  })

  const handleClose = () => {
    onClose()
    setbatteryPackId('')
    setzone()
    setoperator()
    setStation()
    setStation()

    setErrors({
      zone: { result: false, message: '' },
      operator: { result: false, message: '' },
      batteryPackId: { result: false, message: '' },
      station: { result: false, message: '' },
    })
  }

  const handleEnter = () => {
    getAllZones()
    getAllOperators()
    getAllStations()
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.batteryPackId = validations.required(batteryPackId)
    newErrors.zone = validations.isEmptyObject(zone)
    newErrors.operator = validations.isEmptyObject(operator)
    newErrors.station = validations.isEmptyObject(station)

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }
  const handleCreateBattery = () => {
    const body = {
      zoneId: zone?.id,
      operatorCiaId: operator?.id,
      //imei,
      batteryPackId: parseInt(batteryPackId),
    }

    validateForm()
    if (isFormValid()) {
      if (station) {
        body.deliveryStationId = station.id
      }

      create(body)
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onEnter={handleEnter}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('batteries.create.dialogTitle')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateBattery}>
              {t('batteries.create.confirmBtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.mainContainer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label={t('batteries.create.labels.batteryPackId')}
                fullWidth
                value={batteryPackId}
                error={errors.batteryPackId.message.length === 0 ? false : true}
                helperText={errors.batteryPackId.message}
                type={'number'}
                onChange={(e) => {
                  setbatteryPackId(e.target.value)
                  errors.batteryPackId.message = ''
                  setErrors(errors)
                }}
                required
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 9)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={operatorReducer.results}
                getOptionLabel={(option) => option.name}
                style={{ width: '100%' }}
                value={operator}
                onChange={(event, value) => {
                  setoperator(value)
                  errors.operator.message = ''
                  setErrors(errors)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.operator.message.length === 0 ? false : true}
                    helperText={errors.operator.message}
                    label={t('batteries.create.labels.operator')}
                    // variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={zonesReducer.results}
                getOptionLabel={(option) => option.name}
                style={{ width: '100%' }}
                value={zone}
                onChange={(event, value) => {
                  setzone(value)
                  errors.zone.message = ''
                  setErrors(errors)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.zone.message.length === 0 ? false : true}
                    helperText={errors.zone.message}
                    label={t('batteries.create.labels.zone')}
                    // variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={stationsReducer.results.filter(
                  (station) => station.zoneId === zone?.id,
                )}
                getOptionLabel={(option) => option?.nameAndAddress || '-- --'}
                value={station}
                onChange={(event, value) => {
                  setStation(value)
                  errors.station.message = ''
                  setErrors(errors)
                }}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.station.message.length === 0 ? false : true}
                    helperText={errors.station.message}
                    label={t('batteries.create.labels.station')}
                    // variant="outlined"
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sm={3}>
                            <TextField
                                label={t("batteries.create.labels.imei")}
                                fullWidth
                                value={imei}
                                onChange={(e) => setimei(e.target.value)}
                                required
                            />
                        </Grid> */}
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { zonesReducer, operatorReducer, stationsReducer } = state
  return { zonesReducer, operatorReducer, stationsReducer }
}
const actionCreators = {
  getAllOperators: operatorActions.getAllOperators,
  getAllZones: zonesActions.getAllZones,
  getAllStations: stationsActions.getAllStations,
  create: batteriesActions.create,
}

export default connect(mapState, actionCreators)(Create)
