import { operatorConstant } from '../../constants/operator_constant'
import { operatorService } from '../../services/operatorService'

import { alertActions } from './alert_actions'

import i18next from 'i18next'
export const operatorActions = {
  getAllOperators,
  getOperatorById,
  createOperator,
  getOperatorsAssignedToZone,
  getOperatorsPendingValidate,
  canOperate,
  cannotOperate,
  deleteOp,
  update,
  getForTechnician,
}

function update(body) {
  return (dispatch) => {
    operatorService.update(body).then(
      (results) => {
        dispatch(getAllOperators())
        dispatch(
          alertActions.success(i18next.t('services.operator.updateSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.operator.updateError')))
      },
    )
  }
}

function deleteOp(id) {
  return (dispatch) => {
    operatorService.deleteOp(id).then(
      (results) => {
        dispatch(getAllOperators())
        dispatch(
          alertActions.success(i18next.t('services.operator.deleteOpSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.operator.deleteOpError')),
        )
      },
    )
  }
}

function cannotOperate(body) {
  return (dispatch) => {
    operatorService.cannotOperate(body).then(
      (results) => {
        // dispatch(getOperatorsPendingValidate());
        dispatch(getAllOperators())
        dispatch(
          alertActions.success(
            i18next.t('services.operator.cannotOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.operator.cannotOperateError')),
        )
      },
    )
  }
}

function canOperate(id, from) {
  return (dispatch) => {
    operatorService.canOperate(id).then(
      (results) => {
        from === 'pendingValidate'
          ? dispatch(getOperatorsPendingValidate())
          : dispatch(getAllOperators())

        dispatch(
          alertActions.success(
            i18next.t('services.operator.canOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.operator.canOperateError')),
        )
      },
    )
  }
}

function getOperatorsPendingValidate() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getOperatorsPendingValidate().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_OPERATORS_PENDING_VALIDATE_REQUEST }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_OPERATORS_PENDING_VALIDATE_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_OPERATORS_PENDING_VALIDATE_FAILURE,
      error,
    }
  }
}

function getOperatorsAssignedToZone(zoneId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getOperatorsAssignedToZone(zoneId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_OPERATORS_ASSIGNED_TO_ZONE_REQUEST }
  }

  function success(results) {
    return {
      type: operatorConstant.GET_OPERATORS_ASSIGNED_TO_ZONE_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: operatorConstant.GET_OPERATORS_ASSIGNED_TO_ZONE_FAILURE,
      error,
    }
  }
}

function createOperator(body) {
  return (dispatch) => {
    operatorService.createOperator(body).then(
      (results) => {
        dispatch(getAllOperators())
        dispatch(
          alertActions.success(i18next.t('services.operator.createSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.operator.createError') + error,
          ),
        )
      },
    )
  }
}

function getOperatorById(operatorId) {
  return (dispatch) => {
    dispatch(request())
    operatorService.getOperatorById(operatorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_BY_ID_FAILURE, error }
  }
}

function getAllOperators() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getAllOperators().then(
      (results) => {
        dispatch(success(results.content))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_FAILURE, error }
  }
}

function getForTechnician() {
  return (dispatch) => {
    dispatch(request())
    operatorService.getForTechnician().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: operatorConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: operatorConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorConstant.GET_ALL_FAILURE, error }
  }
}
