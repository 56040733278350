import { devolutionConstants } from '../../constants/devolution_contants'
import { devolutionService } from '../../services/devolutionServices'
import { alertActions } from './alert_actions'

export const devolutionActions = {
  getAll,
  getById,
  confirm,
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    devolutionService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: devolutionConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: devolutionConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: devolutionConstants.GET_ALL_FAILURE, error }
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request())
    devolutionService.getById(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: devolutionConstants.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: devolutionConstants.GET_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: devolutionConstants.GET_BY_ID_FAILURE, error }
  }
}

function confirm(id, refundDeposit, stolenBatteries) {
  return (dispatch) => {
    dispatch(request())
    devolutionService.confirm(id, refundDeposit, stolenBatteries).then(
      (results) => {
        dispatch(success(results))
        dispatch(getAll())
        dispatch(alertActions.success('batteries.return.confirmSuccess'))
      },
      (error) => {
        dispatch(alertActions.error(error))
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: devolutionConstants.CONFIRM_DEVOLUTION_REQUEST }
  }

  function success(results) {
    return { type: devolutionConstants.CONFIRM_DEVOLUTION_SUCCESS, results }
  }

  function failure(error) {
    return { type: devolutionConstants.CONFIRM_DEVOLUTION_FAILURE, error }
  }
}
