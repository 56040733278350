import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { technicianActions } from '../../redux/actions/technician_action'

const AssignedToTechnicians = ({
  preSelectedRows,
  technicianId,
  onselectedRowsUpdate,
  getAllTechnicians,
  techniciansReducer,
  data,
  addTitle = true,
  readOnlyConfiguration,
  tableRef,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    getAllTechnicians()
  }, [])

  return (
    <React.Fragment>
      <CustomMaterialTable
        selection={!readOnlyConfiguration}
        tableRef={tableRef}
        onSelectionChange={(rows) =>
          onselectedRowsUpdate && onselectedRowsUpdate(rows)
        }
        preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
        title={addTitle ? t('zones.assignedToTechnicians.table.title') : ''}
        columns={[
          {
            field: 'name',
            title: t('zones.assignedToTechnicians.table.columns.name'),
            editable: 'never',
          },
          {
            field: 'priceBaseByStationToOperator',
            title: t(
              'zones.assignedToTechnicians.table.columns.stationBasePrice',
            ),
            editable: 'onUpdate',
          },
          {
            field: 'priceBaseByBatteryToOperador',
            title: t(
              'zones.assignedToTechnicians.table.columns.batteryBasePrice',
            ),
            editable: 'onUpdate',
          },
          {
            field: 'priceByKwhDeliveryMaxFromCustomer',
            title: t(
              'zones.assignedToTechnicians.table.columns.batteryMaxChargePrice',
            ),
            editable: 'onUpdate',
          },
          {
            field: 'currency',
            title: t('zones.assignedToTechnicians.table.columns.currency'),
            editable: 'never',
          },
        ]}
        data={data || techniciansReducer.results}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { techniciansReducer } = state
  return { techniciansReducer }
}

const actionCreators = {
  getAllTechnicians: technicianActions.getAllTechnicians,
}

export default connect(mapState, actionCreators)(AssignedToTechnicians)
