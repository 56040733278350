import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'

import BatteriesByOperators from '../../batteries/byOperators'
import StationsByOperators from '../../stations/byOperators'
import SupervisorsByOperators from '../../supervisors/byOperators'
import TechniciansByOperators from '../../technicians/byOperators'

import { useTranslation } from 'react-i18next'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default function DetailsSections({ operatorId, selectedRow }) {
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState('panel1')

  const {
    cannotOperate,
    cannotOperateReasons,
    cannotOperateSince,
    cif,
    created,
    deleted,
    docsVerified,
    email,
    emailVerified,
    id,
    isDeleted,
    localMaterialTableRowId,
    modified,
    name,
    phone,
    tableData,
    userCreate,
    userDelete,
    userModify,
    zones,
  } = selectedRow || {
    cannotOperate: '',
    cif: '',
    email: '',
    name: '',
    phone: '',
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <Accordion
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{t('operator.dialogs.details.sectionTitle')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                // variant="outlined"
                fullWidth
                label={t('operator.dialogs.details.labels.name')}
                value={name}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // variant="outlined"
                fullWidth
                label={t('operator.dialogs.details.labels.email')}
                value={email}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // variant="outlined"
                fullWidth
                label={t('operator.dialogs.details.labels.contactPhone')}
                value={phone}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // variant="outlined"
                fullWidth
                label={t('operator.dialogs.details.labels.cif')}
                value={cif}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                // variant="outlined"
                fullWidth
                label={t('operator.dialogs.details.labels.enabled')}
                value={cannotOperate ? t('shared.no') : t('shared.yes')}
              />
            </Grid>
            {/* <Grid item xs={3}>
                            <TextField
                                // variant="outlined"
                                fullWidth
                                label={t("operator.dialogs.details.labels.avgStationPrice")}
                            // value={name}
                            />
                        </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>{t('operator.dialogs.batteries.dialogTitle')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BatteriesByOperators operatorId={operatorId} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>{t('operator.dialogs.stations.dialogTitle')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StationsByOperators operatorId={operatorId} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>
            {t('operator.dialogs.supervisors.dialogTitle')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SupervisorsByOperators operatorId={operatorId} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        square
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>
            {t('operator.dialogs.technicians.dialogTitle')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TechniciansByOperators operatorId={operatorId} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
