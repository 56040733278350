import React, { useEffect } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { batteriesActions } from '../../redux/actions/batteries_actions'

const BatteriesByCustomers = ({
  getBatteriesAssignedToCustomer,
  customerId,
  batteriesReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getBatteriesAssignedToCustomer(customerId)
  }, [])

  return (
    <CustomMaterialTable
      title={t('batteries.byClients.table.title')}
      // saveFilters={true}
      columns={[
        {
          title: t('batteries.byClients.table.columns.batteryId'),
          field: 'batteryPackId',
        },
        {
          title: t('batteries.byClients.table.columns.operator'),
          field: 'operatorCiaName',
        },
        /* {
                    title: t("batteries.byClients.table.columns.obtainingDateSwap"),
                    field: "batteryStation.assigned",
                }, */
        //{ title: t("batteries.byClients.table.columns.imei"), field: "imei" },
        {
          title: t('batteries.byClients.table.columns.zone'),
          field: 'zoneName',
        },
        //{ title: t("batteries.byClients.table.columns.rates"), field: "rate" },
      ]}
      data={batteriesReducer.batteriesAssignedToCustomer}
    />
  )
}

function mapState(state) {
  const { batteriesReducer } = state
  return { batteriesReducer }
}
const actionCreators = {
  getBatteriesAssignedToCustomer:
    batteriesActions.getBatteriesAssignedToCustomer,
}

export default connect(mapState, actionCreators)(BatteriesByCustomers)
