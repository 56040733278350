//  /// <summary>
//         /// No se encuentra.
//         /// Ejemplo: No se encuentra una batería en base de datos.
//         /// </summary>
//         NotFound = 1,

//         /// <summary>
//         /// No puede insertarse por coexistir al mismo tiempo con otro.
//         /// Ejemplo: Email o teléfono duplicados al registrar un cliente.
//         /// Ejemplo: Un cliente tiene más de un descuento activo al mismo tiempo.
//         /// </summary>
//         CannotCoexist = 2,

//         /// <summary>
//         /// No puede modificarse.
//         /// Ejemplo: Se intenta asignar clientes a un descuento antiguo ya cerrado (ApplyUntil en el pasado).
//         /// </summary>
//         CannotUpdate = 3,

//         /// <summary>
//         /// No puede eliminarse.
//         /// </summary>
//         CannotDelete = 4,

//         /// <summary>
//         /// Falta proporcionar algún dato requerido.
//         /// </summary>
//         MissingRequired = 5,

//         /// <summary>
//         /// Dato inválido.
//         /// Ejemplo: Fecha inicial mayor que fecha final.
//         /// Ejemplo: La batería es privada y no se puede intercambiar.
//         /// </summary>
//         InvalidData = 6,
import i18next from 'i18next'

const desctiption = (code) => {
  switch (code) {
    case 1:
      return i18next.t('services.errorCodes.code1')
      break
    case 2:
      return i18next.t('services.errorCodes.code2')
      break
    case 3:
      return i18next.t('services.errorCodes.code3')
      break
    case 4:
      return i18next.t('services.errorCodes.code4')
      break
    case 5:
      return i18next.t('services.errorCodes.code5')
      break
    case 6:
      return i18next.t('services.errorCodes.code6')
      break
    case 7:
      return i18next.t('services.errorCodes.code7')
      break
  }
}
export const returnErrorCode = (errors) => {
  if (errors.hasOwnProperty('code')) {
    let msg = `${desctiption(errors.code)}: `
    errors.messages &&
      errors.messages.map((i) => {
        msg += `${i} `
      })

    return msg
  }
  return errors
}
