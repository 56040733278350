import { operatorConstant } from '../../constants/operator_constant'

const initialState = {
  loadingResults: true,
  results: [],
  operator: {
    zones: [],
  },
  operatorsPendingValidate: [],
  operatorsAssignedToZone: [],
}

export function operatorReducer(state = initialState, action) {
  switch (action.type) {
    case operatorConstant.GET_OPERATORS_ASSIGNED_TO_ZONE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case operatorConstant.GET_OPERATORS_ASSIGNED_TO_ZONE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        operatorsAssignedToZone: action.results,
      }
    case operatorConstant.GET_OPERATORS_ASSIGNED_TO_ZONE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        operatorsAssignedToZone: [],
        error: action.error,
      }

    case operatorConstant.GET_OPERATORS_PENDING_VALIDATE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case operatorConstant.GET_OPERATORS_PENDING_VALIDATE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        operatorsPendingValidate: action.results,
      }
    case operatorConstant.GET_OPERATORS_PENDING_VALIDATE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        operatorsPendingValidate: [],
        error: action.error,
      }

    case operatorConstant.GET_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case operatorConstant.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        operator: action.results,
      }
    case operatorConstant.GET_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        operator: {},
        error: action.error,
      }

    case operatorConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case operatorConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,

        results: action.results,
      }
    case operatorConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    default:
      return state
  }
}
