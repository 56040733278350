import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import i18next from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  isCauUser,
  isManager,
  isManagerReadOnly,
  isSacUser,
  isTechnician,
} from '../../../helpers/roles'
import { devolutionActions } from '../../../redux/actions/devolution_actions'
import CustomMaterialTable from '../../shared/customMaterialTable'
import { displayWithTooltip } from '../../shared/enshortenLargeData'
import ModalConfirm from '../../shared/modalconfirm'

const ReturnBatteries = ({
  devolutionsReducer,
  batteriesReducer,
  getAllDevolutions,
  confirm,
  getDevolutionById,
}) => {
  const { t } = useTranslation()

  const [isDevolutionDetailModalOpen, setIsDevolutionDetailModalOpen] =
    useState(false)

  const [
    isDevolutionDoubleCheckModalOpen,
    setIsDevolutionDoubleCheckModalOpen,
  ] = useState(false)

  const [isRefundDepositChecked, setIsRefundDepositChecked] = useState(true)
  const [hasStolenBatteries, setHasStolenBatteries] = useState(false)
  const [stolenBatteries, setStolenBatteries] = useState([])

  const [selectedItem, setSelectedItem] = useState({})

  useEffect(() => {
    getAllDevolutions()
  }, [])

  const handleDevolution = (item) => {
    setIsDevolutionDetailModalOpen(true)
    setSelectedItem(item)
  }

  const confirmDevolution = (id, doubleCheck = false) => {
    if (doubleCheck) {
      setIsDevolutionDoubleCheckModalOpen(true)
    } else {
      confirm(id, isRefundDepositChecked, stolenBatteries)
      setHasStolenBatteries(false)
      setStolenBatteries([])
    }

    setIsDevolutionDetailModalOpen(false)
  }

  const getDevolutionBatteries = () => {
    return selectedItem.batteryPackIds.map((i) => i.toString())
  }

  return (
    <>
      <CustomMaterialTable
        title={t('batteries.return.title')}
        data={devolutionsReducer.devolutions}
        isLoading={devolutionsReducer.loadingResults}
        exportButton={!isTechnician() && !isCauUser() && !isSacUser()}
        columns={[
          {
            title: t('batteries.return.columns.created'),
            field: 'requested',
            render: (rowData) => {
              return moment(rowData.requested).format('DD/MM/YYYY HH:mm')
            },
          },
          {
            title: t('batteries.return.columns.customerName'),
            field: 'customerFullName',
            render: (rowData) => displayWithTooltip(rowData.customerFullName),
          },
          {
            title: t('batteries.return.columns.operator'),
            field: 'operatorCiaName',
          },
          {
            title: t('batteries.return.columns.location'),
            field: 'stationNameAndAddress',
            render: (rowData) =>
              displayWithTooltip(rowData.stationNameAndAddress),
          },
          {
            title: t('batteries.return.columns.customer'),
            field: 'customerEmail',
          },
          {
            title: t('batteries.return.columns.batteriesNumber'),
            field: 'batteriesNumber',
          },
          {
            title: t('batteries.page.table.columns.contractId'),
            field: 'contractId',
          },
        ]}
        actions={
          !isCauUser() && [
            {
              icon: 'assignment_return',
              tooltip: 'Aceptar devolución',
              onClick: (_, rowData) => handleDevolution(rowData),
              hidden: isCauUser() || isTechnician() || isManagerReadOnly(),
            },
          ]
        }
      />

      <Dialog
        open={isDevolutionDetailModalOpen}
        onClose={() => {
          setIsDevolutionDetailModalOpen(false)
          setHasStolenBatteries(false)
          setStolenBatteries([])
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('batteries.return.actions.modal.title') +
            selectedItem.batteryPackIdsString}
        </DialogTitle>
        <DialogContent>
          {t('batteries.return.actions.modal.body')}

          <FormControlLabel
            control={
              <Checkbox
                checked={isRefundDepositChecked}
                onChange={() =>
                  setIsRefundDepositChecked(!isRefundDepositChecked)
                }
                color="primary"
              />
            }
            label={t('batteries.return.actions.modal.returnDeposit')}
          />

          {isManager() && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasStolenBatteries}
                  onChange={(_, checked) => {
                    setHasStolenBatteries(!hasStolenBatteries)
                    setStolenBatteries(
                      !checked || selectedItem.batteryPackIds.length > 1
                        ? []
                        : selectedItem.batteryPackIds,
                    )
                  }}
                  color="primary"
                />
              }
              label={t('batteries.return.actions.modal.stolenBatteries')}
            />
          )}

          {hasStolenBatteries && selectedItem.batteryPackIds.length > 1 && (
            <Autocomplete
              options={getDevolutionBatteries()}
              style={{ marginTop: 8, width: '100%' }}
              value={stolenBatteries}
              multiple
              onChange={(_, value) => setStolenBatteries(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={i18next.t('batteries.return.form.stolenBatteries')}
                  variant="outlined"
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDevolutionDetailModalOpen(false)}
            color="primary"
          >
            {i18next.t('shared.no')}
          </Button>
          <Button
            onClick={() =>
              confirmDevolution(selectedItem.id, isRefundDepositChecked)
            }
            color="primary"
            disabled={hasStolenBatteries && stolenBatteries.length === 0}
          >
            {i18next.t('shared.yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <ModalConfirm
        isOpen={isDevolutionDoubleCheckModalOpen}
        onClose={() => setIsDevolutionDoubleCheckModalOpen(false)}
        title={
          t('batteries.return.actions.modal.title') +
          selectedItem.batteryPackIdsString
        }
        body={t('batteries.return.actions.modal.doubleCheckBody')}
        onConfirm={() => confirmDevolution(selectedItem.id)}
      />
    </>
  )
}

function mapState(state) {
  const { devolutionsReducer, batteriesReducer } = state
  return { devolutionsReducer, batteriesReducer }
}

const actionCreators = {
  getAllDevolutions: devolutionActions.getAll,
  getDevolutionById: devolutionActions.getById,
  confirm: devolutionActions.confirm,
}

export default connect(mapState, actionCreators)(ReturnBatteries)
