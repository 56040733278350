import { CsvBuilder } from 'filefy'
import i18next from 'i18next'

export function exportCsv(columns, rows) {
  columns = columns.filter((columnDef) => {
    return (
      !columnDef.hidden &&
      (columnDef.field || columnDef.render || columnDef.exportFn) &&
      columnDef.export !== false
    )
  })

  const data = rows.map((rowData) =>
    columns.map((columnDef) =>
      columnDef.exportFn
        ? columnDef.exportFn(rowData)
        : columnDef.render
        ? columnDef.render(rowData)
        : rowData[columnDef.field],
    ),
  )

  new CsvBuilder('data.csv')
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(data)
    .exportFile()
}

export function exportStationsCsv(columns, rows) {
  const stationRows = ['A', 'B', 'C']
  const auxCol1 = []
  const auxCol2 = []
  const auxCol3 = []

  stationRows.forEach((row, index) => {
    auxCol1.push({
      title: `IMEI ${i18next.t('station.details.dashboard.row')} ${row}`,
      exportFn: (rowData) => rowData.rows.at(index)?.imei,
    })
    auxCol2.push({
      title: `FW ${i18next.t('station.details.dashboard.row')} ${row}`,
      exportFn: (rowData) => rowData.rows.at(index)?.fwVersion,
    })
    auxCol3.push({
      title: `Slots ${i18next.t('station.details.dashboard.row')} ${row}`,
      exportFn: (rowData) => rowData.rows.at(index)?.slots?.length ?? 0,
    })
  })

  // we use auxCols to use a single loop & keep the order of the columns
  const stationsColumns = columns.concat(auxCol1).concat(auxCol2).concat(auxCol3)

  return exportCsv(stationsColumns, rows)
}
