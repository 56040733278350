import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { stationsActions } from '../../../redux/actions/stations_actions'
import Dashboard from '../../dashboard'
import PickUpRequestView from './page'

const PickUpRequest = (props) => {
  return <Dashboard component={<PickUpRequestView {...props} />} />
}

function mapState(state) {
  const { batteriesReducer, stationsReducer } = state
  return { batteriesReducer, stationsReducer }
}

const actionCreators = {
  getBatteryRequests: batteriesActions.getBatteryRequests,
  getDeliverableForLogged: batteriesActions.getDeliverableForLogged,
  deliverToCustomer: batteriesActions.deliverToCustomer,
  getToBatteriesDeliveryForAdmin:
    stationsActions.getToBatteriesDeliveryForAdmin,
  getById: stationsActions.getStationById,
  updateDelivery: batteriesActions.deliveryUpdateStation,
  getByZone: stationsActions.getStationsAssignedToZone,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(PickUpRequest)
