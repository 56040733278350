import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import BatteriesAssignedToOperators from '../../batteries/assignedToOperator'

import { connect } from 'react-redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'

import { isEqual } from '../../../helpers/compareArrays'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AssignmentBatteries = ({
  isOpen,
  onClose,
  rowSelected,
  batteriesReducer,
  getAllUnassignedZones,
  getBatteriesAssignedToOperator,
  batteriesassignToOperator,
  batteriesunassignFromOperator,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const { batteriesAssignedToOperator, batteriesUnassigned } = batteriesReducer

  const [selectedBatteries, setselectedBatteries] = useState([])
  const [tableDataBatteries, settableDataBatteries] = useState([])
  const [readOnlyConfiguration, setreadOnlyConfiguration] = useState(true)

  useEffect(() => {
    settableDataBatteries([
      ...batteriesUnassigned,
      ...batteriesAssignedToOperator,
    ])
  }, [batteriesAssignedToOperator, batteriesUnassigned])

  const handleEnter = () => {
    const { id } = rowSelected
    id && getBatteriesAssignedToOperator(id)
    getAllUnassignedZones()
  }
  const handleClose = () => {
    onClose()
    setreadOnlyConfiguration(true)
  }

  const handleSelectedBatteriesUpdate = (rows) => {
    setselectedBatteries(rows)
  }

  const handleSaveAssignment = () => {
    if (!isEqual(batteriesAssignedToOperator, selectedBatteries)) {
      let batteriesToAssign = []
      let batteriesToUnassign = []

      batteriesAssignedToOperator.map((i) => {
        !selectedBatteries.find((s) => s.id === i.id) &&
          batteriesToUnassign.push(i)
      })
      selectedBatteries.map((i) => {
        !batteriesAssignedToOperator.find((s) => s.id === i.id) &&
          batteriesToAssign.push(i)
      })

      const bodyUnassignBatteries = {
        batteries: batteriesToUnassign.map((i) => i.id),
        operatorCiaId: rowSelected.id,
      }
      const bodyAssignBatteries = {
        batteries: batteriesToAssign.map((i) => i.id),
        operatorCiaId: rowSelected.id,
      }

      batteriesToAssign.length > 0 &&
        batteriesassignToOperator(bodyAssignBatteries)
      batteriesToUnassign.length > 0 &&
        batteriesunassignFromOperator(bodyUnassignBatteries)

      handleClose()
    }
  }

  const handleConfirmBtn = () => {
    setreadOnlyConfiguration((prev) => !prev)
    !readOnlyConfiguration && handleSaveAssignment()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('operator.assignment.titleDialog')} ({rowSelected.name})
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setreadOnlyConfiguration(true)}
          >
            {!readOnlyConfiguration && t('operator.assignment.cancelBtn')}
          </Button>
          <Button autoFocus color="inherit" onClick={handleConfirmBtn}>
            {readOnlyConfiguration
              ? t('operator.assignment.confirmBtnEdit')
              : t('operator.assignment.confirmBtnSave')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <BatteriesAssignedToOperators
          addTitle={false}
          onSelectionUpdate={handleSelectedBatteriesUpdate}
          data={
            readOnlyConfiguration
              ? batteriesAssignedToOperator
              : tableDataBatteries
          }
          preSelectedRows={batteriesAssignedToOperator}
          readOnlyConfiguration={readOnlyConfiguration}
        />
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { batteriesReducer } = state
  return { batteriesReducer }
}
const actionCreators = {
  getAllBatteries: batteriesActions.getAllBatteries,
  getAllUnassignedZones: batteriesActions.getAllUnassigned,
  batteriesassignToOperator: batteriesActions.assignToOperator,
  batteriesunassignFromOperator: batteriesActions.unassignFromOperator,
  getBatteriesAssignedToOperator:
    batteriesActions.getBatteriesAssignedToOperator,
}

export default connect(mapState, actionCreators)(AssignmentBatteries)
