import { supervisorConstant } from '../../constants/supervisor_constants'

const initialState = {
  loadingResults: true,
  results: [],
  supervisor: {},
  supervisorsByOperatorId: [],
}

export function supervisorsReducer(state = initialState, action) {
  switch (action.type) {
    case supervisorConstant.GET_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case supervisorConstant.GET_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        supervisorsByOperatorId: action.results,
      }
    case supervisorConstant.GET_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        supervisorsByOperatorId: [],
      }

    case supervisorConstant.GET_BY_SUPERVISOR_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case supervisorConstant.GET_BY_SUPERVISOR_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        supervisor: action.results,
      }
    case supervisorConstant.GET_BY_SUPERVISOR_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        supervisor: {},
      }
    case supervisorConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case supervisorConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case supervisorConstant.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }

    default:
      return state
  }
}
