import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { createRef, useEffect, useState } from 'react'

import { formatDateToInsertIntoComponent } from '../../shared/formatDate'

import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'

import CustomersByDiscounts from '../../customers/byDiscounts'

import { discountsActions } from '../../../redux/actions/discounts_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  gridContainer: {
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({
  isOpen,
  onClose,
  create,
  updateSetting,
  selectedRow,
  update,
  customer,
}) => {
  const tableRef = createRef()
  const { t } = useTranslation()
  const classes = useStyles()

  const [since, setsince] = useState()
  const [percent, setpercent] = useState()
  const [until, setuntil] = useState()
  const [preSelectedRows, setpreSelectedRows] = useState([])
  const [disableBtn, setdisableBtn] = useState(false)
  const [readOnlyConfiguration, setreadOnlyConfiguration] = useState(false)

  useEffect(() => {
    const { dataManager } = tableRef.current || { dataManager: {} }

    const filteredCustomersChecked =
      (dataManager &&
        dataManager.data &&
        dataManager.data.filter((i) => i.tableData.checked)) ||
      []
    setdisableBtn(
      !since || !percent || !until || filteredCustomersChecked.length === 0,
    )
  }, [since, percent, until, tableRef])

  const handleClose = () => {
    onClose()

    setsince()
    setpercent()
    setuntil()
    setreadOnlyConfiguration()
  }

  const handleEnter = () => {
    if (updateSetting) {
      const { applySince, applyUntil, percent, customers } = selectedRow
      setpreSelectedRows(customers)
      setsince(formatDateToInsertIntoComponent(applySince))
      setuntil(formatDateToInsertIntoComponent(applyUntil))
      setpercent(percent)
      setreadOnlyConfiguration(preSelectedRows.alreadyUsedToBill)
    }
  }

  const handleConfirm = () => {
    const { dataManager } = tableRef.current

    const filteredCustomersChecked =
      (dataManager &&
        dataManager.data &&
        dataManager.data.filter((i) => i.tableData.checked)) ||
      []

    const body = {
      percent: parseFloat(percent),
      customers: filteredCustomersChecked.map((i) => i.id),
      applySince: since,
      applyUntil: until,
    }
    updateSetting ? update({ ...body, id: selectedRow.id }) : create(body)

    handleClose()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('discounts.dialogs.create.dialogTitle')}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleConfirm}
            disabled={updateSetting ? false : disableBtn}
          >
            {t('discounts.dialogs.create.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item md={8}>
          <CustomersByDiscounts
            // readOnlyConfiguration={readOnlyConfiguration}
            tableRef={tableRef}
            preSelectedRows={
              updateSetting ? (customer && [customer]) || preSelectedRows : []
            }
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            label={t('discounts.dialogs.create.labels.percent')}
            fullWidth
            value={percent}
            type={'number'}
            onChange={(e) => setpercent(e.target.value)}
            disabled={readOnlyConfiguration}
          />

          <TextField
            fullWidth
            value={since}
            type="date"
            onChange={(e) => setsince(e.target.value)}
            label={t('discounts.dialogs.create.labels.since')}
            disabled={readOnlyConfiguration}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            value={until}
            type="date"
            onChange={(e) => setuntil(e.target.value)}
            label={t('discounts.dialogs.create.labels.until')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}
const actionCreators = {
  create: discountsActions.create,
  update: discountsActions.update,
}

export default connect(mapState, actionCreators)(Create)
