import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'
import Create from './dialogs/create'

import { connect } from 'react-redux'
import { supervisorsActions } from '../../redux/actions/supervisor_action'

import { getOperatorId } from '../../helpers/operator'

import ModalConfirm from '../shared/modalconfirm'

import UpdateSupervisor from './dialogs/update'

const SupervisorsView = ({
  getAllsupervisors,
  supervisorsReducer,
  getSupervisorByOperatorId,
  canOperate,
  cannotOperate,
  deleteSup,
}) => {
  const { t } = useTranslation()

  const [isDialogCreateOpen, setisDialogCreateOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState()
  const [isDialogDisableOpen, setisDialogDisableOpen] = useState(false)
  const [isDialogUpdateOpen, setisDialogUpdateOpen] = useState(false)
  const [isDialogRemove, setisDialogRemove] = useState(false)

  useEffect(() => {
    getSupervisorByOperatorId(getOperatorId())
  }, [])

  const handleConfirmDisable = (reason) => {
    cannotOperate({
      id: selectedRow.id,
      reasons: [reason],
    })
  }
  const handleAction = (rowData, action) => {
    rowData && setselectedRow(rowData)
    switch (action) {
      case 'add':
        setisDialogCreateOpen(true)
        break
      case 'update':
        setisDialogUpdateOpen(true)
        break
      case 'disable':
        setisDialogDisableOpen(true)
        break
      case 'enable':
        canOperate(rowData.id)
        break
      case 'remove':
        setisDialogRemove(true)
        break

      default:
        break
    }
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('supervisors.page.table.title')}
        isLoading={supervisorsReducer.loadingResults}
        columns={[
          {
            title: t('supervisors.page.table.columns.name'),
            field: 'fullName',
          },
          { title: t('supervisors.page.table.columns.email'), field: 'email' },
          {
            title: t('supervisors.page.table.columns.contactPhone'),
            field: 'phone',
          },
        ]}
        data={supervisorsReducer.supervisorsByOperatorId}
        actions={[
          {
            icon: 'add',
            tooltip: t('supervisors.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => handleAction(rowData, 'add'),
          },
          {
            icon: 'edit',
            tooltip: t('supervisors.page.table.actions.edit'),
            onClick: (event, rowData) => handleAction(rowData, 'update'),
          },
          (rowData) => ({
            icon: 'remove_circle_outline',
            tooltip: t('supervisors.page.table.actions.disable'),
            onClick: (event, rowData) => handleAction(rowData, 'disable'),
            hidden: rowData.cannotOperate,
            iconProps: {
              style: { color: rowData.cannotOperate ? 'green' : 'red' },
            },
          }),
          (rowData) => ({
            icon: 'check_circle_outline',
            tooltip: t('supervisors.page.table.actions.enable'),
            onClick: (event, rowData) => handleAction(rowData, 'enable'),
            hidden: !rowData.cannotOperate,
            iconProps: {
              style: { color: !rowData.cannotOperate ? 'red' : 'green' },
            },
          }),
          {
            icon: 'delete',
            tooltip: 'Remove',
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
          },
        ]}
      />
      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setisDialogCreateOpen(false)}
      />

      <UpdateSupervisor
        isOpen={isDialogUpdateOpen}
        onClose={() => setisDialogUpdateOpen(false)}
        rowSelected={selectedRow}
      />
      <ModalConfirm
        isOpen={isDialogDisableOpen}
        onClose={() => setisDialogDisableOpen(false)}
        onConfirm={handleConfirmDisable}
        title={`${t('supervisors.page.confirmModal.title')} (${
          selectedRow && selectedRow.fullName
        })`}
        label={t('supervisors.page.confirmModal.label')}
        inputAvaiable={true}
      />

      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteSup(selectedRow.id)}
        title={`Eliminar supervisor seleccionado (${
          selectedRow && selectedRow.fullName
        })`}
        body={'Confirmación: ¿Quieres eliminar el supervisor seleccionado?'}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { supervisorsReducer } = state
  return { supervisorsReducer }
}

const actionCreators = {
  getAllsupervisors: supervisorsActions.getAllsupervisors,
  getSupervisorByOperatorId: supervisorsActions.getSupervisorByOperatorId,
  canOperate: supervisorsActions.canOperate,
  cannotOperate: supervisorsActions.cannotOperate,
  deleteSup: supervisorsActions.deleteSup,
}

export default connect(mapState, actionCreators)(SupervisorsView)
