export const swapsConstants = {
  GET_ALL_REQUEST: 'SWAPS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'SWAPS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'SWAPS_GET_ALL_FAILURE',

  GET_BY_ID_REQUEST: 'SWAPS_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'SWAPS_GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'SWAPS_GET_BY_ID_FAILURE',

  RESERVE_REQUEST: 'RESERVE_REQUEST',
  RESERVE_SUCCESS: 'RESERVE_SUCCESS',
  RESERVE_FAILURE: 'RESERVE_FAILURE',
}
