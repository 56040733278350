import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../../shared/customMaterialTable'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function SwapList({ isOpen, onClose, data, selectedData }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleClose}>
              {t('station.details.swapList.dialogTitle')} (
              {selectedData.address})
            </Button>
          </Toolbar>
        </AppBar>
        <div>
          <CustomMaterialTable
            title={t('station.details.swapList.table.title')}
            columns={[
              {
                title: t('station.details.swapList.table.columns.reserveDate'),
                field: 'reserveDate',
              },
              {
                title: t(
                  'station.details.swapList.table.columns.finishReserveDate',
                ),
                field: 'finishReserveDate',
              },
              {
                title: t('station.details.swapList.table.columns.stationPrice'),
                field: 'totalPrice',
              },
              {
                title: t('station.details.swapList.table.columns.batteryIdIn'),
                field: 'batteryInserted',
              },
              {
                title: t('station.details.swapList.table.columns.batteryIdOut'),
                field: 'batteryExtracted',
              },
              {
                title: t('station.details.swapList.table.columns.kwhIn'),
                field: 'kwhIn',
              },
              {
                title: t('station.details.swapList.table.columns.kwhOut'),
                field: 'kwhOut',
              },
              {
                title: t('station.details.swapList.table.columns.deltaKwh'),
                field: 'deltaKwh',
              },
              {
                title: t('station.details.swapList.table.columns.user'),
                field: 'customerFullName',
              },
              {
                title: t(
                  'station.details.swapList.table.columns.ammountPerCharge',
                ),
                field: 'totalPriceByKwhsUsed',
              },
              {
                title: t(
                  'station.details.swapList.table.columns.ammountPerDischarge',
                ),
                field: 'totalPriceByKwhsChargedOutOfStation',
              },
              {
                title: t('station.details.swapList.table.columns.currency'),
                field: 'currency',
              },
            ]}
            data={data}
            // actions={[
            //   {
            //     icon: "add",
            //     tooltip: "add",
            //     isFreeAction: true,
            //     onClick: (event, rowData) => alert("You saved " + rowData.name),
            //   },
            // ]}
          />
        </div>
      </Dialog>
    </div>
  )
}
