import React from 'react'
import { Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, auth, path, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (auth ? <Component {...props} /> : null)}
    />
  )
}

export default PrivateRoute
