import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../../shared/customMaterialTable'

import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { customerActions } from '../../../redux/actions/customers_actions'
import { documentsActions } from '../../../redux/actions/documents_actions'
import CustomerPendingValidation from '../documentation/index'
import { isSacUser } from '../../../helpers/roles'

const PendingCustomer = ({
  addTitle = true,
  getPendingValidate,
  customerReducer,
  getByCustomerId,
  documentsReducer,
  getAllDocTypes,
  getAllCountries,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { customersPendingValidate } = customerReducer
  const [selectedRow, setselectedRow] = useState({})
  const [isDocumentsDialogOpen, setisDocumentsDialogOpen] = useState(false)

  const handleOpenDocuments = (rowData) => {
    dispatch(customerActions.openValidationsModal())
    document.getElementById('dashboard').scrollTo({ top: 0, behavior: 'auto' })
    setselectedRow(rowData)
    setisDocumentsDialogOpen(true)
  }

  useEffect(() => {
    getPendingValidate()
    getAllDocTypes()
    getAllCountries()

    return () => dispatch(customerActions.closeValidationsModal())
  }, [])

  return (
    <React.Fragment>
      <CustomMaterialTable
        saveFilters
        tab={'pendingValidateClientsHome'}
        isLoading={customerReducer.loadingResults}
        title={addTitle ? t('customers.pendingValidate.table.title') : ''}
        exportButton={!isSacUser()}
        columns={[
          {
            title: t('customers.pendingValidate.table.columns.created'),
            field: 'created',
            render: (rowData) => {
              return moment(rowData.created).format('DD/MM/YYYY HH:mm')
            },
          },
          {
            title: t('customers.pendingValidate.table.columns.name'),
            field: 'fullName',
          },
          {
            title: t('customers.pendingValidate.table.columns.email'),
            field: 'email',
          },
          {
            title: t('customers.pendingValidate.table.columns.contactPhone'),
            field: 'phone',
          },
          {
            title: t('customers.pendingValidate.table.columns.dni'),
            field: 'idDocCode',
          },
        ]}
        data={customersPendingValidate}
        actions={[
          (rowData) => ({
            icon: 'attach_file',
            tooltip: t('customers.pendingValidate.table.actions.validate'),
            onClick: (event, rowData) => handleOpenDocuments(rowData),
            tooltip: !rowData.cannotOperate
              ? t('customers.page.table.actions.reviewDocuments')
              : t('customers.page.table.actions.validateDocuments'),
            iconProps: {
              style: {
                color: !rowData.cannotOperate ? 'green' : 'red',
              },
            },
          }),
        ]}
      />

      {customerReducer.validationModalOpened && (
        <CustomerPendingValidation
          open={customerReducer.validationModalOpened}
          customerId={selectedRow.id}
          customerData={selectedRow}
          onClose={() => {
            dispatch(customerActions.closeValidationsModal())
            dispatch(customerActions.clearSelectedCustomer())
          }}
        />
      )}
    </React.Fragment>
  )
}

function mapState(state) {
  const { customerReducer, documentsReducer } = state
  return { customerReducer, documentsReducer }
}

const actionCreators = {
  getPendingValidate: customerActions.getPendingValidate,
  getAllDocTypes: customerActions.getAllDocTypes,
  getByCustomerId: documentsActions.getByCustomerId,
  getAllCountries: customerActions.getAllCountries,
}

export default connect(mapState, actionCreators)(PendingCustomer)
