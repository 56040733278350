import React, { useEffect, useState } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import moment from 'moment'
import { connect } from 'react-redux'
import { zonesActions } from '../../redux/actions/zones_actions'

const BatteriesAssignedToZones = ({
  data,
  onSelectionUpdate,
  preSelectedRows,
  addTitle = true,
  readOnlyConfiguration,
  getAllZones,
  zonesReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [displayList, setdisplayList] = useState([])
  useEffect(() => {
    getAllZones()
  }, [])

  useEffect(() => {
    let updatedList = data.map((i) => {
      return {
        ...i,
        zoneData: zonesReducer.results.find(
          (n) => i?.batteryStation?.zoneId === n.id,
        ),
      }
    })
    setdisplayList(updatedList)
  }, [zonesReducer.results])

  return (
    <CustomMaterialTable
      preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
      onSelectionChange={(rows) => onSelectionUpdate(rows)}
      selection={!readOnlyConfiguration}
      title={addTitle ? t('batteries.assignedToZones.table.title') : ''}
      // saveFilters={true}
      columns={[
        {
          title: t('batteries.assignedToZones.table.columns.batteryId'),
          field: 'id',
        },
        {
          title: t('batteries.assignedToZones.table.columns.obtainingDateSwap'),
          field: 'batteryStation.assigned',
          render: (rowData) => {
            return rowData.batteryStation?.assigned
              ? moment(rowData.batteryStation.assigned).format(
                  'DD/MM/YYYY HH:mm',
                )
              : ''
          },
        },
        {
          title: t('batteries.assignedToZones.table.columns.imei'),
          field: 'imei',
        },
        {
          title: t('batteries.assignedToZones.table.columns.zone'),
          field: 'batteryStation.zoneId',
        },
        {
          title: t('batteries.assignedToZones.table.columns.rates'),
          field: 'rate',
        },
        {
          title: t('batteries.assignedToZones.table.columns.batteryPackId'),
          field: 'batteryPackId',
        },
      ]}
      data={data}
    />
  )
}

function mapState(state) {
  const { zonesReducer } = state
  return { zonesReducer }
}

const actionCreators = {
  getAllZones: zonesActions.getAllZones,
}

export default connect(mapState, actionCreators)(BatteriesAssignedToZones)
