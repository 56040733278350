import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'
import Create from './dialogs/create'
import Edit from './dialogs/edit'

import { connect } from 'react-redux'
import { productActions } from '../../redux/actions/products_actions'
import ModalConfirm from '../shared/modalconfirm'
import { isFinancial } from '../../helpers/roles'

const ProductView = ({ getAll, productReducer, deleteP }) => {
  const { t } = useTranslation()

  const [isDialogCreateOpen, setisDialogCreateOpen] = useState(false)
  const [isDialogEditOpen, setisDialogEditOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState()
  const [isDialogRemove, setisDialogRemove] = useState(false)

  useEffect(() => {
    getAll()
  }, [])

  const handleAction = (rowData, action) => {
    rowData && setselectedRow(rowData)
    switch (action) {
      case 'add':
        setisDialogCreateOpen(true)
        break
      case 'edit':
        setisDialogEditOpen(true)
        break
      case 'remove':
        setisDialogRemove(true)
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('product.page.table.title')}
        columns={[
          {
            title: t('product.page.table.columns.code'),
            field: 'code',
          },
          {
            title: t('product.page.table.columns.description'),
            field: 'description',
          },
          {
            title: t('product.page.table.columns.price'),
            field: 'price',
            render: (rowData) => {
              return rowData.price + '€'
            },
          },
        ]}
        data={productReducer.results}
        actions={[
          {
            icon: 'add',
            tooltip: t('operatorUser.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => handleAction(rowData, 'add'),
            hidden: isFinancial(),
          },
          {
            icon: 'edit',
            tooltip: t('operatorUser.page.table.actions.edit'),
            onClick: (event, rowData) => handleAction(rowData, 'edit'),
            hidden: isFinancial(),
          },
          {
            icon: 'delete',
            tooltip: 'Remove',
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
            hidden: isFinancial(),
          },
        ]}
        exportButton={!isFinancial()}
      />
      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setisDialogCreateOpen(false)}
      />
      <Edit
        isOpen={isDialogEditOpen}
        selected={selectedRow}
        onClose={() => setisDialogEditOpen(false)}
      />

      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteP(selectedRow.id)}
        title={`Eliminar producto seleccionado (${
          selectedRow && selectedRow.email
        })`}
        body={'Confirmación: ¿Quieres eliminar el usuario seleccionado?'}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { productReducer } = state
  return { productReducer }
}

const actionCreators = {
  getAll: productActions.getAll,
  deleteP: productActions.deleteProduct,
}

export default connect(mapState, actionCreators)(ProductView)
