import { accountUserConstant } from '../../constants/accountUser_constants'

const initialState = {
  loadingResults: true,
  results: [],
  accountUser: {},
}

export function accountUserReducer(state = initialState, action) {
  switch (action.type) {
    case accountUserConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case accountUserConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case accountUserConstant.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }

    default:
      return state
  }
}
