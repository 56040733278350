import { subscriptionConstants } from '../../constants/subscriptions_constants'

const initialState = {
  loadingResults: true,
  results: [],
  subscription: {},
}

export function subscriptionsReducer(state = initialState, action) {
  switch (action.type) {
    case subscriptionConstants.GET_BY_SUBSCRIPTION_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case subscriptionConstants.GET_BY_SUBSCRIPTION_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        subscription: action.results,
      }
    case subscriptionConstants.GET_BY_SUBSCRIPTION_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        subscription: {},
      }
    case subscriptionConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case subscriptionConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case subscriptionConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }

    default:
      return state
  }
}
