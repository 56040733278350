export const ratesConstants = {
  GET_ALL_REQUEST: 'RATES_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'RATES_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'RATES_GET_ALL_FAILURE',

  GET_BY_RATE_ID_REQUEST: 'RATES_GET_BY_RATE_ID_REQUEST',
  GET_BY_RATE_ID_SUCCESS: 'RATES_GET_BY_RATE_ID_SUCCESS',
  GET_BY_RATE_ID_FAILURE: 'RATES_GET_BY_RATE_ID_FAILURE',

  GET_BY_CUSTOMER_ID_REQUEST: 'RATES_GET_BY_CUSTOMER_ID_REQUEST',
  GET_BY_CUSTOMER_ID_SUCCESS: 'RATES_GET_BY_CUSTOMER_ID_SUCCESS',
  GET_BY_CUSTOMER_ID_FAILURE: 'RATES_GET_BY_CUSTOMER_ID_FAILURE',

  GET_BY_ZONE_ID_REQUEST: 'RATES_GET_BY_ZONE_ID_REQUEST',
  GET_BY_ZONE_ID_SUCCESS: 'RATES_GET_BY_ZONE_ID_SUCCESS',
  GET_BY_ZONE_ID_FAILURE: 'RATES_GET_BY_ZONE_ID_FAILURE',
}
