import React from 'react'

import Draggable from 'react-draggable'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { connect } from 'react-redux'
import { uiActions } from '../../redux/actions/ui_actions'

const componentStyle = {
  main: {
    backgroundColor: '#fafafa',
    zIndex: '99',
    position: 'absolute',
    width: '50%',
    boxShadow: '0px 2px 6px 2px #767879',
    // borderRadius: "1.5%",
    minHeight: '50vh',
  },
  draggable: {
    width: '100%',
    height: '20px',
    backgroundColor: '#25282a9e',
    cursor: 'all-scroll',
  },
  mainContent: {
    padding: '0 4%',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4%',
  },
}

const DraggableDashboard = ({
  savePositionDraggablePosition,
  uiReducer,
  setDraggableDashoardIsOpen,
}) => {
  const { draggableDashboard } = uiReducer //position, isOpen

  const handleDrag = (e, ui) => {
    const { x, y } = ui
    savePositionDraggablePosition({ x, y })
  }
  return draggableDashboard.isOpen ? (
    <Draggable
      axis="both"
      handle=".handle"
      defaultPosition={draggableDashboard.position}
      onDrag={handleDrag}
    >
      <div style={componentStyle.main}>
        <div className="handle" style={componentStyle.draggable}></div>

        <div style={componentStyle.mainContent}>
          <div style={componentStyle.headerContent}>
            <p>Dashboard title</p>
            <IconButton
              aria-label="delete"
              onClick={() => setDraggableDashoardIsOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          info baterías a modo de dasboard de fondo con datos a tiempo real (
          temperaturas, datos de cargas, etc....)
        </div>
      </div>
    </Draggable>
  ) : null
}

function mapState(state) {
  const { uiReducer } = state
  return { uiReducer }
}

const actionCreators = {
  savePositionDraggablePosition: uiActions.savePositionDraggablePosition,
  setDraggableDashoardIsOpen: uiActions.setDraggableDashoardIsOpen,
}

export default connect(mapState, actionCreators)(DraggableDashboard)
