import { ratesConstants } from '../../constants/rates_constants'

const initialState = {
  loadingResults: true,
  results: [],
  rate: {},
  ratesByCustomer: [],
  ratesByZone: [],
}

export function ratesReducer(state = initialState, action) {
  switch (action.type) {
    case ratesConstants.GET_BY_ZONE_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case ratesConstants.GET_BY_ZONE_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        ratesByZone: action.results,
      }
    case ratesConstants.GET_BY_ZONE_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        ratesByZone: [],
      }

    case ratesConstants.GET_BY_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case ratesConstants.GET_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        ratesByCustomer: action.results,
      }
    case ratesConstants.GET_BY_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        ratesByCustomer: [],
      }

    case ratesConstants.GET_BY_RATE_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case ratesConstants.GET_BY_RATE_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        rate: action.results,
      }
    case ratesConstants.GET_BY_RATE_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        rate: {},
      }
    case ratesConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case ratesConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case ratesConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }

    default:
      return state
  }
}
