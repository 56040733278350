export const formatGender = (gender) => {
  switch (gender) {
    case 'undisclosed':
      return 'shared.undisclosed'
    case 'female':
      return 'shared.female'
    case 'male':
    default:
      return 'shared.male'
  }
}
