export const stationsConstants = {
  GET_ALL_REQUEST: 'STATIONS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'STATIONS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'STATIONS_GET_ALL_FAILURE',

  GET_BY_STATION_ID_REQUEST: 'STATIONS_GET_BY_STATION_ID_REQUEST',
  GET_BY_STATION_ID_SUCCESS: 'STATIONS_GET_BY_STATION_ID_SUCCESS',
  GET_BY_STATION_ID_FAILURE: 'STATIONS_GET_BY_STATION_ID_FAILURE',

  GET_WEEK_DAYS_REQUEST: 'STATIONS_GET_WEEK_DAYS_REQUEST',
  GET_WEEK_DAYS_SUCCESS: 'STATIONS_GET_WEEK_DAYS_SUCCESS',
  GET_WEEK_DAYS_FAILURE: 'STATIONS_GET_WEEK_DAYS_FAILURE',

  GET_DYNAMICS_BY_STATION_ID_REQUEST:
    'STATIONS_GET_DYNAMICS_BY_STATION_ID_REQUEST',
  GET_DYNAMICS_BY_STATION_ID_SUCCESS:
    'STATIONS_GET_DYNAMICS_BY_STATION_ID_SUCCESS',
  GET_DYNAMICS_BY_STATION_ID_FAILURE:
    'STATIONS_GET_DYNAMICS_BY_STATION_ID_FAILURE',

  GET_ALL_DYNAMICS_REQUEST: 'STATIONS_GET_ALL_DYNAMICS_REQUEST',
  GET_ALL_DYNAMICS_SUCCESS: 'STATIONS_GET_ALL_DYNAMICS_SUCCESS',
  GET_ALL_DYNAMICS_FAILURE: 'STATIONS_GET_ALL_DYNAMICS_FAILURE',

  GET_STATIONS_ASSIGNED_TO_ZONE_REQUEST:
    'STATIONS_GET_STATIONS_ASSIGNED_TO_ZONE_REQUEST',
  GET_STATIONS_ASSIGNED_TO_ZONE_SUCCESS:
    'STATIONS_GET_STATIONS_ASSIGNED_TO_ZONE_SUCCESS',
  GET_STATIONS_ASSIGNED_TO_ZONE_FAILURE:
    'STATIONS_GET_STATIONS_ASSIGNED_TO_ZONE_FAILURE',

  GET_STATIONS_ASSIGNED_TO_OPERATOR_REQUEST:
    'STATIONS_GET_STATIONS_ASSIGNED_TO_OPERATOR_REQUEST',
  GET_STATIONS_ASSIGNED_TO_OPERATOR_SUCCESS:
    'STATIONS_GET_STATIONS_ASSIGNED_TO_OPERATOR_SUCCESS',
  GET_STATIONS_ASSIGNED_TO_OPERATOR_FAILURE:
    'STATIONS_GET_STATIONS_ASSIGNED_TO_OPERATOR_FAILURE',

  GET_ALL_UNASSIGNED_REQUEST: 'STATIONS_GET_ALL_UNASSIGNED_REQUEST',
  GET_ALL_UNASSIGNED_SUCCESS: 'STATIONS_GET_ALL_UNASSIGNED_SUCCESS',
  GET_ALL_UNASSIGNED_FAILURE: 'STATIONS_GET_ALL_UNASSIGNED_FAILURE',

  GET_UNASSIGNED_BY_ZONE_REQUEST: 'STATIONS_GET_UNASSIGNED_BY_ZONE_REQUEST',
  GET_UNASSIGNED_BY_ZONE_SUCCESS: 'STATIONS_GET_UNASSIGNED_BY_ZONE_SUCCESS',
  GET_UNASSIGNED_BY_ZONE_FAILURE: 'STATIONS_GET_UNASSIGNED_BY_ZONE_FAILURE',

  GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST:
    'STATIONS_GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST',
  GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS:
    'STATIONS_GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS',
  GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE:
    'STATIONS_GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE',

  GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST:
    'STATIONS_GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST',
  GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS:
    'STATIONS_GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS',
  GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE:
    'STATIONS_GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE',

  GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_REQUEST:
    'STATIONS_GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_REQUEST',
  GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_SUCCESS:
    'STATIONS_GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_SUCCESS',
  GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_FAILURE:
    'STATIONS_GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_FAILURE',

  SET_OUT_OF_SERVICE_SUCCESS: 'SET_OUT_OF_SERVICE_SUCCESS',

  GET_STATIONS_FOR_LOGGED_REQUEST: 'STATIONS_LOGGED_REQUEST',
  GET_STATIONS_FOR_LOGGED_SUCCESS: 'STATIONS_LOGGED_SUCCESS',
  GET_STATIONS_FOR_LOGGED_FAILURE: 'STATIONS_LOGGED_FAILURE',
}
