import React, { useEffect } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { supervisorsActions } from '../../redux/actions/supervisor_action'

const SupervisorsByOperators = ({
  operatorId,
  getSupervisorByOperatorId,
  supervisorsReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getSupervisorByOperatorId(operatorId)
  }, [])

  return (
    <CustomMaterialTable
      title={t('supervisors.byOperators.table.title')}
      columns={[
        {
          title: t('supervisors.byOperators.table.columns.name'),
          field: 'fullName',
        },
        {
          title: t('supervisors.byOperators.table.columns.email'),
          field: 'email',
        },
        {
          title: t('supervisors.byOperators.table.columns.contactPhone'),
          field: 'phone',
        },
        // {
        //   title: t("supervisors.byOperators.table.columns.station"),
        //   field: "station",
        // },
        // {
        //   title: t("supervisors.byOperators.table.columns.enabled"),
        //   field: "enabled",
        // },
      ]}
      data={supervisorsReducer.supervisorsByOperatorId}
    />
  )
}

function mapState(state) {
  const { supervisorsReducer } = state
  return { supervisorsReducer }
}
const actionCreators = {
  getSupervisorByOperatorId: supervisorsActions.getSupervisorByOperatorId,
}

export default connect(mapState, actionCreators)(SupervisorsByOperators)
