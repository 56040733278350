import {
  AppBar,
  Button,
  Dialog,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { alertActions } from '../../../redux/actions/alert_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: '1.3rem',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EditPickUp = ({
  onClose,
  open,
  updateDelivery,
  item,
  stationsReducer,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedStation, setSelectedStation] = useState({})

  useEffect(() => {
    setSelectedStation(
      stationsReducer.stationsAssignedToZone.find(
        (station) => station.nameAndAddress === item.stationNameAndAddress,
      ),
    )
  }, [stationsReducer])

  const handleClose = () => onClose()

  const handleSubmit = (id, stationId) => {
    if (!id || !stationId) {
      dispatch(
        alertActions.error(t('batteries.pickUpRequest.validations.noStation')),
      )
    } else {
      updateDelivery(id, stationId)
      onClose()
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>
            {t('batteries.pickUpRequest.actions.editModal.title')}
          </Typography>

          <Button
            autoFocus
            color="inherit"
            onClick={() => handleSubmit(item?.id, selectedStation?.id)}
          >
            {i18next.t('batteries.pickUpRequest.actions.confirm')}
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3} style={{ marginTop: 70 }}>
        <Grid item md={3} implementation="css" smDown component={Hidden} />
        <Grid item md={5}>
          <Autocomplete
            options={stationsReducer.stationsAssignedToZone}
            getOptionLabel={(option) => option.nameAndAddress}
            value={selectedStation}
            onChange={(event, value) => setSelectedStation(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                value={selectedStation}
                helperText={''}
                label={i18next.t('batteries.pickUpRequest.form.location')}
                // error={"" === 0 ? false : true}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}

export default connect(mapState, null)(EditPickUp)
