import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'
import Create from './dialogs/create'

import { connect } from 'react-redux'
import { operatorUserActions } from '../../redux/actions/operatorUser_action'

import ModalConfirm from '../shared/modalconfirm'

import Edit from './dialogs/edit'

const OperatorUserView = ({
  getAll,
  operatorUserReducer,
  canOperate,
  cannotOperate,
  deleteOpUser,
}) => {
  const { t } = useTranslation()

  const [isDialogCreateOpen, setisDialogCreateOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState()
  const [isDialogDisableOpen, setisDialogDisableOpen] = useState(false)
  const [isDialogRemove, setisDialogRemove] = useState(false)
  const [isDialogEditOpen, setisDialogEditOpen] = useState(false)

  useEffect(() => {
    getAll()
  }, [])

  const handleConfirmDisable = (reason) => {
    cannotOperate({
      id: selectedRow.id,
      reasons: [reason],
    })
  }
  const handleAction = (rowData, action) => {
    rowData && setselectedRow(rowData)
    switch (action) {
      case 'add':
        setisDialogCreateOpen(true)
        break
      case 'edit':
        setisDialogEditOpen(true)
        break
      case 'disable':
        setisDialogDisableOpen(true)
        break
      case 'enable':
        canOperate(rowData.id)
        break
      case 'remove':
        setisDialogRemove(true)
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('operatorUser.page.table.title')}
        isLoading={operatorUserReducer.loadingResults}
        columns={[
          {
            title: t('operatorUser.page.table.columns.name'),
            field: 'firstName',
          },
          { title: t('operatorUser.page.table.columns.email'), field: 'email' },
          {
            title: t('operatorUser.page.table.columns.contactPhone'),
            field: 'phone',
          },
          {
            title: t('operatorUser.page.table.columns.station'),
            field: 'station',
          },
        ]}
        data={operatorUserReducer.results}
        actions={[
          {
            icon: 'add',
            tooltip: t('operatorUser.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => handleAction(rowData, 'add'),
          },
          {
            icon: 'edit',
            tooltip: t('operatorUser.page.table.actions.edit'),
            onClick: (event, rowData) => handleAction(rowData, 'edit'),
          },
          (rowData) => ({
            icon: 'remove_circle_outline',
            tooltip: t('supervisors.page.table.actions.disable'),
            onClick: (event, rowData) => handleAction(rowData, 'disable'),
            hidden: rowData.cannotOperate,
            iconProps: {
              style: { color: rowData.cannotOperate ? 'green' : 'red' },
            },
          }),
          (rowData) => ({
            icon: 'check_circle_outline',
            tooltip: t('supervisors.page.table.actions.enable'),
            onClick: (event, rowData) => handleAction(rowData, 'enable'),
            hidden: !rowData.cannotOperate,
            iconProps: {
              style: { color: !rowData.cannotOperate ? 'red' : 'green' },
            },
          }),
          {
            icon: 'delete',
            tooltip: 'Remove',
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
          },
        ]}
      />
      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setisDialogCreateOpen(false)}
      />
      <Edit
        isOpen={isDialogEditOpen}
        onClose={() => setisDialogEditOpen(false)}
        rowSelected={selectedRow}
      />
      <ModalConfirm
        isOpen={isDialogDisableOpen}
        onClose={() => setisDialogDisableOpen(false)}
        onConfirm={handleConfirmDisable}
        title={`${t('supervisors.page.confirmModal.title')} (${
          selectedRow && selectedRow.name
        })`}
        label={t('supervisors.page.confirmModal.label')}
        inputAvaiable={true}
      />

      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteOpUser(selectedRow.id)}
        title={`Eliminar usuario operador seleccionado (${
          selectedRow && selectedRow.name
        })`}
        body={
          'Confirmación: ¿Quieres eliminar el usuario operador seleccionado?'
        }
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { operatorUserReducer } = state
  return { operatorUserReducer }
}

const actionCreators = {
  getAll: operatorUserActions.getAll,
  canOperate: operatorUserActions.canOperate,
  cannotOperate: operatorUserActions.cannotOperate,
  deleteOpUser: operatorUserActions.deleteOpUser,
}

export default connect(mapState, actionCreators)(OperatorUserView)
