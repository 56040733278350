import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isCauUser, isManager, isSacUser, isTechnician } from '../../helpers/roles'
import { incidentsActions } from '../../redux/actions/incidents_actions'
import { swapsService } from '../../services/swapsServices'
import CustomMaterialTable from '../shared/customMaterialTable'
import { displayWithTooltip } from '../shared/enshortenLargeData'
import CreateSwapManual from './dialogs/create'

const SwapsManualView = ({
  incidentsReducer,
  getActiveManualSwapsForLogged,
}) => {
  const { t } = useTranslation()

  const [isAddOpen, setIsAddOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [resultItemSwap, setResultItemSwap] = useState({})

  useEffect(() => {
    getActiveManualSwapsForLogged()
  }, [])

  const handleSwap = (item) => {
    setSelectedItem(item)
    swapsService.getForManualSwapTicketAsync(item.id).then(
      (result) => {
        setResultItemSwap(result)
        setIsAddOpen(true)
      },
      (error) => {},
    )
  }

  const handleAdd = () => {
    setIsAddOpen(true)
  }

  return (
    <>
      <CustomMaterialTable
        title={t('swapManual.page.table.title')}
        data={incidentsReducer.swapsManual}
        isLoading={incidentsReducer.loadingResults}
        exportButton={!isTechnician() && !isCauUser() && !isSacUser()}
        columns={[
          {
            title: t('swapManual.page.table.columns.created'),
            field: 'opened',
            render: (rowData) => {
              return moment(rowData.opened).format('DD/MM/YYYY HH:mm')
            },
          },
          {
            title: t('swapManual.page.table.columns.customerName'),
            field: 'customerFullName',
            render: (rowData) => displayWithTooltip(rowData.customerFullName),
          },
          {
            title: t('swapManual.page.table.columns.operator'),
            field: 'operatorCiaName',
          },
          {
            title: t('swapManual.page.table.columns.location'),
            field: 'stationAddress',
            render: (rowData) => displayWithTooltip(rowData.stationAddress),
          },
          {
            title: t('swapManual.page.table.columns.customer'),
            field: 'customerEmail',
          },
        ]}
        actions={
          !isCauUser() && [
            {
              icon: 'add',
              tooltip: 'Agregar',
              isFreeAction: true,
              hidden: !isManager() && !isTechnician(),
              onClick: (event, rowData) => handleAdd(),
            },
            {
              icon: 'assignment_return',
              tooltip: t('swapManual.page.table.actions.swap'),
              onClick: (event, rowData) => handleSwap(rowData),
              hidden: isCauUser(),
            },
          ]
        }
      />

      <CreateSwapManual
        isOpen={isAddOpen}
        selectedItem={selectedItem}
        resultItem={resultItemSwap}
        onClose={() => {
          setIsAddOpen(false)
          setResultItemSwap(null)
        }}
        getActiveManualSwapsForLogged={getActiveManualSwapsForLogged}
      />
    </>
  )
}

function mapState(state) {
  const { swapsReducer, incidentsReducer } = state
  return { swapsReducer, incidentsReducer }
}

const actionCreators = {
  getActiveManualSwapsForLogged: incidentsActions.getActiveManualSwapsForLogged,
}

export default connect(mapState, actionCreators)(SwapsManualView)
