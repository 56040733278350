import i18next from 'i18next'
import { swapsConstants } from '../../constants/swaps_constants'
import { swapsService } from '../../services/swapsServices'
import { alertActions } from './alert_actions'

export const swapsActions = {
  getAll,
  getById,
  reserve,
}

function getById(id) {
  return (dispatch) => {
    dispatch(request())
    swapsService.getById(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: swapsConstants.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: swapsConstants.GET_BY_ID_SUCCESS, payload: results }
  }

  function failure(error) {
    return { type: swapsConstants.GET_BY_ID_FAILURE, error }
  }
}

function getAll(params = {}) {
  return (dispatch) => {
    dispatch(request())
    swapsService.getAll(params).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: swapsConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: swapsConstants.GET_ALL_SUCCESS, payload: results }
  }

  function failure(error) {
    return { type: swapsConstants.GET_ALL_FAILURE, error }
  }
}

function reserve(stationId, batteriesNumber, customerEmail, rows) {
  return (dispatch) => {
    dispatch(request())
    swapsService
      .proposeReservation(stationId, batteriesNumber, customerEmail, rows)
      .then(
        (swapProposal) => {
          swapsService.createReservation(customerEmail, swapProposal).then(
            (results) => {
              dispatch(success(results))
            },
            (error) => {
              dispatch(failure(error))
            },
          )
        },
        (error) => {
          dispatch(failure(error))
        },
      )

    function request() {
      return { type: swapsConstants.RESERVE_REQUEST }
    }

    function success(results) {
      if (results.length < batteriesNumber) {
        dispatch(
          alertActions.success(
            i18next.t('services.swap.reservationCreatedPartially', {
              createdReserves: results.length,
              createdRetries: batteriesNumber - results.length,
            }),
          ),
        )
      } else {
        dispatch(
          alertActions.success(i18next.t('services.swap.reservationCreated')),
        )
      }

      return { type: swapsConstants.RESERVE_SUCCESS, payload: results }
    }

    function failure(error) {
      dispatch(alertActions.error(error))

      return { type: swapsConstants.RESERVE_FAILURE, error }
    }
  }
}
