import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { billingActions } from '../../redux/actions/billing_actions'
import Dashboard from '../dashboard'
import BillingsView from './page'

const Billings = (props) => {
  return <Dashboard component={<BillingsView {...props} />} />
}

function mapState(state) {
  const { billingReducer } = state
  return { billingReducer }
}

const actionCreators = {
  getAll: billingActions.getAll,
  getPaymentIntents: billingActions.getPaymentIntents,
  refundPayment: billingActions.refundPayment,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Billings)
