import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ActionLocales from '../../../constants/locales/ActionLocales'

export default function ConfirmationModal({
  title,
  message,
  open,
  onCancel,
  onConfirm,
  children,
}) {
  const { t } = useTranslation()

  return (
    <Dialog onClose={onCancel} open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent style={{ minWidth: 320 }}>
        <DialogContentText>
          {message ?? t('defaults.confirmation')}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t(ActionLocales.Cancel)}</Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {t(ActionLocales.Confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
