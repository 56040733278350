import { Button, Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

const DocumentZoom = ({
  open,
  onClose,
  onEnter = () => {},
  imageUrl,
  rotateDeg,
}) => {
  const { t } = useTranslation()

  const { selected } = rotateDeg

  const [imageDeg, setImageDeg] = useState(rotateDeg[selected])

  useEffect(() => {
    setImageDeg(rotateDeg[selected])
  }, [rotateDeg])

  const handleEnter = () => onEnter()

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onEnter={handleEnter}
      onClose={onClose}
    >
      <DialogContent>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={7}
            limitToBounds
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div style={{ position: 'absolute', top: 0, zIndex: 999 }}>
                  <Button
                    style={{
                      backgroundColor: '#e41622',
                      color: 'white',
                      margin: 4,
                    }}
                    onClick={() => zoomIn()}
                  >
                    +
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#e41622',
                      color: 'white',
                      margin: 4,
                    }}
                    onClick={() => zoomOut()}
                  >
                    -
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#e41622',
                      color: 'white',
                      margin: 4,
                    }}
                    onClick={() => resetTransform()}
                  >
                    x
                  </Button>
                </div>
                <TransformComponent wrapperStyle={{ overflow: 'visible' }}>
                  <img
                    src={imageUrl}
                    alt="Document image"
                    style={{
                      maxHeight: '85vh',
                      transform: `rotate(${imageDeg}deg)`,
                    }}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DocumentZoom
