import React, { useState } from 'react'

import {
  AppBar,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isManager, isTechnician } from '../../../helpers/roles'
import { validations } from '../../../helpers/validation'
import { alertActions } from '../../../redux/actions/alert_actions'
import store from '../../../redux/store'
import { batteriesService } from '../../../services/batteriesServices'
import { stationsService } from '../../../services/stationsServices'
import { subscriptionsService } from '../../../services/subscriptionsServices'
import { swapsService } from '../../../services/swapsServices'
import ModalConfirm from '../../shared/modalconfirm'

const filter = createFilterOptions()

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CreateSwapManual = ({
  isOpen,
  onClose,
  onConfirm,
  resultItem,
  selectedItem,
  getActiveManualSwapsForLogged,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [openSwapManual, setOpenSwapManual] = useState(false)
  const [isPossibleSwapManual, setIsPossibleSwapManual] = useState(false)
  const [messagesSwapManual, setMessagesSwapManual] = useState('')
  const [client, setClient] = useState('')
  const [clientOptions, setClientOptions] = useState([])
  const [station, setStation] = useState('')
  const [stationOptions, setStationOptions] = useState([])
  const [battery1, setBattery1] = useState('')
  const [battery1Options, setBattery1Options] = useState([])
  const [battery2, setBattery2] = useState('')
  const [battery2Options, setBattery2Options] = useState([])

  const [errors, setErrors] = useState({
    client: { result: false, message: '' },
    station: { result: false, message: '' },
    battery1: { result: false, message: '' },
    battery2: { result: false, message: '' },
  })

  const handleCloseModal = () => {
    onClose()
    setOpenSwapManual(false)
    setIsPossibleSwapManual(false)
    setMessagesSwapManual('')
    setClient('')
    setStation('')
    setBattery1('')
    setBattery2('')
    setClientOptions([])
    setBattery1Options([])
    setBattery2Options([])
    setErrors({
      client: { result: false, message: '' },
      station: { result: false, message: '' },
      battery1: { result: false, message: '' },
      battery2: { result: false, message: '' },
    })
  }

  const handleClick = () => {
    const body = {
      subscriptionId: client[0],
      stationId: station[0],
      batteryPackId1String: battery1[0],
      batteryPackId2String: battery2[0],
      execute: openSwapManual,
      ticketId: selectedItem?.id,
    }

    validateForm()
    if (isFormValid()) {
      swapsService.createSwapManual(body).then(
        (results) => {
          if (!openSwapManual) {
            setOpenSwapManual(true)
            let msjs = results.map((x) => (
              <React.Fragment key={Math.random()}>
                {x}
                <br />
              </React.Fragment>
            ))
            setMessagesSwapManual(msjs)
            setIsPossibleSwapManual(true)
          } else {
            setOpenSwapManual(false)
            handleCloseModal()
            getActiveManualSwapsForLogged()
            store.dispatch(
              alertActions.success(i18next.t('services.swap.success')),
            )
          }
        },
        (errors) => {
          setOpenSwapManual(true)
          let msjs = errors.map((x) => (
            <React.Fragment key={Math.random()}>
              {x}
              <br />
            </React.Fragment>
          ))
          setMessagesSwapManual(msjs)
          setIsPossibleSwapManual(false)
        },
      )
    }
  }

  const handleEnter = () => {
    if (resultItem?.customerId) {
      callHandleClient(
        '',
        resultItem?.subscriptionId,
        resultItem?.customerId,
        true,
      )
      callHandleStation('', resultItem?.stationId, true)
      callHandleBatteries(resultItem?.stationId, true)
    }
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.client = validations.required(client && client[0])
    newErrors.station = validations.required(station && station[0])
    newErrors.battery1 = validations.validateFloat(battery1 && battery1[0])
    newErrors.battery2 = validations.validateFloat(battery2 && battery2[0])
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const callHandleClient = (
    value,
    subscriptionId,
    customerId,
    autoAssign = false,
  ) => {
    subscriptionsService
      .getToManualSwapSelect(value, subscriptionId, customerId)
      .then(
        (clients) => {
          setClientOptions(clients)
          if (autoAssign && clients.length > 0) {
            setClient(clients[0])
            callHandleBatteriesClient(clients[0][0], autoAssign)
          }
        },
        (error) => {
          setClientOptions([])
        },
      )
  }

  const handleChangeClient = (ev) => {
    if (ev.target.value !== '' || ev.target.value !== null) {
      callHandleClient(
        ev.target.value,
        resultItem?.subscriptionId,
        resultItem?.customerId,
        false,
      )
      errors.client.message = ''
      setErrors(errors)
    } else {
      setClientOptions([])
      setClient('')
    }
  }

  const callHandleStation = (value, stationId, autoAssign = false) => {
    stationsService.getToManualSwapSelect(value, stationId).then(
      (stations) => {
        setStationOptions(stations)
        if (autoAssign && stations.length > 0) {
          setStation(stations[0])
        }
      },
      (error) => {
        setStationOptions([])
      },
    )
  }

  const handleChangeStations = (ev) => {
    if (ev.target.value !== '' || ev.target.value !== null) {
      callHandleStation(ev.target.value, resultItem?.stationId)
      errors.station.message = ''
      setErrors(errors)
    } else {
      setStationOptions([])
      setStation('')
    }
  }

  const callHandleBatteriesClient = (value, autoAssign = false) => {
    batteriesService.getBattery1ToManualSwapSelect(value).then(
      (batteries) => {
        setBattery1Options(batteries)
        if (autoAssign && batteries.length > 0) {
          setBattery1(batteries[0])
        }
      },
      (error) => {
        setBattery1Options([])
      },
    )
  }

  const callHandleBatteries = (stationId, autoAssign = false) => {
    batteriesService.getBattery2ToManualSwapSelect(stationId).then(
      (batteries) => {
        setBattery2Options(batteries)
      },
      (error) => {
        setBattery2Options([])
      },
    )
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleCloseModal}
      onEnter={handleEnter}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('swapManual.dialogs.create.dialogTitle')}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleClick}
            // disabled={!passwordsMatch || selectedStations.length === 0}
          >
            {t('swapManual.dialogs.create.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <Autocomplete
                id="clientId"
                value={client}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setClient(newValue)
                    callHandleBatteriesClient(newValue[0], false)
                  } else {
                    setClient('')
                    setBattery1('')
                    setClientOptions([])
                    setBattery1Options([])
                  }
                }}
                options={clientOptions}
                //getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                getOptionLabel={(option) => (option ? option[1] : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    label={t('swapManual.dialogs.create.labels.client')}
                    helperText={errors.client.message}
                    onChange={handleChangeClient}
                    name="client"
                    error={errors.client.message.length === 0 ? false : true}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <Autocomplete
                id="station"
                value={station}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setStation(newValue)
                    callHandleBatteries(newValue[0])
                  } else {
                    setStation('')
                    setBattery2('')
                    setStationOptions([])
                    setBattery2Options([])
                  }
                }}
                disabled={!isManager() && !isTechnician()}
                options={stationOptions}
                //getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                getOptionLabel={(option) => (option ? option[1] : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    label={t('swapManual.dialogs.create.labels.station')}
                    helperText={errors.station.message}
                    onChange={handleChangeStations}
                    name="station"
                    error={errors.station.message.length === 0 ? false : true}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <Autocomplete
                id="battery1"
                value={battery1}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setBattery1(newValue)
                    errors.battery1.message = ''
                    setErrors(errors)
                  } else {
                    setBattery1('')
                  }
                }}
                freeSolo
                options={battery1Options}
                //getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                getOptionLabel={(option) => (option ? option[1] : '')}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  // Suggest the creation of a new value
                  if (params.inputValue !== '' && isManager()) {
                    filtered.push([params.inputValue, params.inputValue])
                  }
                  return filtered
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    label={t('swapManual.dialogs.create.labels.battery1')}
                    helperText={errors.battery1.message}
                    name="battery1"
                    error={errors.battery1.message.length === 0 ? false : true}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <Autocomplete
                id="battery2"
                value={battery2}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setBattery2(newValue)
                    errors.battery2.message = ''
                    setErrors(errors)
                  } else {
                    setBattery2('')
                  }
                }}
                freeSolo
                options={battery2Options}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  // Suggest the creation of a new value
                  if (params.inputValue !== '' && isManager()) {
                    filtered.push([params.inputValue, params.inputValue])
                  }
                  return filtered
                }}
                //getOptionSelected={(option, value) => option.id == value || option.id == value.id}
                getOptionLabel={(option) => (option ? option[1] : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    label={t('swapManual.dialogs.create.labels.battery2')}
                    helperText={errors.battery2.message}
                    name="battery2"
                    error={errors.battery2.message.length === 0 ? false : true}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <ModalConfirm
            isOpen={openSwapManual}
            onClose={() => setOpenSwapManual(false)}
            onConfirm={handleClick}
            title={
              isPossibleSwapManual
                ? t('swapManual.dialogs.create.modal.titleSuccess')
                : t('swapManual.dialogs.create.modal.titleError')
            }
            cancelConfirm={!isPossibleSwapManual}
            body={messagesSwapManual}
          />
        </Grid>
      </div>
    </Dialog>
  )
}

const actionCreators = {}

export default connect(null, actionCreators)(CreateSwapManual)
