import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    padding: '2%',
  },
  dialogContainer: {
    padding: '1%',
  },

  tabs: {
    marginTop: '4%',
  },
}))

export default useStyles
