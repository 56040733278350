import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { productActions } from '../../../redux/actions/products_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({ isOpen, onClose, onConfirm, create }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [code, setcode] = useState('')
  const [description, setdescription] = useState('')
  const [price, setprice] = useState('')
  const [iva, setiva] = useState('')
  const [freeMonthlyKwhs, setfreeMonthlyKwhs] = useState('')

  const [errors, setErrors] = useState({
    code: { result: false, message: '' },
    description: { result: false, message: '' },
    price: { result: false, message: '' },
    iva: { result: false, message: '' },
  })

  const handleClick = () => {
    const body = {
      code,
      description,
      price: parseFloat(price),
      ivaPercent: parseFloat(iva),
      freeMonthlyKwhs: parseInt(freeMonthlyKwhs),
    }

    validateForm()
    if (isFormValid()) {
      create(body)
      handleClose()
    }
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.code = validations.required(code)
    newErrors.description = validations.required(description)
    newErrors.price = validations.validateFloat(price)
    newErrors.iva = validations.validateFloat(iva)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const handleClose = () => {
    onClose()
    setcode('')
    setdescription('')
    setprice('')
    setiva('')
    setfreeMonthlyKwhs('')

    setErrors({
      code: { result: false, message: '' },
      description: { result: false, message: '' },
      price: { result: false, message: '' },
      iva: { result: false, message: '' },
    })
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('product.dialogs.create.dialogTitle')}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleClick}
            // disabled={!passwordsMatch || selectedStations.length === 0}
          >
            {t('operatorUser.dialogs.create.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('product.dialogs.form.labels.code')}
              value={code}
              onChange={(e) => {
                setcode(e.target.value)
                errors.code.message = ''
                setErrors(errors)
              }}
              error={errors.code.message.length === 0 ? false : true}
              helperText={errors.code.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('product.dialogs.form.labels.description')}
              value={description}
              onChange={(e) => {
                setdescription(e.target.value)
                errors.description.message = ''
                setErrors(errors)
              }}
              error={errors.description.message.length === 0 ? false : true}
              helperText={errors.description.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('product.dialogs.form.labels.price')}
              value={price}
              type={'number'}
              onChange={(e) => {
                setprice(e.target.value)
                errors.price.message = ''
                setErrors(errors)
              }}
              error={errors.price.message.length === 0 ? false : true}
              helperText={errors.price.message}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('product.dialogs.form.labels.ivaPercent')}
              value={iva}
              onChange={(e) => {
                setiva(e.target.value)
                errors.iva.message = ''
                setErrors(errors)
              }}
              error={errors.iva.message.length === 0 ? false : true}
              helperText={errors.iva.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('product.dialogs.form.labels.freeMonthlyKwhs')}
              value={freeMonthlyKwhs}
              onChange={(e) => {
                setfreeMonthlyKwhs(e.target.value)
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

const actionCreators = {
  create: productActions.create,
}

export default connect(null, actionCreators)(Create)
