import { handleCall } from './handleResponse'

export const accountUserService = {
  getAll,
  deleteAcUser,
  create,
}

function create(body) {
  return handleCall(`/stations/v1/FinancialUsers/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function deleteAcUser(id) {
  return handleCall(`/stations/v1/FinancialUsers/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function getAll() {
  return handleCall(`/stations/v1/FinancialUsers/GetAll`, {
    method: 'GET',
    body: null,
  })
}
