import React from 'react'

import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

import DetailsSections from './detailsSections'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    // padding: "2%",
  },
  tabs: {
    marginTop: '4%',
  },
  divider: { marginTop: '2%' },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}
export default function Details({ isOpen, onClose, selectedRow }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }
  const { id, name } = selectedRow

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('operator.dialogs.details.dialogTitle')} ({name})
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.dialogContent}>
        <DetailsSections operatorId={id} selectedRow={selectedRow} />
      </div>
    </Dialog>
  )
}
