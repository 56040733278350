export const discountsConstants = {
  GET_ALL_REQUEST: 'DISCOUNTS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'DISCOUNTS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'DISCOUNTS_GET_ALL_FAILURE',

  GET_BY_ID_REQUEST: 'DISCOUNTS_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'DISCOUNTS_GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILURE: 'DISCOUNTS_GET_BY_ID_FAILURE',

  GET_BY_CUSTOMER_ID_REQUEST: 'DISCOUNTS_GET_BY_CUSTOMER_ID_REQUEST',
  GET_BY_CUSTOMER_ID_SUCCESS: 'DISCOUNTS_GET_BY_CUSTOMER_ID_SUCCESS',
  GET_BY_CUSTOMER_ID_FAILURE: 'DISCOUNTS_GET_BY_CUSTOMER_ID_FAILURE',
}
