import stringConstants from '../constants/strings'
import { authHeader } from '../helpers/authHeader'
import { handleCall } from './handleResponse'

export const userService = {
  insertSilenceUser,
  changePassword,
  resetPassword,
  deleteCustomer,
}

function changePassword(body) {
  var requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  }

  return fetch(
    `${stringConstants.URL_ApiGateway}/stations/v1/users/ChangePassword`,
    requestOptions,
  ).then(handleResponse)
}

function resetPassword(body) {
  var requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(body),
  }

  return fetch(
    `${stringConstants.URL_ApiGateway}/stations/v1/users/ResetPassword`,
    requestOptions,
  ).then(handleResponse)
}

function insertSilenceUser(body) {
  var requestOptions = {
    method: 'POST',
    headers: authHeader(),
    redirect: 'follow',
    body: JSON.stringify(body),
  }

  return fetch(
    `${stringConstants.URL_ApiGateway}/customers/v1/users/NewSilenceUser`,
    requestOptions,
  ).then(handleResponse)
}

function deleteCustomer(email) {
  return handleCall(
    `/customers/v1/Customers/Delete?email=${email}`,
    {
      method: 'DELETE',
      body: null,
    },
    false,
  )
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || text
      return Promise.reject(error)
    }
    return data
  })
}
