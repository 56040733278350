import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: '1%',
    maxWidth: '60%',
    margin: 'auto',
    marginTop: '0px',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function MoreInformation({ isOpen, onClose, selectedData }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Button autoFocus color="inherit" onClick={handleClose}>
            {t('station.details.moreInformation.dialogTitle')} (stationName)
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} className={classes.content}>
        <Grid item md={12} lg={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.zone')}
            value={''}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.operator')}
            value={''}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.deliveryPoint')}
            value={''}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.rows')}
            value={''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.modules')}
            value={''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.modulesInUse')}
            value={''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.state')}
            value={''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.todayPrice')}
            value={''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('station.details.moreInformation.labels.lastConnection')}
            value={''}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
