import { Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { alertActions } from '../../redux/actions/alert_actions'
import { subscriptionsActions } from '../../redux/actions/subscriptions_actions'

class SubscriptionConfirmation extends React.Component {
  constructor(props) {
    super()
    this.state = {
      openModal: false,
    }
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal() {
    this.setState({
      openModal: false,
    })
  }

  componentDidMount() {
    const params = this.props.location.search.split('&')
    const id = params[0].split('=')[1]
    this.props.confirm(id)
  }

  render() {
    const { t, alertReducer } = this.props
    const lastAlert =
      alertReducer.messages.length > 0
        ? alertReducer.messages[alertReducer.messages.length - 1]
        : null
    return (
      <React.Fragment>
        <Grid container>
          <Grid item md={12}>
            {lastAlert &&
              this.props
                .enqueueSnackbar(lastAlert.message, {
                  variant: lastAlert.type,
                  autoHideDuration: 3000,
                  onClose: this.props.handleCloseAlert,
                })
                .toString()
                .substring(0, 0)}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

function mapState(state) {
  const { alertReducer } = state
  return { alertReducer }
}

const actionCreators = {
  confirm: subscriptionsActions.confirm,
  handleCloseAlert: alertActions.clear,
}

function Hook(props) {
  const { enqueueSnackbar } = useSnackbar()
  return (
    <SubscriptionConfirmation {...props} enqueueSnackbar={enqueueSnackbar} />
  )
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Hook)
