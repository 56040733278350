import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import moment from 'moment'
import { connect } from 'react-redux'
import { ratesActions } from '../../redux/actions/rates_actions'

const RatesByCustomers = ({
  getRatesByCustomerId,
  customerId,
  ratesReducer,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    customerId && getRatesByCustomerId(customerId)
  }, [])

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('rates.byClients.table.title')}
        columns={[
          {
            title: t('rates.byClients.table.columns.contract'),
            field: 'contractId',
          },
          {
            title: t('rates.byClients.table.columns.ratesName'),
            field: 'rateName',
          },
          {
            title: t('rates.byClients.table.columns.battery'),
            field: 'batteriesNumber',
          },
          {
            title: t('rates.byClients.table.columns.zone'),
            field: 'zoneName',
          },
          {
            title: t('rates.byClients.table.columns.startDate'),
            field: 'created',
            render: (rowData) => {
              return rowData.created
                ? moment(rowData.created).format('DD/MM/YYYY HH:mm')
                : ''
            },
          },
          {
            title: t('rates.byClients.table.columns.finishDate'),
            field: 'canceled',
            render: (rowData) => {
              return rowData.canceled
                ? moment(rowData.canceled).format('DD/MM/YYYY HH:mm')
                : ''
            },
          },
        ]}
        data={ratesReducer.ratesByCustomer}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { ratesReducer } = state
  return { ratesReducer }
}
const actionCreators = {
  getRatesByCustomerId: ratesActions.getRatesByCustomerId,
}

export default connect(mapState, actionCreators)(RatesByCustomers)
