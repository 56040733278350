import { discountsConstants } from '../../constants/discounts_constants'
import { discountsService } from '../../services/discountsServices'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

export const discountsActions = {
  getAll,
  create,
  deleteDiscount,
  getById,
  update,
  getByCustomer,
  assignDiscount,
  unassignDiscount,
  assignManager,
}

function assignManager(bodyAssignDiscounts, bodyUnassignDiscoutns) {
  return (dispatch) => {
    const { discounts: discountsUnassign } = bodyUnassignDiscoutns
    const { discounts: disccountsAssign } = bodyAssignDiscounts

    if (discountsUnassign.length > 0) {
      discountsService.unassignDiscount(bodyUnassignDiscoutns).then(
        (results) => {
          disccountsAssign.length > 0 &&
            discountsService.assignDiscount(bodyAssignDiscounts).then(
              (results) => {
                dispatch(getAll())
                dispatch(
                  alertActions.success(
                    i18next.t('services.discounts.assignDiscountSuccess'),
                  ),
                )
              },
              (error) => {
                dispatch(
                  alertActions.error(
                    i18next.t('services.discounts.assignDiscountError') +
                      ' ' +
                      error,
                  ),
                )
              },
            )

          dispatch(
            alertActions.success(
              i18next.t('services.discounts.unassignDiscountSuccess'),
            ),
          )
        },
        (error) => {
          dispatch(
            alertActions.error(
              i18next.t('services.discounts.unassignDiscountError') +
                ' ' +
                error,
            ),
          )
        },
      )
    } else {
      disccountsAssign.length > 0 &&
        discountsService.assignDiscount(bodyAssignDiscounts).then(
          (results) => {
            dispatch(getAll())
            dispatch(
              alertActions.success(
                i18next.t('services.discounts.assignDiscountSuccess'),
              ),
            )
          },
          (error) => {
            dispatch(
              alertActions.error(
                i18next.t('services.discounts.assignDiscountError') +
                  ' ' +
                  error,
              ),
            )
          },
        )
    }
  }
}

function assignDiscount(body) {
  return (dispatch) => {
    discountsService.assignDiscount(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.discounts.assignDiscountSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.discounts.assignDiscountError') + ' ' + error,
          ),
        )
      },
    )
  }
}

function unassignDiscount(body) {
  return (dispatch) => {
    discountsService.unassignDiscount(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.discounts.unassignDiscountSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.discounts.unassignDiscountError') + ' ' + error,
          ),
        )
      },
    )
  }
}

function getByCustomer(id) {
  return (dispatch) => {
    if (id) {
      dispatch(request())
      discountsService.getByCustomer(id).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          // dispatch(
          //   alertActions.error("error get all operator action. Message:" + error)
          // );
        },
      )
    }
  }

  function request() {
    return { type: discountsConstants.GET_BY_CUSTOMER_ID_REQUEST }
  }

  function success(results) {
    return { type: discountsConstants.GET_BY_CUSTOMER_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: discountsConstants.GET_BY_CUSTOMER_ID_FAILURE, error }
  }
}

function update(body) {
  return (dispatch) => {
    discountsService.update(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.discounts.updateSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.discounts.updateError') + ' ' + error,
          ),
        )
      },
    )
  }
}
function deleteDiscount(id) {
  return (dispatch) => {
    discountsService.deleteDiscount(id).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.discounts.deleteDiscountSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.discounts.deleteDiscountError') + ' ' + error,
          ),
        )
      },
    )
  }
}

function create(body) {
  return (dispatch) => {
    discountsService.create(body).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.discounts.createSuccess')),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.discounts.createError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request())
    discountsService.getById(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: discountsConstants.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: discountsConstants.GET_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: discountsConstants.GET_BY_ID_FAILURE, error }
  }
}

function getAll(body = { since: null, until: null }) {
  return (dispatch) => {
    dispatch(request())
    discountsService.getAll(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.discounts.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: discountsConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: discountsConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: discountsConstants.GET_ALL_FAILURE, error }
  }
}
