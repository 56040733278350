import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { operatorUserActions } from '../../../redux/actions/operatorUser_action'
import { zonesActions } from '../../../redux/actions/zones_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Edit = ({
  isOpen,
  onClose,
  onConfirm,
  rowSelected,
  update,
  zonesReducer,
  getZonesByOperatorId,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const [name, setname] = useState()
  const [lastName, setlastName] = useState()
  const [contactPhone, setcontactPhone] = useState()
  const [primaryColor, setprimaryColor] = useState('')
  const [zone, setzone] = useState([])

  const [errors, setErrors] = useState({
    name: { result: false, message: '' },
    lastName: { result: false, message: '' },
    contactPhone: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.name = validations.required(name)
    newErrors.lastName = validations.required(lastName)
    newErrors.contactPhone = validations.validatePhone(contactPhone)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })

    return valid
  }

  const handleClose = () => {
    setErrors({
      name: { result: false, message: '' },
      lastName: { result: false, message: '' },
      contactPhone: { result: false, message: '' },
    })

    onClose()
  }

  const handleEnter = () => {
    // getZonesByOperatorId();
    const {
      lastName: lastNameprop,
      firstName: firstNameprop,
      phone: phoneprop,
      // zones: zonesprop,
    } = rowSelected

    setname(firstNameprop)
    setlastName(lastNameprop)
    setcontactPhone(phoneprop)
  }

  const handleUpdate = () => {
    const body = {
      id: rowSelected.id,
      firstName: name,
      phone: contactPhone,
      lastName: lastName,
    }
    validateForm()
    if (isFormValid()) {
      update(body)
      handleClose()
    }
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      onEnter={handleEnter}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('operator.dialogs.edit.dialogTitle')} ({name})
          </Typography>
          <Button autoFocus color="inherit" onClick={handleUpdate}>
            {t('operator.dialogs.edit.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.edit.label.name')}
              value={name}
              onChange={(e) => {
                setname(e.target.value)
                errors.name.message = ''
                setErrors(errors)
              }}
              error={errors.name.message.length === 0 ? false : true}
              helperText={errors.name.message}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operatorUser.dialogs.create.labels.lastName')}
              value={lastName}
              onChange={(e) => {
                setlastName(e.target.value)
                errors.lastName.message = ''
                setErrors(errors)
              }}
              error={errors.lastName.message.length === 0 ? false : true}
              helperText={errors.lastName.message}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.edit.label.contactPhone')}
              value={contactPhone}
              onChange={(e) => {
                setcontactPhone(e.target.value)
                errors.contactPhone.message = ''
                setErrors(errors)
              }}
              error={errors.contactPhone.message.length === 0 ? false : true}
              helperText={errors.contactPhone.message}
            />
          </Grid>
          {/* <Grid item md={3}>
            <Autocomplete
              options={zonesReducer.zonesByOperator}
              getOptionLabel={(option) => option.name}
              multiple
              // style={{ width: 300 }}
              value={zone}
              onChange={(event, value) => setzone(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("operator.dialogs.create.labels.zone")}
                  // variant="outlined"
                />
              )}
            />
          </Grid> */}
        </Grid>
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { operatorReducer, zonesReducer } = state
  return { operatorReducer, zonesReducer }
}
const actionCreators = {
  update: operatorUserActions.update,
  getZonesByOperatorId: zonesActions.getZonesByOperatorId,
}

export default connect(mapState, actionCreators)(Edit)
