import { handleCall } from './handleResponse'

export const zonesService = {
  getAllZones,
  getZoneById,
  createZone,
  updateZone,
  getZonesByOperatorId,
  deleteZone,
}
function deleteZone(id) {
  return handleCall(`/stations/v1/Zones/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function getZonesByOperatorId(operatorId) {
  return handleCall(
    `/stations/v1/Zones/getByOperatorCia?operatorCiaId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function updateZone(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/Zones/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function createZone(body) {
  return handleCall(`/stations/v1/Zones/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getZoneById(zoneId) {
  return handleCall(`/stations/v1/Zones/${zoneId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllZones() {
  return handleCall(`/stations/v1/Zones/GetAll`, { method: 'GET', body: null })
}
