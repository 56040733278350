import { handleCall } from './handleResponse'

export const productService = {
  getAll,
  deleteProd,
  create,
  update,
}

function create(body) {
  return handleCall(`/stations/v1/InvoiceProducts/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function update(id, body) {
  return handleCall(`/stations/v1/InvoiceProducts/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deleteProd(id) {
  return handleCall(`/stations/v1/InvoiceProducts?id=${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function getAll() {
  return handleCall(`/stations/v1/InvoiceProducts/GetAll`, {
    method: 'GET',
    body: null,
  })
}
