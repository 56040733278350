import React, { useEffect } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { batteriesActions } from '../../redux/actions/batteries_actions'

const BatteriesByOperators = ({
  operatorId,
  getBatteriesAssignedToOperator,
  batteriesReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getBatteriesAssignedToOperator(operatorId)
  }, [])

  return (
    <CustomMaterialTable
      title={t('batteries.byOperator.table.title')}
      // saveFilters={true}
      columns={[
        {
          title: t('batteries.byOperator.table.columns.id'),
          field: 'batteryPackId',
        },
        {
          title: t('batteries.byOperator.table.columns.outOfService'),
          field: 'outOfService',
        },
        {
          title: t('batteries.byOperator.table.columns.zone'),
          field: 'zoneName',
        },
        { title: t('batteries.byOperator.table.columns.imei'), field: 'imei' },
        // {
        //   title: t("batteries.byOperator.table.columns.location"),
        //   field: "location",
        // },
      ]}
      data={batteriesReducer.batteriesAssignedToOperator}
    />
  )
}

function mapState(state) {
  const { batteriesReducer } = state
  return { batteriesReducer }
}
const actionCreators = {
  getBatteriesAssignedToOperator:
    batteriesActions.getBatteriesAssignedToOperator,
}

export default connect(mapState, actionCreators)(BatteriesByOperators)
