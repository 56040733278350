export const customerConstants = {
  GET_ALL_CUSTOMERS_REQUEST: 'CUSTOMERS_GETALL_REQUEST',
  GET_ALL_CUSTOMERS_SUCCESS: 'CUSTOMERS_GETALL_SUCCESS',
  GET_ALL_CUSTOMERS_FAILURE: 'CUSTOMERS_GETALL_FAIL',

  GET_CUSTOMER_BY_ID_REQUEST: 'CUSTOMERS_GET_BY_ID_REQUEST',
  GET_CUSTOMER_BY_ID_SUCCESS: 'CUSTOMERS_GET_BY_ID_SUCCESS',
  GET_CUSTOMER_BY_ID_FAILURE: 'CUSTOMERS_GET_BY_ID_FAIL',

  GET_PENDING_VALIDATE_REQUEST: 'CUSTOMERS_PENDING_VALIDATE_REQUEST',
  GET_PENDING_VALIDATE_SUCCESS: 'CUSTOMERS_PENDING_VALIDATE_SUCCESS',
  GET_PENDING_VALIDATE_FAILURE: 'CUSTOMERS_PENDING_VALIDATE_FAIL',

  GET_ALL_COUNTRIES_REQUEST: 'CUSTOMERS_GET_ALL_COUNTRIES_REQUEST',
  GET_ALL_COUNTRIES_SUCCESS: 'CUSTOMERS_GET_ALL_COUNTRIES_SUCCESS',
  GET_ALL_COUNTRIES_FAILURE: 'CUSTOMERS_GET_ALL_COUNTRIES_FAILURE',

  GET_ALL_DOC_TYPE_REQUEST: 'CUSTOMERS_GET_ALL_DOC_TYPE_REQUEST',
  GET_ALL_DOC_TYPE_SUCCESS: 'CUSTOMERS_GET_ALL_DOC_TYPE_SUCCESS',
  GET_ALL_DOC_TYPE_FAILURE: 'CUSTOMERS_GET_ALL_DOC_TYPE_FAILURE',

  UPDATE_PAYMENT_MODE_REQUEST: 'UPDATE_PAYMENT_MODE_REQUEST',
  UPDATE_PAYMENT_MODE_SUCCESS: 'UPDATE_PAYMENT_MODE_SUCCESS',
  UPDATE_PAYMENT_MODE_FAILURE: 'UPDATE_PAYMENT_MODE_FAILURE',

  DOCUMENTS_MODAL_OPEN: 'DOCUMENTS_MODAL_OPEN',
  DOCUMENTS_MODAL_CLOSE: 'DOCUMENTS_MODAL_CLOSE',

  CLEAR_SELECTED_CUSTOMER: 'CLEAR_SELECTED_CUSTOMER',
}
