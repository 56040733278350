import React from 'react'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../../shared/customMaterialTable'

const OperatorsZone = ({ data, addTitle = true }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={addTitle ? t('operator.pendingValidate.table.title') : ''}
        columns={[
          {
            title: t('operator.pendingValidate.table.columns.name'),
            field: 'name',
          },
          {
            title: t('operator.pendingValidate.table.columns.email'),
            field: 'email',
          },
          {
            title: t('operator.pendingValidate.table.columns.contactPhone'),
            field: 'phone',
          },
          // {
          //   title: t("operator.pendingValidate.table.columns.cif"),
          //   field: "cif",
          // },
          // {
          //   title: t("operator.pendingValidate.table.columns.battery"),
          //   field: "battery",
          // },
          // {
          //   title: t("operator.pendingValidate.table.columns.station"),
          //   field: "station",
          // },
          // {
          //   title: t("operator.pendingValidate.table.columns.enabled"),
          //   field: "enabled",
          // },
        ]}
        data={data}
      />
    </React.Fragment>
  )
}

export default OperatorsZone
