export const zonesConstants = {
  GET_ALL_REQUEST: 'ZONES_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'ZONES_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'ZONES_GET_ALL_FAILURE',

  GET_BY_ZONE_ID_REQUEST: 'ZONES_GET_BY_ZONE_ID_REQUEST',
  GET_BY_ZONE_ID_SUCCESS: 'ZONES_GET_BY_ZONE_ID_SUCCESS',
  GET_BY_ZONE_ID_FAILURE: 'ZONES_GET_BY_ZONE_ID_FAILURE',

  GET_BY_OPERATOR_ID_REQUEST: 'ZONES_GET_BY_OPERATOR_ID_REQUEST',
  GET_BY_OPERATOR_ID_SUCCESS: 'ZONES_GET_BY_OPERATOR_ID_SUCCESS',
  GET_BY_OPERATOR_ID_FAILURE: 'ZONES_GET_BY_OPERATOR_ID_FAILURE',
}
