import React, { useRef, useState } from 'react'

import GoogleMapReact from 'google-map-react'
import useSupercluster from 'use-supercluster'
import './App.css'

import stringConstants from '../../../constants/strings'

const AppUrl = process.env.PUBLIC_URL

const Marker = ({ children }) => children
const markerStyle = {
  marginTop: '-34px',
  marginLeft: '-17px',
}
const clusterMarker = {
  position: 'relative',
  textAlign: 'center',
  color: 'white',
  cursor: 'pointer',
}
const pointCountStyle = {
  position: 'absolute',
  top: '82px',
  left: '82px',
}
const pointCountSingleStyle = {
  position: 'absolute',
  // top: "20px",
  // left: "23px",
}

const GoogleMapReactCustom = ({ stations, onMarkerClick }) => {
  const mapRef = useRef()
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)

  const points = stations
    .map((station) => {
      const { location, id } = station
      if (location) {
        return {
          type: 'Feature',
          properties: {
            cluster: false,
            stationId: id,
            stationData: station,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              parseFloat(location.longitude),
              parseFloat(location.latitude),
            ],
          },
        }
      }
    })
    .filter((i) => i)

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  })

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: stringConstants.GOOGLE_API_KEY,
          libraries: ['drawing'],
        }}
        defaultCenter={{ lat: 41.387016, lng: 2.170047 }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom)
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ])
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${10 + (pointCount / points.length) * 20}px`,
                    height: `${10 + (pointCount / points.length) * 20}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20,
                    )
                    mapRef.current.setZoom(expansionZoom)
                    mapRef.current.panTo({ lat: latitude, lng: longitude })
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            )
          }

          return (
            // <CustmMarker/>
            <Marker
              key={`station-${cluster.properties.stationId}`}
              lat={latitude}
              lng={longitude}
            >
              <div
                className="station-marker"
                onClick={() => onMarkerClick(cluster.properties)}
                style={markerStyle}
              >
                <img
                  style={{ width: '35px', cursor: 'pointer' }}
                  src={`${AppUrl}/content/icons/slot.png`}
                  alt="station icon"
                />
              </div>
            </Marker>
          )
        })}
      </GoogleMapReact>
    </div>
  )
}
export default GoogleMapReactCustom
