import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { customerActions } from '../../redux/actions/customers_actions'

const CustomersByDiscounts = ({
  customerReducer,
  getAllCustomers,
  data,
  addTitle = true,
  readOnlyConfiguration,
  preSelectedRows,
  tableRef,
}) => {
  const { results } = customerReducer
  const { t } = useTranslation()

  useEffect(() => {
    getAllCustomers()
  }, [])

  return (
    <CustomMaterialTable
      tableRef={tableRef}
      title={addTitle ? t('customers.page.table.title') : ''}
      columns={[
        { title: t('customers.page.table.columns.name'), field: 'fullName' },
        { title: t('customers.page.table.columns.email'), field: 'email' },
        {
          title: t('customers.page.table.columns.contactPhone'),
          field: 'phone',
        },
        { title: t('customers.page.table.columns.dni'), field: 'nif' },
        {
          title: t('customers.page.table.columns.activeRates'),
          field: 'activeRates',
        },
        {
          title: t('customers.page.table.columns.enabled'),
          field: 'cannotOperate',
          addlookup: true,
        },
        {
          title: t('customers.page.table.columns.documentsValidated'),
          field: 'documentsValidated',
          addlookup: true,
        },
        { title: t('customers.page.table.columns.zone'), field: 'zone' },
      ]}
      data={data || results}
      preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
      selection={!readOnlyConfiguration}
    />
  )
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}
const actionCreators = {
  getAllCustomers: customerActions.getAllCustomers,
}

export default connect(mapState, actionCreators)(CustomersByDiscounts)
