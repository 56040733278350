import React from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

const AssignedToOperators = ({
  data,
  onSelectionUpdate,
  preSelectedRows,
  readOnlyConfiguration,
  addTitle = true,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <CustomMaterialTable
      title={addTitle ? t('station.assignedToOperators.table.title') : ''}
      onSelectionChange={(rows) => onSelectionUpdate(rows)}
      selection={!readOnlyConfiguration}
      preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
      columns={[
        {
          title: t('station.assignedToOperators.table.columns.stationName'),
          field: 'nameAndAddress',
        },
        {
          title: t('station.assignedToOperators.table.columns.zone'),
          field: 'zone',
        },
        {
          title: t('station.assignedToOperators.table.columns.imei'),
          field: 'imei',
        },
        {
          title: t('station.assignedToOperators.table.columns.totalModules'),
          field: 'totalModules',
        },
        {
          title: t('station.assignedToOperators.table.columns.outOfService'),
          field: 'outOfService',
          addlookup: true,
        },
        {
          title: t('station.assignedToOperators.table.columns.chargePrice'),
          field: 'priceByKwhDeliveryFromCustomer',
        },
        // {
        //   title: t("station.assignedToOperators.table.columns.location"),
        //   field: "location",
        // },
      ]}
      data={data}
      // actions={[
      //   {
      //     icon: "add_circle_outline",
      //     tooltip: t("station.assignedToOperators.table.actions.assign"),
      //     onClick: (event, rowData) => alert("You saved " + rowData.name),
      //   },

      //   {
      //     icon: "remove_circle_outline",
      //     tooltip: t("station.assignedToOperators.table.actions.unassign"),
      //     onClick: (event, rowData) => alert("You saved " + rowData.name),
      //   },
      // ]}
    />
  )
}

export default AssignedToOperators
