import { Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { authHeader } from '../../helpers/authHeader'
import {
  isCauUser,
  isFinancial,
  isManager,
  isManagerReadOnly,
  isSacUser,
} from '../../helpers/roles'
import CustomMaterialTable from '../shared/customMaterialTable'
import DatePickerCustom from '../shared/datePickerCustom'
import { displayWithTooltip } from '../shared/enshortenLargeData'
import ModalConfirm from '../shared/modalconfirm'
import BillingDetails from './modals/details'

class BillingView extends React.Component {
  constructor(props) {
    super()
    this.state = {
      modalPayment: false,
      modalHistory: false,
      selectedId: '',
    }
    this.closeModal = this.closeModal.bind(this)
    this.closeHistoryModal = this.closeHistoryModal.bind(this)
  }

  componentDidMount() {
    this.props.getAll()
  }

  openModalPayment(rowData) {
    var xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = function (event) {
      var blob = xhr.response
      var file = new Blob([blob], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    }
    const header = authHeader()
    xhr.open('GET', rowData.fileUrl)
    xhr.setRequestHeader('Authorization', header.Authorization)
    xhr.send()
  }

  openModalHistory() {
    this.setState({
      modalHistory: true,
    })
  }

  getPaymentIntents(id) {
    this.props.getPaymentIntents(id)
  }

  closeModal() {
    this.setState({
      modalPayment: false,
    })
  }

  closeHistoryModal() {
    this.setState({
      modalHistory: false,
    })
  }

  onClickReturnPayment(item) {
    this.setState({
      modalPayment: true,
      item: item,
    })
  }

  render() {
    const { t, billingReducer } = this.props
    const statusCodeMap = {
      A: t('billing.page.table.statusDescription.subscribed'),
      C: t('billing.page.table.statusDescription.charged'),
      E: t('billing.page.table.statusDescription.waiting'),
      F: t('billing.page.table.statusDescription.error'),
    }
    return (
      <React.Fragment>
        <DatePickerCustom searchAction={this.props.getAll} />
        <CustomMaterialTable
          title={t('billing.page.table.title')}
          saveFilters
          isLoading={billingReducer.loadingResults}
          data={billingReducer.results}
          columns={[
            {
              title: t('billing.page.table.columns.created'),
              field: 'created',
              render: (rowData) => {
                return rowData.created
                  ? moment(rowData.created).format('DD/MM/YYYY')
                  : ''
              },
            },
            {
              title: t('billing.page.table.columns.code'),
              field: 'invoiceCode',
            },
            {
              title: t('billing.page.table.columns.customerSystemId'),
              field: 'customerSystemId',
            },
            {
              title: t('billing.page.table.columns.refText'),
              field: 'refText',
            },
            {
              title: t('billing.page.table.columns.amount'),
              field: 'amount',
              render: (rowData) => {
                return '€' + rowData.amount
              },
            },
            {
              title: t('billing.page.table.columns.success'),
              field: 'successTimestamp',
              render: (rowData) => {
                return rowData.successTimestamp
                  ? moment(rowData.successTimestamp).format('DD/MM/YYYY HH:mm')
                  : ''
              },
            },
            {
              title: 'Status code',
              field: 'statusCode',
              lookup: statusCodeMap,
              render: (rowData) => statusCodeMap[rowData.statusCode] ?? '',
            },
            {
              title: t('billing.page.table.columns.failedReason'),
              field: 'failedReason',
              render: (rowData) => {
                let reason = rowData.failedReason

                if (String(rowData.failedReason).includes('insufficient')) {
                  reason = t('billing.page.table.columns.insufficientFunds')
                }

                return displayWithTooltip(reason)
              },
            },
          ]}
          actions={[
            {
              icon: 'get_app',
              tooltip: t('billing.page.table.actions.download'),
              onClick: (event, rowData) => this.openModalPayment(rowData),
            },
            {
              icon: 'assignment',
              tooltip: t('billing.page.table.actions.history'),
              onClick: (event, rowData) => {
                this.openModalHistory()
                this.getPaymentIntents(rowData.id)
                this.setState({ selectedId: rowData.id })
              },
              hidden:
                !isManager() && !isManagerReadOnly() && !isFinancial() && !isSacUser() && !isCauUser(),
            },
            (rowData) => ({
              icon: 'replay',
              tooltip: t('billing.page.table.actions.return'),
              onClick: (event, rowData) => this.onClickReturnPayment(rowData),
              hidden:
                !rowData.success ||
                (!isManager() && !isSacUser()) ||
                rowData.refundTimestamp != null ||
                rowData.invoiceType === 'BA' ||
                rowData.invoiceType === 'FA' ||
                rowData.invoiceType === 'BAS',
            }),
          ]}
          exportButton={!isCauUser() && !isSacUser()}
        />
        <ModalConfirm
          isOpen={this.state.modalPayment}
          onClose={this.closeModal}
          onConfirm={() => this.props.refundPayment(this.state.item.id)}
          title={
            t('billing.page.modal.title') +
            `(€${this.state.item && this.state.item.amount})`
          }
          body={t('billing.page.modal.body')}
        />

        <BillingDetails
          isOpen={this.state.modalHistory}
          onClose={() => this.closeHistoryModal()}
          reducer={this.props.billingReducer}
          filter={this.props.getPaymentIntents}
          selectedId={this.state.selectedId}
        />
      </React.Fragment>
    )
  }
}

export default BillingView
