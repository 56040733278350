import React from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

const AssignedToZones = ({
  data,
  onSelectionUpdate,
  preSelectedRows,
  addTitle = true,
  readOnlyConfiguration,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <CustomMaterialTable
      title={addTitle ? t('station.assignedToZones.table.title') : ''}
      onSelectionChange={(rows) => onSelectionUpdate(rows)}
      selection={!readOnlyConfiguration}
      preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
      columns={[
        /*  {
                     title: t("station.assignedToZones.table.columns.stationId"),
                     field: "id",
                 }, */
        {
          title: t('station.assignedToZones.table.columns.zone'),
          field: 'zoneName',
        },
        {
          title: t('station.assignedToZones.table.columns.imei'),
          field: 'imei',
        },
        {
          title: t('station.assignedToZones.table.columns.totalModules'),
          field: 'totalModules',
        },
        {
          title: t('station.assignedToZones.table.columns.outOfService'),
          field: 'outOfService',
          addlookup: true,
        },
        {
          title: t('station.assignedToZones.table.columns.chargePrice'),
          field: 'priceByKwhDeliveryFromCustomer',
        },
        // {
        //   title: t("station.assignedToZones.table.columns.location"),
        //   field: "location",
        // },
      ]}
      data={data}
    />
  )
}

export default AssignedToZones
