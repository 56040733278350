import { zonesConstants } from '../../constants/zones_constants'

const initialState = {
  loadingResults: true,
  results: [],
  zone: {},
  zonesByOperator: [],
}

export function zonesReducer(state = initialState, action) {
  switch (action.type) {
    case zonesConstants.GET_BY_OPERATOR_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case zonesConstants.GET_BY_OPERATOR_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        zonesByOperator: action.results,
      }
    case zonesConstants.GET_BY_OPERATOR_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        zonesByOperator: [],
      }

    case zonesConstants.GET_BY_ZONE_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case zonesConstants.GET_BY_ZONE_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        zone: action.results,
      }
    case zonesConstants.GET_BY_ZONE_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        zone: {},
      }
    case zonesConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case zonesConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case zonesConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }

    default:
      return state
  }
}
