import React, { Fragment, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import { validations } from '../../../helpers/validation'
import { alertActions } from '../../../redux/actions/alert_actions'

import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { isOperatorManager, isSacUser, isTechnician } from '../../../helpers/roles'
import { operatorActions } from '../../../redux/actions/operator_action'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'
import ShiftDays from '../graphics/shiftDays'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  mainContainer: { padding: '2%' },
  createdRoe: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  addModuleSection: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({
  isOpen,
  onClose,
  getAllOperators,
  getAllZones,
  getForTechnician,
  zonesReducer,
  operatorReducer,
  create,
  updateStation,
  isUpdating = false,
  stationSelected,
  stationsReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [zone, setZone] = useState()
  const [name, setName] = useState('')
  const [operator, setOperator] = useState()
  const [isInfo, setIsInfo] = useState(false)
  const [isStation, setIsStation] = useState(false)
  const [visibleToCustomers, setVisibleToCustomers] = useState(false)
  const [letterRow, setletterRow] = useState()
  const [imeiRow, setImeiRow] = useState()
  const [rows, setRows] = useState([])

  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [infoPointPhone, setInfoPointPhone] = useState('')
  const [shiftDayInfo, setShiftDayInfo] = useState([
    {
      weekDays: [],
      startDate: '',
      endDate: '',
    },
  ])
  const [shiftDayStation, setShiftDayStation] = useState([
    {
      weekDays: [],
      startDate: '',
      endDate: '',
    },
  ])

  const [errors, setErrors] = useState({
    operator: { result: false, message: '' },
    zone: { result: false, message: '' },
    name: { result: false, message: '' },
    city: { result: false, message: '' },
    address: { result: false, message: '' },
    latitude: { result: false, message: '' },
    longitude: { result: false, message: '' },
    phone: { result: false, message: '' },
    rows: { result: false, message: '' },
    shiftDayInfo: [
      {
        startDate: {
          result: true,
          message: '',
        },
        endDate: {
          result: true,
          message: '',
        },
        weekDays: {
          result: true,
          message: '',
        },
      },
    ],
    shiftDayStation: [
      {
        startDate: {
          result: true,
          message: '',
        },
        endDate: {
          result: true,
          message: '',
        },
        weekDays: {
          result: true,
          message: '',
        },
      },
    ],
  })

  const handleClose = () => {
    onClose()

    setName('')
    setZone()
    setOperator(false)
    setIsStation(false)
    setIsInfo(false)
    setletterRow()
    setImeiRow()
    setRows([])
    setCity('')
    setAddress('')
    setLatitude()
    setLongitude()
    setInfoPointPhone()
    setShiftDayInfo([
      {
        weekDays: [],
        startDate: '',
        endDate: '',
      },
    ])
    setShiftDayStation([
      {
        weekDays: [],
        startDate: '',
        endDate: '',
      },
    ])
    setErrors({
      operator: { result: false, message: '' },
      zone: { result: false, message: '' },
      name: { result: false, message: '' },
      city: { result: false, message: '' },
      address: { result: false, message: '' },
      latitude: { result: false, message: '' },
      longitude: { result: false, message: '' },
      phone: { result: false, message: '' },
      rows: { result: false, message: '' },
      startHourInfo: { result: false, message: '' },
      endHourInfo: { result: false, message: '' },
      startHourStation: { result: false, message: '' },
      endHourStation: { result: false, message: '' },
      shiftDayInfo: [
        {
          startDate: {
            result: true,
            message: '',
          },
          endDate: {
            result: true,
            message: '',
          },
          weekDays: {
            result: true,
            message: '',
          },
        },
      ],
      shiftDayStation: [
        {
          startDate: {
            result: true,
            message: '',
          },
          endDate: {
            result: true,
            message: '',
          },
          weekDays: {
            result: true,
            message: '',
          },
        },
      ],
    })
  }

  const handleEnter = () => {
    getAllZones()
    isTechnician() ? getForTechnician() : getAllOperators()

    if (isUpdating) {
      const {
        address,
        city,
        name,
        infoPointPhone,
        location,
        isBatteryStation,
        isDeliveryPoint,
        rows,
        infoPointSchedule,
        stationSchedule,
      } = stationSelected

      setIsInfo(isDeliveryPoint)
      setIsStation(isBatteryStation)
      setName(name)
      setCity(city)
      setAddress(address)
      setLatitude(location?.latitude)
      setLongitude(location?.longitude)
      setInfoPointPhone(infoPointPhone)
      setRows(rows)
      let resultInfo = []
      let resultStation = []
      let newErrors = errors
      if (infoPointSchedule && infoPointSchedule.length > 0) {
        infoPointSchedule.map((item) => {
          resultInfo.push({
            startDate: item.from,
            endDate: item.to,
            weekDays: item.weekDays,
          })
          newErrors.shiftDayInfo.push({
            startDate: { result: true, message: '' },
            endDate: { result: true, message: '' },
            weekDays: { result: true, message: '' },
          })
        })
        newErrors.shiftDayInfo.pop()
      } else {
        resultInfo.push({
          startDate: '',
          endDate: '',
          weekDays: '',
        })
      }
      setShiftDayInfo(resultInfo)
      if (stationSchedule && stationSchedule.length > 0) {
        stationSchedule.map((item) => {
          resultStation.push({
            startDate: item.from,
            endDate: item.to,
            weekDays: item.weekDays,
          })
          newErrors.shiftDayStation.push({
            startDate: { result: true, message: '' },
            endDate: { result: true, message: '' },
            weekDays: { result: true, message: '' },
          })
        })
        newErrors.shiftDayStation.pop()
      } else {
        resultStation.push({
          startDate: '',
          endDate: '',
          weekDays: '',
        })
      }
      setShiftDayStation(resultStation)
      setErrors({ ...newErrors })
    }
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.operator = validations.isEmptyObject(operator)
    newErrors.zone = validations.isEmptyObject(zone)
    newErrors.name = validations.required(name)
    newErrors.city = validations.required(city)
    newErrors.address = validations.required(address)
    newErrors.phone = validations.validatePhone(infoPointPhone || '')
    newErrors.latitude = validations.validateLatitude(latitude)
    newErrors.longitude = validations.validateLongitude(longitude)

    if (isInfo) {
      newErrors.shiftDayInfo.forEach(function (element, i) {
        element.startDate = validations.required(shiftDayInfo[i].startDate)
        element.endDate = validations.required(shiftDayInfo[i].endDate)
        element.weekDays = validations.validateArray(shiftDayInfo[i].weekDays)
      })
    }
    if (isStation) {
      newErrors.shiftDayStation.forEach(function (element, i) {
        element.startDate = validations.required(shiftDayStation[i].startDate)
        element.endDate = validations.required(shiftDayStation[i].endDate)
        element.weekDays = validations.validateArray(
          shiftDayStation[i].weekDays,
        )
      })
    }
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })

    if (isInfo) {
      valid = errors['shiftDayInfo'].map(
        (item) =>
          item.startDate.result && item.endDate.result && item.weekDays.result,
      )
    }

    if (isStation) {
      valid = errors['shiftDayStation'].map(
        (item) =>
          item.startDate.result && item.endDate.result && item.weekDays.result,
      )
    }

    return valid
  }
  const handleCreateStation = () => {
    const body = isUpdating
      ? {
          id: stationSelected?.id,
          city,
          address,
          name: name,
          location: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          },
          infoPoint: isInfo,
          isDeliveryPoint: isInfo,
          isBatteryStation: isStation,
          infoPointPhone,
          infoPointSchedule: shiftDayInfo
            .filter((time) => time.startDate && time.endDate)
            .map(function (item) {
              return {
                from: moment(item.startDate, 'HH:mm').utc().format('HH:mm'),
                to: moment(item.endDate, 'HH:mm').utc().format('HH:mm'),
                weekDays: item.weekDays,
              }
            }),
          stationSchedule: shiftDayStation
            .filter((time) => time.startDate && time.endDate)
            .map(function (item) {
              return {
                from: moment(item.startDate, 'HH:mm').utc().format('HH:mm'),
                to: moment(item.endDate, 'HH:mm').utc().format('HH:mm'),
                weekDays: item.weekDays,
              }
            }),
          rows: rows,
          showToCustomers: visibleToCustomers,
        }
      : {
          zoneId: zone?.id,
          operatorCiaId: operator?.id,
          rows: rows.map((r) => {
            return { letter: r.letter, imei: r.imei }
          }),
          city,
          name,
          address,
          location: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          },
          infoPointPhone,
          infoPoint: isInfo,
          isDeliveryPoint: isInfo,
          isBatteryStation: isStation,
          infoPointSchedule: shiftDayInfo
            .filter((time) => time.startDate && time.endDate)
            .map(function (item) {
              return {
                from: moment(item.startDate, 'HH:mm').utc().format('HH:mm'),
                to: moment(item.endDate, 'HH:mm').utc().format('HH:mm'),
                weekDays: item.weekDays,
              }
            }),
          stationSchedule: shiftDayStation
            .filter((time) => time.startDate && time.endDate)
            .map(function (item) {
              return {
                from: moment(item.startDate, 'HH:mm').utc().format('HH:mm'),
                to: moment(item.endDate, 'HH:mm').utc().format('HH:mm'),
                weekDays: item.weekDays,
              }
            }),
          showToCustomers: visibleToCustomers,
        }

    validateForm()

    if (isFormValid()) {
      isUpdating ? updateStation(body) : create(body)
      handleClose()
    }
  }

  const validateRows = (letter) => {
    let result = !!validations.validateRow(letter)

    if (result) {
      result = !rows.find(
        (r) => letter.toUpperCase() === r.letter.toUpperCase(),
      )

      !result &&
        dispatch(alertActions.error(t('station.create.validations.rowExists')))
    }

    return result
  }

  const handleAddRows = () => {
    if (validateRows(letterRow)) {
      const updatedRows = [
        ...rows,
        {
          id: Math.random() * Math.random(),
          letter: letterRow,
          imei: imeiRow,
          edit: false,
          saved: false,
        },
      ]

      setletterRow('')
      setImeiRow('')
      setRows(updatedRows)
    }
  }

  const addShiftDay = (typeShift) => {
    errors[typeShift].push({
      startDate: { result: true, message: '' },
      endDate: { result: true, message: '' },
      weekDays: { result: true, message: '' },
    })
    setErrors({ ...errors })
    if (typeShift == 'shiftDayStation') {
      shiftDayStation.push({
        weekDays: [],
        startDate: '',
        endDate: '',
      })
      setShiftDayStation([...shiftDayStation])
    } else {
      shiftDayInfo.push({
        weekDays: [],
        startDate: '',
        endDate: '',
      })
      setShiftDayInfo([...shiftDayInfo])
    }
  }
  const removeShiftDay = (index, typeShift) => {
    if (typeShift == 'shiftDayStation') {
      setShiftDayStation(shiftDayStation.filter((_, i) => i !== index))
    } else {
      setShiftDayInfo(shiftDayInfo.filter((_, i) => i !== index))
    }
    errors[typeShift] = errors[typeShift].filter((_, i) => i !== index)
    setErrors({ ...errors })
  }

  const setShiftDay = (event, index, typeShift) => {
    let { name, value } = event.target
    var date = new Date()
    var array = value.split(':')
    date.setHours(array[0], array[1])
    array = name.split('_')
    let updatedShiftDays =
      typeShift == 'shiftDayStation' ? shiftDayStation : shiftDayInfo
    updatedShiftDays[index][array[0]] = value
    errors[typeShift][index][array[0]].message = ''
    typeShift == 'shiftDayStation'
      ? setShiftDayStation([...updatedShiftDays])
      : setShiftDayInfo([...updatedShiftDays])
  }

  const handleShiftDaysUpdates = (days, option = null, index, typeShift) => {
    let updatedShiftDays =
      typeShift == 'shiftDayStation' ? shiftDayStation : shiftDayInfo
    if (option) {
      switch (true) {
        case option === '1':
          updatedShiftDays[index].weekDays = [5, 6]
          break
        case option === '2':
          updatedShiftDays[index].weekDays = [0, 1, 2, 3, 4, 5, 6]
          break
        case option === '3':
          updatedShiftDays[index].weekDays = [0, 1, 2, 3, 4]
          break
        default:
          updatedShiftDays[index].weekDays = days.map((d) =>
            d.hasOwnProperty('key') ? parseInt(d.key) : d,
          )
      }
    } else {
      updatedShiftDays[index].weekDays = days.map((d) =>
        d.hasOwnProperty('key') ? parseInt(d.key) : d,
      )
    }
    errors[typeShift][index].weekDays.message = ''
    typeShift == 'shiftDayStation'
      ? setShiftDayStation([...updatedShiftDays])
      : setShiftDayInfo([...updatedShiftDays])
  }

  const handleRemoveRow = (selectedRow) =>
    setRows(rows.filter((row) => row.letter !== selectedRow.letter))

  const handleEditRow = (event, letter) => {
    let index = rows.findIndex((row) => letter === row.letter)
    const newRows = [...rows]
    newRows[index].imei = event.target.value
    setRows(newRows)
  }

  useEffect(() => {
    setVisibleToCustomers(isUpdating ? stationSelected.showToCustomers : true)
  }, [isUpdating, stationSelected])

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onEnter={handleEnter}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {!isUpdating
                ? t('station.create.dialogTitle')
                : t('station.create.dialogTitleUpdate')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateStation}>
              {isUpdating
                ? t('station.create.updateBtn')
                : t('station.create.confirmBtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.mainContainer}>
          <Grid container spacing={2}>
            {!isUpdating && (
              <Fragment>
                <Grid item xs={3}>
                  <Autocomplete
                    options={operatorReducer.results}
                    getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    value={operator}
                    onChange={(event, value) => {
                      setOperator(value)
                      errors.operator.message = ''
                      setErrors(errors)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={
                          errors.operator.message.length === 0 ? false : true
                        }
                        helperText={errors.operator.message}
                        label={t('station.create.labels.operator')}
                        // variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Autocomplete
                    options={zonesReducer.results.filter(
                      (zone) => operator && operator?.zones.includes(zone.id),
                    )}
                    getOptionLabel={(option) => option.name}
                    // style={{ width: 300 }}
                    value={zone}
                    onChange={(event, value) => {
                      setZone(value)
                      errors.zone.message = ''
                      setErrors(errors)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={errors.zone.message.length === 0 ? false : true}
                        helperText={errors.zone.message}
                        label={t('station.create.labels.zone')}
                        // variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Fragment>
            )}
            <Grid item xs={3}>
              <TextField
                label={t('station.create.labels.name')}
                fullWidth
                value={name}
                error={errors.name.message.length === 0 ? false : true}
                helperText={errors.name.message}
                disabled={isOperatorManager() || isSacUser()}
                onChange={(e) => {
                  setName(e.target.value)
                  errors.name.message = ''
                  setErrors(errors)
                }}
                required
                InputProps={{
                  readOnly: isOperatorManager() ? true : false,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {!isUpdating && <Grid item sm={3}></Grid>}
            <Grid item xs={3}>
              <TextField
                label={t('station.create.labels.city')}
                fullWidth
                value={city}
                error={errors.city.message.length === 0 ? false : true}
                helperText={errors.city.message}
                onChange={(e) => {
                  setCity(e.target.value)
                  errors.city.message = ''
                  setErrors(errors)
                }}
                required
                disabled={isOperatorManager() || isSacUser()}
                InputProps={{
                  readOnly: isOperatorManager() ? true : false,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t('station.create.labels.address')}
                fullWidth
                value={address}
                error={errors.address.message.length === 0 ? false : true}
                helperText={errors.address.message}
                disabled={isOperatorManager() || isSacUser()}
                onChange={(e) => {
                  setAddress(e.target.value)
                  errors.address.message = ''
                  setErrors(errors)
                }}
                required
                InputProps={{
                  readOnly: isOperatorManager() ? true : false,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                label={t('station.create.labels.latitude')}
                fullWidth
                value={latitude}
                error={errors.latitude.message.length === 0 ? false : true}
                helperText={errors.latitude.message}
                disabled={isOperatorManager() || isSacUser()}
                onChange={(e) => {
                  setLatitude(e.target.value)
                  errors.latitude.message = ''
                  setErrors(errors)
                }}
                required
                InputProps={{
                  readOnly: isOperatorManager() ? true : false,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t('station.create.labels.longitude')}
                fullWidth
                value={longitude}
                error={errors.longitude.message.length === 0 ? false : true}
                helperText={errors.longitude.message}
                disabled={isOperatorManager() || isSacUser()}
                onChange={(e) => {
                  setLongitude(e.target.value)
                  errors.longitude.message = ''
                  setErrors(errors)
                }}
                required
                InputProps={{
                  readOnly: isOperatorManager() ? true : false,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t('station.create.labels.infoPointPhone')}
                fullWidth
                value={infoPointPhone}
                error={errors.phone.message.length === 0 ? false : true}
                helperText={errors.phone.message}
                onChange={(e) => {
                  setInfoPointPhone(e.target.value)
                  errors.phone.message = ''
                  setErrors(errors)
                }}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInfo}
                    onChange={() => {
                      setIsInfo((prev) => !prev)
                      if (isInfo) {
                        errors.shiftDayStation.map((item) => {
                          item.message = ''
                        })
                        setErrors(errors)
                      }
                    }}
                    color="primary"
                    disabled={isOperatorManager() || isSacUser()}
                  />
                }
                label={t('station.create.labels.isPointSell')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isStation}
                    onChange={() => {
                      setIsStation((prev) => !prev)
                      if (isStation) {
                        errors.shiftDayInfo.map((item) => {
                          item.message = ''
                        })
                        setErrors(errors)
                      }
                    }}
                    color="primary"
                    disabled={isOperatorManager() || isSacUser()}
                  />
                }
                label={t('station.create.labels.isStation')}
              />
              {!isInfo && !isStation && (
                <div style={{ color: 'red' }}>
                  {' '}
                  * Debes seleccionar si es punto de venta o estación{' '}
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibleToCustomers}
                    onChange={() => setVisibleToCustomers((prev) => !prev)}
                    color="primary"
                    disabled={isOperatorManager() || isSacUser()}
                  />
                }
                label={t('station.create.labels.visibleToCustomers')}
              />
            </Grid>
            {!isUpdating ? (
              <Grid item xs={6}></Grid>
            ) : (
              <Grid item xs={3}></Grid>
            )}

            {shiftDayInfo.map((item, index) => (
              <ShiftDays
                item={item}
                index={index}
                length={shiftDayInfo.length}
                isInfo={isInfo}
                errors={errors}
                typeShift={'shiftDayInfo'}
                setErrors={setErrors}
                setShiftDay={setShiftDay}
                add={() => addShiftDay('shiftDayInfo')}
                remove={() => removeShiftDay(index, 'shiftDayInfo')}
                stationsReducer={stationsReducer}
                checkUpdates={(days, option) =>
                  handleShiftDaysUpdates(days, option, index, 'shiftDayInfo')
                }
              />
            ))}
            {shiftDayStation.map((item, index) => (
              <ShiftDays
                item={item}
                index={index}
                length={shiftDayStation.length}
                isInfo={isStation}
                errors={errors}
                typeShift={'shiftDayStation'}
                setErrors={setErrors}
                setShiftDay={setShiftDay}
                add={() => addShiftDay('shiftDayStation')}
                remove={() => removeShiftDay(index, 'shiftDayStation')}
                stationsReducer={stationsReducer}
                checkUpdates={(days, option) =>
                  handleShiftDaysUpdates(days, option, index, 'shiftDayStation')
                }
              />
            ))}

            <Fragment>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Grid
                    container
                    spacing={1}
                    className={classes.addModuleSection}
                  >
                    <Grid item md={3}>
                      <TextField
                        label={t('station.create.labels.letter')}
                        // fullWidth
                        value={letterRow}
                        disabled={!isStation || isOperatorManager()}
                        onChange={(e) =>
                          e.target.value.length <= 1 &&
                          setletterRow(e.target.value)
                        }
                        InputProps={{
                          readOnly: isOperatorManager() || isSacUser(),
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        label={t('station.create.labels.imei')}
                        // fullWidth
                        value={imeiRow}
                        disabled={!isStation || isOperatorManager()}
                        onChange={(e) => setImeiRow(e.target.value)}
                        InputProps={{
                          readOnly: isOperatorManager() || isSacUser(),
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        onClick={handleAddRows}
                        color="primary"
                        variant="contained"
                        disabled={!letterRow || !imeiRow}
                      >
                        {t('station.create.newRowBtn')}
                      </Button>
                    </Grid>
                    <Grid item md={3}></Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <p>{t('station.create.createdRows')}</p>
                  {rows.map((r, index) => {
                    return (
                      <Grid container className={classes.createdRoe}>
                        <Grid item md={3}>
                          <TextField
                            label={t('station.create.labels.letter')}
                            // fullWidth
                            value={r.letter}
                            inputProps={{ readOnly: true }}
                            disabled={isOperatorManager()}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            label={t('station.create.labels.imei')}
                            // fullWidth
                            name={'' + index}
                            key={index}
                            value={r.imei}
                            inputProps={{
                              readOnly:
                                isOperatorManager() || isSacUser()
                                  ? true
                                  : isUpdating
                                  ? false
                                  : true,
                            }}
                            onChange={(e) => handleEditRow(e, r.letter)}
                            disabled={isOperatorManager() || isSacUser()}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <Button
                            onClick={() => handleRemoveRow(r)}
                            color="primary"
                            variant="contained"
                            disabled={
                              isUpdating && (isOperatorManager() || isSacUser())
                            }
                          >
                            {t('station.create.removeBtn')}
                          </Button>
                        </Grid>
                        <Grid item md={3}></Grid>
                      </Grid>
                    )
                  })}
                </Grid>
                <Grid item xs={6}>
                  {isStation && rows.length === 0 && (
                    <div
                      style={{ color: 'red' }}
                      onChange={(e) => {
                        errors.rows.message = ''
                        setErrors(errors)
                      }}
                    >
                      {errors.rows.message}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Fragment>
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}

function mapState(state) {
  const { zonesReducer, operatorReducer } = state
  return { zonesReducer, operatorReducer }
}
const actionCreators = {
  getAllOperators: operatorActions.getAllOperators,
  getAllZones: zonesActions.getAllZones,
  getForTechnician: operatorActions.getForTechnician,
  create: stationsActions.create,
  updateStation: stationsActions.updateStation,
}

export default connect(mapState, actionCreators)(Create)
