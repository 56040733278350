import { MenuBook, People } from '@material-ui/icons'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull'
import BuildIcon from '@material-ui/icons/Build'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import ContactsIcon from '@material-ui/icons/Contacts'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import EvStationIcon from '@material-ui/icons/EvStation'
import ExploreIcon from '@material-ui/icons/Explore'
import HelpIcon from '@material-ui/icons/Help'
import HomeIcon from '@material-ui/icons/Home'
import LabelIcon from '@material-ui/icons/Label'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import MapIcon from '@material-ui/icons/Map'
import PaymentIcon from '@material-ui/icons/Payment'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import ScreenLockLandscape from '@material-ui/icons/ScreenLockLandscape'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle'
import React from 'react'
import { rolesConstants } from '../../helpers/roles'

const iconStyle = (path) => {
  return {
    color: window.location.pathname === path ? 'white' : '#e41622',
  }
}

export const MENU_ROLE_ORDER = [
  {
    role: rolesConstants.BS_ADMIN,
    order: [...Array(26).keys()],
  },
  {
    role: rolesConstants.BS_OPERATOR_MANAGER,
    order: [6, 7, 17, 15, 19, 1, 13, 20, 22, 23, 25],
  },
]

/**
 * 0 : home
 * 1: map
 * 2: zones
 * 3: subscriptions
 * 4: insurances
 * 5: rates
 * 6: requestBattery
 * 7: returnBatteries
 * 8: discounts
 * 9: operators
 * 10: customers
 * 11: operatorUser
 * 12: accountUser
 * 13: supervisors
 * 14: technicians
 * 15: stations
 * 16: products
 * 17: batteries
 * 18: report
 * 19: swaps
 * 20: incidents
 * 21: payments
 * 22: automatic-reservation
 * 23: reservation-attempts
 * 24: users
 * 25: help
 */

export const MENU_ITEMS = [
  {
    to: '/home',
    icon: () => <HomeIcon style={iconStyle('/home')} />,
    primary: 'dashboard.items.home',
  },
  {
    to: '/map',
    icon: () => <MapIcon style={iconStyle('/map')} />,
    primary: 'dashboard.items.map',
  },
  {
    to: '/zones',
    icon: () => <ExploreIcon style={iconStyle('/zones')} />,
    primary: 'dashboard.items.zones',
  },
  {
    to: '/subscriptions',
    icon: () => <CardMembershipIcon style={iconStyle('/subscriptions')} />,
    primary: 'dashboard.items.subscriptions',
  },
  {
    to: '/insurances',
    icon: () => <ScreenLockLandscape style={iconStyle('/insurances')} />,
    primary: 'dashboard.items.insurances',
  },
  {
    to: '/rates',
    icon: () => <LocalAtmIcon style={iconStyle('/rates')} />,
    primary: 'dashboard.items.rates',
  },
  {
    to: '/requestBattery',
    icon: () => <AssignmentReturnIcon style={iconStyle('/requestBattery')} />,
    primary: 'dashboard.items.requestBattery',
  },
  {
    to: '/returnBatteries',
    icon: () => (
      <AssignmentReturnedIcon style={iconStyle('/returnBatteries')} />
    ),
    primary: 'dashboard.items.batteriesReturn',
  },
  {
    to: '/discounts',
    icon: () => <LocalOfferIcon style={iconStyle('/discounts')} />,
    primary: 'dashboard.items.discounts',
  },
  {
    to: '/operators',
    icon: () => <AccountBoxIcon style={iconStyle('/operators')} />,
    primary: 'dashboard.items.operators',
  },
  {
    to: '/customers',
    icon: () => <ContactsIcon style={iconStyle('/customers')} />,
    primary: 'dashboard.items.customers',
  },
  {
    to: '/operatorUser',
    icon: () => <AccountCircleIcon style={iconStyle('/operatorUser')} />,
    primary: 'dashboard.items.operatorUser',
  },
  {
    to: '/accountUser',
    icon: () => <AccountCircleIcon style={iconStyle('/accountUser')} />,
    primary: 'dashboard.items.accountUser',
  },
  {
    to: '/supervisors',
    icon: () => <SupervisedUserCircleIcon style={iconStyle('/supervisors')} />,
    primary: 'dashboard.items.supervisors',
  },
  {
    to: '/technicians',
    icon: () => <BuildIcon style={iconStyle('/technicians')} />,
    primary: 'dashboard.items.technician',
  },
  {
    to: '/stations',
    icon: () => <EvStationIcon style={iconStyle('/stations')} />,
    primary: 'dashboard.items.station',
  },
  {
    to: '/products',
    icon: () => <LabelIcon style={iconStyle('/products')} />,
    primary: 'dashboard.items.products',
  },
  {
    to: '/batteries',
    icon: () => <BatteryChargingFullIcon style={iconStyle('/batteries')} />,
    primary: 'dashboard.items.batteries',
  },
  {
    to: '/report',
    icon: () => <DataUsageIcon style={iconStyle('/report')} />,
    primary: 'dashboard.items.report',
  },
  {
    to: '/swaps',
    icon: () => <SwapHorizontalCircleIcon style={iconStyle('/swaps')} />,
    primary: 'dashboard.items.swaps',
  },
  {
    to: '/incidents',
    icon: () => <ReportProblemIcon style={iconStyle('/incidents')} />,
    primary: 'dashboard.items.incidents',
  },
  {
    to: '/payments',
    icon: () => <PaymentIcon style={iconStyle('/payments')} />,
    primary: 'dashboard.items.payments',
  },
  {
    to: '/automatic-reservation',
    isExternal: true,
    icon: () => <MenuBook style={iconStyle('/automatic-reservation')} />,
    primary: 'dashboard.items.automaticReservation',
  },
  {
    to: '/reservation-attempts',
    isExternal: true,
    icon: () => <MenuBook style={iconStyle('/reservation-attempts')} />,
    primary: 'dashboard.items.reservationAttempts',
  },
  {
    to: '/users',
    isExternal: true,
    icon: () => <People style={iconStyle('/users')} />,
    primary: 'dashboard.items.users',
  },
  {
    to: '/help',
    icon: () => <HelpIcon style={iconStyle('/help')} />,
    primary: 'dashboard.items.help',
  },
]

export const MENU_DEFAULT_ORDER = {
  role: 'all',
  order: MENU_ITEMS.map((_, index) => index),
}
