import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { swapsActions } from '../../../redux/actions/swaps_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({
  isOpen,
  onClose,
  onConfirm,
  create,
  getAllStations,
  createReserve,
  stationsReducer,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const rowTitle = t('swaps.usage.table.columns.row')

  const [customerEmail, setCustomerEmail] = useState('')
  const [batteriesNumber, setBatteriesNumber] = useState(1)
  const [station, setStation] = useState('')
  const [rows, setRows] = useState([])

  const [errors, setErrors] = useState({
    customerEmail: { result: false, message: '' },
    batteriesNumber: { result: false, message: '' },
    station: { result: false, message: '' },
  })

  const handleEnter = () => {
    getAllStations()
  }

  const handleClick = () => {
    validateForm()

    if (isFormValid()) {
      createReserve(station.id, parseInt(batteriesNumber), customerEmail, rows)
      handleClose()
    }
  }

  const validateForm = () => {
    let newErrors = errors
    newErrors.customerEmail = validations.validateEmail(customerEmail)
    newErrors.batteriesNumber = validations.validateHigherInt(
      1,
      batteriesNumber,
    )
    newErrors.station = validations.required(station)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const handleClose = () => {
    onClose()
    setCustomerEmail('')
    setBatteriesNumber(1)
    setStation({})
    setRows([])

    setErrors({
      customerEmail: { result: false, message: '' },
      batteriesNumber: { result: false, message: '' },
      station: { result: false, message: '' },
    })
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('swaps.dialogs.create.title')}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClick}>
            {t('swaps.dialogs.create.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>

      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label={t('swaps.dialogs.form.labels.customerEmail')}
              value={customerEmail}
              onChange={(e) => {
                setCustomerEmail(e.target.value)
                errors.customerEmail.message = ''
                setErrors(errors)
              }}
              error={errors.customerEmail.message.length === 0 ? false : true}
              helperText={errors.customerEmail.message}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label={t('swaps.dialogs.form.labels.batteriesNumber')}
              value={batteriesNumber}
              onChange={(e) => {
                setBatteriesNumber(e.target.value)
                errors.batteriesNumber.message = ''
                setErrors(errors)
              }}
              error={errors.batteriesNumber.message.length === 0 ? false : true}
              helperText={errors.batteriesNumber.message}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              options={stationsReducer.results}
              getOptionLabel={(option) => option?.nameAndAddress || '-- --'}
              value={station}
              onChange={(event, value) => {
                setStation(value)
                errors.station.message = ''
                setErrors(errors)
              }}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.station.message.length === 0 ? false : true}
                  helperText={errors.station.message}
                  label={t('batteries.create.labels.station')}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="row-select-label">{t('swaps.dialogs.form.labels.rows')}</InputLabel>
              <Select
                labelId="row-select-label"
                id="row-select"
                multiple
                value={rows}
                onChange={(e) => {
                  const { value } = e.target
                  setRows(value)
                }}
                input={<Input />}
                renderValue={(selected) =>
                  selected.map((i) => `${rowTitle} ${i.letter}`).join(', ')
                }
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  variant: 'menu',
                }}
              >
                {station?.rows?.map((row) => (
                  <MenuItem key={row.letter} value={row} >
                    <Checkbox
                      checked={rows.some((r) => r.letter === row.letter)}
                      color="primary"
                    />
                    <ListItemText primary={`${rowTitle} ${row.letter}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}

const actionCreators = {
  getAllStations: stationsActions.getAllStations,
  createReserve: swapsActions.reserve,
}

export default connect(mapState, actionCreators)(Create)
