import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import moment from 'moment'
import { formatGender } from '../shared/formatGender'

const CustomerData = ({ customerDataSelectedRow }) => {
  // const  useStyles();
  const { t } = useTranslation()

  const {
    firstName,
    lastName,
    email,
    birthday,
    phone,
    gender,
    idDocCode,
    isTrusted,
  } = customerDataSelectedRow || {
    birthday: '',
    email: '',
    firstName: '',
    gender: '',
    idDocCode: '',
    isTrusted: true,
    lastName: '',
    phone: '',
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            // variant='standard'
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.name')}
            value={firstName}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            // variant="outlined"
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.lastName')}
            value={lastName}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            // variant="outlined"
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.email')}
            value={email}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            // variant="outlined"
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.birthDate')}
            value={moment(birthday).format('DD/MM/YYYY')}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            // variant="outlined"
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.contactPhone')}
            value={phone}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            // variant="outlined"
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.genre')}
            value={t(formatGender(gender))}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            // variant="outlined"
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.idCard')}
            value={idDocCode}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            // variant="outlined"
            fullWidth
            inputProps={{ readOnly: true }}
            // disabled
            label={t('customers.customerData.labels.isTrusted')}
            value={t(isTrusted ? 'shared.yes' : 'shared.no')}
          />
        </Grid>
        <Grid item xs={12}>
          <p>{t('customers.customerData.labels.activeRates')}</p>
        </Grid>
        <Grid item xs={12}>
          <p>imagenes DNI</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default CustomerData
