import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { isCauUser, isManagerReadOnly, isSacUser } from '../../helpers/roles'
import { insurancesActions } from '../../redux/actions/insurances_actions'
import CustomMaterialTable from '../shared/customMaterialTable'
import ModalConfirm from '../shared/modalconfirm'

const InsurancesView = ({
  insurancesReducer,
  getAllInsurances,
  getTermsAndConditions,
  autorenew,
}) => {
  useEffect(() => {
    getAllInsurances()
  }, [])

  const [isEditModalOpened, setIsEditModalOpened] = useState(false)
  const [selectedData, setSelectedData] = useState({})

  const { t } = useTranslation()

  return (
    <div>
      <CustomMaterialTable
        title={t('insurances.page.title')}
        data={insurancesReducer.insurances}
        isLoading={insurancesReducer.loadingResults}
        columns={[
          {
            title: t('insurances.page.table.columns.insuranceId'),
            field: 'contractId',
          },
          {
            title: t('insurances.page.table.columns.suscriptionId'),
            field: 'subscriptionContractId',
          },
          {
            title: t('insurances.page.table.columns.customerSystemId'),
            field: 'customerSystemId',
          },
          {
            title: t('insurances.page.table.columns.rate'),
            field: 'rateName',
            render: () => 'Cobertura daños y robo BP',
          },
          {
            title: t('insurances.page.table.columns.zone'),
            field: 'zoneName',
          },
          {
            title: t('insurances.page.table.columns.startDate'),
            field: 'since',
            render: (rowData) =>
              rowData.since
                ? moment(rowData.since).format('DD/MM/YYYY HH:mm')
                : '',
          },
          {
            title: t('insurances.page.table.columns.endDate'),
            field: 'until',
            render: (rowData) =>
              rowData.until
                ? moment(rowData.until).format('DD/MM/YYYY HH:mm')
                : '',
          },
          {
            title: t('insurances.page.table.columns.autoRenewal'),
            field: 'autorenew',
            lookup: {
              0: t('swaps.usage.labels.yes'),
              1: t('swaps.usage.labels.no'),
            },
            customFilterAndSearch: (term, rowData) =>
              handleTrueFalsyTermSwitch(term, rowData.autorenew),
            render: (rowData) => {
              return rowData.autorenew
                ? t('swaps.usage.labels.yes')
                : t('swaps.usage.labels.no')
            },
          },
          {
            title: t('insurances.page.table.columns.renewsAmount'),
            field: 'renewsAmount',
          },
          {
            title: t('insurances.page.table.columns.status'),
            field: 'status',
            render: (rowData) => {
              switch (rowData.status) {
                case 0:
                  return t('insurances.page.table.columns.inactive')
                case 1:
                  return t('insurances.page.table.columns.active')
                case 2:
                  return t('insurances.page.table.columns.notStarted')
              }
            },
          },
        ]}
        actions={
          !isCauUser() && [
            {
              icon: 'download',
              tooltip: t('insurances.page.table.actions.download'),
              onClick: (_, rowData) => getTermsAndConditions(rowData.id),
              hidden: isCauUser(),
            },
            (rowData) => ({
              icon: 'edit',
              tooltip: t('insurances.page.table.actions.edit'),
              onClick: () => {
                setIsEditModalOpened(true)
                setSelectedData(rowData)
              },
              hidden: rowData.status === 0 || isCauUser() || isManagerReadOnly(),
            }),
          ]
        }
        exportButton={!isCauUser() && !isSacUser()}
      />
      <ModalConfirm
        title={`${t('insurances.page.renewModal.title')} (${
          selectedData.contractId
        })`}
        body={
          selectedData?.autorenew
            ? t('insurances.page.renewModal.renewalEnabledBody')
            : t('insurances.page.renewModal.renewalDisabledBody')
        }
        isOpen={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        onConfirm={() => autorenew(selectedData)}
      />
    </div>
  )
}

function mapState(state) {
  const { insurancesReducer } = state
  return { insurancesReducer }
}

const actionCreators = {
  getAllInsurances: insurancesActions.getAll,
  getTermsAndConditions: insurancesActions.getTermsAndConditions,
  autorenew: insurancesActions.autorenew,
}

export default connect(mapState, actionCreators)(InsurancesView)
