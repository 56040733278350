import { ratesConstants } from '../../constants/rates_constants'
import { ratesService } from '../../services/ratesServices'
import { alertActions } from './alert_actions'

import i18next from 'i18next'
export const ratesActions = {
  getAllRate,
  getRateById,
  createRate,
  updateRate,
  getRatesByCustomerId,
  getByZone,
  deleteRate,
}

function deleteRate(id) {
  return (dispatch) => {
    ratesService.deleteRate(id).then(
      (results) => {
        dispatch(getAllRate())
        dispatch(
          alertActions.success(i18next.t('services.rates.deleteRateSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.rates.deleteRateError')),
        )
      },
    )
  }
}

function getByZone(zoneId) {
  return (dispatch) => {
    dispatch(request())
    ratesService.getByZone(zoneId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: ratesConstants.GET_BY_ZONE_ID_REQUEST }
  }

  function success(results) {
    return { type: ratesConstants.GET_BY_ZONE_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: ratesConstants.GET_BY_ZONE_ID_FAILURE, error }
  }
}

function getRatesByCustomerId(customerId) {
  return (dispatch) => {
    dispatch(request())
    ratesService.getRatesByCustomerId(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: ratesConstants.GET_BY_CUSTOMER_ID_REQUEST }
  }

  function success(results) {
    return { type: ratesConstants.GET_BY_CUSTOMER_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: ratesConstants.GET_BY_CUSTOMER_ID_FAILURE, error }
  }
}

function updateRate(body) {
  return (dispatch) => {
    ratesService.updateRate(body).then(
      () => {
        dispatch(
          alertActions.success(i18next.t('services.rates.updateRateSuccess')),
        )
        dispatch(getAllRate())
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.rates.updateRateError') + error,
          ),
        )
      },
    )
  }
}

function createRate(body) {
  return (dispatch) => {
    ratesService.createRate(body).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.rates.createRateSuccess')),
        )
        dispatch(getAllRate())
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.rates.createRateError') + error,
          ),
        )
      },
    )
  }
}

function getRateById(rateId) {
  return (dispatch) => {
    dispatch(request())
    ratesService.getRateById(rateId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: ratesConstants.GET_BY_RATE_ID_REQUEST }
  }

  function success(results) {
    return { type: ratesConstants.GET_BY_RATE_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: ratesConstants.GET_BY_RATE_ID_FAILURE, error }
  }
}

function getAllRate() {
  return (dispatch) => {
    dispatch(request())
    ratesService.getAllRate().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: ratesConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: ratesConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: ratesConstants.GET_ALL_FAILURE, error }
  }
}
