import React, { useEffect, useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { operatorActions } from '../../../redux/actions/operator_action'
import { zonesActions } from '../../../redux/actions/zones_actions'

import CustomMaterialTable from '../../shared/customMaterialTable'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  createdRoe: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  addModuleSection: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  selectorSection: {
    marginTop: '2%',
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AssignmentManager = ({
  isOpen,
  onClose,
  selectedData,
  zonesReducer,
  operatorReducer,
  getAllOperators,
  getAllZones,

  unassignFromOperatorAndZoneAction,
  assignToOperatorAndZoneAction,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [btnConfirmDisabled, setbtnConfirmDisabled] = useState(true)
  const [localselectedData, setlocalselectedData] = useState([])
  const [assignToOperatorAndZone, setassignToOperatorAndZone] = useState([])
  const [operator, setoperator] = useState()
  const [zone, setzone] = useState()
  const [unassignFromOperatorAndZone, setunassignFromOperatorAndZone] =
    useState([])

  useEffect(() => {
    let updatedList =
      Array.isArray(selectedData) &&
      selectedData.map((i) => {
        return {
          ...i,
          operatorData: operatorReducer.results.find(
            (n) => n?.batteryStation?.id === i.operatorCiaId,
          ),
          zoneData: zonesReducer.results.find(
            (n) => i?.batteryStation?.zoneId === n.id,
          ),
        }
      })

    setlocalselectedData(updatedList)
  }, [operatorReducer.results, zonesReducer.results])

  useEffect(() => {
    setbtnConfirmDisabled(
      (unassignFromOperatorAndZone.length === 0 &&
        assignToOperatorAndZone.length === 0) ||
        (assignToOperatorAndZone.length > 0 && (!zone || !operator)),
    )
  }, [operator, zone, assignToOperatorAndZone, unassignFromOperatorAndZone])

  const handleClose = () => {
    onClose()
    setlocalselectedData([])
    setunassignFromOperatorAndZone([])
    setassignToOperatorAndZone([])
    setoperator()
    setzone()
  }

  const handleEnter = () => {
    setlocalselectedData(selectedData)
    getAllOperators()
    getAllZones()
  }

  const handleAssign = () => {
    const bodyUnassign = {
      batteries: unassignFromOperatorAndZone.map((i) => i.id),
    }
    const bodyAssign = {
      batteries: assignToOperatorAndZone.map((i) => i.id),
      zoneId: zone && zone.id,
      operatorCiaId: operator && operator.id,
    }

    unassignFromOperatorAndZone.length > 0 &&
      unassignFromOperatorAndZoneAction(bodyUnassign)
    assignToOperatorAndZone.length > 0 &&
      assignToOperatorAndZoneAction(bodyAssign)
    handleClose()
  }

  const handleAction = (rowData, list) => {
    switch (list) {
      case 'toOperatorAndZone':
        setassignToOperatorAndZone((prev) => [...prev, rowData])
        break
      case 'unassignFrom':
        setunassignFromOperatorAndZone((prev) => [...prev, rowData])
        break
    }

    const handleActionIndex = localselectedData
      .map((e) => e.id)
      .indexOf(rowData.id)

    let handleActionlocalselectedDataUpdated = localselectedData
    handleActionlocalselectedDataUpdated.splice(handleActionIndex, 1)

    setlocalselectedData([...handleActionlocalselectedDataUpdated])
  }

  const handleAddToLocalSelectedData = (rowData, list) => {
    switch (list) {
      case 'toOperatorAndZone':
        const indexToOperatorAndZone = assignToOperatorAndZone
          .map((e) => e.id)
          .indexOf(rowData.id)

        let toOperatorAndZoneUpdated = assignToOperatorAndZone
        toOperatorAndZoneUpdated.splice(indexToOperatorAndZone, 1)
        setassignToOperatorAndZone([...toOperatorAndZoneUpdated])
        break
      case 'unassignFrom':
        const indexUnassignFrom = unassignFromOperatorAndZone
          .map((e) => e.id)
          .indexOf(rowData.id)

        let unassignFromUpdated = unassignFromOperatorAndZone
        unassignFromUpdated.splice(indexUnassignFrom, 1)
        setunassignFromOperatorAndZone([...unassignFromUpdated])
        break
    }

    const updatedLocalSelectedData = localselectedData
    updatedLocalSelectedData.push(rowData)

    setlocalselectedData([...updatedLocalSelectedData])
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      onEnter={handleEnter}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('station.assignmentManager.dialogTitle')}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleAssign}
            disabled={btnConfirmDisabled}
          >
            {t('station.assignmentManager.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div>
        <CustomMaterialTable
          title={''}
          columns={[
            {
              title: 'Id',
              field: 'id',
            },
            {
              title: t('station.page.table.columns.operator'),
              field: 'operatorData.name',
            },
            {
              title: t('station.page.table.columns.imei'),
              field: 'imei',
            },
            // {
            //   title: t("station.page.table.columns.state"),
            //   field: "batteryStation.status",
            // },
            {
              title: t('station.page.table.columns.zone'),
              field: 'zoneData.name',
            },
          ]}
          data={localselectedData}
          actions={[
            (rowData) => ({
              icon: 'add_circle_outline',
              tooltip: 'assign to operator and Zone',
              onClick: (event, rowData) =>
                handleAction(rowData, 'toOperatorAndZone'),
              hidden: rowData.batteryStation.zoneId,
            }),
            (rowData) => ({
              icon: 'remove_circle_outline',
              tooltip: 'unassign from zone and operator',
              onClick: (event, rowData) =>
                handleAction(rowData, 'unassignFrom'),
              hidden: !rowData.batteryStation.zoneId,
            }),
          ]}
        />
        <div className={classes.selectorSection}>
          <p>{t('station.assignmentManager.helpTextSelectorSection')}</p>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Autocomplete
                options={operatorReducer.results}
                getOptionLabel={(option) => option.name}
                // style={{ width: 300 }}
                value={operator}
                onChange={(event, value) => setoperator(value)}
                disabled={assignToOperatorAndZone.length === 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('batteries.create.labels.operator')}
                    // variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <Autocomplete
                options={zonesReducer.results}
                getOptionLabel={(option) => option.name}
                // style={{ width: 300 }}
                value={zone}
                onChange={(event, value) => setzone(value)}
                disabled={assignToOperatorAndZone.length === 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('batteries.create.labels.zone')}
                    // variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item mid={3}>
              <Typography variant="subtitle1">
                {t('station.assignmentManager.assignToOperatorAndZone')}
              </Typography>
              <List dense={true}>
                {assignToOperatorAndZone.map((i) => {
                  return (
                    <ListItem key={i.id}>
                      <ListItemText primary={i.id} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            handleAddToLocalSelectedData(i, 'toOperatorAndZone')
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
            <Grid item mid={3}>
              <Typography variant="subtitle1">
                {t('station.assignmentManager.unassignToOperatorAndZone')}
              </Typography>
              <List dense={true}>
                {unassignFromOperatorAndZone.map((i) => {
                  return (
                    <ListItem>
                      <ListItemText primary={i.id} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            handleAddToLocalSelectedData(i, 'unassignFrom')
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { zonesReducer, operatorReducer } = state
  return { zonesReducer, operatorReducer }
}
const actionCreators = {
  getAllOperators: operatorActions.getAllOperators,
  getAllZones: zonesActions.getAllZones,

  unassignFromOperatorAndZoneAction:
    batteriesActions.unassignFromZoneAndOperatorCia,
  assignToOperatorAndZoneAction: batteriesActions.assignToZoneAndOperator,
}

export default connect(mapState, actionCreators)(AssignmentManager)
