import { incidentsConstants } from '../../constants/incidents_constants'
import { incidentsService } from '../../services/incidentsService'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

export const incidentsActions = {
  getAll,
  create,
  getById,
  addNote,
  closeIncident,
  getActiveManualSwapsForLogged,
}

function closeIncident(body) {
  return (dispatch) => {
    incidentsService.closeIncident(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.incidents.closeIncidentSuccess'),
          ),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.incidents.closeIncidentError')}`,
          ),
        )
      },
    )
  }
}
function addNote(body) {
  const { id } = body
  return (dispatch) => {
    incidentsService.addNote(body).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.incidents.addNoteSuccess')),
        )
        dispatch(getById(id))
      },
      (error) => {
        dispatch(
          alertActions.error(`${i18next.t('services.incidents.addNoteError')}`),
        )
      },
    )
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request())
    incidentsService.getById(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.incidents.getById')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: incidentsConstants.GET_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: incidentsConstants.GET_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: incidentsConstants.GET_BY_ID_FAILURE, error }
  }
}

function getActiveManualSwapsForLogged() {
  return (dispatch) => {
    dispatch(request())
    incidentsService.getActiveManualSwapsForLogged().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error))
      },
    )
  }

  function request() {
    return { type: incidentsConstants.GET_SWAPS_MANUAL_REQUEST }
  }

  function success(results) {
    return { type: incidentsConstants.GET_SWAPS_MANUAL_SUCCESS, results }
  }

  function failure(error) {
    return { type: incidentsConstants.GET_SWAPS_MANUAL_FAILURE, error }
  }
}

function create(body) {
  return (dispatch) => {
    incidentsService.create(body).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.incidents.createSuccess')),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.incidents.createError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function getAll(dateRange) {
  return (dispatch) => {
    dispatch(request())
    incidentsService.getAll(dateRange).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(
            `${i18next.t('services.incidents.getAll')}: ${error}`,
          ),
        )
      },
    )
  }

  function request() {
    return { type: incidentsConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: incidentsConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: incidentsConstants.GET_ALL_FAILURE, error }
  }
}
