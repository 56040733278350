export const batteriesConstants = {
  GET_ALL_REQUEST: 'BATTERIES_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'BATTERIES_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'BATTERIES_GET_ALL_FAILURE',

  GET_BY_BATTERY_ID_REQUEST: 'BATTERIES_GET_BY_BATTERY_ID_REQUEST',
  GET_BY_BATTERY_ID_SUCCESS: 'BATTERIES_GET_BY_BATTERY_ID_SUCCESS',
  GET_BY_BATTERY_ID_FAILURE: 'BATTERIES_GET_BY_BATTERY_ID_FAILURE',

  GET_DYNAMICS_BY_BATTERY_ID_REQUEST:
    'BATTERIES_GET_DYNAMICS_BY_BATTERY_ID_REQUEST',
  GET_DYNAMICS_BY_BATTERY_ID_SUCCESS:
    'BATTERIES_GET_DYNAMICS_BY_BATTERY_ID_SUCCESS',
  GET_DYNAMICS_BY_BATTERY_ID_FAILURE:
    'BATTERIES_GET_DYNAMICS_BY_BATTERY_ID_FAILURE',

  GET_ALL_DYNAMICS_REQUEST: 'BATTERIES_GET_ALL_DYNAMICS_REQUEST',
  GET_ALL_DYNAMICS_SUCCESS: 'BATTERIES_GET_ALL_DYNAMICS_SUCCESS',
  GET_ALL_DYNAMICS_FAILURE: 'BATTERIES_GET_ALL_DYNAMICS_FAILURE',

  GET_BATTERIES_ASSIGNED_TO_ZONE_REQUEST:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_ZONE_REQUEST',
  GET_BATTERIES_ASSIGNED_TO_ZONE_SUCCESS:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_ZONE_SUCCESS',
  GET_BATTERIES_ASSIGNED_TO_ZONE_FAILURE:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_ZONE_FAILURE',

  GET_BATTERIES_ASSIGNED_TO_OPERATOR_REQUEST:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_OPERATOR_REQUEST',
  GET_BATTERIES_ASSIGNED_TO_OPERATOR_SUCCESS:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_OPERATOR_SUCCESS',
  GET_BATTERIES_ASSIGNED_TO_OPERATOR_FAILURE:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_OPERATOR_FAILURE',

  GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_REQUEST:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_REQUEST',
  GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_SUCCESS:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_SUCCESS',
  GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_FAILURE:
    'BATTERIES_GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_FAILURE',

  GET_ALL_UNASSIGNED_REQUEST: 'BATTERIES_GET_ALL_UNASSIGNED_REQUEST',
  GET_ALL_UNASSIGNED_SUCCESS: 'BATTERIES_GET_ALL_UNASSIGNED_SUCCESS',
  GET_ALL_UNASSIGNED_FAILURE: 'BATTERIES_GET_ALL_UNASSIGNED_FAILURE',

  GET_UNASSIGNED_BY_ZONE_REQUEST: 'BATTERIES_GET_UNASSIGNED_BY_ZONE_REQUEST',
  GET_UNASSIGNED_BY_ZONE_SUCCESS: 'BATTERIES_GET_UNASSIGNED_BY_ZONE_SUCCESS',
  GET_UNASSIGNED_BY_ZONE_FAILURE: 'BATTERIES_GET_UNASSIGNED_BY_ZONE_FAILURE',

  GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST:
    'BATTERIES_GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST',
  GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS:
    'BATTERIES_GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS',
  GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE:
    'BATTERIES_GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE',

  GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST:
    'BATTERIES_GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST',
  GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS:
    'BATTERIES_GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS',
  GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE:
    'BATTERIES_GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE',

  GET_PICKUP_REQUEST_REQUEST: 'BATTERIES_GET_PICKUP_REQUEST_REQUEST',
  GET_PICKUP_REQUEST_SUCCESS: 'BATTERIES_GET_PICKUP_REQUEST_SUCCESS',
  GET_PICKUP_REQUEST_FAILURE: 'BATTERIES_GET_PICKUP_REQUEST_FAILURE',

  GET_BATTERIES_AVAILABLE_REQUEST: 'BATTERIES_GET_AVAILABLE_REQUEST',
  GET_BATTERIES_AVAILABLE_SUCCESS: 'BATTERIES_GET_AVAILABLE_SUCCESS',
  GET_BATTERIES_AVAILABLE_FAILURE: 'BATTERIES_GET_AVAILABLE_FAILURE',

  GET_BATTERIES_FOR_LOGGED_REQUEST: 'BATTERIES_LOGGED_REQUEST',
  GET_BATTERIES_FOR_LOGGED_SUCCESS: 'BATTERIES_LOGGED_SUCCESS',
  GET_BATTERIES_FOR_LOGGED_FAILURE: 'BATTERIES_LOGGED_FAILURE',

  GET_BATTERY_CHECK_REQUEST: 'BATTERY_CHECK_REQUEST',

  UPDATE_PICKUP_REQUEST: 'UPDATE_PICKUP_REQUEST',
  UPDATE_PICKUP_SUCCESS: 'UPDATE_PICKUP_SUCCESS',
  UPDATE_PICKUP_FAILURE: 'UPDATE_PICKUP_FAILURE',

  GET_DELIVERABLE_IN_STATION_REQUEST: 'GET_DELIVERABLE_IN_STATION_REQUEST',
  GET_DELIVERABLE_IN_STATION_SUCCESS: 'GET_DELIVERABLE_IN_STATION_SUCCESS',
  GET_DELIVERABLE_IN_STATION_FAILURE: 'GET_DELIVERABLE_IN_STATION_FAILURE',

  CREATE_BATTERY_INSERTION_REQUEST: 'CREATE_BATTERY_INSERTION_REQUEST',
  CREATE_BATTERY_INSERTION_SUCCESS: 'CREATE_BATTERY_INSERTION_SUCCESS',
  CREATE_BATTERY_INSERTION_FAILURE: 'CREATE_BATTERY_INSERTION_FAILURE',

  ASSIGN_TO_STATION_REQUEST: 'ASSIGN_TO_STATION_REQUEST',
  ASSIGN_TO_STATION_SUCCESS: 'ASSIGN_TO_STATION_SUCCESS',
  ASSIGN_TO_STATION_FAILURE: 'ASSIGN_TO_STATION_FAILURE',
}
