import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { ratesActions } from '../../../redux/actions/rates_actions'
import { formatDateToInsertIntoComponent } from '../../shared/formatDate'

const RatesUpdateDialog = ({
  isOpen,
  onClose,
  updateRate,
  selected,
  productReducer,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const [name, setname] = useState('')
  const [fixedInvoiceProductCode, setFixedInvoiceProductCode] = useState('')
  const [variableInvoiceProductCode, setVariableInvoiceProductCode] =
    useState('')
  const [depositInvoiceProductCode, setDepositInvoiceProductCode] = useState('')
  const [includedBatteries, setIncludedBatteries] = useState('')
  const [validityMonths, setValidityMonths] = useState(null)
  const [permanenceMonth, setPermanenceMonths] = useState('')
  const [swapMarginMinute, setSwapMarginMinutes] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [finishDate, setFinishDate] = useState('')
  const [allowEdit, setAllowEdit] = useState(false)
  const [visibleApp, setVisibleApp] = useState(false)
  const [regenerateSwaps, setRegenerateSwaps] = useState(false)

  const [errors, setErrors] = React.useState({
    name: { result: false, message: '' },
    fixedInvoiceProductCode: { result: false, message: '' },
    includedBatteries: { result: false, message: '' },
    validityMonths: { result: true, message: '' },
    variableInvoiceProductCode: { result: false, message: '' },
    depositInvoiceProductCode: { result: true, message: '' },
    permanenceMonths: { result: false, message: '' },
    swapMarginMinutes: { result: false, message: '' },
    finishDate: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.name = validations.required(name)
    newErrors.fixedInvoiceProductCode = validations.required(
      fixedInvoiceProductCode,
    )
    newErrors.variableInvoiceProductCode = validations.required(
      variableInvoiceProductCode,
    )
    newErrors.depositInvoiceProductCode = validations.required(
      depositInvoiceProductCode,
    )
    newErrors.permanenceMonths = validations.required(permanenceMonth)
    newErrors.includedBatteries = validations.validateHigherInt(
      1,
      includedBatteries,
    )
    if (!regenerateSwaps)
      newErrors.swapMarginMinutes = validations.required(swapMarginMinute)
    else newErrors.swapMarginMinutes = { result: true, message: '' }
    newErrors.finishDate = validations.required(finishDate, null, '1-01-01')
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) {
        valid = false
      }
    })

    return valid
  }

  const handleClose = () => {
    onClose()
    setname('')
    setIncludedBatteries('')
    setValidityMonths(null)
    setStartDate('')
    setFinishDate('')
    setPermanenceMonths('')
    setSwapMarginMinutes('')
    setDepositInvoiceProductCode('')
    setVisibleApp(false)
    setRegenerateSwaps(false)
    setErrors({
      name: { result: false, message: '' },
      fixedInvoiceProductCode: { result: false, message: '' },
      variableInvoiceProductCode: { result: false, message: '' },
      depositInvoiceProductCode: { result: true, message: '' },
      permanenceMonths: { result: false, message: '' },
      swapMarginMinutes: { result: false, message: '' },
      finishDate: { result: false, message: '' },
      includedBatteries: { result: false, message: '' },
      validityMonths: { result: true, message: '' },
    })
  }

  const handleEnter = () => {
    const {
      name,
      applySince,
      applyUntil,
      permanenceMonths,
      swapMarginMinutes,
      batteriesNumber,
      isVisibleInApp,
      regenerateSwaps,
      depositProductCode,
      fixedProductCode,
      variableProductCode,
      validityMonths,
    } = selected

    setAllowEdit(moment(applySince).isAfter(applyUntil))
    setname(name)
    setIncludedBatteries(batteriesNumber ?? 1)
    setValidityMonths(validityMonths)
    setPermanenceMonths(permanenceMonths)
    setSwapMarginMinutes(swapMarginMinutes)
    setFixedInvoiceProductCode(fixedProductCode)
    setVariableInvoiceProductCode(variableProductCode)
    setDepositInvoiceProductCode(depositProductCode)
    setStartDate(formatDateToInsertIntoComponent(applySince))
    setFinishDate(formatDateToInsertIntoComponent(applyUntil))
    setVisibleApp(isVisibleInApp)
    setRegenerateSwaps(regenerateSwaps)
  }

  const handleConfirm = () => {
    validateForm()

    if (isFormValid()) {
      const body = {
        name,
        depositProductCode: depositInvoiceProductCode,
        fixedProductCode: fixedInvoiceProductCode,
        variableProductCode: variableInvoiceProductCode,
        batteriesNumber: parseInt(includedBatteries),
        ...(validityMonths && { validityMonths: parseInt(validityMonths) }),
        permanenceMonths: parseInt(permanenceMonth),
        swapMarginMinutes: parseInt(swapMarginMinute ?? 1),
        applySince: startDate,
        applyUntil: finishDate,
        isVisibleInApp: visibleApp,
        regenerateSwaps: regenerateSwaps,
        id: selected.id,
      }

      updateRate(body)
      handleClose()
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      onEnter={handleEnter}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('rates.update.dialogTitle')}
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled={allowEdit}
                // variant="outlined"
                required
                fullWidth
                label={t('rates.update.labels.name')}
                value={name}
                error={errors.name.message.length === 0 ? false : true}
                helperText={errors.name.message}
                onChange={(e) => {
                  setname(e.target.value)
                  errors.name.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibleApp}
                    onChange={() => setVisibleApp(!visibleApp)}
                    color="primary"
                  />
                }
                label={t('rates.update.labels.visible')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={regenerateSwaps}
                    onChange={() => setRegenerateSwaps(!regenerateSwaps)}
                    color="primary"
                  />
                }
                label={t('rates.update.labels.regenerateSwaps')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                // variant="outlined"
                select
                required
                fullWidth
                label={t('rates.create.labels.fixedInvoiceProductCode')}
                value={fixedInvoiceProductCode}
                error={
                  errors.fixedInvoiceProductCode.message.length === 0
                    ? false
                    : true
                }
                helperText={errors.fixedInvoiceProductCode.message}
                onChange={(e) => {
                  setFixedInvoiceProductCode(e.target.value)
                  errors.fixedInvoiceProductCode.message = ''
                  setErrors(errors)
                }}
              >
                {productReducer.results.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.code + ' ' + option.price + '€'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // variant="outlined"
                select
                required
                fullWidth
                label={t('rates.create.labels.variableInvoiceProductCode')}
                value={variableInvoiceProductCode}
                error={
                  errors.variableInvoiceProductCode.message.length === 0
                    ? false
                    : true
                }
                helperText={errors.variableInvoiceProductCode.message}
                onChange={(e) => {
                  setVariableInvoiceProductCode(e.target.value)
                  errors.variableInvoiceProductCode.message = ''
                  setErrors(errors)
                }}
              >
                {productReducer.results.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.code + ' ' + option.price + '€'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // variant="outlined"
                select
                fullWidth
                label={t('rates.create.labels.depositInvoiceProductCode')}
                value={depositInvoiceProductCode}
                error={
                  errors.depositInvoiceProductCode.message.length === 0
                    ? false
                    : true
                }
                helperText={errors.depositInvoiceProductCode.message}
                onChange={(e) => {
                  setDepositInvoiceProductCode(e.target.value)
                  errors.depositInvoiceProductCode.message = ''
                  setErrors(errors)
                }}
              >
                {productReducer.results.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.code + ' ' + option.price + '€'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                // variant='standard'
                required
                fullWidth
                type="number"
                label={t('rates.create.labels.permanenceMonths')}
                value={permanenceMonth}
                error={
                  errors.permanenceMonths.message.length === 0 ? false : true
                }
                helperText={errors.permanenceMonths.message}
                onChange={(e) => {
                  setPermanenceMonths(e.target.value)
                  errors.permanenceMonths.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // variant='standard'
                required={!regenerateSwaps}
                fullWidth
                type="number"
                label={t('rates.create.labels.swapMarginMinutes')}
                value={swapMarginMinute}
                error={
                  errors.swapMarginMinutes.message.length === 0 ? false : true
                }
                helperText={errors.swapMarginMinutes.message}
                disabled={regenerateSwaps}
                onChange={(e) => {
                  setSwapMarginMinutes(e.target.value)
                  errors.swapMarginMinutes.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                disabled={allowEdit}
                // variant='standard'
                required
                fullWidth
                label={t('rates.update.labels.includedBatteries')}
                error={
                  errors.includedBatteries.message.length === 0 ? false : true
                }
                helperText={errors.includedBatteries.message}
                value={includedBatteries}
                onChange={(e) => {
                  setIncludedBatteries(e.target.value)
                  errors.includedBatteries.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('rates.create.labels.validityMonths')}
                value={validityMonths}
                error={
                  errors.validityMonths.message.length === 0 ? false : true
                }
                helperText={errors.validityMonths.message}
                type="number"
                onChange={(e) => {
                  setValidityMonths(e.target.value)
                  errors.validityMonths.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={!allowEdit}
                // variant="outlined"
                required
                fullWidth
                type="date"
                label={t('rates.update.labels.startDate')}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // variant="outlined"
                required
                type="date"
                fullWidth
                label={t('rates.update.labels.finishDate')}
                value={finishDate}
                error={errors.finishDate.message.length === 0 ? false : true}
                helperText={errors.finishDate.message}
                onChange={(e) => {
                  setFinishDate(e.target.value)
                  errors.finishDate.message = ''
                  setErrors(errors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {t('rates.update.cancelBtn')}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t('rates.update.confirmBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function mapState() {
  return {}
}

const actionCreators = {
  updateRate: ratesActions.updateRate,
}

export default connect(mapState, actionCreators)(RatesUpdateDialog)
