import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import React from 'react'
import { useTranslation } from 'react-i18next'

const HelpView = () => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t('help.page.help_title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ListItem>
          <ListItemIcon>
            <Tooltip title={t('help.page.tooltips.mail')} placement="top">
              <EmailIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>batterystation@silence.eco</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Tooltip title={t('help.page.tooltips.phone')}>
              <PhoneIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>666 96 52 62</ListItemText>
        </ListItem>
      </Grid>
    </Grid>
  )
}

export default HelpView
