import { technicianConstant } from '../../constants/technician_constants'
import { techniciansService } from '../../services/techniciansServices'

import { getOperatorId } from '../../helpers/operator'
import { isManager } from '../../helpers/roles'
import { alertActions } from './alert_actions'

import i18next from 'i18next'
export const technicianActions = {
  getAllTechnicians,
  getTechnicianById,
  getTechniciansByOperatorId,
  create,
  canOperate,
  cannotOperate,
  deleteTechn,
  update,
}

function update(body) {
  return (dispatch) => {
    techniciansService.update(body).then(
      (results) => {
        dispatch(getAllTechnicians())
        dispatch(
          alertActions.success(i18next.t('services.technician.updateSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.technician.updateError')),
        )
      },
    )
  }
}

function deleteTechn(id) {
  return (dispatch) => {
    techniciansService.deleteTechn(id).then(
      (results) => {
        dispatch(getAllTechnicians())
        dispatch(
          alertActions.success(
            i18next.t('services.technician.deleteOpSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.technician.deleteOpError')),
        )
      },
    )
  }
}
function cannotOperate(body) {
  return (dispatch) => {
    techniciansService.cannotOperate(body).then(
      (results) => {
        // dispatch(getOperatorsPendingValidate());
        dispatch(getAllTechnicians())
        dispatch(
          alertActions.success(
            i18next.t('services.technician.cannotOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.technician.cannotOperateError'),
          ),
        )
      },
    )
  }
}

function canOperate(id) {
  return (dispatch) => {
    techniciansService.canOperate(id).then(
      (results) => {
        // dispatch(getOperatorsPendingValidate());
        dispatch(getAllTechnicians())
        dispatch(
          alertActions.success(
            i18next.t('services.technician.canOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.technician.canOperateError')),
        )
      },
    )
  }
}
function create(body) {
  return (dispatch) => {
    dispatch(request())
    techniciansService.create(body).then(
      (results) => {
        dispatch(getAllTechnicians())
        dispatch(
          alertActions.success(i18next.t('services.technician.createSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.technician.createError')),
        )
      },
    )
  }

  function request() {
    return { type: technicianConstant.GET_BY_OPERATOR_ID_REQUEST }
  }

  function success(results) {
    return { type: technicianConstant.GET_BY_OPERATOR_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: technicianConstant.GET_BY_OPERATOR_ID_FAILURE, error }
  }
}

function getTechniciansByOperatorId(operatorId) {
  return (dispatch) => {
    dispatch(request())
    techniciansService
      .getTechniciansByOperatorId(getOperatorId() || operatorId)
      .then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          // dispatch(
          //   alertActions.error("error get all supervisor action. Message:" + error)
          // );
        },
      )
  }

  function request() {
    return { type: technicianConstant.GET_BY_OPERATOR_ID_REQUEST }
  }

  function success(results) {
    return { type: technicianConstant.GET_BY_OPERATOR_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: technicianConstant.GET_BY_OPERATOR_ID_FAILURE, error }
  }
}

function getTechnicianById(supervisorId) {
  return (dispatch) => {
    dispatch(request())
    techniciansService.getTechnicianById(supervisorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all supervisor action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: technicianConstant.GET_BY_TECHNICIAN_ID_REQUEST }
  }

  function success(results) {
    return { type: technicianConstant.GET_BY_TECHNICIAN_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: technicianConstant.GET_BY_TECHNICIAN_ID_FAILURE, error }
  }
}

function getAllTechnicians() {
  return (dispatch) => {
    if (isManager()) {
      dispatch(request())
      techniciansService.getAllTechnicians().then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
          // dispatch(
          //   alertActions.error("error get all supervisor action. Message:" + error)
          // );
        },
      )
    } else {
      dispatch(getTechniciansByOperatorId())
    }
  }

  function request() {
    return { type: technicianConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: technicianConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: technicianConstant.GET_ALL_FAILURE, error }
  }
}
