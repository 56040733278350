import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Dashboard from '../dashboard'
import AccountUserView from './page'

const AccountUser = (props) => {
  return <Dashboard component={<AccountUserView {...props} />} />
}

function mapState(state) {
  const { accountUserReducer } = state
  return { accountUserReducer }
}

const actionCreators = {}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(AccountUser)
