import React from 'react'

import {
  AppBar,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import MapDetails from '../../../shared/mapDetails'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SwapDetails = ({ swapsReducer, isOpen, onClose }) => {
  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }
  const {
    batteryChargedOutOfStationKwhs,
    batteryUsedIncludedKwhs,
    batteryUsedKwhs,
    canceled,
    costByKwhChargedOutOfStation,
    costByKwhUsed,
    customerFullName,
    customerId,
    displayTimestamp,
    expired,
    extractedBatteryId,
    extractedBatteryPackId,
    extractedBatterySlotNumber,
    extractedBatterySoc,
    finalized,
    id,
    insertAllowedBatteries,
    insertedBatteryId,
    insertedBatteryPackId,
    insertedBatterySlotNumber,
    insertedBatterySoc,
    isCanceled,
    isExpired,
    isFinalized,
    isPending,
    isSelected,
    operatorCiaId,
    operatorCiaName,
    reserved,
    rowImei,
    rowLetter,
    stationAddress,
    stationId,
    stationLocation,
    status,
    totalPriceByKwhsChargedOutOfStation,
    totalPriceByKwhsUsed,
    willExpire,
    zoneId,
    zoneName,
  } = swapsReducer?.swap
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar style={{ position: 'relative' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            style={{
              marginLeft: 11,
              flex: 1,
            }}
          >
            {t('batteries.details.container.modalTitle')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid>
          <Grid container spacing={1}>
            <Grid item md={12}>
              {stationLocation ? (
                <MapDetails location={stationLocation} height={'40vh'} />
              ) : (
                <p>location data not found</p>
              )}
            </Grid>
            <Grid item md={6}>
              <span>{t('swaps.usage.labels.name')}</span>
            </Grid>
            <Grid item md={6}>
              <span>{customerFullName}</span>
            </Grid>

            <Grid item md={6}>
              <span>{t('swaps.usage.labels.contactPhone')}</span>
            </Grid>
            <Grid item md={6}>
              <span>{customerId}</span>
            </Grid>

            <Grid item md={6}>
              <span>{t('swaps.usage.labels.imeiBatteryIn')}</span>
            </Grid>
            <Grid item md={6}>
              <span>{insertedBatteryPackId}</span>
            </Grid>

            <Grid item md={6}>
              <span>{t('swaps.usage.labels.imeiBatteryOut')}</span>
            </Grid>
            <Grid item md={6}>
              <span>{extractedBatteryId}</span>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

function mapState(state) {
  const { swapsReducer } = state
  return { swapsReducer }
}

const actionCreators = {
  //
}

export default connect(mapState, null)(SwapDetails)
