import { devolutionConstants } from '../../constants/devolution_contants'

const initialState = {
  loadingResults: true,
  devolutions: [],
  devolution: {},
  results: [],
}

export function devolutionsReducer(state = initialState, action) {
  switch (action.type) {
    case devolutionConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case devolutionConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        devolutions: action.results,
      }
    case devolutionConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        devolutions: [],
      }
    case devolutionConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case devolutionConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        devolution: action.results,
      }
    case devolutionConstants.GET_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        devolution: {},
      }
    default:
      return state
  }
}
