const dobleDigit = (n) => {
  return (n < 10 ? '0' : '') + n
}
export const formatDateToDisplay = (date, includeMinutes = false) => {
  if (date && date != '0001-01-01T00:00:00Z' && date != '0001-01-01T00:00:00') {
    const dateFormated = new Date(date)

    const minutes = includeMinutes
      ? ` ${dobleDigit(dateFormated.getHours())}:${dobleDigit(
          dateFormated.getMinutes(),
        )}`
      : ''
    return `${dobleDigit(dateFormated.getDate())}/${dobleDigit(
      dateFormated.getMonth() + 1,
    )}/${dateFormated.getFullYear()}${minutes}`
  } else {
    return '' //N/A
  }
}

export const formatDateToInsertIntoComponent = (
  date,
  includeMinutes = false,
) => {
  const dateFormated = new Date(date)
  const minutes = includeMinutes
    ? `T${dobleDigit(dateFormated.getUTCHours())}:${dobleDigit(
        dateFormated.getUTCMinutes(),
      )}`
    : ''
  return `${dateFormated.getUTCFullYear()}-${dobleDigit(
    dateFormated.getUTCMonth() + 1,
  )}-${dobleDigit(dateFormated.getUTCDate())}${minutes}`
}
