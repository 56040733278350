import React, { useEffect, useState } from 'react'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isFinancial, isTechnician } from '../../../helpers/roles'
import { validations } from '../../../helpers/validation'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { operatorActions } from '../../../redux/actions/operator_action'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const Create = ({
  batteriesReducer,
  operatorReducer,
  zonesReducer,
  stationsReducer,
  getAllUnassigned,
  getAllOperators,
  getAllUnassignedStations,
  getAllZones,
  createOperator,
  isOpen,
  onClose,
  getUnassignedsToOperatorCiaByZones,
  getUnassignedsToOperatorCiaByZonesBatteries,
  getBatteriesForLogged,
  getStationsForLogged,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [repeatPassword, setrepeatPassword] = useState('')
  const [passwordsMatch, setpasswordsMatch] = useState(true)
  const [zone, setzone] = useState([]) //(listado con opción de seleccionar 1 o más)
  const [selectedBatteries, setselectedBatteries] = useState([])
  const [selectedStations, setselectedStations] = useState([])
  const [contactPhone, setcontactPhone] = useState('')
  const [cif, setcif] = useState('')

  const [errors, setErrors] = React.useState({
    zone: { result: false, message: '' },
    name: { result: false, message: '' },
    email: { result: false, message: '' },
    password: { result: true, message: '' },
    repeatPassword: { result: true, message: '' },
    contactPhone: { result: false, message: '' },
    cif: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.zone = validations.validateArray(zone)
    newErrors.name = validations.required(name)
    if (password) newErrors.password = validations.required(password)
    newErrors.email = validations.validateEmail(email)
    if (repeatPassword)
      newErrors.repeatPassword = validations.required(repeatPassword)
    newErrors.contactPhone = validations.validatePhone(contactPhone)
    newErrors.cif = validations.validateCIF_Nif(cif)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  useEffect(() => {
    setpasswordsMatch(
      password === repeatPassword || (password == '' && repeatPassword == ''),
    )
  }, [password, repeatPassword])

  useEffect(() => {
    if (isTechnician()) {
      getStationsForLogged(zone.map((i) => i.id))
      getBatteriesForLogged(zone.map((i) => i.id))
    } else {
      if (!isFinancial()) getUnassignedsToOperatorCiaByZones(zone.map((i) => i.id))
      getUnassignedsToOperatorCiaByZonesBatteries(zone.map((i) => i.id))
    }
  }, [zone])

  const handleClose = () => {
    onClose()
    setname('')
    setemail('')
    setpassword('')
    setzone([])
    setselectedBatteries([])
    setselectedStations([])
    setcontactPhone('')
    setcif('')
    setErrors({
      zone: { result: false, message: '' },
      name: { result: false, message: '' },
      email: { result: false, message: '' },
      password: { result: true, message: '' },
      repeatPassword: { result: true, message: '' },
      contactPhone: { result: false, message: '' },
      cif: { result: false, message: '' },
    })
  }

  const handleEnter = () => {
    getAllOperators()
    getAllZones()
    isTechnician()
      ? getBatteriesForLogged()
      : getUnassignedsToOperatorCiaByZonesBatteries()
    isTechnician()
      ? getStationsForLogged()
      : getUnassignedsToOperatorCiaByZones()
  }

  const handleBatteriesSelectedUpdate = (rows) => {
    setselectedBatteries(rows)
  }

  const handleStationsSelectedUpdate = (rows) => {
    setselectedStations(rows)
  }

  const hadleCreateOperator = () => {
    validateForm()
    if (isFormValid()) {
      const body = {
        name,
        email,
        password: password,
        phone: contactPhone.includes('+34')
          ? contactPhone
          : `+34${contactPhone}`,
        cif,
        zones: zone.map((z) => z.id),
        batteries: selectedBatteries.map((b) => b.id),
        stations: selectedStations.map((s) => s.id),
      }

      createOperator(body)
      handleClose()
    }
  }

  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('operator.dialogs.create.dialogTitle')}
          </Typography>
          <Button autoFocus color="inherit" onClick={hadleCreateOperator}>
            {t('operator.dialogs.create.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.create.labels.name')}
              value={name}
              error={errors.name.message.length === 0 ? false : true}
              helperText={errors.name.message}
              onChange={(e) => {
                setname(e.target.value)
                errors.name.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.create.labels.email')}
              value={email}
              error={errors.email.message.length === 0 ? false : true}
              helperText={errors.email.message}
              onChange={(e) => {
                setemail(e.target.value)
                errors.email.message = ''
                setErrors(errors)
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.create.labels.contactPhone')}
              value={contactPhone}
              error={errors.contactPhone.message.length === 0 ? false : true}
              helperText={errors.contactPhone.message}
              type={'tel'}
              inputProps={{ maxLength: 12 }}
              onChange={(e) => {
                setcontactPhone(e.target.value)
                errors.contactPhone.message = ''
                setErrors(errors)
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.create.labels.cif')}
              value={cif}
              error={errors.cif.message.length === 0 ? false : true}
              helperText={errors.cif.message}
              type={'cif'}
              onChange={(e) => {
                setcif(e.target.value)
                errors.cif.message = ''
                setErrors(errors)
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.create.labels.password')}
              value={password}
              error={errors.password.message.length === 0 ? false : true}
              helperText={errors.password.message}
              type={'password'}
              onChange={(e) => {
                setpassword(e.target.value)
                errors.password.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.create.labels.repeatPassword')}
              value={repeatPassword}
              type={'password'}
              onChange={(e) => {
                setrepeatPassword(e.target.value)
                errors.repeatPassword.message = ''
                setErrors(errors)
              }}
              helperText={
                passwordsMatch
                  ? errors.repeatPassword.message
                  : t('operator.dialogs.create.labels.helperTextRepeatPassword')
              }
              error={
                !passwordsMatch || errors.repeatPassword.message.length > 0
                  ? true
                  : false
              }
            />
          </Grid>

          <Grid item xs={3}>
            <Autocomplete
              options={zonesReducer.results}
              getOptionLabel={(option) => option.name}
              multiple
              // style={{ width: 300 }}
              value={zone}
              onChange={(event, value) => {
                setzone(value)
                errors.zone.message = ''
                setErrors(errors)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.zone.message.length === 0 ? false : true}
                  helperText={errors.zone.message}
                  label={t('operator.dialogs.create.labels.zone')}
                  // variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>

        <div className={classes.tabs}>
          {/* <p>{t("operator.dialogs.create.infoText")}</p>

                    <Accordion
                        square
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                        >
                            <Typography>
                                {t("operator.dialogs.create.tabs.batteries")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <BatteriesAssignedToOperators
                                data={batteriesReducer.unassignedsToOperatorCiaByZones}
                                onSelectionUpdate={handleBatteriesSelectedUpdate}
                                preSelectedRows={selectedBatteries}
                                addTitle={false}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        square
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                    >
                        <AccordionSummary
                            aria-controls="panel2d-content"
                            id="panel2d-header"
                        >
                            <Typography>
                                {t("operator.dialogs.create.tabs.stations")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AssignedToOperators
                                data={stationsReducer.unassignedsToOperatorCiaByZones}
                                onSelectionUpdate={handleStationsSelectedUpdate}
                                preSelectedRows={selectedStations}
                                addTitle={false}
                            />
                        </AccordionDetails>
                    </Accordion> */}
        </div>
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { batteriesReducer, stationsReducer, operatorReducer, zonesReducer } =
    state
  return { batteriesReducer, stationsReducer, operatorReducer, zonesReducer }
}
const actionCreators = {
  createOperator: operatorActions.createOperator,
  getAllOperators: operatorActions.getAllOperators,
  getAllUnassigned: batteriesActions.getAllUnassigned,
  getAllUnassignedStations: stationsActions.getAllUnassignedStations,
  getUnassignedsToOperatorCiaByZones:
    stationsActions.getUnassignedsToOperatorCiaByZones,
  getAllZones: zonesActions.getAllZones,
  getUnassignedsToOperatorCiaByZonesBatteries:
    batteriesActions.getUnassignedsToOperatorCiaByZones,
  getBatteriesForLogged: batteriesActions.getForLogged,
  getStationsForLogged: stationsActions.getForLogged,
}

export default connect(mapState, actionCreators)(Create)
