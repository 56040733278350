import { discountsConstants } from '../../constants/discounts_constants'

const initialState = {
  loadingResults: true,
  results: [],
  discount: {},
  byCustomer: [],
}

export function discountsReducer(state = initialState, action) {
  switch (action.type) {
    case discountsConstants.GET_BY_CUSTOMER_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case discountsConstants.GET_BY_CUSTOMER_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        byCustomer: action.results,
      }
    case discountsConstants.GET_BY_CUSTOMER_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        byCustomer: [],
      }

    case discountsConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case discountsConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        discount: action.results,
      }
    case discountsConstants.GET_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        discount: {},
      }

    case discountsConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case discountsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        results: action.results,
      }
    case discountsConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        results: [],
      }

    default:
      return state
  }
}
