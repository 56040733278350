import { handleCall } from './handleResponse'

export const insurancesServices = {
  getAll,
  getTermsAndConditions,
  autorenew,
}

function getAll() {
  return handleCall(`/stations/v1/Insurance`, {
    method: 'GET',
    body: null,
  })
}

function getTermsAndConditions(insuranceId) {
  return handleCall(
    `/stations/v1/Insurance/TermsAndConditions?insuranceId=${insuranceId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function autorenew(id) {
  return handleCall(`/stations/v1/Insurance/${id}/Autorenew`, {
    method: 'PATCH',
    body: null,
  })
}
