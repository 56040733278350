import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../../shared/customMaterialTable'
import { displayWithTooltip } from '../../shared/enshortenLargeData'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: '1%',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SwapList = ({ isOpen, onClose, reducer }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <div className={classes.container}>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {t('billing.page.historyModal.title')}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.tableContainer}>
          <CustomMaterialTable
            isLoading={reducer.loadingResults}
            data={reducer.paymentIntents}
            title=""
            columns={[
              {
                title: t('billing.page.historyModal.table.id'),
                field: 'invoiceId',
                render: (rowData) => displayWithTooltip(rowData.invoiceId, 5),
              },
              // {
              //     title: t("billing.page.historyModal.table.paymentIntent"),
              //     field: "paymentIntentId",
              //     render: rowData => displayWithTooltip(rowData.paymentIntentId, 5)
              // },
              {
                title: t('billing.page.historyModal.table.date'),
                field: 'requestDateTime',
                render: (rowData) =>
                  moment(rowData.requestDateTime).format('DD/MM/YYYY HH:mm'),
              },
              {
                title: t('billing.page.historyModal.table.customer'),
                field: 'customerEmail',
                render: (rowData) =>
                  displayWithTooltip(rowData.customerEmail, 7),
              },
              {
                title: t('billing.page.historyModal.table.amount'),
                field: 'amount',
              },
              {
                title: t('billing.page.historyModal.table.status'),
                field: 'status',
                render: (rowData) => displayWithTooltip(rowData.status, 10),
              },
              {
                title: t('billing.page.historyModal.table.currency'),
                field: 'currency',
              },
              {
                title: t('billing.page.historyModal.table.errorType'),
                field: 'errorType',
              },
              {
                title: t('billing.page.historyModal.table.errorMessage'),
                field: 'errorMessage',
                render: (rowData) =>
                  displayWithTooltip(rowData.errorMessage, 10),
              },
            ]}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default SwapList
