import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { discountsActions } from '../../redux/actions/discounts_actions'

import { formatDateToDisplay } from '../shared/formatDate'

import ModalConfirm from '../shared/modalconfirm'

import CreateRate from './dialogs/create'

import DatePickerCustom from '../shared/datePickerCustom'

const DiscountsView = ({ getAll, discountsReducer, deleteDiscount }) => {
  const { t } = useTranslation()

  const [isUpdatingData, setisUpdatingData] = useState(false)
  const [isCreateRateDialogOpen, setisCreateRateDialogOpen] = useState(false)

  const [selectedRow, setselectedRow] = useState({})
  const [isDialogRemove, setisDialogRemove] = useState(false)

  useEffect(() => {
    // getAll();
  }, [])

  const handleAction = (rowData, action) => {
    rowData && setselectedRow(rowData)
    switch (action) {
      case 'remove':
        setisDialogRemove(true)
        break

      case 'add':
        setisUpdatingData(false)
        setisCreateRateDialogOpen(true)
        break
      case 'edit':
        setisUpdatingData(true)
        setisCreateRateDialogOpen(true)
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <DatePickerCustom searchAction={getAll} />
      <CustomMaterialTable
        addSummaryRow={true}
        title={t('discounts.page.table.title')}
        columns={[
          {
            title: t('discounts.page.table.columns.since'),
            field: 'applySince',
            render: (rowData) => formatDateToDisplay(rowData.applySince),
          },
          {
            title: t('discounts.page.table.columns.until'),
            field: 'applyUntil',
            render: (rowData) => formatDateToDisplay(rowData.applyUntil),
          },
          {
            title: t('discounts.page.table.columns.created'),
            field: 'created',
            render: (rowData) => formatDateToDisplay(rowData.created),
          },
          {
            title: t('discounts.page.table.columns.percent') + ' (%)',
            field: 'percent',
            addCustomFilterComponentNumberCells: true,
          },
        ]}
        data={discountsReducer.results}
        actions={[
          {
            icon: 'add',
            isFreeAction: true,
            tooltip: t('discounts.page.table.actions.add'),
            onClick: (event, rowData) => handleAction(rowData, 'add'),
          },
          {
            icon: 'edit',
            tooltip: t('discounts.page.table.actions.edit'),
            onClick: (event, rowData) => handleAction(rowData, 'edit'),
            // hidden: rowData.hasOwnProperty("summaryRow"),
          },
          {
            icon: 'delete',
            tooltip: t('discounts.page.table.actions.remove'),
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
          },
        ]}
      />
      <CreateRate
        updateSetting={isUpdatingData}
        isOpen={isCreateRateDialogOpen}
        selectedRow={selectedRow}
        onClose={() => setisCreateRateDialogOpen(false)}
      />

      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteDiscount(selectedRow.id)}
        title={`Eliminar descuento seleccionado`}
        body={'Confirmación: ¿Quieres eliminar el descuento seleccionado?'}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { discountsReducer } = state
  return { discountsReducer }
}

const actionCreators = {
  getAll: discountsActions.getAll,
  deleteDiscount: discountsActions.deleteDiscount,
}

export default connect(mapState, actionCreators)(DiscountsView)
