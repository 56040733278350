import { subscriptionConstants } from '../../constants/subscriptions_constants'
import { subscriptionsService } from '../../services/subscriptionsServices'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

export const subscriptionsActions = {
  getAll,
  getById,
  create,
  cancel,
  cancelAbort,
  confirm,
  getContract,
}

function create(customerEmail, rateId, vin = null) {
  return (dispatch) => {
    subscriptionsService.create(customerEmail, rateId, vin).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.subscription.createSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.subscription.createError')),
        )
      },
    )
  }
}

function cancel(id) {
  return (dispatch) => {
    subscriptionsService.cancel(id).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.subscription.cancelSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.subscription.cancelError')),
        )
      },
    )
  }
}

function cancelAbort(id) {
  return (dispatch) => {
    subscriptionsService.cancelAbort(id).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.subscription.cancelAbortSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.subscription.cancelAbortError'),
          ),
        )
      },
    )
  }
}

function confirm(id) {
  return (dispatch) => {
    subscriptionsService.confirmation(id).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.subscription.confirmationSuccess_cat'),
          ),
        )
        dispatch(
          alertActions.success(
            i18next.t('services.subscription.confirmationSuccess_es'),
          ),
        )
        dispatch(
          alertActions.success(
            i18next.t('services.subscription.confirmationSuccess_en'),
          ),
        )
      },
      (error) => {
        dispatch(
          //alertActions.error(i18next.t("services.subscription.confirmationError"))
          alertActions.error(error),
        )
      },
    )
  }
}

function getById(subscriptionId) {
  return (dispatch) => {
    dispatch(request())
    subscriptionsService.getById(subscriptionId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: subscriptionConstants.GET_BY_SUBSCRIPTION_ID_REQUEST }
  }

  function success(results) {
    return {
      type: subscriptionConstants.GET_BY_SUBSCRIPTION_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: subscriptionConstants.GET_BY_SUBSCRIPTION_ID_FAILURE,
      error,
    }
  }
}

function getAll(rangeDate) {
  return (dispatch) => {
    dispatch(request())
    subscriptionsService.getAll(rangeDate).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: subscriptionConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: subscriptionConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: subscriptionConstants.GET_ALL_FAILURE, error }
  }
}

function getContract(id) {
  return (dispatch) => {
    dispatch(request())
    subscriptionsService.getContract(id).then(
      (results) => {
        window.open(results)
      },
      (error) => {
        alertActions.error(i18next.t('services.subscription.contractError'))
      },
    )
  }

  function request() {
    return { type: subscriptionConstants.GET_CONTRACT_REQUEST }
  }
}
