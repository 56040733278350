import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import AssignedToZones from '../../stations/assignedToZones'

import { connect } from 'react-redux'
import { stationsActions } from '../../../redux/actions/stations_actions'

import { isEqual } from '../../../helpers/compareArrays'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AssignmentStations = ({
  isOpen,
  onClose,
  selectedData = { name: '' },
  stationsReducer,
  getAllUnassignedStations,
  assignToZone,
  getUnassignedByZone,
  getStationsAssignedToZone,
  unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const {
    stationsAssignedToZone,
    results,
    stationsUnassignedByZoneAndOperator,
  } = stationsReducer

  const [selectedStations, setselectedStations] = useState([])
  const [tableDataStations, settableDataStations] = useState([])
  const [readOnlyConfiguration, setreadOnlyConfiguration] = useState(true)

  useEffect(() => {
    settableDataStations([
      ...stationsUnassignedByZoneAndOperator,
      ...stationsAssignedToZone,
    ])
  }, [stationsUnassignedByZoneAndOperator, stationsAssignedToZone])

  const handleEnter = () => {
    const { id } = selectedData
    id && getStationsAssignedToZone(id)
    getAllUnassignedZonesAndOperatorCia()
    // getUnassignedByZone([id]);
  }

  const handleSelectedStationsUpdate = (rows) => {
    setselectedStations(rows)
  }

  const handleSaveAssignment = () => {
    if (!isEqual(tableDataStations, selectedStations)) {
      let stationToAssign = []
      let stationToUnassign = []

      stationsAssignedToZone.map((i) => {
        !selectedStations.find((s) => s.id === i.id) &&
          stationToUnassign.push(i)
      })
      selectedStations.map((i) => {
        !stationsAssignedToZone.find((s) => s.id === i.id) &&
          stationToAssign.push(i)
      })

      const bodyUnassignStation = {
        stations: stationToUnassign.map((i) => i.id),
        zoneId: selectedData.id,
      }
      const bodyAssignStation = {
        stations: stationToAssign.map((i) => i.id),
        zoneId: selectedData.id,
      }

      stationToAssign.length > 0 && assignToZone(bodyAssignStation)
      stationToUnassign.length > 0 &&
        unassignFromZoneAndOperatorCia(bodyUnassignStation)

      handleClose()
    }
  }

  const handleConfirmBtn = () => {
    setreadOnlyConfiguration((prev) => !prev)
    !readOnlyConfiguration && handleSaveAssignment()
  }

  const handleClose = () => {
    onClose()
    setreadOnlyConfiguration(true)
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('zones.dialogs.assignment.titleDialogStations')} (
            {selectedData.name})
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setreadOnlyConfiguration(true)}
          >
            {!readOnlyConfiguration && t('operator.assignment.cancelBtn')}
          </Button>
          <Button autoFocus color="inherit" onClick={handleConfirmBtn}>
            {readOnlyConfiguration
              ? t('operator.assignment.confirmBtnEdit')
              : t('operator.assignment.confirmBtnSave')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <AssignedToZones
          addTitle={false}
          onSelectionUpdate={handleSelectedStationsUpdate}
          data={
            readOnlyConfiguration ? stationsAssignedToZone : tableDataStations
          }
          preSelectedRows={stationsAssignedToZone}
          readOnlyConfiguration={readOnlyConfiguration}
        />
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}
const actionCreators = {
  getAllUnassignedStations: stationsActions.getAllUnassignedStations,
  getAllStations: stationsActions.getAllStations,
  assignToZone: stationsActions.assignToZone,
  getUnassignedByZone: stationsActions.getUnassignedByZone,
  getStationsAssignedToZone: stationsActions.getStationsAssignedToZone,
  unassignFromZoneAndOperatorCia:
    stationsActions.unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia:
    stationsActions.getAllUnassignedZonesAndOperatorCia,
}

export default connect(mapState, actionCreators)(AssignmentStations)
