import { insurancesConstants } from '../../constants/insurances_constants'

const initialState = {
  insurances: [],
  loadingResults: false,
}

export function insurancesReducer(state = initialState, action) {
  switch (action.type) {
    case insurancesConstants.GET_ALL_INSURANCES_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case insurancesConstants.GET_ALL_INSURANCES_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        insurances: action.results,
      }
    case insurancesConstants.GET_ALL_INSURANCES_FAILURE:
      return {
        ...state,
        loadingResults: false,
      }
    default:
      return state
  }
}
