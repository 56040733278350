import React from 'react'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../../shared/customMaterialTable'

const RatesZone = ({ data, addTitle = true }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={addTitle ? t('rates.byZones.table.title') : ''}
        columns={[
          {
            title: t('rates.byZones.table.columns.name'),
            field: 'name',
          },
          {
            title: t('rates.byZones.table.columns.basePrice'),
            field: 'priceBaseByBatteryFromCustomer',
          },
          {
            title: t('rates.byZones.table.columns.includedkwh'),
            field: 'freeMonthlyKwhsDeliveryByBattery',
          },
        ]}
        data={data}
      />
    </React.Fragment>
  )
}

export default RatesZone
