import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

import Grid from '@material-ui/core/Grid'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import BatteryGrid from './batteryGrid'

import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  contentContainer: {
    padding: '2%',
  },
  cardReserves: {
    maxHeight: '200px',
    overflowY: 'scroll',
    boxShadow: ' 1px 1px 6px 1px #d0d0d0',
    padding: '1%',
  },
  cardCurrentReserves: {},
  cardFinalidedReserves: {},
  currentReserveContent: {
    fontSize: '0.9em',
    marginBottom: '15%',
  },
  batteryGrid: {
    marginTop: '5%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DUMMY_DATA_MODULES = [
  {
    hasBattery: true,
    reserved: true,
    id: 0,
    temp: '24ºC',
    charging: true,
    message: 'EN PROCESO DE ACTUALIZACIÓN',
  },
  {
    hasBattery: false,
    reserved: false,
    id: 1,
    temp: '',
    charging: false,
    message: '',
  },
  {
    hasBattery: false,
    reserved: false,
    id: 1,
    temp: '',
    charging: false,
    message: '',
  },
]
const DUMMY_CURRENT_RESERVES = [
  {
    id: 0,
    dataOut: {
      position: 3,
      batteryId: 111,
    },
    dataIn: {
      position: 2,
      batteryId: 333,
    },
  },
  {
    id: 2,
    dataOut: {
      position: 5,
      batteryId: 141,
    },
    dataIn: {
      position: 2,
      batteryId: 90,
    },
  },
]

const DUMMY_STAION_DATA = {
  zone: 'España',
  station: '01',
  location: 'link maps',
  date: '00/00/000',
  hour: '00:00:00',
  state: 'autonomo',
}

export default function StationDashboard({ isOpen, selectedData, onClose }) {
  const classes = useStyles()

  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('station.details.dashboard.dialogTitle')} (stationName)
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.contentContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <p>
                {t('station.details.dashboard.zone')}: {DUMMY_STAION_DATA.zone}
              </p>
              <p>
                {t('station.details.dashboard.station')}:
                {DUMMY_STAION_DATA.station}
              </p>
              <p>
                {t('station.details.dashboard.location')}:
                {DUMMY_STAION_DATA.location}
              </p>
              <p>
                {t('station.details.dashboard.date')}: {DUMMY_STAION_DATA.date}
              </p>
              <p>
                {t('station.details.dashboard.hour')}: {DUMMY_STAION_DATA.hour}
              </p>
              <p>
                {t('station.details.dashboard.state')}:{DUMMY_STAION_DATA.state}
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card className={classes.cardReserves}>
                    <CardContent>
                      <h4>
                        {t('station.details.dashboard.reserveInProgress')}:
                      </h4>
                      {DUMMY_CURRENT_RESERVES.map((r) => {
                        return (
                          <div className={classes.currentReserveContent}>
                            <p>
                              {t('station.details.dashboard.reserveId')}:{r.id}
                            </p>
                            <p>
                              {t('station.details.dashboard.positionOut')}:
                              {r.dataOut.position} |
                              {t('station.details.dashboard.batteryId')}:
                              {r.dataIn.batteryId}
                            </p>
                            <p>
                              {t('station.details.dashboard.positionIn')}:
                              {r.dataIn.position} |
                              {t('station.details.dashboard.batteryId')}:
                              {r.dataIn.batteryId}
                            </p>
                          </div>
                        )
                      })}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card className={classes.cardReserves}>
                    <CardContent>
                      {t('station.details.dashboard.reservationCompleted')}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.batteryGrid}>
            <BatteryGrid moduleData={DUMMY_DATA_MODULES} />
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}
