import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const BatteriesInsertedModal = ({ isOpen, onClose, messages }) => {
  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('station.dialogs.batteriesInserted.title')}</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            {messages.map((message) => (
              <p> {message} </p>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="small"
          onClick={handleClose}
        >
          {t('station.dialogs.stationDetails.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BatteriesInsertedModal
