import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import i18next from 'i18next'
import modalConstants from '../../helpers/modalConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ModalConfirm({
  isOpen,
  onClose,
  onConfirm,
  title,
  body,
  label,
  inputAvaiable,
  isInputRequired,
  disabledCondition = modalConstants.CANCEL_CONFIRM,
  cancelConfirm,
  cancelOption = false,
  closeOnConfirm = true,
  onCancel = () => {},
}) {
  const [value, setvalue] = useState()
  const [inputHasError, setInputHasError] = useState(false)

  const handleClose = () => {
    onClose()
    setInputHasError(false)
    setvalue()
  }

  const MODAL_DISABLED_ACTIONS = {
    1: cancelConfirm ? true : false,
    2: !value,
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleConfirm = () => {
    if (inputAvaiable && isInputRequired && !value) {
      setInputHasError(true)
    } else {
      setInputHasError(false)
      onConfirm(value)
      closeOnConfirm && handleClose()
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {body && <p>{body}</p>}
        {inputAvaiable && (
          <TextField
            fullWidth
            label={label}
            value={value}
            onChange={(e) => setvalue(e.target.value)}
            required={isInputRequired}
            error={inputHasError}
          />
        )}
      </DialogContent>
      <DialogActions>
        {cancelOption && (
          <Button onClick={handleCancel} color="primary">
            {i18next.t('shared.cancel')}
          </Button>
        )}
        <Button onClick={handleClose} color="primary">
          {i18next.t('shared.no')}
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={MODAL_DISABLED_ACTIONS[disabledCondition]}
          color="primary"
        >
          {i18next.t('shared.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
