import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import Dashboard from '../../dashboard'
import ReturnBatteriesView from './page'

const ReturnBatteries = (props) => {
  return <Dashboard component={<ReturnBatteriesView {...props} />} />
}

function mapState(state) {
  const { batteriesReducer } = state
  return { batteriesReducer }
}

const actionCreators = {
  getBatteryRequests: batteriesActions.getBatteryRequests,
  getDeliverableForLogged: batteriesActions.getDeliverableForLogged,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(ReturnBatteries)
