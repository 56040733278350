import { incidentsConstants } from '../../constants/incidents_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  incident: {},
  swapsManual: [],
}

export function incidentsReducer(state = initialState, action) {
  switch (action.type) {
    case incidentsConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case incidentsConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        incident: action.results,
      }
    case incidentsConstants.GET_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        incident: {},
        error: action.error,
      }

    case incidentsConstants.GET_ALL_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case incidentsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case incidentsConstants.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case incidentsConstants.GET_SWAPS_MANUAL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case incidentsConstants.GET_SWAPS_MANUAL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        swapsManual: action.results,
      }
    case incidentsConstants.GET_SWAPS_MANUAL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
        swapsManual: [],
      }
    default:
      return state
  }
}
