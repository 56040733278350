import GoogleMapReact from 'google-map-react'
import React, { useRef } from 'react'

import stringConstants from '../../../constants/strings'

import { useTranslation } from 'react-i18next'
const Map = ({
  defaultCenter = {
    lat: 41.390205,
    lng: 2.154007,
  },
  libraries = ['drawing'],
  children,
  displayOnly = false,
  ...rest
}) => {
  return (
    <GoogleMapReact
      {...rest}
      bootstrapURLKeys={{
        key: stringConstants.GOOGLE_API_KEY,
        libraries,
      }}
      options={{
        styles: [],
        mapTypeId: 'roadmap',
        mapTypeControl: !displayOnly, //true,
        streetViewControl: !displayOnly, //true,
        rotateControl: !displayOnly, //true,
        scaleControl: !displayOnly, //true,
      }}
      defaultCenter={defaultCenter}
      defaultZoom={12}
    >
      {children}
    </GoogleMapReact>
  )
}

const MapsGeofence = ({
  displayOnly = true,
  mapStateLoading,
  mapStyle = { height: '80vh', width: '100%' },
}) => {
  const { t } = useTranslation()
  const mapInstance = useRef()
  const mapApi = useRef()

  const apiHasLoaded = ({ map, maps }) => {
    mapInstance.current = map
    mapApi.current = maps

    // remove transit layers
    const transitLayer = new mapApi.current.TransitLayer()
    transitLayer.setMap(null)
  }

  return (
    <React.Fragment>
      <div style={mapStyle}>
        {!mapStateLoading && (
          <React.Fragment>
            <Map
              displayOnly={displayOnly}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={apiHasLoaded}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default MapsGeofence
