import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

import PendingCustomer from '../customers/pendingToValidate/pending'

import { useTranslation } from 'react-i18next'

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const HomeView = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <PendingCustomer />

      {/* <Accordion
                square
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>{t("home.tabs.pendingCustomersTabText")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PendingCustomer addTitle={false} />
                </AccordionDetails>
            </Accordion> */}

      {/* <Accordion
                square
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>{t("home.tabs.pendingOperatorsTabText")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PendingOperators addTitle={false} />
                </AccordionDetails>
            </Accordion> */}
    </div>
  )
}

export default HomeView
