import cat from './default.cat.json'
import en from './default.en.json'
import es from './default.json'

import alert_dialog_cat from '../../components/alert-dialog/locales/default.cat.json'
import alert_dialog_en from '../../components/alert-dialog/locales/default.en.json'
import alert_dialog_es from '../../components/alert-dialog/locales/default.json'

import customers_cat from '../../components/customers/locales/default.cat.json'
import customers_en from '../../components/customers/locales/default.en.json'
import customers_es from '../../components/customers/locales/default.json'

import dashboard_cat from '../../components/dashboard/locales/default.cat.json'
import dashboard_en from '../../components/dashboard/locales/default.en.json'
import dashboard_es from '../../components/dashboard/locales/default.json'

import constansts_cat from '../../constants/locales/default.cat.json'
import constansts_en from '../../constants/locales/default.en.json'
import constansts_es from '../../constants/locales/default.json'

import services_cat from '../../services/locales/default.cat.json'
import services_en from '../../services/locales/default.en.json'
import services_es from '../../services/locales/default.json'

import incidents_cat from '../../components/incidents/locales/default.cat.json'
import incidents_en from '../../components/incidents/locales/default.en.json'
import incidents_es from '../../components/incidents/locales/default.json'

import operator_cat from '../../components/operator/locales/default.cat.json'
import operator_en from '../../components/operator/locales/default.en.json'
import operator_es from '../../components/operator/locales/default.json'

import reports_cat from '../../components/reports/locales/default.cat.json'
import reports_en from '../../components/reports/locales/default.en.json'
import reports_es from '../../components/reports/locales/default.json'

import ui_cat from '../../components/UI/locales/default.cat.json'
import ui_en from '../../components/UI/locales/default.en.json'
import ui_es from '../../components/UI/locales/default.json'

import login_cat from '../../components/login/locales/default.cat.json'
import login_en from '../../components/login/locales/default.en.json'
import login_es from '../../components/login/locales/default.json'

import home_cat from '../../components/home/locales/default.cat.json'
import home_en from '../../components/home/locales/default.en.json'
import home_es from '../../components/home/locales/default.json'

import zones_cat from '../../components/zones/locales/default.cat.json'
import zones_en from '../../components/zones/locales/default.en.json'
import zones_es from '../../components/zones/locales/default.json'

import rates_cat from '../../components/rates/locales/default.cat.json'
import rates_en from '../../components/rates/locales/default.en.json'
import rates_es from '../../components/rates/locales/default.json'

import station_cat from '../../components/stations/locales/default.cat.json'
import station_en from '../../components/stations/locales/default.en.json'
import station_es from '../../components/stations/locales/default.json'

import batteries_cat from '../../components/batteries/locales/default.cat.json'
import batteries_en from '../../components/batteries/locales/default.en.json'
import batteries_es from '../../components/batteries/locales/default.json'

import supervisors_cat from '../../components/supervisors/locales/default.cat.json'
import supervisors_en from '../../components/supervisors/locales/default.en.json'
import supervisors_es from '../../components/supervisors/locales/default.json'

import technicians_cat from '../../components/technicians/locales/default.cat.json'
import technicians_en from '../../components/technicians/locales/default.en.json'
import technicians_es from '../../components/technicians/locales/default.json'

import discounts_cat from '../../components/discounts/locales/default.cat.json'
import discounts_en from '../../components/discounts/locales/default.en.json'
import discounts_es from '../../components/discounts/locales/default.json'

import swaps_cat from '../../components/swaps/locales/default.cat.json'
import swaps_en from '../../components/swaps/locales/default.en.json'
import swaps_es from '../../components/swaps/locales/default.json'

import map_cat from '../../components/map/locales/default.cat.json'
import map_en from '../../components/map/locales/default.en.json'
import map_es from '../../components/map/locales/default.json'

import subscriptions_cat from '../../components/subscriptions/locales/default.cat.json'
import subscriptions_en from '../../components/subscriptions/locales/default.en.json'
import subscriptions_es from '../../components/subscriptions/locales/default.json'

import shared_cat from '../../components/shared/locales/default.cat.json'
import shared_en from '../../components/shared/locales/default.en.json'
import shared_es from '../../components/shared/locales/default.json'

import payments_cat from '../../components/payments/locales/default.cat.json'
import payments_en from '../../components/payments/locales/default.en.json'
import payments_es from '../../components/payments/locales/default.json'

import billing_cat from '../../components/billing/locales/default.cat.json'
import billing_en from '../../components/billing/locales/default.en.json'
import billing_es from '../../components/billing/locales/default.json'

import operatorUser_cat from '../../components/operatorUser/locales/default.cat.json'
import operatorUser_en from '../../components/operatorUser/locales/default.en.json'
import operatorUser_es from '../../components/operatorUser/locales/default.json'

import accountUser_cat from '../../components/accountUser/locales/default.cat.json'
import accountUser_en from '../../components/accountUser/locales/default.en.json'
import accountUser_es from '../../components/accountUser/locales/default.json'

import help_cat from '../../components/help/locales/default.cat.json'
import help_en from '../../components/help/locales/default.en.json'
import help_es from '../../components/help/locales/default.json'

import product_cat from '../../components/products/locales/default.cat.json'
import product_en from '../../components/products/locales/default.en.json'
import product_es from '../../components/products/locales/default.json'

import swapManual_cat from '../../components/swap_manual/locales/default.cat.json'
import swapManual_en from '../../components/swap_manual/locales/default.en.json'
import swapManual_es from '../../components/swap_manual/locales/default.json'

import insurances_cat from '../../components/insurances/locales/default.cat.json'
import insurances_en from '../../components/insurances/locales/default.en.json'
import insurances_es from '../../components/insurances/locales/default.json'

es['es']['alert-dialog'] = alert_dialog_es['es']
en['en']['alert-dialog'] = alert_dialog_en['en']
cat['cat']['alert-dialog'] = alert_dialog_cat['cat']

es['es'].dashboard = dashboard_es['es']
en['en'].dashboard = dashboard_en['en']
cat['cat'].dashboard = dashboard_cat['cat']

es['es'].customers = customers_es['es']
en['en'].customers = customers_en['en']
cat['cat'].customers = customers_cat['cat']

es['es'].material_table = constansts_es['es']
en['en'].material_table = constansts_en['en']
cat['cat'].material_table = constansts_cat['cat']

es['es'].defaults = constansts_es['es'].defaults
en['en'].defaults = constansts_en['en'].defaults
cat['cat'].defaults = constansts_cat['cat'].defaults

es['es'].validations = constansts_es['es'].validations
en['en'].validations = constansts_en['en'].validations
cat['cat'].validations = constansts_cat['cat'].validations

es['es'].action = constansts_es['es'].action
en['en'].action = constansts_en['en'].action
cat['cat'].action = constansts_cat['cat'].action

es['es'].services = services_es['es'].services
en['en'].services = services_en['en'].services
cat['cat'].services = services_cat['cat'].services

es['es'].incidents = incidents_es['es']
en['en'].incidents = incidents_en['en']
cat['cat'].incidents = incidents_cat['cat']

es['es'].operator = operator_es['es']
en['en'].operator = operator_en['en']
cat['cat'].operator = operator_cat['cat']

es['es'].reports = reports_es['es']
en['en'].reports = reports_en['en']
cat['cat'].reports = reports_cat['cat']

es['es'].ui = ui_es['es']
en['en'].ui = ui_en['en']
cat['cat'].ui = ui_cat['cat']

es['es'].login = login_es['es']
en['en'].login = login_en['en']
cat['cat'].login = login_cat['cat']

es['es'].home = home_es['es']
en['en'].home = home_en['en']
cat['cat'].home = home_cat['cat']

es['es'].zones = zones_es['es']
en['en'].zones = zones_en['en']
cat['cat'].zones = zones_cat['cat']

es['es'].rates = rates_es['es']
en['en'].rates = rates_en['en']
cat['cat'].rates = rates_cat['cat']

es['es'].station = station_es['es']
en['en'].station = station_en['en']
cat['cat'].station = station_cat['cat']

es['es'].batteries = batteries_es['es']
en['en'].batteries = batteries_en['en']
cat['cat'].batteries = batteries_cat['cat']

es['es'].supervisors = supervisors_es['es']
en['en'].supervisors = supervisors_en['en']
cat['cat'].supervisors = supervisors_cat['cat']

es['es'].technicians = technicians_es['es']
en['en'].technicians = technicians_en['en']
cat['cat'].technicians = technicians_cat['cat']

es['es'].discounts = discounts_es['es']
en['en'].discounts = discounts_en['en']
cat['cat'].discounts = discounts_cat['cat']

es['es'].swaps = swaps_es['es']
en['en'].swaps = swaps_en['en']
cat['cat'].swaps = swaps_cat['cat']

es['es'].map = map_es['es']
en['en'].map = map_en['en']
cat['cat'].map = map_cat['cat']

es['es'].subscriptions = subscriptions_es['es']
en['en'].subscriptions = subscriptions_en['en']
cat['cat'].subscriptions = subscriptions_cat['cat']

es['es'].shared = shared_es['es']
en['en'].shared = shared_en['en']
cat['cat'].shared = shared_cat['cat']

es['es'].payments = payments_es['es']
en['en'].payments = payments_en['en']
cat['cat'].payments = payments_cat['cat']

es['es'].billing = billing_es['es']
en['en'].billing = billing_en['en']
cat['cat'].billing = billing_cat['cat']

es['es'].operatorUser = operatorUser_es['es']
en['en'].operatorUser = operatorUser_en['en']
cat['cat'].operatorUser = operatorUser_cat['cat']

es['es'].accountUser = accountUser_es['es']
en['en'].accountUser = accountUser_en['en']
cat['cat'].accountUser = accountUser_cat['cat']

es['es'].help = help_es['es']
en['en'].help = help_en['en']
cat['cat'].help = help_cat['cat']

es['es'].product = product_es['es']
en['en'].product = product_en['en']
cat['cat'].product = product_cat['cat']

es['es'].swapManual = swapManual_es['es']
en['en'].swapManual = swapManual_en['en']
cat['cat'].swapManual = swapManual_cat['cat']

es['es'].insurances = insurances_es['es']
en['en'].insurances = insurances_en['en']
cat['cat'].insurances = insurances_cat['cat']

export { cat, en, es }
