import React from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import { isTechnician } from '../../helpers/roles'
import CustomMaterialTable from '../shared/customMaterialTable'

const DUMMY_DATA = [
  {
    zone: 'zona',
    station: 'estación',
    stationPrice: 'stationPrice',
    reserveDate: 'reserveDate',
    finishSwapDate: 'fecha de swap finalizado',
    batteryIdIn: 'batteryIdIn',
    batteryIdOut: 'batteryIdOut',
    kwhIn: 'kwhIn',
    kwhOut: 'kwhOut',
    deltaKwh: 'deltaKwh',
    currency: 'currency',
    price: 'price',
    rateIncluded: 'incluído en tarifa',
    id: '1',
  },
  {
    zone: 'zona2',
    station: 'estación2',
    stationPrice: 'stationPrice2',
    reserveDate: 'reserveDate2',
    finishSwapDate: 'fecha de swap finalizado2',
    batteryIdIn: 'batteryIdIn2',
    batteryIdOut: 'batteryIdOut2',
    kwhIn: 'kwhIn2',
    kwhOut: 'kwhOut2',
    deltaKwh: 'deltaKwh2',
    currency: 'currency2',
    price: 'price2',
    rateIncluded: 'incluído en tarifa2',
    id: '2',
  },
]

const SwapsByCustomers = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <CustomMaterialTable
      title={t('swaps.byCustomers.table.title')}
      exportButton={!isTechnician()}
      columns={[
        { title: t('swaps.byCustomers.table.columns.zone'), field: 'zone' },
        {
          title: t('swaps.byCustomers.table.columns.station'),
          field: 'station',
        },
        {
          title: t('swaps.byCustomers.table.columns.stationPrice'),
          field: 'stationPrice',
        },
        {
          title: t('swaps.byCustomers.table.columns.reserveDate'),
          field: 'reserveDate',
        },
        {
          title: t('swaps.byCustomers.table.columns.finishSwapDate'),
          field: 'finishSwapDate',
        },
        {
          title: t('swaps.byCustomers.table.columns.batteryIdIn'),
          field: 'batteryIdIn',
        },
        {
          title: t('swaps.byCustomers.table.columns.batteryIdOut'),
          field: 'batteryIdOut',
        },
        { title: t('swaps.byCustomers.table.columns.kwhIn'), field: 'kwhIn' },
        { title: t('swaps.byCustomers.table.columns.kwhOut'), field: 'kwhOut' },
        {
          title: t('swaps.byCustomers.table.columns.deltaKwh'),
          field: 'deltaKwh',
        },
        {
          title: t('swaps.byCustomers.table.columns.currency'),
          field: 'currency',
        },
        { title: t('swaps.byCustomers.table.columns.price'), field: 'price' },
        {
          title: t('swaps.byCustomers.table.columns.rateIncluded'),
          field: 'rateIncluded',
        },
      ]}
      data={DUMMY_DATA}
    />
  )
}

export default SwapsByCustomers
