import 'react-image-gallery/styles/css/image-gallery.css'

import { CircularProgress, IconButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { authHeader } from '../../../../helpers/authHeader'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FullScreenIcon from '@material-ui/icons/Fullscreen'
import RotateLeft from '@material-ui/icons/RotateLeft'
import RotateRight from '@material-ui/icons/RotateRight'

import DocumentZoom from '../../dialogs/documentZoom'
import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import './styles.css'

const DocumentsCarousel = ({
  documents,
  documentsRotation,
  setDocumentsRotation,
}) => {
  const [documentsImg, setDocumentsImg] = useState([])
  const [loading, setLoading] = useState(false)
  const [startIndex, setStartIndex] = useState(0)
  const [zoomModalOpened, setIsZoomModalOpened] = useState(false)

  useEffect(() => {
    requestImages()
  }, [documents])

  const { t } = useTranslation()
  const classes = useStyles()

  const handleRotateDocument = (id, from = 'right') => {
    let rotation = documentsRotation[id] + (from === 'left' ? -90 : 90)

    if (rotation > 360) rotation = 90
    if (rotation < 0) rotation = 270

    setDocumentsRotation({
      ...documentsRotation,
      selected: id,
      [id]: rotation,
    })
  }

  const isVertical = (rotation) => [360, 180, 0].includes(rotation)

  function fetchWithAuthentication(url) {
    const headers = new Headers()
    const headersAuth = authHeader()
    headers.set('Authorization', headersAuth.Authorization)
    return fetch(url, { headers })
  }

  const loadImage = async (url) => await fetchWithAuthentication(url)

  const requestImages = async () => {
    setLoading(true)
    try {
      const results = await Promise.all(
        documents.map((doc) => loadImage(doc.url)),
      )
      setLoading(false)
      setDocumentsImg(
        results
          .filter((result) => result.ok)
          .map((result) => {
            const id = documents.find((doc) => result.url === doc.url).id
            return { url: result.url, id }
          }),
      )
    } catch {
      setLoading(false)
    }
  }

  const renderDocuments = () => {
    if (!documentsImg) return []

    const docs = documentsImg.map((document) => {
      const rotation = documentsRotation[document.id]
      return {
        original: document.url,
        thumbnail: document.url,
        thumbnailClass: classes.thumbnailContainer,
        renderItem: () => (
          <>
            <div
              style={{
                height: '50vh',
                padding: '5%',
                position: 'relative',
                transform: `rotate(${rotation}deg)`,
              }}
            >
              <img
                src={document.url}
                style={{
                  maxHeight: '100%',
                  maxWidth: '50%',
                  minHeight: isVertical(rotation) ? 'unset' : '70vh',
                  position: 'absolute',
                  inset: 0,
                  margin: 'auto',
                }}
              />
            </div>
            <div>
              <span>
                <IconButton
                  onClick={() => {
                    handleRotateDocument(document.id, 'left')
                  }}
                >
                  <RotateLeft />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setIsZoomModalOpened(true)
                    setDocumentsRotation({
                      ...documentsRotation,
                      selected: document.id,
                    })
                  }}
                >
                  <FullScreenIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleRotateDocument(document.id, 'right')
                  }}
                >
                  <RotateRight />
                </IconButton>
              </span>
            </div>
          </>
        ),
        renderThumbInner: () => (
          <div className={classes.thumbnailImgContainer}>
            <img
              src={document.url}
              style={{
                flex: 1,
                objectFit: 'cover',
                width: 'inherit',
                height: 'inherit',
                transform: `rotate(${rotation}deg)`,
              }}
            />
          </div>
        ),
      }
    })
    return docs
  }

  const renderLeftNav = (onClick, disabled) => (
    <IconButton
      className={classes.leftNav}
      disabed={disabled}
      onClick={onClick}
    >
      <ChevronLeft />
    </IconButton>
  )

  const renderRightNav = (onClick, disabled) => (
    <IconButton
      className={classes.rightNav}
      disabed={disabled}
      onClick={onClick}
    >
      <ChevronRight />
    </IconButton>
  )

  return loading ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <CircularProgress />
    </div>
  ) : documentsImg?.length > 0 ? (
    <>
      <ReactImageGallery
        items={renderDocuments()}
        showPlayButton={false}
        showFullscreenButton={false}
        startIndex={startIndex}
        onSlide={(index) => setStartIndex(index)}
        renderLeftNav={(onClick, disabled) => renderLeftNav(onClick, disabled)}
        renderRightNav={(onClick, disabled) =>
          renderRightNav(onClick, disabled)
        }
      />
      <DocumentZoom
        open={zoomModalOpened}
        onClose={() => setIsZoomModalOpened(false)}
        rotateDeg={documentsRotation}
        imageUrl={documents[startIndex].url}
      />
    </>
  ) : (
    <p style={{ textAlign: 'center' }}>
      {' '}
      {t('customers.dialogs.validateDocuments.noDocuments')}{' '}
    </p>
  )
}

export default DocumentsCarousel
