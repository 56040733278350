import React from 'react'

import { Link, useLocation } from 'react-router-dom'

import { connect } from 'react-redux'

import { checkRelationRoleAndLinks } from '../../helpers/drawerList'

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { MENU_DEFAULT_ORDER, MENU_ITEMS, MENU_ROLE_ORDER } from './constants'

const ListItems = ({ t, authenticationReducer }) => {
  const location = useLocation()

  const listItemStyle = (path) => {
    return {
      background: location.pathname === path ? '#e41622' : 'inherit',
    }
  }

  const findRole =
    MENU_ROLE_ORDER.find(
      (item) => item.role === authenticationReducer.user.maxRole,
    ) || MENU_DEFAULT_ORDER

  return authenticationReducer.pendingSelectUserRole ||
    authenticationReducer.infoPending ? (
    <div></div>
  ) : (
    <div>
      {findRole.order.map((role) => {
        const roleObj = MENU_ITEMS[role]
        if (checkRelationRoleAndLinks(authenticationReducer, roleObj.to)) {
          return (
            <Tooltip
              title={t(roleObj.primary)}
              placement="right"
              arrow
              key={Math.random()}
            >
              <ListItem
                button
                component={Link}
                key={Math.random()}
                to={!roleObj.isExternal && roleObj.to}
                style={listItemStyle(roleObj.to)}
                onClick={() =>
                  roleObj.isExternal && (window.location.href = roleObj.to)
                }
              >
                <ListItemIcon>{roleObj.icon()}</ListItemIcon>
                <ListItemText
                  style={{
                    color: 'white',
                  }}
                  primary={t(roleObj.primary)}
                />
              </ListItem>
            </Tooltip>
          )
        }
      })}
    </div>
  )
}
function mapState(state) {
  const { authenticationReducer, operatorReducer } = state
  return { authenticationReducer, operatorReducer }
}

export default connect(mapState, null)(ListItems)
