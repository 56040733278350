import i18next from 'i18next'

// TODO: Move to another class
const languageCodeSelect = (lng) => {
  switch (lng) {
    case 'cat':
      return 'ca-ES'
    case 'es':
      return 'es-ES'
    default:
      return 'en-US'
  }
}

export function authHeader() {
  // return authorization header with jwt token
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    const now = new Date(now_utc)
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    // return item.idToken;
    return item.value //item.idToken ||
  }
  const token = getWithExpiry('bs_token')

  return {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    'Content-Language': languageCodeSelect(i18next.languages[0]),
  }
}
