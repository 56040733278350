import React, { useEffect, useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

import BatteriesAssignedToZones from '../../batteries/assignedToZones'

import { connect } from 'react-redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'

import { isEqual } from '../../../helpers/compareArrays'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AssignmentBatteries = ({
  isOpen,
  onClose,
  selectedData = { name: '' },
  batteriesReducer,

  getAllBatteries,
  getAllUnassigned,
  assignToZone,
  getBatteriesAssignedToZone,
  unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const { batteriesAssignedToZone, batteriesUnassignedByZoneAndOperatorCia } =
    batteriesReducer

  const [selectedBatteries, setselectedBatteries] = useState([])
  const [tableDataBatteries, settableDataBatteries] = useState([])
  const [readOnlyConfiguration, setreadOnlyConfiguration] = useState(true)

  useEffect(() => {
    settableDataBatteries([
      ...batteriesUnassignedByZoneAndOperatorCia,
      ...batteriesAssignedToZone,
    ])
  }, [batteriesAssignedToZone, batteriesUnassignedByZoneAndOperatorCia])

  const handleEnter = () => {
    const { id } = selectedData
    id && getBatteriesAssignedToZone(id)
    getAllUnassignedZonesAndOperatorCia()
  }

  const handleSelectedBatteriesUpdate = (rows) => {
    setselectedBatteries(rows)
  }

  const handleSaveAssignment = () => {
    if (!isEqual(batteriesAssignedToZone, selectedBatteries)) {
      let batteriesToAssign = []
      let batteriesToUnassign = []

      batteriesAssignedToZone.map((i) => {
        !selectedBatteries.find((s) => s.id === i.id) &&
          batteriesToUnassign.push(i)
      })
      selectedBatteries.map((i) => {
        !batteriesAssignedToZone.find((s) => s.id === i.id) &&
          batteriesToAssign.push(i)
      })

      const bodyUnassignBatteries = {
        batteries: batteriesToUnassign.map((i) => i.id),
        zoneId: selectedData.id,
      }
      const bodyAssignBatteries = {
        batteries: batteriesToAssign.map((i) => i.id),
        zoneId: selectedData.id,
      }

      batteriesToAssign.length > 0 && assignToZone(bodyAssignBatteries)
      batteriesToUnassign.length > 0 &&
        unassignFromZoneAndOperatorCia(bodyUnassignBatteries)

      handleClose()
    }
  }

  const handleConfirmBtn = () => {
    setreadOnlyConfiguration((prev) => !prev)
    !readOnlyConfiguration && handleSaveAssignment()
  }
  const handleClose = () => {
    onClose()
    setreadOnlyConfiguration(true)
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('zones.dialogs.assignment.titleDialogBatteries')} (
            {selectedData.name})
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setreadOnlyConfiguration(true)}
          >
            {!readOnlyConfiguration && t('operator.assignment.cancelBtn')}
          </Button>
          <Button autoFocus color="inherit" onClick={handleConfirmBtn}>
            {readOnlyConfiguration
              ? t('operator.assignment.confirmBtnEdit')
              : t('operator.assignment.confirmBtnSave')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <BatteriesAssignedToZones
          addTitle={false}
          onSelectionUpdate={handleSelectedBatteriesUpdate}
          data={
            readOnlyConfiguration ? batteriesAssignedToZone : tableDataBatteries
          }
          readOnlyConfiguration={readOnlyConfiguration}
          selection={!readOnlyConfiguration}
          preSelectedRows={readOnlyConfiguration ? [] : batteriesAssignedToZone}
        />
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { batteriesReducer } = state
  return { batteriesReducer }
}
const actionCreators = {
  getAllBatteries: batteriesActions.getAllBatteries,
  getAllUnassigned: batteriesActions.getAllUnassigned,
  assignToZone: batteriesActions.assignToZone,
  getBatteriesAssignedToZone: batteriesActions.getBatteriesAssignedToZone,
  unassignFromZoneAndOperatorCia:
    batteriesActions.unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia:
    batteriesActions.getAllUnassignedZonesAndOperatorCia,
}

export default connect(mapState, actionCreators)(AssignmentBatteries)
