import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'
import Create from './dialogs/create'

import { connect } from 'react-redux'
import { accountUserActions } from '../../redux/actions/accountUser_actions'
import ModalConfirm from '../shared/modalconfirm'

const AccountUserView = ({ getAll, accountUserReducer, deleteAcUser }) => {
  const { t } = useTranslation()

  const [isDialogCreateOpen, setisDialogCreateOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState()
  const [isDialogRemove, setisDialogRemove] = useState(false)

  useEffect(() => {
    getAll()
  }, [])

  const handleAction = (rowData, action) => {
    rowData && setselectedRow(rowData)
    switch (action) {
      case 'add':
        setisDialogCreateOpen(true)
        break
      case 'remove':
        setisDialogRemove(true)
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('accountUser.page.table.title')}
        columns={[
          {
            title: t('accountUser.page.table.columns.email'),
            field: 'email',
          },
        ]}
        data={accountUserReducer.results}
        actions={[
          {
            icon: 'add',
            tooltip: t('operatorUser.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => handleAction(rowData, 'add'),
          },
          {
            icon: 'delete',
            tooltip: 'Remove',
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
          },
        ]}
      />
      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setisDialogCreateOpen(false)}
      />

      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteAcUser(selectedRow.id)}
        title={`Eliminar usuario seleccionado (${
          selectedRow && selectedRow.email
        })`}
        body={'Confirmación: ¿Quieres eliminar el usuario seleccionado?'}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { accountUserReducer } = state
  return { accountUserReducer }
}

const actionCreators = {
  getAll: accountUserActions.getAll,
  deleteAcUser: accountUserActions.deleteAcUser,
}

export default connect(mapState, actionCreators)(AccountUserView)
