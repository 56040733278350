import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import AssignmentBatteries from './dialogs/asignmentBatteries'
import AssignmentStations from './dialogs/asignmentStations'
import Batteries from './dialogs/batteries'
import Create from './dialogs/create'
import Details from './dialogs/details'
import Edit from './dialogs/edit'
import Stations from './dialogs/stations.js'
import Supervisors from './dialogs/supervisors'
import Technicians from './dialogs/technicians'

import CustomMaterialTable from '../shared/customMaterialTable'
import ModalConfirm from '../shared/modalconfirm'

import { connect } from 'react-redux'
import { isCauUser, isFinancial, isSacUser, isTechnician } from '../../helpers/roles'
import { operatorActions } from '../../redux/actions/operator_action'

const OperatorsView = ({
  operatorReducer,
  getAllOperators,
  getOperatorById,
  getForTechnician,
  cannotOperate,
  canOperate,
  deleteOp,
}) => {
  const { t } = useTranslation()

  const [isDialogStationsOpen, setisDialogStationsOpen] = useState(false)
  const [isDialogBatteriesOpen, setisDialogBatteriesOpen] = useState(false)
  const [isDialogSupervisorsOpen, setisDialogSupervisorsOpen] = useState(false)
  const [isDialogTechniciansOpen, setisDialogTechniciansOpen] = useState(false)
  const [isDialogAssignmentBatteriesOpen, setisDialogAssignmentBatteriesOpen] =
    useState(false)
  const [isDialogAssignmentStationsOpen, setisDialogAssignmentStationsOpen] =
    useState(false)
  const [isDialogCreateOpen, setisDialogCreateOpen] = useState(false)
  const [isDialogDetailsOpen, setisDialogDetailsOpen] = useState(false)
  const [isDialogRemoveOpen, setisDialogRemoveOpen] = useState(false)
  const [isDialogEditOpen, setisDialogEditOpen] = useState(false)
  const [rowSelected, setrowSelected] = useState({})
  const [isDialogRemove, setisDialogRemove] = useState(false)

  useEffect(() => {
    isTechnician() ? getForTechnician() : getAllOperators()
  }, [])

  const handleAction = (rowData, action) => {
    rowData && setrowSelected(rowData)
    switch (action) {
      case 'add':
        setisDialogCreateOpen(true)
        break
      case 'details':
        if (isFinancial()) return
        setisDialogDetailsOpen(true)
        break
      case 'assignmentStations':
        setisDialogAssignmentStationsOpen(true)
        break
      case 'assignmentBatteries':
        setisDialogAssignmentBatteriesOpen(true)
        break
      case 'batteries':
        setisDialogBatteriesOpen(true)
        break
      case 'disable':
        setisDialogRemoveOpen(true)
        break
      case 'supervisor':
        setisDialogSupervisorsOpen(true)
        break
      case 'technician':
        setisDialogTechniciansOpen(true)
        break
      case 'edit':
        setisDialogEditOpen(true)
        break
      case 'enable':
        canOperate(rowData.id)
        break
      case 'remove':
        setisDialogRemove(true)
        break
      default:
        break
    }
  }

  const handleConfirmDisableOperator = (reason) => {
    cannotOperate({
      id: rowSelected.id,
      reasons: [reason],
    })
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('operator.page.table.title')}
        isLoading={operatorReducer.loadingResults}
        saveFilters={true}
        onRowClick={(rowData) => handleAction(rowData, 'details')}
        rowClickAvaiable={true}
        exportButton={!isTechnician() && !isFinancial() && !isCauUser() && !isSacUser()}
        columns={[
          { title: t('operator.page.table.columns.name'), field: 'name' },
          { title: t('operator.page.table.columns.email'), field: 'email' },
          {
            title: t('operator.page.table.columns.contactPhone'),
            field: 'phone',
            customFilterAndSearch: (term, rowData) => {
              const trimmedTerm = String(term).replace(/\s+/g, '')
              const phone = String(rowData.phone).replace(/\s+/g, '')
              return phone.includes(trimmedTerm)
            },
          },
          { title: t('operator.page.table.columns.cif'), field: 'cif' },
          // {
          //   title: t("operator.page.table.columns.battery"),
          //   field: "battery",
          // },
          // { title: t("operator.page.table.columns.station"), field: "station" },
          // { title: t("operator.page.table.columns.zone"), field: "zone" },
          {
            title: t('operator.page.table.columns.enabled'),
            field: 'cannotOperate',
            addlookup: true,
          },
        ]}
        data={operatorReducer.results}
        actions={[
          {
            icon: 'add',
            tooltip: t('operator.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => handleAction(null, 'add'),
            hidden: isSacUser() || isTechnician() || isFinancial(),
          },
          {
            icon: 'dehaze',
            tooltip: t('operator.page.table.actions.details'),
            onClick: (event, rowData) => handleAction(rowData, 'details'),
            hidden: isFinancial(),
          },
          {
            icon: 'ev_station',
            tooltip: t('operator.page.table.actions.stations'),
            onClick: (event, rowData) =>
              handleAction(rowData, 'assignmentStations'),
            hidden: isFinancial(),
          },
          {
            icon: 'battery_charging_full',
            tooltip: t('operator.page.table.actions.batteries'),
            onClick: (event, rowData) =>
              handleAction(rowData, 'assignmentBatteries'),
            hidden: isFinancial(),
          },
          // {
          //   icon: "ev_station",
          //   tooltip: t("operator.page.table.actions.stations"),
          //   onClick: (event, rowData) => handleAction(rowData, "stations"),
          // },
          // {
          //   icon: "battery_charging_full",
          //   tooltip: t("operator.page.table.actions.batteries"),
          //   onClick: (event, rowData) => handleAction(rowData, "batteries"),
          // },
          // {
          //   icon: "supervisor_account",
          //   tooltip: t("operator.page.table.actions.supervisor"),
          //   onClick: (event, rowData) => handleAction(rowData, "supervisor"),
          // },
          // {
          //   icon: "build",
          //   tooltip: t("operator.page.table.actions.technician"),
          //   onClick: (event, rowData) => handleAction(rowData, "technician"),
          // },
          {
            icon: 'edit',
            tooltip: t('operator.page.table.actions.edit'),
            onClick: (event, rowData) => handleAction(rowData, 'edit'),
            hidden: isSacUser() || isCauUser() || isTechnician() || isFinancial(),
          },
          (rowData) => ({
            icon: 'remove_circle_outline',
            tooltip: t('operator.page.table.actions.disable'),
            onClick: (event, rowData) => handleAction(rowData, 'disable'),
            hidden:
              rowData.cannotOperate ||
              isSacUser() ||
              isCauUser() ||
              isTechnician() || 
              isFinancial(),
            iconProps: {
              style: { color: rowData.cannotOperate ? 'green' : 'red' },
            },
          }),
          (rowData) => ({
            icon: 'check_circle_outline',
            tooltip: t('operator.page.table.actions.enable'),
            onClick: (event, rowData) => handleAction(rowData, 'enable'),
            iconProps: {
              style: { color: !rowData.cannotOperate ? 'red' : 'green' },
            },
            hidden:
              !rowData.cannotOperate ||
              isSacUser() ||
              isCauUser() ||
              isTechnician() ||
              isFinancial(),
          }),
          {
            icon: 'delete',
            tooltip: 'Remove',
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
            hidden: isSacUser() || isCauUser() || isTechnician() || isFinancial(),
          },
        ]}
      />

      <Stations
        isOpen={isDialogStationsOpen}
        onClose={() => setisDialogStationsOpen(false)}
        rowSelected={rowSelected}
      />
      <Batteries
        isOpen={isDialogBatteriesOpen}
        onClose={() => setisDialogBatteriesOpen(false)}
        rowSelected={rowSelected}
      />
      <Supervisors
        isOpen={isDialogSupervisorsOpen}
        onClose={() => setisDialogSupervisorsOpen(false)}
        rowSelected={rowSelected}
      />
      <Technicians
        isOpen={isDialogTechniciansOpen}
        onClose={() => setisDialogTechniciansOpen(false)}
        rowSelected={rowSelected}
      />
      <AssignmentBatteries
        isOpen={isDialogAssignmentBatteriesOpen}
        onClose={() => setisDialogAssignmentBatteriesOpen(false)}
        rowSelected={rowSelected}
      />
      <AssignmentStations
        isOpen={isDialogAssignmentStationsOpen}
        onClose={() => setisDialogAssignmentStationsOpen(false)}
        rowSelected={rowSelected}
      />
      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setisDialogCreateOpen(false)}
      />
      <Details
        isOpen={isDialogDetailsOpen}
        onClose={() => setisDialogDetailsOpen(false)}
        selectedRow={rowSelected}
      />
      <Edit
        isOpen={isDialogEditOpen}
        onClose={() => setisDialogEditOpen(false)}
        rowSelected={rowSelected}
      />
      <ModalConfirm
        isOpen={isDialogRemoveOpen}
        onClose={() => setisDialogRemoveOpen(false)}
        onConfirm={handleConfirmDisableOperator}
        title={`${t('operator.page.confirmAction.disableOperator.title')} (${
          rowSelected.name
        })`}
        label={t('operator.page.confirmAction.disableOperator.label')}
        inputAvaiable={true}
      />
      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteOp(rowSelected.id)}
        title={`Eliminar operador seleccionado (${
          rowSelected && rowSelected.name
        })`}
        body={'Confirmación: ¿Quieres eliminar el operador seleccionado?'}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { operatorReducer } = state
  return { operatorReducer }
}
const actionCreators = {
  getAllOperators: operatorActions.getAllOperators,
  getOperatorById: operatorActions.getOperatorById,
  cannotOperate: operatorActions.cannotOperate,
  canOperate: operatorActions.canOperate,
  deleteOp: operatorActions.deleteOp,
  getForTechnician: operatorActions.getForTechnician,
}

export default connect(mapState, actionCreators)(OperatorsView)
