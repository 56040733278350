import React, { Fragment, useEffect, useState } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import TextField from '@material-ui/core/TextField'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { DateRangePicker } from 'react-date-range'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

import moment from 'moment'

import { connect } from 'react-redux'
import { operatorActions } from '../../../redux/actions/operator_action'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'

const StationGraphics = ({
  zonesReducer,
  operatorReducer,
  stationsReducer,
  getAllOperators,
  getAllZones,
  getAllStations,
}) => {
  const [dummyChartData, setdummyChartData] = useState([])
  const [chartsItems, setchartsItems] = useState([
    { item: 'numberOfSwaps', color: '#8884d8', show: true },
    { item: 'pricePerCharge', color: '#82ca9d', show: true },
    { item: 'kwhCharged', color: '#005aa9', show: true },
    { item: 'kwhDelivered', color: '#e63900', show: true },
  ])

  const [currentScopeData, setcurrentScopeData] = useState('lastDay')
  const [rangeValue, setrangeValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  useEffect(() => {
    getAllOperators()
    getAllZones()
    getAllStations()
  }, [])

  useEffect(() => {
    const dummyCurrentScope =
      Math.abs(currentScopeData) === 0 ? 24 : Math.abs(currentScopeData) || 0
    setdummyChartData(
      [...Array(dummyCurrentScope).keys()]
        .map((i) => i + 1)
        .map((e) => {
          return {
            name: `${dummyCurrentScope === 0 ? 'HOUR' : 'DAY'} - ${e}`,
            pricePerCharge: Math.random() * e,
            numberOfSwaps: Math.random() * e,
            kwhCharged: Math.random() * e,
            kwhDelivered: Math.random() * e,
          }
        }),
    )
  }, [currentScopeData])

  const handleSelect = (ranges) => {
    const { key, endDate, startDate } = ranges.selection
    setrangeValue({
      startDate,
      endDate,
      key,
    })

    setcurrentScopeData(moment(startDate).diff(moment(endDate), 'days'))
  }

  const handleClickLegendItem = (e) => {
    const { dataKey } = e
    let updatedchartsItems = chartsItems.map((i) => {
      return i.item === dataKey ? { ...i, show: !i.show } : i
    })
    setchartsItems(updatedchartsItems)
  }
  return (
    <Fragment>
      <div
        style={{
          marginBottom: '4%',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <DateRangePicker ranges={[rangeValue]} onChange={handleSelect} />

        <div>
          <Autocomplete
            id="combo-box-demo"
            options={zonesReducer.results}
            getOptionLabel={(option) => option.name}
            style={{ width: 300, marginBottom: '5%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zona"
                //  variant="outlined"
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={operatorReducer.results}
            getOptionLabel={(option) => option.name}
            style={{ width: 300, marginBottom: '5%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Operador"
                // variant="outlined"
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={stationsReducer.results}
            getOptionLabel={(option) => option.id}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estación"
                // variant="outlined"
              />
            )}
          />
        </div>
      </div>
      {/* </div> */}
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            width={730}
            height={250}
            data={dummyChartData || []}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend
              onClick={handleClickLegendItem}
              wrapperStyle={{ cursor: 'pointer' }}
            />
            {chartsItems.map((i) => {
              return (
                <Line
                  type="monotone"
                  dataKey={i.item}
                  stroke={i.show ? i.color : '#c7c7c791'}
                  strokeWidth={i.show ? 1.5 : 0.5}
                />
              )
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Fragment>
  )
}

function mapState(state) {
  const { zonesReducer, operatorReducer, stationsReducer } = state
  return { zonesReducer, operatorReducer, stationsReducer }
}
const actionCreators = {
  getAllOperators: operatorActions.getAllOperators,
  getAllZones: zonesActions.getAllZones,
  getAllStations: stationsActions.getAllStations,
}

export default connect(mapState, actionCreators)(StationGraphics)
