import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: ' 1px 1px 6px 1px #d0d0d0',
  },
  secondGrid: {
    marginTop: '1%',
  },
}))

export default function BatteryGrid({ moduleData }) {
  const classes = useStyles()
  //   const extractData =
  function FormRow({ data }) {
    return (
      <React.Fragment>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            {data[0] ? 'hay datos 1' : 'NO hay datos 1'}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            {data[1] ? 'hay datos 2' : 'NO hay datos 2'}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            {data[2] ? 'hay datos 3' : 'NO hay datos 3'}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            {data[3] ? 'hay datos 4' : 'NO hay datos 4'}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            {data[4] ? 'hay datos 5' : 'NO hay datos 5'}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            {data[5] ? 'hay datos 6' : 'NO hay datos 6'}
          </Paper>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <FormRow data={moduleData} />
        </Grid>

        <Grid container item xs={12} spacing={3} className={classes.secondGrid}>
          <FormRow data={[1, 2, 3, 4, 5, 6]} />
        </Grid>
      </Grid>
    </div>
  )
}
