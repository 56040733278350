import { handleCall } from './handleResponse'

export const discountsService = {
  getAll,
  create,
  deleteDiscount,
  getById,
  update,
  getByCustomer,
  assignDiscount,
  unassignDiscount,
}

function assignDiscount(body) {
  return handleCall(`/stations/v1/Discounts/AssignDiscountsToCustomer`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function unassignDiscount(body) {
  return handleCall(`/stations/v1/Discounts/UnassignDiscountsToCustomer`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function getByCustomer(id) {
  return handleCall(`/stations/v1/Discounts/GetByCustomer?customerId=${id}`, {
    method: 'GET',
    body: null,
  })
}

function update(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/Discounts/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deleteDiscount(id) {
  return handleCall(`/stations/v1/Discounts/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function create(body) {
  return handleCall(`/stations/v1/Discounts/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getById(id) {
  return handleCall(`/stations/v1/Discounts/${id}`, {
    method: 'GET',
    body: null,
  })
}

function getAll(body) {
  const { since, until } = body
  const params = since && until ? `?since=${since}&until=${until}` : ''

  return handleCall(`/stations/v1/Discounts/Get${params}`, {
    method: 'GET',
    body: null,
  })
}
