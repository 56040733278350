import { operatorUserConstant } from '../../constants/operatorUser_constants'
import { operatorUserService } from '../../services/operatorUserServices'

import { alertActions } from './alert_actions'

import i18next from 'i18next'
export const operatorUserActions = {
  getAll,
  canOperate,
  cannotOperate,
  deleteOpUser,
  update,
  create,
}

function create(body) {
  return (dispatch) => {
    operatorUserService.create(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.operator.createSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.operator.createError')))
      },
    )
  }
}

function update(body) {
  return (dispatch) => {
    operatorUserService.update(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.operator.updateSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.operator.updateError')))
      },
    )
  }
}

function deleteOpUser(id) {
  return (dispatch) => {
    operatorUserService.deleteOpUser(id).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.operator.deleteOpSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.operator.deleteOpError')),
        )
      },
    )
  }
}
function cannotOperate(body) {
  return (dispatch) => {
    operatorUserService.cannotOperate(body).then(
      (results) => {
        // dispatch(getOperatorsPendingValidate());
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.operator.cannotOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.operator.cannotOperateError')),
        )
      },
    )
  }
}

function canOperate(id) {
  return (dispatch) => {
    operatorUserService.canOperate(id).then(
      (results) => {
        // dispatch(getOperatorsPendingValidate());
        dispatch(getAll())
        dispatch(
          alertActions.success(
            i18next.t('services.operator.canOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.operator.canOperateError')),
        )
      },
    )
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    operatorUserService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all supervisor action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: operatorUserConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: operatorUserConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: operatorUserConstant.GET_ALL_FAILURE, error }
  }
}
