import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import i18next from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useModal } from '../../../hooks/useModal'
import { alertActions } from '../../../redux/actions/alert_actions'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { subscriptionsService } from '../../../services/subscriptionsServices'
import ConfirmationModal from '../../shared/modals/ConfirmationModal'
import useStyles from '../styles'

const Cancel = ({
  isOpen,
  onClose,
  onCancel,
  zoneId,
  selectedSubscription,
  isActive,
  stationsReducer,
  getStationsAssignedToZone,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    open: openConfirmationDialog,
    close: closeConfirmationDialog,
    isOpen: isConfirmationDialogOpen,
  } = useModal()

  const [selectedStation, setSelectedStation] = useState(null)

  const [errors, setErrors] = useState({
    selectedStation: { result: true, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const handleEnter = () => {
    if (!isActive) {
      openConfirmationDialog()
    }

    getStationsAssignedToZone(zoneId)
  }

  const handleClose = () => {
    setErrors({
      customerEmail: { result: false, message: '' },
      selectedRate: { result: false, message: '' },
      vin: { result: true, message: '' },
    })

    if (onClose) onClose()
  }

  const handleCancelSubscription = () => {
    validateForm()

    if (isFormValid()) {
      openConfirmationDialog()
    }
  }

  function handleCancelConfirm() {
    closeConfirmationDialog()
    onClose()
    subscriptionsService
      .cancel(selectedSubscription, selectedStation?.id)
      .then(() => {
        dispatch(alertActions.success(t('subscriptions.alert.cancel.success')))
        onCancel()
      })
      .catch(() => {
        dispatch(alertActions.error(t('subscriptions.alert.cancel.error')))
      })
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onEnter={handleEnter}
        aria-labelledby="responsive-dialog-title"
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('subscriptions.dialogs.cancel.title')}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleCancelSubscription}
            >
              {t('subscriptions.dialogs.create.confirmBtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            {isActive && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    options={stationsReducer.stationsAssignedToZone}
                    getOptionLabel={(option) => option.nameAndAddress}
                    value={selectedStation}
                    onChange={(event, value) => setSelectedStation(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={selectedStation}
                        helperText={''}
                        label={i18next.t(
                          'subscriptions.dialogs.cancel.label.station',
                        )}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        title={t('subscriptions.dialogs.cancel.title')}
        message={t('subscriptions.dialogs.cancel.confirm')}
        open={isConfirmationDialogOpen}
        onCancel={closeConfirmationDialog}
        onConfirm={handleCancelConfirm}
      >
        {isActive && (
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                variant="caption"
                display="block"
                style={{
                  fontWeight: 'bold',
                  color: 'rgba(0, 0, 0, 0.8)',
                }}
              >
                {t('subscriptions.dialogs.cancel.label.station')}
              </Typography>
              <Typography>{selectedStation?.name}</Typography>
            </Grid>
          </Grid>
        )}
      </ConfirmationModal>
    </>
  )
}

function mapState(state) {
  const { stationsReducer, ratesReducer } = state
  return { stationsReducer, ratesReducer }
}

const actionCreators = {
  getStationsAssignedToZone: stationsActions.getStationsAssignedToZone,
}

export default connect(mapState, actionCreators)(Cancel)
