import { alertConstants } from '../../constants'

export const alertActions = {
  success,
  error,
  clear,
  warning,
  successMultiple,
  errorMultiple,
}

function success(msg) {
  const message = {
    type: 'success',
    message: msg,
  }

  return { type: alertConstants.SUCCESS, message }
}

function successMultiple(array) {
  return { type: alertConstants.SUCCESS_MULTI, array }
}

function errorMultiple(array) {
  const messages = array.map((item) => {
    return { type: 'error', message: item }
  })

  return { type: alertConstants.ERROR_MULTI, messages }
}

function error(msg) {
  const message = {
    type: 'error',
    message: msg,
  }

  return { type: alertConstants.ERROR, message }
}

function clear() {
  return { type: alertConstants.CLEAR }
}

function warning(message) {
  return { type: alertConstants.WARNING, message }
}
