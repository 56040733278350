import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import React from 'react'

import { connect } from 'react-redux'
import { uiActions } from '../../redux/actions/ui_actions'

const Spinner = ({ setSpinner, uiReducer }) => {
  const { showSpinner } = uiReducer
  const handleClose = () => {
    setSpinner(false)
  }

  return (
    <Dialog
      open={showSpinner}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <CircularProgress color="primary" />
      </DialogContent>
    </Dialog>
  )
}

function mapState(state) {
  const { uiReducer } = state
  return { uiReducer }
}

const actionCreators = {
  setSpinner: uiActions.setSpinner,
}

export default connect(mapState, actionCreators)(Spinner)
