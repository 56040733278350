import React, { useEffect, useState } from 'react'

import useStyles from './styles'

import AssignmentManager from './dialogs/assignmentManager'
import Create from './dialogs/create'
import MoreInformation from './pagesDetails/moreInformation'
import Rows from './pagesDetails/rows'
import StationDashboard from './pagesDetails/stationDashboard'
import SwapList from './pagesDetails/swapList'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'
import SelectOption from '../shared/selectOption'

import { connect } from 'react-redux'
import { handleTrueFalsyTermSwitch } from '../../helpers/filters'
import { operatorActions } from '../../redux/actions/operator_action'
import { stationsActions } from '../../redux/actions/stations_actions'
import { swapsActions } from '../../redux/actions/swaps_actions'
import { zonesActions } from '../../redux/actions/zones_actions'
import { formatDateToDisplay } from '../shared/formatDate'

import {
  isAltechOperations,
  isCauUser,
  isFinancial,
  isManager,
  isManagerReadOnly,
  isOperator,
  isOperatorManager,
  isSacUser,
  isSupervisor,
  isTechnician,
} from '../../helpers/roles'

import { displayWithTooltip } from '../shared/enshortenLargeData'
import ModalConfirm from '../shared/modalconfirm'
import StationDetails from './dialogs/details'
import { exportStationsCsv } from '../../helpers/exportCsv'

const DUMMY_OPERATORS = [
  {
    id: 1,
    name: 'operator 1',
  },
  {
    id: 2,
    name: 'operator 2',
  },
]

const StationsView = ({
  getAllStations,
  getStationById,
  createStation,
  stationsReducer,
  deleteStation,
  getDynamicsById,
  getAllZones,
  zonesReducer,
  getAllOperators,
  operatorReducer,
  getAllSwaps,
  swapsReducer,
  getWeekDays,
  authenticationReducer,
  getForLogged,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isDialogRemove, setisDialogRemove] = useState(false)
  const [isOpenDetail, setisOpenDetail] = useState(false)
  const [localData, setlocalData] = useState([])
  const [isAssignDialogOpen, setisAssignDialogOpen] = useState(false)
  const [isCreateDialogOpen, setisCreateDialogOpen] = useState(false)
  const [isAssignmentManagerDialogOpen, setisAssignmentManagerDialogOpen] =
    useState(false)
  const [isStationDashboardOpen, setisStationDashboardOpen] = useState(false)
  const [isStationRowsOpen, setisStationRowsOpen] = useState(false)
  const [isStationSwapListOpen, setisStationSwapListOpen] = useState(false)
  const [isStationMoreInformationOpen, setisStationMoreInformationOpen] =
    useState(false)
  const [selectedData, setselectedData] = useState({})
  const [assignMnmgtSelection, setassignMnmgtSelection] = useState(false)
  const [editStationEnabled, seteditStationEnabled] = useState(false)

  const handleClickStationAction = (rowData) => {
    setselectedData(rowData)
    getDynamicsById(rowData.id)
    getStationById(rowData.id)
    setisOpenDetail(true)
  }

  const handleAssignToStation = () => {
    setisAssignDialogOpen(true)
  }

  const handleAssignmentManager = (rowData) => {
    setselectedData(rowData)
    setisAssignmentManagerDialogOpen(true)
  }

  const handleCloseAssignManagement = () => {
    setisAssignmentManagerDialogOpen(false)
    setassignMnmgtSelection(false)
    setlocalData(
      isManager() ||
        isSacUser() ||
        isCauUser() ||
        isManagerReadOnly() ||
        isTechnician()
        ? stationsReducer.results
        : stationsReducer.stationsAssignedToOperator,
    )
  }

  useEffect(() => {
    if (authenticationReducer.user && authenticationReducer.user.roles) {
      isTechnician() ? getForLogged() : getAllStations()
      getWeekDays()
    }
  }, [authenticationReducer.user?.roles])

  const handleAction = (rowData, action) => {
    rowData && setselectedData(rowData)
    switch (action) {
      case 'remove':
        setisDialogRemove(true)
        break
      case 'edit':
        seteditStationEnabled(true)
        setisCreateDialogOpen(true)
        break

      case 'add':
        seteditStationEnabled(false)
        setisCreateDialogOpen(true)
        break
      default:
        break
    }
  }

  const handleOpenStationRows = () => {
    setisStationRowsOpen(true)
  }

  const handleOpenSwaps = () => {
    setisStationSwapListOpen(true)
    getAllSwaps({ stationId: selectedData?.id })
  }

  return (
    <div className={classes.stationContainer}>
      <div>
        <CustomMaterialTable
          selection={assignMnmgtSelection}
          isLoading={stationsReducer.loadingResults}
          saveFilters={true}
          autoclickSelected={true}
          exportButton={
            !isTechnician() && !isFinancial() && !isCauUser() && !isSacUser()
          }
          data={
            isManager() ||
            isSacUser() ||
            isCauUser() ||
            isFinancial() ||
            isSupervisor() ||
            isAltechOperations() ||
            isManagerReadOnly() ||
            isTechnician()
              ? stationsReducer.results
              : stationsReducer.stationsAssignedToOperator
          }
          title={t('station.page.table.title')}
          exportCsv={exportStationsCsv}
          columns={[
            {
              title: t('station.page.table.columns.name'),
              field: 'nameAndAddress',
              render: (rowData) => displayWithTooltip(rowData.nameAndAddress),
              exportFn: (rowData) => rowData.nameAndAddress,
            },
            {
              title: t('station.page.table.columns.operator'),
              field: 'operatorCiaName',
            },
            {
              title: t('station.page.table.columns.zone'),
              field: 'zoneName',
            },
            {
              title: t('station.page.table.columns.city'),
              tooltip: t('station.page.table.columns.city'),
              field: 'city',
            },
            {
              title: t('station.page.table.columns.deliveryPoint'),
              tooltip: t('station.page.table.columns.deliveryPointTooltip'),
              field: 'isDeliveryPoint',
              render: (rowData) =>
                rowData.isDeliveryPoint
                  ? t('station.page.table.options.yes')
                  : t('station.page.table.options.no'),
              lookup: {
                0: t('station.page.table.options.yes'),
                1: t('station.page.table.options.no'),
              },
              customFilterAndSearch: (term, rowData) =>
                handleTrueFalsyTermSwitch(term, rowData.isDeliveryPoint),
            },
            {
              title: t('station.page.table.columns.isStation'),
              tooltip: t('station.page.table.columns.isStationTooltip'),
              field: 'isBatteryStation',
              render: (rowData) =>
                rowData.isBatteryStation
                  ? t('station.page.table.options.yes')
                  : t('station.page.table.options.no'),
              lookup: {
                0: t('station.page.table.options.yes'),
                1: t('station.page.table.options.no'),
              },
              customFilterAndSearch: (term, rowData) =>
                handleTrueFalsyTermSwitch(term, rowData.isBatteryStation),
            },
            {
              title: t('station.page.table.columns.manufactureDate'),
              tooltip: t('station.page.table.columns.manufactureDate'),
              field: 'created',
              render: (rowData) => formatDateToDisplay(rowData?.created, true),
            },
            {
              title: t('station.page.table.columns.rows'),
              tooltip: t('station.page.table.columns.rows'),
              field: 'rows.length',
              exportFn: (rowData) => rowData.rows.length,
              customFilterAndSearch: (term, rowData) =>
                rowData.rows.length.toString().includes(term),
            },
            {
              title: t('station.page.table.columns.lastConnection'),
              tooltip: t('station.page.table.columns.lastConnectionTooltip'),
              field: 'lastConnectionTimestamp',
              render: (rowData) =>
                formatDateToDisplay(rowData?.lastConnectionTimestamp, true),
            },
            {
              title: t('station.page.table.columns.state'),
              tooltip: t('station.page.table.columns.state'),
              field: 'outOfService',
              render: (rowData) =>
                rowData.rows?.every(
                  (r) =>
                    r.outOfService || r.slots?.every((s) => s.outOfService),
                )
                  ? t('station.page.table.values.maintenance')
                  : t('station.page.table.values.active'),
              customFilterAndSearch: (term, rowData) =>
                rowData.rows?.every(
                  (r) =>
                    r.outOfService || r.slots?.every((s) => s.outOfService),
                )
                  ? t('station.page.table.values.maintenance')
                      .toLowerCase()
                      .includes(term.toLowerCase())
                  : t('station.page.table.values.active')
                      .toLowerCase()
                      .includes(term.toLowerCase()),
            },
            {
              title: t('station.page.table.columns.batteries'),
              tooltip: t('station.page.table.columns.batteries'),
              field: 'insertedBatteries.length',
              exportFn: (rowData) => rowData.insertedBatteries.length,
              customFilterAndSearch: (term, rowData) =>
                rowData.insertedBatteries.length.toString().includes(term),
            },
          ]}
          actions={[
            {
              icon: 'dehaze',
              tooltip: t('station.page.table.actions.details'),
              onClick: (event, rowData) => handleClickStationAction(rowData),
              hidden: isFinancial(),
            },
            {
              icon: 'add',
              tooltip: t('station.page.table.actions.add'),
              isFreeAction: true,
              onClick: (event, rowData) => handleAction(rowData, 'add'),
              hidden: !isManager() && !isSupervisor(),
            },
            {
              icon: 'gavel',
              tooltip: t('station.page.table.actions.activateAssignation'),
              isFreeAction: true,
              onClick: (event, rowData) =>
                setassignMnmgtSelection((prev) => !prev),
              hidden: !isManager(),
            },
            {
              icon: 'assignment',
              tooltip: t('station.page.table.actions.openAssignation'),
              onClick: (event, rowData) => {
                handleAssignmentManager(rowData)
              },
              hidden: !assignMnmgtSelection || !isManager(),
            },
            {
              icon: 'edit',
              tooltip: t('station.page.table.actions.update'),
              onClick: (event, rowData) => handleAction(rowData, 'edit'),
              hidden:
                assignMnmgtSelection ||
                !(
                  isManager() ||
                  isOperatorManager() ||
                  isAltechOperations() ||
                  isSacUser() ||
                  isTechnician() ||
                  isOperator() ||
                  isSupervisor()
                ),
            },
            {
              icon: 'delete',
              tooltip: t('station.page.table.actions.delete'),
              onClick: (event, rowData) => handleAction(rowData, 'remove'),
              hidden: assignMnmgtSelection || !isManager(),
            },
          ]}
        />
      </div>

      {selectedData.hasOwnProperty('id') ? (
        <StationDetails
          isOpen={isOpenDetail}
          onClose={() => setisOpenDetail(false)}
          selectedStation={selectedData}
        />
      ) : (
        <p>{t('station.page.selectStationText')}</p>
      )}

      <AssignmentManager
        selectedData={selectedData}
        isOpen={isAssignmentManagerDialogOpen}
        onClose={handleCloseAssignManagement}
      />
      <StationDashboard
        isOpen={isStationDashboardOpen}
        onClose={() => setisStationDashboardOpen(false)}
        selectedData={selectedData}
      />
      <SwapList
        isOpen={isStationSwapListOpen}
        onClose={() => setisStationSwapListOpen(false)}
        selectedData={selectedData}
        data={swapsReducer.swaps}
      />
      <MoreInformation
        isOpen={isStationMoreInformationOpen}
        onClose={() => setisStationMoreInformationOpen(false)}
        selectedData={selectedData}
      />
      <Rows
        isOpen={isStationRowsOpen}
        onClose={() => setisStationRowsOpen(false)}
        selectedData={selectedData}
        dynamic={stationsReducer.dynamic}
      />
      <Create
        isOpen={isCreateDialogOpen}
        onClose={() => setisCreateDialogOpen(false)}
        isUpdating={editStationEnabled}
        stationSelected={selectedData}
        stationsReducer={stationsReducer}
      />
      <SelectOption
        isOpen={isAssignDialogOpen}
        onClose={() => setisAssignDialogOpen(false)}
        data={DUMMY_OPERATORS}
        title={'Selecciona un operador para asignarlo a la estación de carga'}
        body={''}
        objectChild={'name'}
        label={'Operadores'}
      />

      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteStation(selectedData.id)}
        title={
          t('station.page.table.modal.title') +
          (selectedData && selectedData.nameAndAddress)
        }
        body={t('station.page.table.modal.body')}
      />
    </div>
  )
}

function mapState(state) {
  const {
    stationsReducer,
    zonesReducer,
    operatorReducer,
    swapsReducer,
    authenticationReducer,
  } = state
  return {
    stationsReducer,
    zonesReducer,
    operatorReducer,
    swapsReducer,
    authenticationReducer,
  }
}

const actionCreators = {
  getAllStations: stationsActions.getAllStations,
  getDynamicsById: stationsActions.getDynamicsById,
  getStationById: stationsActions.getStationById,
  createStation: stationsActions.createStation,
  deleteStation: stationsActions.deleteStation,
  getAllZones: zonesActions.getAllZones,
  getAllOperators: operatorActions.getAllOperators,
  getAllSwaps: swapsActions.getAll,
  getWeekDays: stationsActions.getWeekDays,
  getForLogged: stationsActions.getForLogged,
}

export default connect(mapState, actionCreators)(StationsView)
