import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'

import { useTranslation } from 'react-i18next'

import DiscountsByCustomers from '../../discounts/byCustomers'
import RatesByCustomers from '../../rates/byClients'
import SwapsByCustomers from '../../swaps/byCustomers'
import CustomerData from '../customerData'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Details({ isOpen, onClose, selectedRow }) {
  const classes = useStyles()

  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('customers.dialogs.details.dialogTitle')} ({selectedRow.fullName}
            )
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {t('customers.dialogs.details.accordion.data')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomerData customerDataSelectedRow={selectedRow} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              {t('customers.dialogs.details.accordion.ratesList')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RatesByCustomers customerId={selectedRow.id} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>
              {t('customers.dialogs.details.accordion.discountsList')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DiscountsByCustomers readOnlyConfiguration={true} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>
              {t('customers.dialogs.details.accordion.swapsList')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwapsByCustomers />
          </AccordionDetails>
        </Accordion>
      </div>
    </Dialog>
  )
}
