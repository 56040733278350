import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  card: {
    boxShadow: '0px 0px 8px 0px #b5b7b1',
    width: '100%',
    height: '100%',
  },
  contentPage: {
    padding: '2%',
  },
  //   cardsContainer: {
  //     display: "flex",
  //     justifyContent: "space-evenly",
  //   },
  comboBoxContent: {
    display: 'flex',
    marginBottom: '1%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
  },
  cardData: {
    boxShadow: '0px 0 5px 1px #cfcfcf',
    padding: '1%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: '20%',
    minWidth: '20%',
    margin: 'auto',
    height: '100%',
    orderRadius: '1%',
  },
})

export default useStyles
