import { customerConstants } from '../../constants/customer_constants'

const initialState = {
  showResults: false,
  loadingResults: false,
  results: [],
  countries: [],
  customer: {},
  docTypes: [],
  customersPendingValidate: [],
  validationModalOpened: false,
}

export function customerReducer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.GET_PENDING_VALIDATE_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case customerConstants.GET_PENDING_VALIDATE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        customersPendingValidate: action.results,
      }
    case customerConstants.GET_PENDING_VALIDATE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        customersPendingValidate: {},
        error: action.error,
      }

    case customerConstants.GET_CUSTOMER_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case customerConstants.GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        customer: action.results,
      }
    case customerConstants.GET_CUSTOMER_BY_ID_FAILURE:
      return {
        ...state,
        loadingResults: false,
        customer: {},
        error: action.error,
      }

    case customerConstants.GET_ALL_CUSTOMERS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case customerConstants.GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case customerConstants.GET_ALL_CUSTOMERS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case customerConstants.GET_ALL_COUNTRIES_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        countries: action.countries,
      }
    case customerConstants.GET_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case customerConstants.GET_ALL_DOC_TYPE_REQUEST:
      return {
        ...state,
        showResults: false,
        loadingResults: true,
      }
    case customerConstants.GET_ALL_DOC_TYPE_SUCCESS:
      return {
        ...state,
        showResults: true,
        loadingResults: false,
        docTypes: action.types,
      }
    case customerConstants.GET_ALL_DOC_TYPE_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    case customerConstants.UPDATE_PAYMENT_MODE_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case customerConstants.UPDATE_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        loadingResults: false,
      }
    case customerConstants.UPDATE_PAYMENT_MODE_FAILURE:
      return {
        ...state,
        loadingResults: false,
      }
    case customerConstants.DOCUMENTS_MODAL_OPEN:
      return {
        ...state,
        validationModalOpened: true,
      }
    case customerConstants.DOCUMENTS_MODAL_CLOSE:
      return {
        ...state,
        validationModalOpened: false,
      }
    case customerConstants.CLEAR_SELECTED_CUSTOMER:
      return {
        ...state,
        customer: {},
      }
    default:
      return state
  }
}
