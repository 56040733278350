import React, { useEffect } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { technicianActions } from '../../redux/actions/technician_action'

const TechniciansByOperators = ({
  getTechniciansByOperatorId,
  techniciansReducer,
  operatorId,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getTechniciansByOperatorId(operatorId)
  }, [])
  return (
    <CustomMaterialTable
      title={t('technicians.byOperators.table.title')}
      columns={[
        {
          title: t('technicians.byOperators.table.columns.name'),
          field: 'fullName',
        },
        {
          title: t('technicians.byOperators.table.columns.email'),
          field: 'email',
        },
        {
          title: t('technicians.byOperators.table.columns.contactPhone'),
          field: 'phone',
        },
        // {
        //   title: t("technicians.byOperators.table.columns.zone"),
        //   field: "zone",
        // },
        // {
        //   title: t("technicians.byOperators.table.columns.enabled"),
        //   field: "enabled",
        // },
      ]}
      data={techniciansReducer.techniciansByOperatorId}
    />
  )
}

function mapState(state) {
  const { techniciansReducer } = state
  return { techniciansReducer }
}
const actionCreators = {
  getTechniciansByOperatorId: technicianActions.getTechniciansByOperatorId,
}

export default connect(mapState, actionCreators)(TechniciansByOperators)
