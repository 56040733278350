import React, { useEffect } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { stationsActions } from '../../redux/actions/stations_actions'

const StationsByOperators = ({
  operatorId,
  getStationsAssignedToOperator,
  stationsReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getStationsAssignedToOperator(operatorId)
  }, [])

  const handleGetTotalModulesOfStation = (rowData) => {
    const { rows } = rowData
    let totalModulesCount = 0

    rows.map((i) => (totalModulesCount += i?.slots?.length))

    return (totalModulesCount || 0).toString()
  }

  return (
    <CustomMaterialTable
      title={t('station.byOperator.table.title')}
      columns={[
        {
          title: t('station.byOperator.table.columns.stationName'),
          field: 'nameAndAddress',
        },
        {
          title: t('station.byOperator.table.columns.zone'),
          field: 'zoneName',
        },
        {
          title: t('station.byOperator.table.columns.totalModules'),
          render: (rowData) => handleGetTotalModulesOfStation(rowData),
          customFilterAndSearch: (term, rowData) =>
            term == handleGetTotalModulesOfStation(rowData),
        },
        {
          title: t('station.byOperator.table.columns.outOfService'),
          field: 'outOfService',
          addlookup: true,
        },
        {
          title: t('station.byOperator.table.columns.chargePrice'),
          field: 'priceByKwhDeliveryFromCustomer',
        },
        // {
        //   title: t("station.byOperator.table.columns.location"),
        //   field: "location",
        // },
      ]}
      data={stationsReducer.stationsAssignedToOperator}
    />
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}
const actionCreators = {
  getStationsAssignedToOperator: stationsActions.getStationsAssignedToOperator,
}

export default connect(mapState, actionCreators)(StationsByOperators)
