export default {
  Details: 'action.details',
  Edit: 'action.edit',
  Delete: 'action.delete',
  Create: 'action.create',
  Close: 'action.close',
  Cancel: 'action.cancel',
  Confirm: 'action.confirm',
  Upload: 'action.upload',
  Download: 'action.download',
}
