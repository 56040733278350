import React from 'react'

const MyDataView = () => {
  return (
    <React.Fragment>
      <p>MyDataView</p>
    </React.Fragment>
  )
}

export default MyDataView
