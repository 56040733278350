import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { stationsActions } from '../../../redux/actions/stations_actions'
import { supervisorsActions } from '../../../redux/actions/supervisor_action'
import AssignedToSupervisors from '../../stations/assignedToSupervisors'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({
  isOpen,
  onClose,
  onConfirm,
  create,
  getAllUnassignedStations,
  stationsReducer,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const [name, setname] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [repeatPassword, setrepeatPassword] = useState('')
  const [passwordsMatch, setpasswordsMatch] = useState(false)
  const [phone, setphone] = useState('')
  const [zone, setzone] = useState([])
  const [selectedStations, setselectedStations] = useState([])

  const [errors, setErrors] = React.useState({
    lastName: { result: false, message: '' },
    zone: { result: false, message: '' },
    name: { result: false, message: '' },
    email: { result: false, message: '' },
    password: { result: false, message: '' },
    repeatPassword: { result: false, message: '' },
    phone: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.zone = validations.validateArray(zone)
    newErrors.name = validations.required(name)
    newErrors.lastName = validations.required(lastName)
    newErrors.password = validations.required(password)
    newErrors.email = validations.validateEmail(email)
    newErrors.repeatPassword = validations.required(repeatPassword)
    newErrors.phone = validations.validatePhone(phone)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  useEffect(() => {
    setpasswordsMatch(password === repeatPassword)
  }, [password, repeatPassword])

  const handleClose = () => {
    onClose()
    setname('')
    setlastName('')
    setemail('')
    setpassword('')
    setphone('')
    setzone([])
    setselectedStations([])
    setErrors({
      lastName: { result: false, message: '' },
      zone: { result: false, message: '' },
      name: { result: false, message: '' },
      email: { result: false, message: '' },
      password: { result: false, message: '' },
      repeatPassword: { result: false, message: '' },
      phone: { result: false, message: '' },
    })
  }

  const handleRowsStationsUpdated = (rows) => {
    setselectedStations(rows)
  }

  const handleCreateSupervisor = () => {
    validateForm()
    if (isFormValid()) {
      const body = {
        email,
        phone: phone.includes('+34') ? phone : `+34${phone}`,
        password,
        firstName: name,
        lastName,
        stations: selectedStations.map((i) => i.id),
      }

      create(body)
      handleClose()
    }
  }

  const handleEnter = () => {
    getAllUnassignedStations()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      onEnter={handleEnter}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('supervisors.dialogs.create.dialogTitle')}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleCreateSupervisor}
            disabled={!passwordsMatch || selectedStations.length === 0}
          >
            {t('supervisors.dialogs.create.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('supervisors.dialogs.create.labels.name')}
              value={name}
              error={errors.name.message.length === 0 ? false : true}
              helperText={errors.name.message}
              onChange={(e) => {
                setname(e.target.value)
                errors.name.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('supervisors.dialogs.create.labels.lastName')}
              value={lastName}
              error={errors.lastName.message.length === 0 ? false : true}
              helperText={errors.lastName.message}
              onChange={(e) => {
                setlastName(e.target.value)
                errors.lastName.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('supervisors.dialogs.create.labels.email')}
              value={email}
              error={errors.email.message.length === 0 ? false : true}
              helperText={errors.email.message}
              type={'email'}
              onChange={(e) => {
                setemail(e.target.value)
                errors.email.message = ''
                setErrors(errors)
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('supervisors.dialogs.create.labels.phone')}
              value={phone}
              error={errors.phone.message.length === 0 ? false : true}
              helperText={errors.phone.message}
              onChange={(e) => {
                setphone(e.target.value)
                errors.phone.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('supervisors.dialogs.create.labels.password')}
              value={password}
              error={errors.password.message.length === 0 ? false : true}
              helperText={errors.password.message}
              type={'password'}
              onChange={(e) => {
                setpassword(e.target.value)
                errors.password.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={repeatPassword}
              onChange={(e) => {
                setrepeatPassword(e.target.value)
                errors.repeatPassword.message = ''
                setErrors(errors)
              }}
              label={t('customers.dialogs.create.labels.repeatPassword')}
              type={'password'}
              helperText={
                passwordsMatch
                  ? errors.repeatPassword.message
                  : t('operator.dialogs.create.labels.helperTextRepeatPassword')
              }
              error={
                !passwordsMatch || errors.repeatPassword.message.length > 0
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid item xs={12}>
            <p>{t('supervisors.dialogs.create.helperTextStationTable')}</p>
            <AssignedToSupervisors
              onselectedRowsUpdate={handleRowsStationsUpdated}
              preloañdData={stationsReducer.stationsUnassigned}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}

const actionCreators = {
  create: supervisorsActions.create,
  getAllUnassignedStations: stationsActions.getAllUnassignedStations,
}

export default connect(mapState, actionCreators)(Create)
