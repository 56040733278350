import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'
import Create from './dialogs/create'

import { connect } from 'react-redux'
import { technicianActions } from '../../redux/actions/technician_action'

import ModalConfirm from '../shared/modalconfirm'

import UpdateTechnician from './dialogs/update'

const TechnicianView = ({
  getTechniciansByOperatorId,
  techniciansReducer,
  canOperate,
  cannotOperate,
  deleteTechn,
}) => {
  const { t } = useTranslation()

  const [isDialogCreateOpen, setisDialogCreateOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState()
  const [isDialogDisableOpen, setisDialogDisableOpen] = useState(false)
  const [isDialogRemove, setisDialogRemove] = useState(false)
  const [isDialogUpdateOpen, setisDialogUpdateOpen] = useState(false)

  useEffect(() => {
    getTechniciansByOperatorId()
  }, [])

  const handleConfirmDisable = (reason) => {
    cannotOperate({
      id: selectedRow.id,
      reasons: [reason],
    })
  }
  const handleAction = (rowData, action) => {
    rowData && setselectedRow(rowData)
    switch (action) {
      case 'add':
        setisDialogCreateOpen(true)
        break

      case 'update':
        setisDialogUpdateOpen(true)
        break
      case 'disable':
        setisDialogDisableOpen(true)
        break
      case 'enable':
        canOperate(rowData.id)
        break
      case 'remove':
        setisDialogRemove(true)
        break
      default:
        break
    }
  }

  return (
    <React.Fragment>
      <CustomMaterialTable
        title={t('technicians.page.table.title')}
        isLoading={techniciansReducer.loadingResults}
        columns={[
          {
            title: t('technicians.page.table.columns.name'),
            field: 'fullName',
          },
          {
            title: t('technicians.page.table.columns.email'),
            field: 'email',
          },
          {
            title: t('technicians.page.table.columns.contactPhone'),
            field: 'phone',
          },
          {
            title: t('technicians.page.table.columns.operator'),
            field: 'operatorName',
          },
        ]}
        data={techniciansReducer.techniciansByOperatorId}
        actions={[
          {
            icon: 'edit',
            tooltip: t('supervisors.page.table.actions.edit'),
            onClick: (event, rowData) => handleAction(rowData, 'update'),
          },
          {
            icon: 'add',
            tooltip: t('technicians.page.table.actions.add'),
            isFreeAction: true,
            onClick: (event, rowData) => handleAction(rowData, 'add'),
          },
          (rowData) => ({
            icon: 'remove_circle_outline',
            tooltip: t('supervisors.page.table.actions.disable'),
            onClick: (event, rowData) => handleAction(rowData, 'disable'),
            hidden: rowData.cannotOperate,
            iconProps: {
              style: { color: rowData.cannotOperate ? 'green' : 'red' },
            },
          }),
          (rowData) => ({
            icon: 'check_circle_outline',
            tooltip: t('supervisors.page.table.actions.enable'),
            onClick: (event, rowData) => handleAction(rowData, 'enable'),
            hidden: !rowData.cannotOperate,
            iconProps: {
              style: { color: !rowData.cannotOperate ? 'red' : 'green' },
            },
          }),
          {
            icon: 'delete',
            tooltip: 'Remove',
            onClick: (event, rowData) => handleAction(rowData, 'remove'),
          },
        ]}
      />

      <Create
        isOpen={isDialogCreateOpen}
        onClose={() => setisDialogCreateOpen(false)}
      />
      <ModalConfirm
        isOpen={isDialogDisableOpen}
        onClose={() => setisDialogDisableOpen(false)}
        onConfirm={handleConfirmDisable}
        title={`${t('supervisors.page.confirmModal.title')} (${
          selectedRow && selectedRow.fullName
        })`}
        label={t('supervisors.page.confirmModal.label')}
        inputAvaiable={true}
      />
      <UpdateTechnician
        isOpen={isDialogUpdateOpen}
        onClose={() => setisDialogUpdateOpen(false)}
        rowSelected={selectedRow}
      />
      <ModalConfirm
        isOpen={isDialogRemove}
        onClose={() => setisDialogRemove(false)}
        onConfirm={() => deleteTechn(selectedRow.id)}
        title={`Eliminar técnico seleccionado (${
          selectedRow && selectedRow.fullName
        })`}
        body={'Confirmación: ¿Quieres eliminar el técnico seleccionado?'}
      />
    </React.Fragment>
  )
}

function mapState(state) {
  const { techniciansReducer } = state
  return { techniciansReducer }
}

const actionCreators = {
  getTechniciansByOperatorId: technicianActions.getTechniciansByOperatorId,
  canOperate: technicianActions.canOperate,
  cannotOperate: technicianActions.cannotOperate,
  deleteTechn: technicianActions.deleteTechn,
}

export default connect(mapState, actionCreators)(TechnicianView)
