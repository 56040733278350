import React, { useEffect, useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { operatorUserActions } from '../../../redux/actions/operatorUser_action'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({ isOpen, onClose, onConfirm, create }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const [name, setname] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [repeatPassword, setrepeatPassword] = useState('')
  const [passwordsMatch, setpasswordsMatch] = useState(false)
  const [phone, setphone] = useState('')
  const [zone, setzone] = useState()
  const [selectedStations, setselectedStations] = useState([])

  const [errors, setErrors] = useState({
    name: { result: false, message: '' },
    lastName: { result: false, message: '' },
    email: { result: false, message: '' },
    password: { result: false, message: '' },
    repeatPassword: { result: false, message: '' },
    phone: { result: false, message: '' },
  })

  useEffect(() => {
    setpasswordsMatch(password === repeatPassword)
  }, [password, repeatPassword])

  const validateForm = () => {
    let newErrors = errors
    newErrors.name = validations.required(name)
    newErrors.lastName = validations.required(lastName)
    newErrors.email = validations.validateEmail(email)
    newErrors.password = validations.required(password)
    newErrors.repeatPassword = validations.required(repeatPassword)
    newErrors.phone = validations.validatePhone(phone)

    if (!password === repeatPassword) {
      newErrors.repeatPassword = {
        result: false,
        message: 'Passwords must be equal',
      }
      newErrors.password = { result: false, message: 'ASDASD' }
    }

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const handleClose = () => {
    onClose()
    setname('')
    setlastName('')
    setemail('')
    setpassword('')
    setphone('')
    setzone('')
    setrepeatPassword('')
    setselectedStations([])

    setErrors({
      name: { result: false, message: '' },
      lastName: { result: false, message: '' },
      email: { result: false, message: '' },
      password: { result: false, message: '' },
      repeatPassword: { result: false, message: '' },
      phone: { result: false, message: '' },
    })
  }

  const handleRowsStationsUpdated = (rows) => {
    setselectedStations(rows)
  }

  const handleCreateSupervisor = () => {
    const body = {
      email,
      phone: phone.includes('+34') ? phone : `+34${phone}`,
      password,
      firstName: name,
      lastName,
      // stations: selectedStations.map((i) => i.id),
    }

    validateForm()
    if (isFormValid()) {
      create(body)
      handleClose()
    }
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('operatorUser.dialogs.create.dialogTitle')}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={handleCreateSupervisor}
            // disabled={!passwordsMatch || selectedStations.length === 0}
          >
            {t('operatorUser.dialogs.create.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operatorUser.dialogs.create.labels.name')}
              value={name}
              onChange={(e) => {
                setname(e.target.value)
                errors.name.message = ''
                setErrors(errors)
              }}
              error={errors.name.message.length === 0 ? false : true}
              helperText={errors.name.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operatorUser.dialogs.create.labels.lastName')}
              value={lastName}
              onChange={(e) => {
                setlastName(e.target.value)
                errors.lastName.message = ''
                setErrors(errors)
              }}
              error={errors.lastName.message.length === 0 ? false : true}
              helperText={errors.lastName.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operatorUser.dialogs.create.labels.email')}
              value={email}
              type={'email'}
              onChange={(e) => {
                setemail(e.target.value)
                errors.email.message = ''
                setErrors(errors)
              }}
              error={errors.email.message.length === 0 ? false : true}
              helperText={errors.email.message}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operatorUser.dialogs.create.labels.phone')}
              value={phone}
              onChange={(e) => {
                setphone(e.target.value)
                errors.phone.message = ''
                setErrors(errors)
              }}
              error={errors.phone.message.length === 0 ? false : true}
              helperText={errors.phone.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operatorUser.dialogs.create.labels.password')}
              value={password}
              type={'password'}
              onChange={(e) => {
                setpassword(e.target.value)
                errors.password.message = ''
                setErrors(errors)
              }}
              error={errors.password.message.length === 0 ? false : true}
              helperText={errors.password.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={repeatPassword}
              onChange={(e) => {
                setrepeatPassword(e.target.value)
                errors.repeatPassword.message = ''
                setErrors(errors)
              }}
              label={t('customers.dialogs.create.labels.repeatPassword')}
              type={'password'}
              error={
                !passwordsMatch || errors.repeatPassword.message.length > 0
                  ? true
                  : false
              }
              helperText={
                !passwordsMatch
                  ? t('operator.dialogs.create.labels.helperTextRepeatPassword')
                  : errors.password.message
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <p>{t("operatorUser.dialogs.create.helperTextStationTable")}</p>
            <AssignedToSupervisors
              onselectedRowsUpdate={handleRowsStationsUpdated}
            />
          </Grid> */}
        </Grid>
      </div>
    </Dialog>
  )
}

const actionCreators = {
  create: operatorUserActions.create,
}

export default connect(null, actionCreators)(Create)
