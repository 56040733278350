import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import MaskedInput from 'react-text-mask'

import { connect } from 'react-redux'
import { customerActions } from '../../../redux/actions/customers_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: '2%',
  },
  inputGrid: {
    marginBottom: '2%',
  },
  dialogContainer: {
    padding: '2%',
  },
}))

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        '+',
        /[1-9]/,
        /[1-9]/,
        ')',
        ' ',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Create = ({ isOpen, onClose, customerReducer, create }) => {
  const { results } = customerReducer
  const classes = useStyles()

  const { t } = useTranslation()

  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [repeatPassword, setrepeatPassword] = useState('')
  const [passwordsMatch, setpasswordsMatch] = useState(false)
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [birthday, setbirthday] = useState('')
  const [gender, setgender] = useState('')
  const [phone, setphone] = useState('')
  const [country, setcountry] = useState('')
  const [city, setcity] = useState('')
  const [address, setaddress] = useState('')
  const [postalCode, setpostalCode] = useState('')
  const [idDocCode, setidDocCode] = useState('')
  const [idDocType, setidDocType] = useState('')

  // const [phone, setphone] = React.useState({
  //   textmask: "(34)",
  //   // numberformat: "1320",
  // });

  const handleChange = (event) => {
    setphone({
      ...phone,
      [event.target.name]: event.target.value,
    })
  }

  const handleClose = () => {
    onClose()
    setemail('')
    setpassword('')
    setrepeatPassword('')
    setfirstName('')
    setlastName('')
    setbirthday('')
    setgender('')
    setphone('')
    setcountry('')
    setcity('')
    setaddress('')
    setpostalCode('')
    setidDocCode('')
    setidDocType('')
  }

  const handleCreate = () => {
    const body = {
      email,
      password,
      idDocType,
      idDocCode,
      firstName,
      lastName,
      birthday,
      gender,
      phone: phone.includes('+34') ? phone : `+34${phone}`,
      // phone,
      country,
      city,
      address,
      postalCode,
    }

    create(body)
    handleClose()
  }

  useEffect(() => {
    setpasswordsMatch(password === repeatPassword)
  }, [password, repeatPassword])

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('customers.dialogs.create.dialogTitle')}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleCreate}>
            {t('customers.dialogs.create.addBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContainer}>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
              label={t('customers.dialogs.create.labels.firstName')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
              label={t('customers.dialogs.create.labels.lastName')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={birthday}
              type="date"
              onChange={(e) => setbirthday(e.target.value)}
              label={t('customers.dialogs.create.labels.birthDate')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              select
              value={gender}
              onChange={(e) => setgender(e.target.value)}
              label={t('customers.dialogs.create.labels.genre')}
            >
              <MenuItem key={'male'} value={'male'}>
                {t('customers.dialogs.create.labels.male')}
              </MenuItem>
              <MenuItem key={'female'} value={'female'}>
                {t('customers.dialogs.create.labels.female')}
              </MenuItem>
              <MenuItem key={'other'} value={'other'}>
                {t('customers.dialogs.create.labels.other')}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item md={3}>
            <TextField
              fullWidth
              value={email}
              onChange={(e) => setemail(e.target.value)}
              label={t('customers.dialogs.create.labels.email')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              label={t('customers.dialogs.create.labels.password')}
              type={'password'}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={repeatPassword}
              onChange={(e) => setrepeatPassword(e.target.value)}
              label={t('customers.dialogs.create.labels.repeatPassword')}
              type={'password'}
              helperText={
                passwordsMatch
                  ? ''
                  : t('operator.dialogs.create.labels.helperTextRepeatPassword')
              }
              error={!passwordsMatch}
            />
          </Grid>
          <Grid item md={3}></Grid>

          <Grid item md={3}>
            {/* <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="formatted-text-mask-input">phone</InputLabel>
              <Input
                value={phone.textmask}
                onChange={handleChange}
                name="textmask"
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom}
              />
            </FormControl> */}
            <TextField
              fullWidth
              value={phone}
              onChange={(e) => setphone(e.target.value)}
              label={t('customers.dialogs.create.labels.phone')}
            />
          </Grid>

          <Grid item md={3}>
            <TextField
              fullWidth
              select
              value={idDocType}
              onChange={(e) => setidDocType(e.target.value)}
              label={t('customers.dialogs.create.labels.idDocType')}
            >
              <MenuItem key={'nif'} value={'nif'}>
                {t('customers.dialogs.create.labels.nif')}
              </MenuItem>
              <MenuItem key={'nie'} value={'nie'}>
                {t('customers.dialogs.create.labels.nie')}
              </MenuItem>
              <MenuItem key={'passport'} value={'passport'}>
                {t('customers.dialogs.create.labels.passport')}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={idDocCode}
              onChange={(e) => setidDocCode(e.target.value)}
              label={t('customers.dialogs.create.labels.idDocCode')}
            />
          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={country}
              onChange={(e) => setcountry(e.target.value)}
              label={t('customers.dialogs.create.labels.country')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={city}
              onChange={(e) => setcity(e.target.value)}
              label={t('customers.dialogs.create.labels.city')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={postalCode}
              onChange={(e) => setpostalCode(e.target.value)}
              label={t('customers.dialogs.create.labels.postalCode')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              fullWidth
              value={address}
              onChange={(e) => setaddress(e.target.value)}
              label={t('customers.dialogs.create.labels.address')}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}
const actionCreators = {
  create: customerActions.create,
}

export default connect(mapState, actionCreators)(Create)
