import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function SelectOption({
  isOpen,
  onClose,
  onConfirm,
  data,
  title,
  body,
  label,
  objectChild,
}) {
  const [autocompleteValue, setautocompleteValue] = useState()
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        {body}
        <Autocomplete
          options={data}
          getOptionLabel={(option) => option[objectChild]}
          // style={{ marginTop: "1%" }}
          value={autocompleteValue}
          onChange={(event, value) => setautocompleteValue(value)}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="standard" required />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button onClick={() => onConfirm(autocompleteValue)} color="primary">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
