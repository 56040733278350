import { handleCall } from './handleResponse'

export const operatorService = {
  getAllOperators,
  getOperatorById,
  createOperator,
  getOperatorsAssignedToZone,
  getOperatorsPendingValidate,
  canOperate,
  cannotOperate,
  deleteOp,
  update,
  getForTechnician,
}

function update(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/OperatorCias/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deleteOp(id) {
  return handleCall(`/stations/v1/OperatorCias/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function canOperate(id) {
  return handleCall(`/stations/v1/OperatorCias/${id}/CanOperate`, {
    method: 'PUT',
    body: null,
  })
}

function cannotOperate(body) {
  const { id } = body
  delete body.id

  return handleCall(`/stations/v1/OperatorCias/${id}/CannotOperate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function getOperatorsPendingValidate() {
  return handleCall(`/stations/v1/OperatorCias/GetPendingToValidate`, {
    method: 'GET',
    body: null,
  })
}

function getOperatorsAssignedToZone(zoneId) {
  return handleCall(`/stations/v1/operatorCias/GetByZone?zoneId=${zoneId}`, {
    method: 'GET',
    body: null,
  })
}

function getOperatorById(operatorId) {
  return handleCall(`/stations/v1/OperatorCias/${operatorId}`, {
    method: 'GET',
    body: null,
  })
}

function createOperator(body) {
  return handleCall(`/stations/v1/OperatorCias/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getAllOperators() {
  return handleCall(`/stations/v1/OperatorCias`, {
    method: 'GET',
    body: null,
  })
}

function getForTechnician() {
  return handleCall(`/stations/v1/OperatorCias/GetForTechnician`, {
    method: 'GET',
    body: null,
  })
}
