import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: '2vh',
  },
  comboBoxContainer: {
    marginBottom: '2%',
  },
}))

export default useStyles
