import {
  AppBar,
  Button,
  Dialog,
  Grid,
  Hidden,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import i18next from 'i18next'
import React from 'react'
import { validations } from '../../../helpers/validation'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class ModalAssingBattery extends React.Component {
  constructor(props) {
    super()
    this.state = {
      batteries: [],
      errors: {
        batteries: { result: false, message: '' },
      },
    }
    this.validateForm = this.validateForm.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  validateForm() {
    let errors = this.state.errors
    errors.batteries = validations.validateArray(this.state.batteries)
    if (errors.batteries.result)
      errors.batteries = validations.checkEqualsLength(
        this.props.item.batteriesNumber,
        this.state.batteries.length,
      )
    this.setState({ errors })
  }

  formatData(id, batteries) {
    return {
      id: id,
      batteryPackIds: batteries.map((x) => parseInt(x.batteryPackId)),
    }
  }

  isFormValid() {
    let valid = true
    let properties = Object.getOwnPropertyNames(this.state.errors)
    properties.forEach((element) => {
      if (!this.state.errors[element].result) valid = false
    })
    return valid
  }

  clearState() {
    this.setState({
      batteries: [],
      errors: {
        batteries: { result: false, message: '' },
      },
    })
  }

  handleClose() {
    this.clearState()
    this.props.onClose()
  }

  showAssignedBatteries() {
    const { batteriesReducer } = this.props
    return batteriesReducer.loadingResults
      ? []
      : batteriesReducer.batteriesAvailable
  }

  sendForm() {
    this.validateForm()
    if (this.isFormValid()) {
      const id = this.props.item.id
      this.props.deliverToCustomer(this.formatData(id, this.state.batteries))
      this.clearState()
      this.props.onClose()
    }
  }

  render() {
    const { batteriesReducer } = this.props
    return (
      <React.Fragment>
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                {i18next.t('batteries.pickUpRequest.form.batteries')}
              </Typography>
              <Button autoFocus color="inherit" onClick={this.sendForm}>
                {i18next.t('batteries.pickUpRequest.actions.confirm')}
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container spacing={3} style={{ marginTop: 70 }}>
            <Grid item md={3} implementation="css" smDown component={Hidden} />
            <Grid item md={5}>
              <Autocomplete
                options={this.showAssignedBatteries()}
                getOptionLabel={(option) =>
                  option.batteryPackId ? String(option.batteryPackId) : ''
                }
                style={{ width: '100%' }}
                value={this.state.batteries}
                multiple
                onChange={(event, value) => {
                  const error = this.state.errors
                  error.batteries.message = ''
                  this.setState({ batteries: value })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={this.state.errors.batteries.message}
                    label={i18next.t('batteries.pickUpRequest.form.batteries')}
                    error={
                      this.state.errors.batteries.message.length === 0
                        ? false
                        : true
                    }
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default ModalAssingBattery
