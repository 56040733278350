import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
  },
  infoPointItem: {
    '& li': {
      padding: '0px 10px 0px 10px',
    },
    '& li:first-child': {
      backgroundColor: '#e41622',
      color: 'white',
    },
  },
}))

export default useStyles
