import React from 'react'

import Dashboard from '../dashboard'
import ZonesView from './page'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { customerActions } from '../../redux/actions/customers_actions'

import { withTranslation } from 'react-i18next'

const Zones = (props) => {
  return <Dashboard component={<ZonesView {...props} />} />
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}

const actionCreators = {
  filter: customerActions.filter,
  getAll: customerActions.getAll,
  getById: customerActions.getId,
  clearTable: customerActions.clearResults,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Zones)
