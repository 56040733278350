import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DUMMY_DATA = [
  {
    reserveDate: 'reserveDate',
    finishReserveDate: 'finishReserveDate',
    stationPrice: 'stationPrice',
    batteryIdIn: 'batteryIdIn',
    batteryIdOut: 'batteryIdOut',
    kwhIn: 'kwhIn',
    kwhOut: 'kwhOut',
    deltaKwh: 'deltaKwh',
    user: 'usuario',
    ammountPerCharge: 'ammountPerCharge',
    ammountPerDischarge: 'ammountPerDischarge',
    currency: 'currency',
    id: '1',
  },
  {
    reserveDate: 'reserveDate2',
    finishReserveDate: 'finishReserveDate2',
    stationPrice: 'stationPrice2',
    batteryIdIn: 'batteryIdIn2',
    batteryIdOut: 'batteryIdOut2',
    kwhIn: 'kwhIn2',
    kwhOut: 'kwhOut2',
    deltaKwh: 'deltaKwh2',
    user: 'usuario2',
    ammountPerCharge: 'ammountPerCharge2',
    ammountPerDischarge: 'ammountPerDischarge2',
    currency: 'currency2',
    id: '2',
  },
]

export default function SwapList({ isOpen, onClose, selectedData }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('station.details.container.swapList')} (
              {selectedData?.batteryPackId})
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <MaterialTable
            title=""
            columns={[
              {
                title: t('station.details.swapList.table.columns.reserveDate'),
                field: 'reserveDate',
              },
              {
                title: t(
                  'station.details.swapList.table.columns.finishReserveDate',
                ),
                field: 'finishReserveDate',
              },
              {
                title: t('station.details.swapList.table.columns.stationPrice'),
                field: 'stationPrice',
              },
              {
                title: t('station.details.swapList.table.columns.batteryIdIn'),
                field: 'batteryIdIn',
              },
              {
                title: t('station.details.swapList.table.columns.batteryIdOut'),
                field: 'batteryIdOut',
              },
              {
                title: t('station.details.swapList.table.columns.kwhIn'),
                field: 'kwhIn',
              },
              {
                title: t('station.details.swapList.table.columns.kwhOut'),
                field: 'kwhOut',
              },
              {
                title: t('station.details.swapList.table.columns.deltaKwh'),
                field: 'deltaKwh',
              },
              {
                title: t('station.details.swapList.table.columns.user'),
                field: 'user',
              },
              {
                title: t(
                  'station.details.swapList.table.columns.ammountPerCharge',
                ),
                field: 'ammountPerCharge',
              },
              {
                title: t(
                  'station.details.swapList.table.columns.ammountPerDischarge',
                ),
                field: 'ammountPerDischarge',
              },
              {
                title: t('station.details.swapList.table.columns.currency'),
                field: 'currency',
              },
            ]}
            data={DUMMY_DATA}
            options={{
              padding: 'dense',
              filtering: true,
              pageSize: 20,
              search: true,
              actionsColumnIndex: -1,
            }}
            // actions={[
            //   {
            //     icon: "add",
            //     tooltip: "add",
            //     isFreeAction: true,
            //     onClick: (event, rowData) => alert("You saved " + rowData.name),
            //   },
            // ]}
          />
        </div>
      </Dialog>
    </div>
  )
}
