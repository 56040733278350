import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { useModal } from '../../../hooks/useModal'
import { ratesActions } from '../../../redux/actions/rates_actions'
import { subscriptionsActions } from '../../../redux/actions/subscriptions_actions'
import ConfirmationModal from '../../shared/modals/ConfirmationModal'
import useStyles from '../styles'

const Create = ({
  isOpen,
  onClose,
  getAllRates,
  createSubscription,
  ratesReducer,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    open: openConfirmationDialog,
    close: closeConfirmationDialog,
    isOpen: isConfirmationDialogOpen,
  } = useModal()

  const [customerEmail, setCustomerEmail] = useState('')
  const [selectedRate, setSelectedRate] = useState('')
  const [vin, setVin] = useState(null)

  const [errors, setErrors] = useState({
    customerEmail: { result: false, message: '' },
    selectedRate: { result: false, message: '' },
    vin: { result: true, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors

    newErrors.customerEmail = validations.required(customerEmail)
    newErrors.selectedRate = validations.required(selectedRate)

    if (selectedRate?.isLinkedToVehicle) {
      newErrors.vin = validations.required(vin)
    }

    if (newErrors.customerEmail.result) {
      newErrors.customerEmail = validations.validateEmail(customerEmail)
    }

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const handleEnter = () => {
    getAllRates()
  }

  const handleClose = () => {
    setCustomerEmail('')
    setSelectedRate('')
    setVin(null)

    setErrors({
      customerEmail: { result: false, message: '' },
      selectedRate: { result: false, message: '' },
      vin: { result: true, message: '' },
    })

    if (onClose) onClose()
  }

  const handleCreateSubscription = () => {
    validateForm()

    if (isFormValid()) {
      openConfirmationDialog()
    }
  }

  const handleConfirmCreateSubscription = () => {
    createSubscription(customerEmail, selectedRate.id, vin)
    closeConfirmationDialog()
    handleClose()
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        onEnter={handleEnter}
        aria-labelledby="responsive-dialog-title"
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('subscriptions.dialogs.create.dialogTitle')}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleCreateSubscription}
            >
              {t('subscriptions.dialogs.create.confirmBtn')}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <div className={classes.dialogContent}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  label={t('subscriptions.dialogs.create.label.customerEmail')}
                  value={customerEmail}
                  onChange={(e) => {
                    setCustomerEmail(e.target.value)
                    errors.customerEmail.message = ''
                    setErrors(errors)
                  }}
                  error={
                    errors.customerEmail.message.length === 0 ? false : true
                  }
                  helperText={errors.customerEmail.message}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  options={ratesReducer.results}
                  getOptionLabel={(option) => option?.name || '-- --'}
                  value={selectedRate}
                  onChange={(_, value) => {
                    setSelectedRate(value)
                    errors.selectedRate.message = ''
                    setErrors(errors)
                  }}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        errors.selectedRate.message.length === 0 ? false : true
                      }
                      helperText={errors.selectedRate.message}
                      label={t('subscriptions.dialogs.create.label.rate')}
                    />
                  )}
                />
              </Grid>

              {selectedRate?.isLinkedToVehicle && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t('subscriptions.dialogs.create.label.vin')}
                    value={vin}
                    onChange={(e) => {
                      setVin(e.target.value)
                      errors.vin.message = ''
                      setErrors(errors)
                    }}
                    error={errors.vin.message.length === 0 ? false : true}
                    helperText={errors.vin.message}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        title={t('subscriptions.dialogs.confirmCreate.title')}
        message={t('subscriptions.dialogs.confirmCreate.message')}
        open={isConfirmationDialogOpen}
        onCancel={closeConfirmationDialog}
        onConfirm={handleConfirmCreateSubscription}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography
              variant="caption"
              display="block"
              style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.8)' }}
            >
              {t('subscriptions.dialogs.create.label.customerEmail')}
            </Typography>
            <Typography>{customerEmail}</Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="caption"
              display="block"
              style={{
                fontWeight: 'bold',
                color: 'rgba(0, 0, 0, 0.8)',
              }}
            >
              {t('subscriptions.dialogs.create.label.rate')}
            </Typography>
            <Typography>{selectedRate?.name}</Typography>
          </Grid>

          {selectedRate?.isLinkedToVehicle && (
            <Grid item>
              <Typography
                variant="caption"
                display="block"
                style={{
                  fontWeight: 'bold',
                  color: 'rgba(0, 0, 0, 0.8)',
                }}
              >
                {t('subscriptions.dialogs.create.label.vin')}
              </Typography>
              <Typography>{vin}</Typography>
            </Grid>
          )}
        </Grid>
      </ConfirmationModal>
    </>
  )
}

function mapState(state) {
  const { ratesReducer } = state
  return { ratesReducer }
}

const actionCreators = {
  getAllRates: ratesActions.getAllRate,
  createSubscription: subscriptionsActions.create,
}

export default connect(mapState, actionCreators)(Create)
