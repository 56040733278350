import React from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../shared/customMaterialTable'

const BatteriesAssignedToOperators = ({
  data,
  onSelectionUpdate,
  preSelectedRows,
  readOnlyConfiguration,
  addTitle = true,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <CustomMaterialTable
      title={addTitle ? t('batteries.assignedToOperator.table.title') : ''}
      onSelectionChange={(rows) => onSelectionUpdate(rows)}
      preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
      selection={!readOnlyConfiguration}
      columns={[
        {
          title: t('batteries.assignedToOperator.table.columns.batteryId'),
          field: 'batteryPackId',
        },
        {
          title: t(
            'batteries.assignedToOperator.table.columns.obtainingDateSwap',
          ),
          field: 'obtainingDateSwap',
        },
        {
          title: t('batteries.assignedToOperator.table.columns.imei'),
          field: 'imei',
        },
        {
          title: t('batteries.assignedToOperator.table.columns.zone'),
          field: 'zone',
        },
        {
          title: t('batteries.assignedToOperator.table.columns.rates'),
          field: 'rate',
        },
      ]}
      data={data}
      // actions={[
      //   {
      //     icon: "add_circle_outline",
      //     tooltip: t("batteries.assignedToOperator.table.actions.assign"),
      //     onClick: (event, rowData) => alert("You saved " + rowData.name),
      //   },

      //   {
      //     icon: "remove_circle_outline",
      //     tooltip: t("batteries.assignedToOperator.table.actions.unassign"),
      //     onClick: (event, rowData) => alert("You saved " + rowData.name),
      //   },
      // ]}
    />
  )
}

export default BatteriesAssignedToOperators
