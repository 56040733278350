import store from '../redux/store'

export const rolesConstants = {
  BS_ADMIN: 'Bs.Admin',
  BS_ADMINREADONLY: 'Bs.AdminReadOnly',
  BS_OPERATOR: 'Bs.Operator',
  BS_OPERATOR_MANAGER: 'Bs.OperatorManager',
  BS_SUPERVISOR: 'Bs.Supervisor',
  BS_TECHNICIAN: 'Bs.Technician',
  BS_FINANCIAL: 'Bs.FinancialRole',
  BS_CUSTOMER: 'Bs.Customer',
  ALTECH_OPERATIONS: 'AltechOperations',
  SAC_USER: 'Bs.Sac',
  CAU_USER: 'Bs.Cau',
}

export const rolesFirebase = [
  rolesConstants.BS_ADMIN,
  rolesConstants.BS_ADMINREADONLY,
  rolesConstants.BS_OPERATOR,
  rolesConstants.BS_SUPERVISOR,
  rolesConstants.BS_OPERATOR_MANAGER,
  rolesConstants.BS_FINANCIAL,
  rolesConstants.BS_TECHNICIAN,
  rolesConstants.ALTECH_OPERATIONS,
  rolesConstants.CAU_USER,
  rolesConstants.SAC_USER,
]

export const rolesAllowed = [
  rolesConstants.BS_ADMIN,
  rolesConstants.BS_ADMINREADONLY,
  rolesConstants.BS_OPERATOR,
  rolesConstants.BS_SUPERVISOR,
  rolesConstants.BS_OPERATOR_MANAGER,
  rolesConstants.BS_FINANCIAL,
  rolesConstants.BS_TECHNICIAN,
  rolesConstants.ALTECH_OPERATIONS,
  rolesConstants.CAU_USER,
  rolesConstants.SAC_USER,
]

export const isRoleAllowedToAccess = (role) => {
  return rolesAllowed.filter((ra) => role === ra).length > 0
}

export const allowAccessRole = (rolesDetected) => {
  let rolesAllowedResult = []
  rolesDetected.map((i) => {
    rolesAllowed.map((roleConstant) => {
      roleConstant === i && rolesAllowedResult.push(i)
    })
  })
  return rolesAllowedResult
}

export const detectMaxRole = (roles = []) => {
  if (roles.find((i) => i === rolesConstants.BS_ADMIN))
    return rolesConstants.BS_ADMIN
  if (roles.find((i) => i === rolesConstants.BS_ADMINREADONLY))
    return rolesConstants.BS_ADMINREADONLY
  if (roles.find((i) => i === rolesConstants.BS_OPERATOR_MANAGER))
    return rolesConstants.BS_OPERATOR_MANAGER
  if (roles.find((i) => i === rolesConstants.BS_OPERATOR))
    return rolesConstants.BS_OPERATOR
  if (roles.find((i) => i === rolesConstants.BS_SUPERVISOR))
    return rolesConstants.BS_SUPERVISOR
  if (roles.find((i) => i === rolesConstants.BS_TECHNICIAN))
    return rolesConstants.BS_TECHNICIAN
  if (roles.find((i) => i === rolesConstants.BS_FINANCIAL))
    return rolesConstants.BS_FINANCIAL
  if (roles.find((i) => i === rolesConstants.SAC_USER))
    return rolesConstants.SAC_USER
  if (roles.find((i) => i === rolesConstants.CAU_USER))
    return rolesConstants.CAU_USER
  if (roles.find((i) => i === rolesConstants.ALTECH_OPERATIONS))
    return rolesConstants.ALTECH_OPERATIONS
  return ''
}

const isRole = (stringRole) => {
  const { authenticationReducer } = store.getState()
  const { user } = authenticationReducer

  return detectMaxRole(user?.roles) === stringRole
}

export const isOperator = () => {
  return isRole(rolesConstants.BS_OPERATOR)
}

export const isTechnician = () => {
  return isRole(rolesConstants.BS_TECHNICIAN)
}

export const isManager = () => {
  return isRole(rolesConstants.BS_ADMIN)
}

export const isManagerReadOnly = () => {
  return isRole(rolesConstants.BS_ADMINREADONLY)
}

export const isSupervisor = () => {
  return isRole(rolesConstants.BS_SUPERVISOR)
}

export const isOperatorManager = () => {
  return isRole(rolesConstants.BS_OPERATOR_MANAGER)
}

export const isFinancial = () => {
  return isRole(rolesConstants.BS_FINANCIAL)
}

export const isAltechOperations = () => {
  return isRole(rolesConstants.ALTECH_OPERATIONS)
}

export const isSacUser = () => {
  return isRole(rolesConstants.SAC_USER)
}

export const isCauUser = () => {
  return isRole(rolesConstants.CAU_USER)
}

store.subscribe(isManager)
