import { loginService } from './loginService'

import { authHeader } from '../helpers/authHeader'

import stringConstants from '../constants/strings'

import { returnErrorCode } from '../helpers/errorCode'

export const handleCall = (endpoint, options, baseUrl = true) => {
  const { method, body } = options

  const selectedRole = localStorage.getItem('SELECTED_ROLE')

  let requestOptions = {
    method,
    headers: {
      ...authHeader(),
      ...(selectedRole != null && { 'Request-Role': selectedRole }),
    },
    redirect: 'follow',
    body,
  }

  body === 'GET' && delete requestOptions.body

  const result = fetch(
    `${
      baseUrl
        ? stringConstants.URL_ApiGateway
        : stringConstants.URL_FleetApiGateway
    }${endpoint}`,
    requestOptions,
  ).then((response) => {
    if (response.status === 401) {
      return handleResponse(response, endpoint, options).then(
        (results) => {
          //
        },
        (error) => {
          return handleCall(endpoint, options)
        },
      )
    } else {
      return handleResponse(response, endpoint, options)
    }
  })

  return result
}

export const handleResponse = (response, endpoint, options) => {
  return response.text().then((text) => {
    if (response.status === 204 || response.status === 404) {
      return []
    }

    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        var refreshToken = loginService.getWithExpiry('bs_refresh_token')
        loginService.refreshToken(refreshToken).then((token) => {
          const parseToken = JSON.parse(token)
          loginService.setWithExpiry(
            'bs_token',
            parseToken.access_token,
            parseToken.expires_in,
          )
          // handleCall(endpoint, options);
        })
      }
      const error = (data && data.message) || text
      return Promise.reject(returnErrorCode(JSON.parse(error)))
    }

    // check if the token is close to expire
    var date = new Date()
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )
    var now = new Date(now_utc)
    now.setMinutes(now.getMinutes() + 20)
    var token_expire = new Date(
      loginService.getWithExpiry('bs_token_dateExpiry'),
    )
    // if in 10 minutes expire, renew the token
    if (now > token_expire) {
      refreshToken = loginService.getWithExpiry('bs_refresh_token')
      loginService.refreshToken(refreshToken).then((token) => {
        loginService.setWithExpiry(
          'bs_token',
          token.access_token,
          token.expires_in,
        )
      })
    }

    return data
  })
}
