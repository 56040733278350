import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

import { useTranslation } from 'react-i18next'

import CustomMaterialTable from '../../shared/customMaterialTable'

const DUMMY_DATA = [
  {
    id: '1',
    row: 'Fila',
    moduleId: 'Id modulo',
    position: 'position',
    led_status: 'led_status',
    temp: 'temp',
    lock_sensor: 'lock_sensor',
    bp_lock: 'bp_lock',
    errors: 'errors',
    accel: 'accel',
    chargerInfo: 'charger info',
    batteryId: 'Id batería',
    energyChargedSinceLastSwap: 'energía cargada desde último swap',
    currentEnergy: 'energía actual',
    statusFrame: 'status frame',
    statusFlags: 'status flags',
    electricity: 'corriente',
  },
  {
    id: '2',
    row: 'Fila2',
    moduleId: 'Id modulo2',
    position: 'position2',
    led_status: 'led_status2',
    temp: 'temp2',
    lock_sensor: 'lock_sensor2',
    bp_lock: 'bp_lock2',
    errors: 'errors2',
    accel: 'accel2',
    chargerInfo: 'charger info2',
    batteryId: 'Id batería2',
    energyChargedSinceLastSwap: 'energía cargada desde último swap2',
    currentEnergy: 'energía actual2',
    statusFrame: 'status frame2',
    statusFlags: 'status flags2',
    electricity: 'corriente2',
  },
]

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  contentContainer: {
    padding: '1%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Rows({ isOpen, onClose, selectedData, dynamic }) {
  const classes = useStyles()

  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('station.details.rows.dialogTitle')} ({selectedData.address})
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.contentContainer}>
          <CustomMaterialTable
            title={'filas'}
            columns={[
              {
                title: t('station.details.rows.table.columns.row'),
                field: 'row',
              },
              {
                title: t('station.details.rows.table.columns.moduleId'),
                field: 'moduleId',
              },
              {
                title: t('station.details.rows.table.columns.position'),
                field: 'position',
              },
              {
                title: t('station.details.rows.table.columns.led_status'),
                field: 'led_status',
              },
              {
                title: t('station.details.rows.table.columns.temp'),
                field: 'temp',
              },
              {
                title: t('station.details.rows.table.columns.lock_sensor'),
                field: 'lock_sensor',
              },
              {
                title: t('station.details.rows.table.columns.bp_lock'),
                field: 'bp_lock',
              },
              {
                title: t('station.details.rows.table.columns.errors'),
                field: 'errors',
              },
              {
                title: t('station.details.rows.table.columns.accel'),
                field: 'accel',
              },
              {
                title: t('station.details.rows.table.columns.chargerInfo'),
                field: 'chargerInfo',
              },
              {
                title: t('station.details.rows.table.columns.batteryId'),
                field: 'batteryId',
              },
              {
                title: t(
                  'station.details.rows.table.columns.energyChargedSinceLastSwap',
                ),
                field: 'energyChargedSinceLastSwap',
              },
              {
                title: t('station.details.rows.table.columns.currentEnergy'),
                field: 'currentEnergy',
              },
              {
                title: t('station.details.rows.table.columns.statusFrame'),
                field: 'statusFrame',
              },
              {
                title: t('station.details.rows.table.columns.statusFlags'),
                field: 'statusFlags',
              },
              {
                title: t('station.details.rows.table.columns.electricity'),
                field: 'electricity',
              },
            ]}
            data={DUMMY_DATA}
          />
        </div>
      </Dialog>
    </div>
  )
}
