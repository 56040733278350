import i18next from 'i18next'
import { insurancesConstants } from '../../constants/insurances_constants'
import { insurancesServices } from '../../services/insurancesServices'
import { alertActions } from './alert_actions'

export const insurancesActions = {
  getAll,
  getTermsAndConditions,
  autorenew,
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    insurancesServices.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: insurancesConstants.GET_ALL_INSURANCES_REQUEST }
  }

  function success(results) {
    return { type: insurancesConstants.GET_ALL_INSURANCES_SUCCESS, results }
  }

  function failure(error) {
    return { type: insurancesConstants.GET_ALL_INSURANCES_FAILURE, error }
  }
}

function getTermsAndConditions(insuranceId) {
  return () => {
    insurancesServices.getTermsAndConditions(insuranceId).then(
      (url) => {
        window.open(url)
      },
      () => {
        alertActions.error(i18next.t('services.subscription.contractError'))
      },
    )
  }
}

function autorenew(data) {
  return (dispatch) => {
    insurancesServices.autorenew(data.id).then(
      (results) => {
        dispatch(
          alertActions.success(
            data.autorenew
              ? i18next.t('insurances.page.renewModal.renewalDisabledSuccess')
              : i18next.t('insurances.page.renewModal.renewalEnabledSuccess'),
          ),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(alertActions.error(error.detail))
      },
    )
  }
}
