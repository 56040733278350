import { billingConstant } from '../../constants/billing_constants'
import { billingService } from '../../services/billingService'

import i18next from 'i18next'
import { alertActions } from './alert_actions'

export const billingActions = {
  getAll,
  refundPayment,
  getPaymentIntents,
}

function getAll(body) {
  return (dispatch) => {
    dispatch(request())
    billingService.getAllInvoices(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.documents.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: billingConstant.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: billingConstant.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: billingConstant.GET_ALL_FAILURE, error }
  }
}

function refundPayment(idPayment) {
  return (dispatch) => {
    dispatch(request())
    billingService.refundPayment(idPayment).then(
      (results) => {
        dispatch(success(results))
        dispatch(
          alertActions.success(i18next.t('services.payments.refundSuccess')),
        )
        dispatch(getAll())
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(i18next.t('services.payments.refundError')))
      },
    )
  }

  function request() {
    return { type: billingConstant.GET_REFUND_PAYMENT_REQUEST }
  }

  function success(results) {
    return { type: billingConstant.GET_REFUND_PAYMENT_SUCCESS, results }
  }

  function failure(error) {
    return { type: billingConstant.GET_REFUND_PAYMENT_FAILURE, error }
  }
}

function getPaymentIntents(id) {
  return (dispatch) => {
    dispatch(request())
    billingService.getPaymentIntents(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: billingConstant.GET_PAYMENT_INTENTS_REQUEST }
  }

  function success(results) {
    return { type: billingConstant.GET_PAYMENT_INTENTS_SUCCESS, results }
  }

  function failure(error) {
    return { type: billingConstant.GET_PAYMENT_INTENTS_FAILURE, error }
  }
}
