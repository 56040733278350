import { handleCall } from './handleResponse'

export const techniciansService = {
  getAllTechnicians,
  getTechnicianById,
  getTechniciansByOperatorId,
  create,
  canOperate,
  cannotOperate,
  deleteTechn,
  update,
}

function update(body) {
  const { id } = body
  delete body.id

  return handleCall(`/stations/v1/technicians/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deleteTechn(id) {
  return handleCall(`/stations/v1/technicians/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function canOperate(id) {
  return handleCall(`/stations/v1/technicians/${id}/CanOperate`, {
    method: 'PUT',
    body: null,
  })
}

function cannotOperate(body) {
  const { id } = body
  delete body.id

  return handleCall(`/stations/v1/technicians/${id}/CannotOperate`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}
function create(body) {
  return handleCall(`/stations/v1/Technicians/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getTechniciansByOperatorId(operatorId) {
  return handleCall(
    `/stations/v1/technicians/GetByOperatorCia?operatorCiaId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getTechnicianById(technicianId) {
  return handleCall(`/stations/v1/Technicians/${technicianId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllTechnicians() {
  return handleCall(`/stations/v1/Technicians/GetAll`, {
    method: 'GET',
    body: null,
  })
}
