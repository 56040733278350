import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'

import StationGraphics from '../stations/graphics'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2%',
  },
}))

const ReportView = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <StationGraphics />
    </div>
  )
}

export default ReportView
