import { handleCall } from './handleResponse'

export const ratesService = {
  getAllRate,
  getRateById,
  createRate,
  updateRate,
  getRatesByCustomerId,
  getByZone,
  deleteRate,
}

function deleteRate(id) {
  return handleCall(`/stations/v1/rates/Delete?id=${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function getByZone(zoneId) {
  return handleCall(`/stations/v1/rates/GetByZone?zoneId=${zoneId}`, {
    method: 'GET',
    body: null,
  })
}

function getRatesByCustomerId(customerId) {
  //pending endpoint
  return handleCall(
    `/stations/v1/subscriptions/GetByCustomer?customerId=` + customerId,
    { method: 'GET', body: null },
  )
  // return handleCall(`/stations/v1/Rates/${customerId}`, {
  //   method: "GET",
  //   body: null,
  // });
}

function updateRate(body) {
  const { id } = body
  delete body.id
  return handleCall(`/stations/v1/Rates/${id}/Update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function createRate(body) {
  return handleCall(`/stations/v1/Rates/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getRateById(rateId) {
  return handleCall(`/stations/v1/Rates/${rateId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllRate() {
  return handleCall(`/stations/v1/Rates/GetAll`, {
    method: 'GET',
    body: null,
  })
}
