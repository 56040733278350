import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { operatorActions } from '../../../redux/actions/operator_action'
import { zonesActions } from '../../../redux/actions/zones_actions'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: '2%',
  },
  tabs: {
    marginTop: '4%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Edit = ({
  isOpen,
  onClose,
  onConfirm,
  rowSelected,
  update,
  zonesReducer,
  getAllZones,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const [name, setname] = useState('')
  const [nif, setnif] = useState('')
  const [contactPhone, setcontactPhone] = useState('')
  const [primaryColor, setprimaryColor] = useState('')
  const [zone, setzone] = useState([])

  const [errors, setErrors] = React.useState({
    zone: { result: false, message: '' },
    name: { result: false, message: '' },
    contactPhone: { result: false, message: '' },
    nif: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.zone = validations.validateArray(zone)
    newErrors.name = validations.required(name)
    newErrors.contactPhone = validations.validatePhone(contactPhone)
    newErrors.nif = validations.validateCIF_Nif(nif)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  useEffect(() => {
    const { zones: zonesprop } = rowSelected
    zonesprop &&
      setzone(
        zonesReducer.results.filter((i) => {
          return zonesprop.find((n) => n === i.id)
        }),
      )
  }, [zonesReducer.results])

  const handleClose = () => {
    onClose()
    setname('')
    setnif('')
    setcontactPhone('')
    setzone([])
    setErrors({
      zone: { result: false, message: '' },
      name: { result: false, message: '' },
      contactPhone: { result: false, message: '' },
      nif: { result: false, message: '' },
    })
  }

  const handleEnter = () => {
    getAllZones()
    const {
      cif: cifprop,
      name: nameprop,
      phone: phoneprop,
      zones: zonesprop,
    } = rowSelected
    setname(nameprop)
    setnif(cifprop)
    setcontactPhone(phoneprop)
  }

  const handleUpdate = () => {
    validateForm()
    if (isFormValid()) {
      const body = {
        id: rowSelected.id,
        name: name,
        phone: contactPhone,
        cif: nif,
        zones: zone.map((i) => i.id),
      }
      update(body)
      handleClose(handleClose)
    }
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      onEnter={handleEnter}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('operator.dialogs.edit.dialogTitle')} ({rowSelected.name})
          </Typography>
          <Button autoFocus color="inherit" onClick={handleUpdate}>
            {t('operator.dialogs.edit.confirmBtn')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.edit.label.name')}
              value={name}
              error={errors.name.message.length === 0 ? false : true}
              helperText={errors.name.message}
              onChange={(e) => {
                setname(e.target.value)
                errors.name.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.edit.label.nif')}
              value={nif}
              error={errors.nif.message.length === 0 ? false : true}
              helperText={errors.nif.message}
              onChange={(e) => {
                setnif(e.target.value)
                errors.nif.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              // variant="outlined"
              required
              fullWidth
              label={t('operator.dialogs.edit.label.contactPhone')}
              value={contactPhone}
              error={errors.contactPhone.message.length === 0 ? false : true}
              helperText={errors.contactPhone.message}
              onChange={(e) => {
                setcontactPhone(e.target.value)
                errors.contactPhone.message = ''
                setErrors(errors)
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Autocomplete
              options={zonesReducer.results}
              getOptionLabel={(option) => option.name}
              multiple
              // style={{ width: 300 }}
              value={zone}
              onChange={(event, value) => {
                setzone(value)
                errors.zone.message = ''
                setErrors(errors)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.zone.message.length === 0 ? false : true}
                  helperText={errors.zone.message}
                  label={t('operator.dialogs.create.labels.zone')}
                  // variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { operatorReducer, zonesReducer } = state
  return { operatorReducer, zonesReducer }
}
const actionCreators = {
  update: operatorActions.update,
  getAllZones: zonesActions.getAllZones,
}

export default connect(mapState, actionCreators)(Edit)
