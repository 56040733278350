import { billingConstant } from '../../constants/billing_constants'

const initialState = {
  loadingResults: false,
  results: [],
  paymentIntents: [],
}

export function billingReducer(state = initialState, action) {
  switch (action.type) {
    case billingConstant.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
        results: [],
      }
    case billingConstant.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        showResults: true,
        results: action.results,
      }
    case billingConstant.GET_ALL_FAILURE:
      return {
        ...state,
        loadingResults: false,
        results: [],
        error: action.error,
      }
    case billingConstant.GET_PAYMENT_INTENTS_REQUEST:
      return {
        ...state,
        loadingResults: true,
        showResults: false,
      }
    case billingConstant.GET_PAYMENT_INTENTS_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        paymentIntents: action.results,
      }
    case billingConstant.GET_PAYMENT_INTENTS_FAILURE:
      return {
        ...state,
        loadingResults: false,
        error: action.error,
      }
    default:
      return state
  }
}
