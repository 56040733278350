export const billingConstant = {
  GET_ALL_REQUEST: 'BILLING_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'BILLING_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'BILLING_GET_ALL_FAILURE',

  GET_REFUND_PAYMENT_REQUEST: 'BILLING_GET_REFUND_PAYMENT_REQUEST',
  GET_REFUND_PAYMENT_SUCCESS: 'BILLING_GET_REFUND_PAYMENT_SUCCESS',
  GET_REFUND_PAYMENT_FAILURE: 'BILLING_GET_REFUND_PAYMENT_FAILURE',

  GET_PAYMENT_INTENTS_REQUEST: 'GET_PAYMENT_INTENTS_REQUEST',
  GET_PAYMENT_INTENTS_SUCCESS: 'GET_PAYMENT_INTENTS_SUCCESS',
  GET_PAYMENT_INTENTS_FAILURE: 'GET_PAYMENT_INTENTS_FAILURE',
}
