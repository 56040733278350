import { zonesConstants } from '../../constants/zones_constants'
import { zonesService } from '../../services/zonesServices'
import { alertActions } from './alert_actions'

import { getOperatorId } from '../../helpers/operator'

import i18next from 'i18next'

export const zonesActions = {
  getAllZones,
  getZoneById,
  createZone,
  updateZone,
  getZonesByOperatorId,
  deleteZone,
}
//
function deleteZone(id) {
  return (dispatch) => {
    zonesService.deleteZone(id).then(
      (results) => {
        dispatch(getAllZones())
        dispatch(
          alertActions.success(i18next.t('services.zone.deleteZoneSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.zone.deleteZoneError')))
      },
    )
  }
}

function getZonesByOperatorId(operatorId) {
  const id = getOperatorId() || operatorId
  return (dispatch) => {
    if (id) {
      dispatch(request())
      zonesService.getZonesByOperatorId(id).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    }
  }

  function request() {
    return { type: zonesConstants.GET_BY_OPERATOR_ID_REQUEST }
  }

  function success(results) {
    return { type: zonesConstants.GET_BY_OPERATOR_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: zonesConstants.GET_BY_OPERATOR_ID_FAILURE, error }
  }
}

function updateZone(body) {
  return (dispatch) => {
    // dispatch(request());
    zonesService.updateZone(body).then(
      (results) => {
        // success(results);
        dispatch(
          alertActions.success(i18next.t('services.zone.updateZoneSuccess')),
        )
        dispatch(getAllZones())
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.zone.updateZoneError') + error,
          ),
        )
        // dispatch(failure(error));
      },
    )
  }
}

function createZone(body) {
  return (dispatch) => {
    // dispatch(request());
    zonesService.createZone(body).then(
      (results) => {
        // success(results);
        dispatch(getAllZones())

        dispatch(
          alertActions.success(i18next.t('services.zone.createZoneSuccess')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.zone.createZoneError') + error,
          ),
        )
        // dispatch(failure(error));
      },
    )
  }
}

function getZoneById(zoneId) {
  return (dispatch) => {
    dispatch(request())
    zonesService.getZoneById(zoneId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: zonesConstants.GET_BY_ZONE_ID_REQUEST }
  }

  function success(results) {
    return { type: zonesConstants.GET_BY_ZONE_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: zonesConstants.GET_BY_ZONE_ID_FAILURE, error }
  }
}

function getAllZones() {
  return (dispatch) => {
    dispatch(request())
    zonesService.getAllZones().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: zonesConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: zonesConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: zonesConstants.GET_ALL_FAILURE, error }
  }
}
