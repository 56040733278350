import { handleCall } from './handleResponse'

export const billingService = {
  getAllInvoices,
  refundPayment,
  getPaymentIntents,
}

function getAllInvoices(body) {
  const { since, until } = body || { since: null, until: null }

  const filter = since && until ? `?since=${since}&until=${until}` : ''
  return handleCall(`/stations/v1/invoices/GetForLogged${filter}`, {
    method: 'GET',
    body: null,
  })
}

function refundPayment(id) {
  return handleCall(`/stations/v1/invoices/${id}/refund`, {
    method: 'POST',
  })
}

function getPaymentIntents(id) {
  return handleCall(`/stations/v1/invoices/${id}/PaymentIntents`, {
    method: 'GET',
    body: null,
  })
}
