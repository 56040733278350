import i18next from 'i18next'
import { customerConstants } from '../../constants/customer_constants'
import { customerService } from '../../services/customersServices'
import { alertActions } from './alert_actions'

export const customerActions = {
  getAllCustomers,
  getCustomerById,
  create,
  getPendingValidate,
  canOperate,
  cannotOperate,
  update,
  getAllCountries,
  getAllDocTypes,
  updatePaymentMode,
  closeValidationsModal,
  openValidationsModal,
  clearSelectedCustomer,
}

function cannotOperate(body, from) {
  return (dispatch) => {
    customerService.cannotOperate(body).then(
      (results) => {
        from === 'pendingValidate'
          ? dispatch(getPendingValidate())
          : dispatch(getAllCustomers())
        dispatch(
          alertActions.success(
            i18next.t('services.customers.cannotOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.customers.cannotOperateError'),
          ),
        )
      },
    )
  }
}

function canOperate(id, from) {
  return (dispatch) => {
    customerService.canOperate(id).then(
      (results) => {
        from === 'pendingValidate'
          ? dispatch(getPendingValidate())
          : dispatch(getAllCustomers())

        dispatch(
          alertActions.success(
            i18next.t('services.customers.canOperateSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.customers.canOperateError')),
        )
      },
    )
  }
}

function getPendingValidate() {
  return (dispatch) => {
    dispatch(request())
    customerService.getPendingValidate().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error(i18next.t("services.customers.getAllError"))
        // );
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_PENDING_VALIDATE_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GET_PENDING_VALIDATE_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GET_PENDING_VALIDATE_FAILURE, error }
  }
}

function create(body) {
  return (dispatch) => {
    customerService.create(body).then(
      (results) => {
        dispatch(
          alertActions.success(i18next.t('services.customers.createSuccess')),
        )
        dispatch(getAllCustomers())
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.customers.createError')} ` + error,
          ),
        )
      },
    )
  }
}

function getCustomerById(customerId) {
  return (dispatch) => {
    dispatch(request())
    customerService.getCustomerById(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.customers.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_CUSTOMER_BY_ID_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GET_CUSTOMER_BY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GET_CUSTOMER_BY_ID_FAILURE, error }
  }
}

function getAllCustomers() {
  return (dispatch) => {
    dispatch(request())
    customerService.getAllCustomers().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(
          alertActions.error(i18next.t('services.customers.getAllError')),
        )
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_ALL_CUSTOMERS_REQUEST }
  }

  function success(results) {
    return { type: customerConstants.GET_ALL_CUSTOMERS_SUCCESS, results }
  }

  function failure(error) {
    return { type: customerConstants.GET_ALL_CUSTOMERS_FAILURE, error }
  }
}

function update(email, body, from) {
  return (dispatch) => {
    customerService.update(email, body).then(
      (results) => {
        dispatch(
          from === 'Customers' ? getAllCustomers() : getPendingValidate(),
        )
        dispatch(
          alertActions.success(
            i18next.t('services.customers.updateForAdminSuccess'),
          ),
        )
      },
      (error) => {
        let output = `${i18next.t('services.customers.updateForAdminError')}: `

        if (error.errors) {
          for (const [key, value] of Object.entries(error?.errors)) {
            output += `${value} `
          }
        } else {
          output += ` ${error}`
        }

        dispatch(alertActions.error(output))
      },
    )
  }
}

function getAllCountries() {
  return (dispatch) => {
    dispatch(request())
    customerService.getAllCountries().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_ALL_COUNTRIES_REQUEST }
  }

  function success(countries) {
    return { type: customerConstants.GET_ALL_COUNTRIES_SUCCESS, countries }
  }

  function failure(error) {
    return { type: customerConstants.GET_ALL_COUNTRIES_FAILURE, error }
  }
}

function getAllDocTypes() {
  return (dispatch) => {
    dispatch(request())
    customerService.getAllDocTypes().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: customerConstants.GET_ALL_DOC_TYPE_REQUEST }
  }

  function success(types) {
    return { type: customerConstants.GET_ALL_DOC_TYPE_SUCCESS, types }
  }

  function failure(error) {
    return { type: customerConstants.GET_ALL_DOC_TYPE_FAILURE, error }
  }
}

function updatePaymentMode(customerId) {
  return (dispatch) => {
    dispatch(request())
    customerService.updatePaymentMode(customerId).then(
      () => {
        dispatch(getAllCustomers())
        dispatch(success())
        dispatch(
          alertActions.success(
            i18next.t('services.customers.updatePaymentModeSuccess'),
          ),
        )
      },
      () => {
        dispatch(failure())
        dispatch(
          alertActions.error(
            i18next.t('services.customers.updatePaymentModeError'),
          ),
        )
      },
    )
  }

  function request() {
    return { type: customerConstants.UPDATE_PAYMENT_MODE_REQUEST }
  }

  function success() {
    return { type: customerConstants.UPDATE_PAYMENT_MODE_SUCCESS }
  }

  function failure() {
    return { type: customerConstants.UPDATE_PAYMENT_MODE_FAILURE }
  }
}

function openValidationsModal() {
  return (dispatch) =>
    dispatch({ type: customerConstants.DOCUMENTS_MODAL_OPEN })
}

function closeValidationsModal() {
  return (dispatch) =>
    dispatch({ type: customerConstants.DOCUMENTS_MODAL_CLOSE })
}

function clearSelectedCustomer() {
  return (dispatch) =>
    dispatch({ type: customerConstants.CLEAR_SELECTED_CUSTOMER })
}
