import { swapsConstants } from '../../constants/swaps_constants'

const initialState = {
  loadingResults: false,
  isCreatingReserve: false,
  swap: {},
  swaps: [],
}

export function swapsReducer(state = initialState, action) {
  switch (action.type) {
    case swapsConstants.GET_BY_ID_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case swapsConstants.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        swap: action.payload,
      }
    case swapsConstants.GET_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        swap: [],
      }

    case swapsConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loadingResults: true,
      }
    case swapsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingResults: false,
        swaps: action.payload,
      }
    case swapsConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingResults: false,
        swaps: [],
      }

    case swapsConstants.RESERVE_REQUEST:
      return {
        ...state,
        isCreatingReserve: true,
      }
    case swapsConstants.RESERVE_SUCCESS:
      return {
        ...state,
        isCreatingReserve: false,
      }
    case swapsConstants.RESERVE_FAILURE:
      return {
        ...state,
        error: action.error,
        isCreatingReserve: false,
      }

    default:
      return state
  }
}
