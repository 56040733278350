import i18next from 'i18next'
import { productConstants } from '../../constants/product_constants'
import { productService } from '../../services/productServices'
import { alertActions } from './alert_actions'

export const productActions = {
  getAll,
  deleteProduct,
  update,
  create,
}

function create(body) {
  return (dispatch) => {
    productService.create(body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.product.createSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.product.createError')))
      },
    )
  }
}

function update(id, body) {
  return (dispatch) => {
    productService.update(id, body).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.product.updateSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.product.updateError')))
      },
    )
  }
}

function deleteProduct(id) {
  return (dispatch) => {
    productService.deleteProd(id).then(
      (results) => {
        dispatch(getAll())
        dispatch(
          alertActions.success(i18next.t('services.product.deleteSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(i18next.t('services.product.deleteError')))
      },
    )
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())
    productService.getAll().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all supervisor action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: productConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: productConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: productConstants.GET_ALL_FAILURE, error }
  }
}
