import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { technicianActions } from '../../../redux/actions/technician_action'
import { zonesActions } from '../../../redux/actions/zones_actions'
import AssignedToTechnicians from '../../zones/assignedToTechnicians'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  textFieldSection: {
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const UpdateTechnician = ({
  isOpen,
  onClose,
  rowSelected,
  zonesReducer,
  stationassignToOperator,
  stationsunassignFromOperator,
  getZonesByOperatorId,
  update,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tableRef = createRef()

  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [phone, setphone] = useState('')

  const [errors, setErrors] = React.useState({
    lastName: { result: false, message: '' },
    firstName: { result: false, message: '' },
    phone: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.firstName = validations.required(firstName)
    newErrors.lastName = validations.required(lastName)
    newErrors.phone = validations.validatePhone(phone)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const { zonesByOperator } = zonesReducer
  const [tableDataZones, settableDataZones] = useState([])
  const [readOnlyConfiguration, setreadOnlyConfiguration] = useState(true)
  const [
    zonesAssignedToSelectedSupervisor,
    setzonesAssignedToSelectedSupervisor,
  ] = useState([])
  const [enterStationAssignedToSelected, setenterStationAssignedToSelected] =
    useState([])

  useEffect(() => {
    let extractStationsAssignedToSupervisors = []
    zonesByOperator.map(
      (i) =>
        enterStationAssignedToSelected.find((n) => n === i.id) &&
        extractStationsAssignedToSupervisors.push(i),
    )
    setzonesAssignedToSelectedSupervisor(extractStationsAssignedToSupervisors)

    settableDataZones([
      ...extractStationsAssignedToSupervisors,
      ...zonesByOperator,
    ])
  }, [zonesByOperator, enterStationAssignedToSelected])

  const handleEnter = () => {
    getZonesByOperatorId()
    setenterStationAssignedToSelected((rowSelected && rowSelected.zones) || [])

    const {
      firstName: firstNameProp,
      lastName: lastNameProp,
      phone: phoneProp,
    } = rowSelected

    setfirstName(firstNameProp)
    setlastName(lastNameProp)
    setphone(phoneProp)
  }

  const handleClose = () => {
    onClose()
    setreadOnlyConfiguration(true)
    setErrors({
      lastName: { result: false, message: '' },
      firstName: { result: false, message: '' },
      phone: { result: false, message: '' },
    })
  }

  const handleUpdate = () => {
    const { dataManager } = tableRef.current

    const filteredStationsChecked =
      (dataManager &&
        dataManager.data &&
        dataManager.data.filter((i) => i.tableData.checked)) ||
      []
    validateForm()
    if (isFormValid()) {
      const bodyUptade = {
        id: rowSelected.id,
        firstName,
        lastName,
        phone,
        zones: filteredStationsChecked.map((i) => i.id),
      }

      update(bodyUptade)
      handleClose()
    }
  }

  const handleConfirmBtn = () => {
    if (readOnlyConfiguration) setreadOnlyConfiguration((prev) => !prev)
    !readOnlyConfiguration && handleUpdate()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Actualización supervisor ({rowSelected && rowSelected.name})
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setreadOnlyConfiguration(true)}
          >
            {!readOnlyConfiguration && t('operator.assignment.cancelBtn')}
          </Button>
          <Button autoFocus color="inherit" onClick={handleConfirmBtn}>
            {readOnlyConfiguration
              ? t('operator.assignment.confirmBtnEdit')
              : t('operator.assignment.confirmBtnSave')}
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} className={classes.textFieldSection}>
        <Grid item md={2}>
          <TextField
            value={firstName}
            error={errors.firstName.message.length === 0 ? false : true}
            helperText={errors.firstName.message}
            onChange={(e) => {
              setfirstName(e.target.value)
              errors.firstName.message = ''
              setErrors(errors)
            }}
            label={t('technicians.dialogs.create.labels.name')}
            disabled={readOnlyConfiguration}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            value={lastName}
            error={errors.lastName.message.length === 0 ? false : true}
            helperText={errors.lastName.message}
            onChange={(e) => {
              setlastName(e.target.value)
              errors.lastName.message = ''
              setErrors(errors)
            }}
            label={t('technicians.dialogs.create.labels.lastName')}
            disabled={readOnlyConfiguration}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            value={phone}
            error={errors.phone.message.length === 0 ? false : true}
            helperText={errors.phone.message}
            onChange={(e) => {
              setphone(e.target.value)
              errors.phone.message = ''
              setErrors(errors)
            }}
            label={t('technicians.dialogs.create.labels.contactPhone')}
            disabled={readOnlyConfiguration}
          />
        </Grid>
      </Grid>
      <div>
        <Divider />
      </div>
      <div className={classes.root}>
        <AssignedToTechnicians
          tableRef={tableRef}
          data={
            readOnlyConfiguration
              ? zonesAssignedToSelectedSupervisor
              : tableDataZones
          }
          preSelectedRows={zonesAssignedToSelectedSupervisor}
          readOnlyConfiguration={readOnlyConfiguration}
        />
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { zonesReducer } = state
  return { zonesReducer }
}

const actionCreators = {
  getZonesByOperatorId: zonesActions.getZonesByOperatorId,
  update: technicianActions.update,
}

export default connect(mapState, actionCreators)(UpdateTechnician)
