import store from '../redux/store'

export const checkFisrtLogin = () => {
  const { operatorReducer } = store.getState()

  return (
    operatorReducer.hasOwnProperty('dataOperatorLogged') &&
    operatorReducer.dataOperatorLogged.canOperate
  )
}

export const getOperatorId = () => {
  const { authenticationReducer } = store.getState()
  const { operatorUser } = authenticationReducer.user || {
    operatorUser: { operatorCiaId: '' },
  }
  return (operatorUser && operatorUser.operatorCiaId) || null
}

export const getSupervisorOperatorCiaId = () => {
  const { authenticationReducer } = store.getState()
  return authenticationReducer.user?.supervisor?.operatorCiaId
}

store.subscribe(checkFisrtLogin)
