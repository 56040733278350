import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import AssignedToOperators from '../../stations/assignedToOperators'

import { connect } from 'react-redux'
import { stationsActions } from '../../../redux/actions/stations_actions'

import { isEqual } from '../../../helpers/compareArrays'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AssignmentStations = ({
  isOpen,
  onClose,
  rowSelected,
  getStationsAssignedToOperator,
  stationsReducer,
  stationassignToOperator,
  getAllUnassignedStations,
  stationsunassignFromOperator,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const { stationsAssignedToOperator, results, stationsUnassigned } =
    stationsReducer

  const [selectedStations, setselectedStations] = useState([])
  const [tableDataStations, settableDataStations] = useState([])
  const [readOnlyConfiguration, setreadOnlyConfiguration] = useState(true)

  useEffect(() => {
    settableDataStations([...stationsUnassigned, ...stationsAssignedToOperator])
  }, [stationsUnassigned, stationsAssignedToOperator])

  const handleEnter = () => {
    const { id } = rowSelected
    id && getStationsAssignedToOperator(id)
    // getAllStations();
    getAllUnassignedStations()
  }
  const handleClose = () => {
    onClose()
    setreadOnlyConfiguration(true)
  }

  const handleSelectedStationsUpdate = (rows) => {
    setselectedStations(rows)
  }

  const handleSaveAssignment = () => {
    if (!isEqual(tableDataStations, selectedStations)) {
      let stationToAssign = []
      let stationToUnassign = []

      stationsAssignedToOperator.map((i) => {
        !selectedStations.find((s) => s.id === i.id) &&
          stationToUnassign.push(i)
      })
      selectedStations.map((i) => {
        !stationsAssignedToOperator.find((s) => s.id === i.id) &&
          stationToAssign.push(i)
      })

      const bodyUnassignStation = {
        stations: stationToUnassign.map((i) => i.id),
        operatorCiaId: rowSelected.id,
      }
      const bodyAssignStation = {
        stations: stationToAssign.map((i) => i.id),
        operatorCiaId: rowSelected.id,
      }

      stationToAssign.length > 0 && stationassignToOperator(bodyAssignStation)
      stationToUnassign.length > 0 &&
        stationsunassignFromOperator(bodyUnassignStation)

      handleClose()
    }
  }

  const handleConfirmBtn = () => {
    setreadOnlyConfiguration((prev) => !prev)
    !readOnlyConfiguration && handleSaveAssignment()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('operator.assignment.titleDialog2')} ({rowSelected.name})
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setreadOnlyConfiguration(true)}
          >
            {!readOnlyConfiguration && t('operator.assignment.cancelBtn')}
          </Button>
          <Button autoFocus color="inherit" onClick={handleConfirmBtn}>
            {readOnlyConfiguration
              ? t('operator.assignment.confirmBtnEdit')
              : t('operator.assignment.confirmBtnSave')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <AssignedToOperators
          addTitle={false}
          onSelectionUpdate={handleSelectedStationsUpdate}
          data={
            readOnlyConfiguration
              ? stationsAssignedToOperator
              : tableDataStations
          }
          preSelectedRows={stationsAssignedToOperator}
          readOnlyConfiguration={readOnlyConfiguration}
        />
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}
const actionCreators = {
  getAllUnassignedStations: stationsActions.getAllUnassignedStations,
  getAllStations: stationsActions.getAllStations,
  stationsunassignFromOperator: stationsActions.unassignFromOperator,
  stationassignToOperator: stationsActions.assignToOperator,
  getStationsAssignedToOperator: stationsActions.getStationsAssignedToOperator,
}

export default connect(mapState, actionCreators)(AssignmentStations)
