import { uiConstants } from '../../constants/ui_constants'

import { history } from '../../helpers/history'

export const uiActions = {
  setOpenDrawer,
  setFilterTable,
  savePositionDraggablePosition,
  setDraggableDashoardIsOpen,
}

function setOpenDrawer(bool) {
  return { type: uiConstants.OPEN_DRAWER, bool }
}

function setFilterTable(obj) {
  const currentLocation = history.location.pathname.split('/:')[0]
  const object = { ...obj, currentLocation }
  return { type: uiConstants.SET_FILTER, object }
}

function savePositionDraggablePosition(pos) {
  return { type: uiConstants.SET_POSITION, pos }
}

function setDraggableDashoardIsOpen(bool) {
  return { type: uiConstants.SET_DRAGGABLE_DASHBOARD_IS_OPEN, bool }
}
