import React, { useEffect } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { stationsActions } from '../../redux/actions/stations_actions'

const AssignedToSupervisors = ({
  preSelectedRows,
  supervisorId,
  onselectedRowsUpdate,
  getAllStations,
  getStationsAssignedToOperator,
  stationsReducer,
  data,
  addTitle = true,
  readOnlyConfiguration,
  tableRef,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    getStationsAssignedToOperator()
  }, [])

  return (
    <CustomMaterialTable
      tableRef={tableRef}
      selection
      onSelectionChange={(rows) =>
        onselectedRowsUpdate && onselectedRowsUpdate(rows)
      }
      preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
      selection={!readOnlyConfiguration}
      title={addTitle ? t('station.assignedToSupervisors.table.title') : ''}
      columns={[
        /* {
                    title: t("station.assignedToSupervisors.table.columns.stationId"),
                    field: "id",
                }, */
        {
          title: t('station.assignedToSupervisors.table.columns.zone'),
          field: 'zoneName',
        },
        {
          title: t('station.assignedToSupervisors.table.columns.imei'),
          field: 'imei',
        },
        {
          title: t('station.assignedToSupervisors.table.columns.totalModules'),
          field: 'totalModules',
        },
        {
          title: t('station.assignedToSupervisors.table.columns.outOfService'),
          field: 'outOfService',
          addlookup: true,
        },
        {
          title: t('station.assignedToSupervisors.table.columns.chargePrice'),
          field: 'priceByKwhDeliveryFromCustomer',
        },
        // {
        //   title: t("station.assignedToSupervisors.table.columns.location"),
        //   field: "location",
        // },
      ]}
      data={data || stationsReducer.stationsAssignedToOperator}
    />
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}

const actionCreators = {
  getAllStations: stationsActions.getAllStations,
  getStationsAssignedToOperator: stationsActions.getStationsAssignedToOperator,
}

export default connect(mapState, actionCreators)(AssignedToSupervisors)
