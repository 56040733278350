import React, { useEffect, useState } from 'react'

import useStyles from './styles'

import { useTranslation } from 'react-i18next'
import CustomMaterialTable from '../shared/customMaterialTable'

import { connect } from 'react-redux'
import { discountsActions } from '../../redux/actions/discounts_actions'

import { Fragment } from 'react'
import CreateRate from '../discounts/dialogs/create'

import { formatDateToDisplay } from '../shared/formatDate'

const DiscountsByCustomers = ({
  getByCustomer,
  discountsReducer,
  customer,
  addActionCreateDiscount = false,
  addTitle = true,
  data,
  onSelectionUpdate,
  preSelectedRows,
  readOnlyConfiguration,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isCreateDiscountDialogOpen, setisCreateDiscountDialogOpen] =
    useState(false)

  useEffect(() => {
    if (customer) {
      const { id } = customer
      id && getByCustomer(id)
    }
  }, [])

  return (
    <Fragment>
      <CustomMaterialTable
        title={addTitle ? t('discounts.byCustomers.table.title') : ''}
        onSelectionChange={(rows) => onSelectionUpdate(rows)}
        selection={!readOnlyConfiguration}
        preSelectedRows={readOnlyConfiguration ? [] : preSelectedRows}
        columns={[
          {
            title: t('discounts.byCustomers.table.columns.startDate'),
            field: 'applySince',
            render: (rowData) => formatDateToDisplay(rowData.applySince),
          },
          {
            title: t('discounts.byCustomers.table.columns.finishDate'),
            field: 'applyUntil',
            render: (rowData) => formatDateToDisplay(rowData.applyUntil),
          },
          {
            title: t('discounts.byCustomers.table.columns.percentaje'),
            field: 'percent',
          },
        ]}
        data={data || discountsReducer.byCustomer}
        actions={[
          {
            icon: 'add',
            isFreeAction: true,
            tooltip: t('discounts.page.table.actions.add'),
            onClick: (event, rowData) => setisCreateDiscountDialogOpen(true),
            hidden: !addActionCreateDiscount,
          },
        ]}
      />
      <CreateRate
        customer={customer}
        isOpen={isCreateDiscountDialogOpen}
        onClose={() => setisCreateDiscountDialogOpen(false)}
      />
    </Fragment>
  )
}

function mapState(state) {
  const { discountsReducer } = state
  return { discountsReducer }
}
const actionCreators = {
  getByCustomer: discountsActions.getByCustomer,
}

export default connect(mapState, actionCreators)(DiscountsByCustomers)
