import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { roleLinkList } from '../../helpers/drawerList'
import { alertActions } from '../../redux/actions/alert_actions'
import { uiActions } from '../../redux/actions/ui_actions'
import { userActions } from '../../redux/actions/user_actions'
import ListItems from './listItems'
import MenuUser from './menu'
import useStyles from './styles'

import PersonalData from './dialog/personalData'
import SelectRole from './dialog/selectRole'

import Spinner from '../shared/dialogSpinner'

import { useSnackbar } from 'notistack'
import DraggableDialog from '../stations/dragable'

const Dashboardview = (props) => {
  const classes = props.styles
  const { alertReducer, authenticationReducer } = props
  const { t } = props
  const { usersGroup: { maxRole } = '' } = authenticationReducer
  const [showDialogSelectorRoles, setshowDialogSelectorRoles] = useState(false)
  const [isDialogPersonalDataOpen, setisDialogPersonalDataOpen] =
    useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const handleChangeRole = () => {
    setshowDialogSelectorRoles(true)
  }
  const handleOpenPersonalData = () => {
    setisDialogPersonalDataOpen(true)
  }

  const handleSelected = (selected) => {
    setshowDialogSelectorRoles(false)
    props.selectRole(selected, true)
  }

  const lastAlert =
    alertReducer.messages.length > 0
      ? alertReducer.messages[alertReducer.messages.length - 1]
      : null

  return (
    <div className={classes.root}>
      <DraggableDialog />
      <CssBaseline />
      <Spinner />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, props.open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              props.open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Battery Station
          </Typography>

          <MenuUser
            t={t}
            onChangeRole={handleChangeRole}
            onOpenPersonalData={handleOpenPersonalData}
            logout={props.logout}
            // name={authenticationReducer.user.name || authenticationReducer.user.email}
            name={
              authenticationReducer.user && authenticationReducer.user.maxRole
            }
            cssClass={classes.username}
            changeLanguage={props.changeLanguage}
          />
        </Toolbar>
      </AppBar>
      <SelectRole
        isOpen={showDialogSelectorRoles}
        data={authenticationReducer.usersGroup} //
        onSelected={handleSelected}
        onClose={() => setshowDialogSelectorRoles(false)}
      />
      <PersonalData
        isOpen={isDialogPersonalDataOpen}
        onClose={() => setisDialogPersonalDataOpen(false)}
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !props.open && classes.drawerPaperClose,
          ),
        }}
        open={props.open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={props.handleDrawerClose}>
            <ChevronLeftIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItems t={t} />
        </List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <main
        id="dashboard"
        className={classes.content}
        style={{
          overflow: props.customerReducer.validationModalOpened
            ? 'hidden'
            : 'auto',
        }}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper elevation={0}>
                {lastAlert &&
                  enqueueSnackbar(lastAlert.message, {
                    variant: lastAlert.type,
                    autoHideDuration: 3000,
                    onClose: props.handleCloseAlert,
                  })
                    .toString()
                    .substring(0, 0)}
                {authenticationReducer.infoPending ||
                authenticationReducer.pendingSelectUserRole
                  ? null
                  : props.component}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>{/* <Copyright /> */}</Box>
        </Container>
      </main>
    </div>
  )
}

function Hook(props) {
  const classes = useStyles()
  const { uiReducer, setOpenDrawer } = props
  const [open, setOpen] = React.useState(false)
  const [openAlert] = React.useState(false)
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }
  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    props.clear()
  }

  return (
    <Dashboardview
      styles={classes}
      component={props.component}
      open={uiReducer.isDrawerOpen}
      logout={props.logout}
      alertReducer={props.alertReducer}
      authenticationReducer={props.authenticationReducer}
      setOpen={setOpen}
      openAlert={openAlert}
      {...props}
      handleDrawerOpen={handleDrawerOpen}
      handleDrawerClose={handleDrawerClose}
      handleCloseAlert={handleCloseAlert}
    />
  )
}

function mapState(state) {
  const { alertReducer, authenticationReducer, uiReducer, customerReducer } =
    state
  return { alertReducer, authenticationReducer, uiReducer, customerReducer }
}

const actionCreators = {
  clear: alertActions.clear,
  logout: userActions.logout,
  selectRole: userActions.selectRole,
  setOpenDrawer: uiActions.setOpenDrawer,
}

export default compose(
  withTranslation('common'),
  connect(mapState, actionCreators),
)(Hook)
