import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  isManager,
  isManagerReadOnly,
  isOperatorManager,
  isSacUser,
  isSupervisor,
  isTechnician,
} from '../../helpers/roles'
import Dashboard from '../dashboard'
import MapView from '../map/page'
import HomeView from './page'
import PageOperator from './pageOperator'
import PageSupervisor from './pageSupervisor'

const Home = () => {
  const renderComponentView = () => {
    if (isManager() || isManagerReadOnly() || isSacUser()) return <HomeView />
    if (isOperatorManager()) return <PageOperator />
    if (isSupervisor()) return <PageSupervisor />
    if (isTechnician()) return <MapView />
  }

  return <Dashboard component={renderComponentView()} />
}

export default Home
