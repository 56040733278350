import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { validations } from '../../../helpers/validation'
import { ratesActions } from '../../../redux/actions/rates_actions'
import { zonesActions } from '../../../redux/actions/zones_actions'

const RatesCreateDialog = ({
  isOpen,
  onClose,
  createRate,
  getAllZones,
  zonesReducer,
  productReducer,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const [zone, setZone] = useState('')
  const [name, setName] = useState('')
  const [fixedInvoiceProductCode, setFixedInvoiceProductCode] = useState('')
  const [variableInvoiceProductCode, setVariableInvoiceProductCode] =
    useState('')
  const [depositInvoiceProductCode, setDepositInvoiceProductCode] = useState('')
  const [includedBatteries, setIncludedBatteries] = useState('')
  const [validityMonths, setValidityMonths] = useState(null)
  const [permanenceMonths, setPermanenceMonths] = useState('')
  const [swapMarginMinutes, setSwapMarginMinutes] = useState(null)
  const [visibleApp, setVisibleApp] = useState(false)
  const [regenerateSwaps, setRegenerateSwaps] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [finishDate, setFinishDate] = useState('')

  const [errors, setErrors] = React.useState({
    zone: { result: false, message: '' },
    name: { result: false, message: '' },
    fixedInvoiceProductCode: { result: false, message: '' },
    variableInvoiceProductCode: { result: false, message: '' },
    depositInvoiceProductCode: { result: true, message: '' },
    includedBatteries: { result: false, message: '' },
    validityMonths: { result: true, message: '' },
    permanenceMonths: { result: false, message: '' },
    swapMarginMinutes: { result: false, message: '' },
    startDate: { result: false, message: '' },
    finishDate: { result: false, message: '' },
  })

  const validateForm = () => {
    let newErrors = errors
    newErrors.zone = validations.required(zone)
    newErrors.name = validations.required(name)
    newErrors.fixedInvoiceProductCode = validations.required(
      fixedInvoiceProductCode,
    )
    newErrors.variableInvoiceProductCode = validations.required(
      variableInvoiceProductCode,
    )
    newErrors.depositInvoiceProductCode = validations.required(
      depositInvoiceProductCode,
    )
    newErrors.includedBatteries = validations.validateHigherInt(
      1,
      includedBatteries,
    )
    newErrors.permanenceMonths = validations.required(permanenceMonths)
    if (!regenerateSwaps)
      newErrors.swapMarginMinutes = validations.required(swapMarginMinutes)
    else newErrors.swapMarginMinutes = { result: true, message: '' }
    newErrors.startDate = validations.required(startDate)
    newErrors.finishDate = validations.required(finishDate)
    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((element) => {
      if (!errors[element].result) valid = false
    })
    return valid
  }

  const handleClose = () => {
    onClose()
    setZone('')
    setName('')
    setIncludedBatteries('')
    setValidityMonths(null)
    setStartDate('')
    setFinishDate('')
    setPermanenceMonths('')
    setSwapMarginMinutes('')
    setVisibleApp(false)
    setRegenerateSwaps(false)
    setFixedInvoiceProductCode('')
    setVariableInvoiceProductCode('')
    setDepositInvoiceProductCode('')
    setErrors({
      zone: { result: false, message: '' },
      name: { result: false, message: '' },
      fixedInvoiceProductCode: { result: false, message: '' },
      variableInvoiceProductCode: { result: false, message: '' },
      depositInvoiceProductCode: { result: true, message: '' },
      includedBatteries: { result: false, message: '' },
      validityMonths: { result: true, message: '' },
      permanenceMonths: { result: false, message: '' },
      swapMarginMinutes: { result: false, message: '' },
      startDate: { result: false, message: '' },
      finishDate: { result: false, message: '' },
    })
  }

  const handleEnter = () => {
    getAllZones()
  }

  const handleConfirm = () => {
    validateForm()

    if (isFormValid()) {
      const body = {
        zoneId: zone.id,
        name,
        depositProductCode: depositInvoiceProductCode,
        fixedProductCode: fixedInvoiceProductCode,
        variableProductCode: variableInvoiceProductCode,
        batteriesNumber: parseInt(includedBatteries),
        ...(validityMonths && { validityMonths: parseInt(validityMonths) }),
        permanenceMonths: parseInt(permanenceMonths),
        swapMarginMinutes: parseInt(swapMarginMinutes ?? 1),
        applySince: startDate,
        applyUntil: finishDate,
        isVisibleInApp: visibleApp,
        regenerateSwaps: regenerateSwaps,
      }

      createRate(body)
      handleClose()
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      onEnter={handleEnter}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t('rates.create.dialogTitle')}
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                options={zonesReducer.results}
                getOptionLabel={(option) => option.name}
                value={zone}
                onChange={(_, value) => {
                  setZone(value)
                  errors.zone.message = ''
                  setErrors(errors)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('rates.create.labels.zone')}
                    error={errors.zone.message.length === 0 ? false : true}
                    helperText={errors.zone.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibleApp}
                    onChange={() => setVisibleApp(!visibleApp)}
                    color="primary"
                  />
                }
                label={t('rates.create.labels.visible')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={regenerateSwaps}
                    onChange={() => {
                      setRegenerateSwaps(!regenerateSwaps)
                      errors.swapMarginMinutes.message = ''
                    }}
                    color="primary"
                  />
                }
                label={t('rates.create.labels.regenerateSwaps')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label={t('rates.create.labels.name')}
                value={name}
                error={errors.name.message.length === 0 ? false : true}
                helperText={errors.name.message}
                onChange={(e) => {
                  setName(e.target.value)
                  errors.name.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                required
                fullWidth
                label={t('rates.create.labels.fixedInvoiceProductCode')}
                value={fixedInvoiceProductCode}
                error={
                  errors.fixedInvoiceProductCode.message.length === 0
                    ? false
                    : true
                }
                helperText={errors.fixedInvoiceProductCode.message}
                onChange={(e) => {
                  setFixedInvoiceProductCode(e.target.value)
                  errors.fixedInvoiceProductCode.message = ''
                  setErrors(errors)
                }}
              >
                {productReducer.results.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.code + ' ' + option.price + '€'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                required
                fullWidth
                label={t('rates.create.labels.variableInvoiceProductCode')}
                value={variableInvoiceProductCode}
                error={
                  errors.variableInvoiceProductCode.message.length === 0
                    ? false
                    : true
                }
                helperText={errors.variableInvoiceProductCode.message}
                onChange={(e) => {
                  setVariableInvoiceProductCode(e.target.value)
                  errors.variableInvoiceProductCode.message = ''
                  setErrors(errors)
                }}
              >
                {productReducer.results.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.code + ' ' + option.price + '€'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label={t('rates.create.labels.depositInvoiceProductCode')}
                value={depositInvoiceProductCode}
                error={
                  errors.depositInvoiceProductCode.message.length === 0
                    ? false
                    : true
                }
                helperText={errors.depositInvoiceProductCode.message}
                onChange={(e) => {
                  setDepositInvoiceProductCode(e.target.value)
                  errors.depositInvoiceProductCode.message = ''
                  setErrors(errors)
                }}
              >
                {productReducer.results.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.code + ' ' + option.price + '€'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label={t('rates.create.labels.permanenceMonths')}
                value={permanenceMonths}
                error={
                  errors.permanenceMonths.message.length === 0 ? false : true
                }
                helperText={errors.permanenceMonths.message}
                type="number"
                onChange={(e) => {
                  setPermanenceMonths(e.target.value)
                  errors.permanenceMonths.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={!regenerateSwaps}
                fullWidth
                type="number"
                label={t('rates.create.labels.swapMarginMinutes')}
                value={swapMarginMinutes}
                error={
                  errors.swapMarginMinutes.message.length === 0 ? false : true
                }
                helperText={errors.swapMarginMinutes.message}
                disabled={regenerateSwaps}
                onChange={(e) => {
                  setSwapMarginMinutes(e.target.value)
                  errors.swapMarginMinutes.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label={t('rates.create.labels.includedBatteries')}
                value={includedBatteries}
                error={
                  errors.includedBatteries.message.length === 0 ? false : true
                }
                helperText={errors.includedBatteries.message}
                onChange={(e) => {
                  setIncludedBatteries(e.target.value)
                  errors.includedBatteries.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('rates.create.labels.validityMonths')}
                value={validityMonths}
                error={
                  errors.validityMonths.message.length === 0 ? false : true
                }
                helperText={errors.validityMonths.message}
                type="number"
                onChange={(e) => {
                  setValidityMonths(e.target.value)
                  errors.validityMonths.message = ''
                  setErrors(errors)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                type="date"
                label={t('rates.create.labels.startDate')}
                value={startDate}
                error={errors.startDate.message.length === 0 ? false : true}
                helperText={errors.startDate.message}
                onChange={(e) => {
                  setStartDate(e.target.value)
                  errors.startDate.message = ''
                  setErrors(errors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                type="date"
                fullWidth
                label={t('rates.create.labels.finishDate')}
                value={finishDate}
                error={errors.finishDate.message.length === 0 ? false : true}
                helperText={errors.finishDate.message}
                onChange={(e) => {
                  setFinishDate(e.target.value)
                  errors.finishDate.message = ''
                  setErrors(errors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {t('rates.create.cancelBtn')}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t('rates.create.confirmBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function mapState(state) {
  const { zonesReducer } = state
  return { zonesReducer }
}

const actionCreators = {
  createRate: ratesActions.createRate,
  getAllZones: zonesActions.getAllZones,
}

export default connect(mapState, actionCreators)(RatesCreateDialog)
